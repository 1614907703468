import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  currentCustomerSelector,
  CustomersDataSelector,
  manageAction,
  SitesDataSelector,
} from "store/modules/manage";
import { manageAsyncAction } from "store/modules/manage/saga";
import { useManageData, useComponentId } from "hooks";
import { MANAGE_STATUS } from "assets/constants/string";

const useManageCustomerData = () => {
  const componentId = useComponentId();
  const dispatch = useAppDispatch();
  const { status, search } = useManageData();
  const [customersDataLoading, customersData, customersDataError] = [
    useAppSelector(CustomersDataSelector.loading),
    useAppSelector(CustomersDataSelector.data),
    useAppSelector(CustomersDataSelector.error),
  ];
  const currentCustomer = useAppSelector(currentCustomerSelector);

  const options = useMemo(() => {
    return _.map(customersData, ({ id, name }) => ({
      label: name,
      value: String(id),
    }));
  }, [customersData]);

  const currentCustomerData = useMemo(() => {
    return _.head(
      _.map(
        _.filter(customersData, ({ id }) => id === +currentCustomer),
        ({ id, name, address, maxAccount, numUsers }) => {
          return {
            customerId: id,
            customerName: name,
            address,
            accountsCount: `${numUsers} / ${maxAccount}`,
          };
        }
      )
    );
  }, [customersData, currentCustomer]);

  const [sitesData] = [useAppSelector(SitesDataSelector.data)];

  const getAllCustomers = useCallback(() => {
    dispatch(manageAsyncAction.getAllCustomers.request({}));
  }, [dispatch]);

  const getCustomerWithSites = useCallback(
    ({ customerId }: { customerId: number }) => {
      dispatch(manageAsyncAction.getCustomerWithSites.request({ customerId }));
    },
    [dispatch]
  );

  const setCurrentCustomer = useCallback(
    (customerId: string) => {
      dispatch(manageAction.setCurrentCustomer(customerId));
    },
    [dispatch]
  );

  const addCustomer = useCallback(
    ({
      address,
      customerName,
      corpNum,
      maxAccount,
      repName,
      repDivision,
      repOfficePhone,
      repCellPhone,
      repEmail,
      memo,
      managerName,
      useService = false,
      numUsers = 0,
      numSites = 0,
      numServices = 0,
      onSuccess,
      onFailure,
    }: {
      address: string;
      customerName: string; //required
      corpNum: string; //required
      maxAccount: number;
      repName: string;
      repDivision: string;
      repOfficePhone: string;
      repCellPhone: string;
      repEmail?: string;
      memo?: string;
      managerName?: string;
      useService?: boolean;
      numUsers?: number;
      numSites?: number;
      numServices?: number;
      onSuccess: () => void;
      onFailure: () => void;
    }) => {
      dispatch(
        manageAsyncAction.addCustomer.request({
          address,
          customerName,
          corpNum,
          maxAccount,
          repName,
          repDivision,
          repOfficePhone,
          repCellPhone,
          repEmail,
          memo,
          managerName,
          useService,
          numUsers,
          numSites,
          numServices,
          onSuccess,
          onFailure,
        })
      );
    },
    [dispatch]
  );

  const setCustomer = useCallback(
    ({
      customerId,
      customerName,
      address,
      corpNum,
      repName,
      repDivision,
      repOfficePhone,
      repCellPhone,
      repEmail,
      memo,
      maxAccount,
      managerName,
      onSuccess,
      onFailure,
    }: {
      customerId: number;
      customerName: string;
      address: string;
      corpNum: string;
      repName: string;
      repDivision: string;
      repOfficePhone: string;
      repCellPhone: string;
      repEmail: string;
      memo: string;
      maxAccount: number;
      managerName: string;
      onSuccess: () => void;
      onFailure: () => void;
    }) => {
      dispatch(
        manageAsyncAction.setCustomer.request({
          customerId,
          customerName,
          address,
          corpNum,
          repName,
          repDivision,
          repOfficePhone,
          repCellPhone,
          repEmail,
          memo,
          maxAccount,
          managerName,
          onSuccess,
          onFailure,
        })
      );
    },
    [dispatch]
  );

  const { customersTableData } = useMemo(() => {
    const customersTableOriginalData = _.map(
      customersData,
      (
        {
          name,
          useService,
          numServices,
          id,
          numSites,
          repName,
          managerName,
          lastModifiedDate,
        },
        idx
      ) => {
        useService = numServices ? true : false;
        return {
          idx,
          key: `${componentId}-customers-table-data-${idx}`,
          customerId: id,
          customerName: name,
          numSites,
          managerName,
          repName,
          useService,
          lastModifiedDate,
        };
      }
    );

    const customersTableData = _.filter(
      _.filter(customersTableOriginalData, ({ useService }) => {
        if (status === MANAGE_STATUS.ALL) return true;
        if (status === MANAGE_STATUS.ENABLE) return useService;
        if (status === MANAGE_STATUS.DISABLE) return !useService;
      }),
      ({
        customerName,
        repName,
      }: {
        customerName: string;
        repName: string;
      }) => {
        return _.includes(customerName, search) || _.includes(repName, search);
      }
    );

    return { customersTableData };
  }, [customersData, search, status, componentId]);

  const { customerDetailFormData, customerDetailSitesData } = useMemo(() => {
    const customerDetailFormData = _.head(
      _.map(
        customersData,
        ({
          address,
          corpNum,
          managerName,
          maxAccount,
          memo,
          name,
          repName,
          repEmail,
          repCellPhone,
          repDivision,
          repOfficePhone,
        }) => ({
          customerName: name,
          address,
          corpNum,
          maxAccount,
          repName,
          repEmail,
          repCellPhone,
          repOfficePhone,
          repDivision,
          managerName,
          memo,
        })
      )
    );

    const customerDetailSitesData = _.map(
      sitesData,
      ({ cuid, name, category, url }, idx) => ({
        idx,
        key: `${componentId}-customer-detail-sites-table-data-${idx}`,
        cuid,
        siteName: name,
        url,
        category,
      })
    );

    return { customerDetailFormData, customerDetailSitesData };
  }, [customersData, sitesData, componentId]);

  return {
    customersDataLoading,
    customersTableData,
    customersData,
    customerDetailFormData,
    customerDetailSitesData,
    currentCustomer,
    currentCustomerData,
    options,
    setCurrentCustomer,
    addCustomer,
    setCustomer,
    getAllCustomers,
    getCustomerWithSites,
  };
};
export default useManageCustomerData;
