import { createAsyncAction } from "typesafe-actions";
import type { ManageRequest } from "types/api";
import { ManageService } from "apis/services";
import { AxiosResponse } from "axios";
import { put, takeLatest } from "@redux-saga/core/effects";
import type { APIError } from "types/api";
import { COMPLETE, INCOMPLETE } from "assets/constants/string";
import _ from "lodash";
import type {
  ContractStatus,
  LoggerStatus,
  APIStatus,
  ReportStatus,
} from "types/home";
import { callWrapperSaga } from "utils/callWrapperSaga";

export const GET_CONTRACT_STATUS = `home/GET_CONTRACT_STATUS`;
export const GET_CONTRACT_STATUS_SUCCESS = `home/GET_CONTRACT_STATUS_SUCCESS`;
export const GET_CONTRACT_STATUS_ERROR = `home/GET_CONTRACT_STATUS_ERROR`;


export const getContractStatus = createAsyncAction(
  GET_CONTRACT_STATUS,
  GET_CONTRACT_STATUS_SUCCESS,
  GET_CONTRACT_STATUS_ERROR
)<ManageRequest, Pick<ContractStatus, "status">, APIError>();

function* getContractStatusSaga(
  action: ReturnType<typeof getContractStatus.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      ManageService.fetchSite,
      action.payload
    );
    const { useService: totalService, services } = data;

    const serviceStatus = _.reduce(
      _.map(services, ({ type, useService }) => ({ [type]: useService })),
      (serviceStatus, service) => {
        return { ...serviceStatus, ...service };
      },
      {}
    );

    yield put(
      getContractStatus.success({
        status: {
          service: totalService ? COMPLETE : INCOMPLETE,
          rec: _.get(serviceStatus, "rec") ? COMPLETE : INCOMPLETE,
          push: _.get(serviceStatus, "push") ? COMPLETE : INCOMPLETE,
          onSite: _.get(serviceStatus, "onSite") ? COMPLETE : INCOMPLETE,
          rts: _.get(serviceStatus, "streaming") ? COMPLETE : INCOMPLETE,
          etc: _.get(serviceStatus, "etc") ? COMPLETE : INCOMPLETE,
        },
      })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getContractStatus.failure({ data, status }));
  }
}

export const GET_LOGGER_STATUS = `home/GET_LOGGER_STATUS`;
export const GET_LOGGER_STATUS_SUCCESS = `home/GET_LOGGER_STATUS_SUCCESS`;
export const GET_LOGGER_STATUS_ERROR = `home/GET_LOGGER_STATUS_ERROR`;

export const getLoggerStatus = createAsyncAction(
  GET_LOGGER_STATUS,
  GET_LOGGER_STATUS_SUCCESS,
  GET_LOGGER_STATUS_ERROR
)<
  ManageRequest & { todayStr: string },
  Pick<LoggerStatus, "status">,
  APIError
>();

function* getLoggerStatusSaga(
  action: ReturnType<typeof getLoggerStatus.request>
) {
  try {
    const { cuid, todayStr } = action.payload;
    const { data } = yield callWrapperSaga(
      ManageService.fetchLogCollectStatusOfSite,
      {
        cuid,
      }
    );
    const todayLogs = _.filter(data, (log) => log.dateKey === todayStr);
    const logFailed = _.some(
      todayLogs,
      (log) => log.errorCount / log.rowCount >= 0.5
    );
    yield put(
      getLoggerStatus.success({
        status: _.isEmpty(todayLogs) || logFailed ? INCOMPLETE : COMPLETE,
      })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getLoggerStatus.failure({ data, status }));
  }
}

export const GET_API_STATUS = `home/GET_API_STATUS`;
export const GET_API_STATUS_SUCCESS = `home/GET_API_STATUS_SUCCESS`;
export const GET_API_STATUS_ERROR = `home/GET_API_STATUS_ERROR`;

export const getAPIStatus = createAsyncAction(
  GET_API_STATUS,
  GET_API_STATUS_SUCCESS,
  GET_API_STATUS_ERROR
)<ManageRequest, Pick<APIStatus, "status">, APIError>();

function* getAPIStatusSaga(action: ReturnType<typeof getAPIStatus.request>) {
  try {
    const { data } = yield callWrapperSaga(
      ManageService.fetchAllTasks,
      {} as ManageRequest
    );

    const { cuid } = action.payload;
    yield put(
      getAPIStatus.success({
        status: _.some(
          data,
          (task) => task.cuid === cuid && task.type === "rec"
        )
          ? COMPLETE
          : INCOMPLETE,
      })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getAPIStatus.failure({ data, status }));
  }
}

export const GET_REPORT_STATUS = `home/GET_REPORT_STATUS`;
export const GET_REPORT_STATUS_SUCCESS = `home/GET_REPORT_STATUS_SUCCESS`;
export const GET_REPORT_STATUS_ERROR = `home/GET_REPORT_STATUS_ERROR`;

export const getReportStatus = createAsyncAction(
  GET_REPORT_STATUS,
  GET_REPORT_STATUS_SUCCESS,
  GET_REPORT_STATUS_ERROR
)<
  ManageRequest & { todayStr: string },
  Pick<ReportStatus, "status">,
  APIError
>();

function* getReportStatusSaga(
  action: ReturnType<typeof getReportStatus.request>
) {
  //받는 데이터 Form이 getLoggerStatusSaga와 같이서 일시적으로 같은 api호출 중
  try {
    const { cuid, todayStr } = action.payload;
    // const { data } = yield callWrapperSaga(
    //   ManageService.fetchReportStatusOfSite,
    //   {
    //     cuid,
    //   }
    // );

    const { data } = yield callWrapperSaga(
      ManageService.fetchLogCollectStatusOfSite,
      {
        cuid,
      }
    );

    const todayReports = _.filter(
      data,
      (report) => report.dateKey === todayStr // | report.dateKey / 100
    );
    const ReportFailed = _.some(
      todayReports,
      (report) => report.errorCount / report.rowCount >= 0.5
    );

    yield put(
      getReportStatus.success({
        status: _.isEmpty(todayReports) || ReportFailed ? INCOMPLETE : COMPLETE,
      })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getReportStatus.failure({ data, status }));
  }
}

export const homeAsyncAction = {
  getContractStatus,
  getLoggerStatus,
  getAPIStatus,
  getReportStatus,
};

export default function* homeSaga() {
  yield takeLatest(GET_CONTRACT_STATUS, getContractStatusSaga);
  yield takeLatest(GET_LOGGER_STATUS, getLoggerStatusSaga);
  yield takeLatest(GET_API_STATUS, getAPIStatusSaga);
  yield takeLatest(GET_REPORT_STATUS, getReportStatusSaga);
}
