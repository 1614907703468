import Client from "apis";
import {
  BrandCategoryListRequest,
  ProductListRequest,
  ProductTransitionChartRequest,
  ProductPerformanceChartRequest,
} from "types/api";

class ProductReportService {
  static fetchBrandCategoryListResult = async (
    productRequest: BrandCategoryListRequest
  ) => {
    return await Client.publicInstance.get(`${productRequest.apiUrl}`);
  };

  static fetchProductListResult = async (
    productRequest: ProductListRequest
  ) => {
    return await Client.publicInstance.get(`${productRequest.apiUrl}`);
  };

  static fetchProductTransitionChartResult = async (
    productRequest: ProductTransitionChartRequest
  ) => {
    return await Client.publicInstance.get(`${productRequest.apiUrl}`);
  };

  static fetchProductPerformanceChartResult = async (
    productRequest: ProductPerformanceChartRequest
  ) => {
    return await Client.publicInstance.get(`${productRequest.apiUrl}`);
  };
}

export default ProductReportService;
