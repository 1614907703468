import {
  createSlice,
  createSelector,
  PayloadAction,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  CriteriaResponse,
  ItemSegInfoResponse,
  SelectedInputOptions,
  SegBestResponse,
  SimilarSegItemsRequest,
  SimilarSegResponse,
  AssociatedSegResponse,
  BlogV2State,
} from "types/blogV2";
import { VideoStatus } from "types/blog";
import { APIError } from "types/api";
import {
  fetchCriteria,
  fetchItemSegInfo,
  fetchSegBest,
  fetchAssociatedSeg,
  fetchSimilarSeg,
  fetchHexaImageUrl,
  fetchBarImageUrl,
  fetchSegBestImageUrl,
  fetchSimilarImageUrl,
  fetchAccosiateImageUrl,
  fetchVideo,
} from "./saga";
import { notification } from "antd";
import blogService from "apis/services/blogService";
import blogService02 from "apis/services/blogServiceV2";
export const BLOGV2 = "blogV2";

const initialState: BlogV2State = {
  imageUploadResponse: {
    hexaUrl: {
      data: "",
      status: "request",
      error: null,
    },
    barUrl: {
      data: "",
      status: "request",
      error: null,
    },
    segBestUrl: {
      data: "",
      status: "request",
      error: null,
    },
    similarSegUrl: {
      data: "",
      status: "request",
      error: null,
    },
    associatedSegUrl: {
      data: "",
      status: "request",
      error: null,
    },
  },
  userText: {
    segBest: "",
    similar: "",
  },
  keepPage: 1,
  language: "ko",
  segTabIndex: 1,
  openModal: "none",
  criteria: [],
  request: {
    date: undefined,
    targetType: undefined,
    targetName: undefined,
    objectId: undefined,
  },
  itemSegInfo: null,
  associatedSeg: null,
  useAssociatedSection: true,
  segBest: null,
  similarSeg: null,
  useSimilarSection: true,
  useBestSection: true,
  htmlSource: "",
  currentState: null,
  currentVideo: null,
  videoThumbnail: "",
  videoText: "",
};

const blogSlice02 = createSlice({
  name: BLOGV2,
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
    },
    setSegBestText(state, action: PayloadAction<string>) {
      state.userText.segBest = action.payload;
    },
    setSimilarText(state, action: PayloadAction<string>) {
      state.userText.similar = action.payload;
    },
    setClearCurrentState(state, action: PayloadAction<undefined>) {
      state.segTabIndex = 1;
      state.request = {
        date: undefined,
        targetType: undefined,
        targetName: undefined,
        objectId: undefined,
      };
      state.itemSegInfo = null;
      state.segBest = null;
      state.similarSeg = null;
      state.useSimilarSection = true;
      state.useBestSection = true;
      state.htmlSource = "";
      state.currentState = null;
      state.userText = {
        segBest: "",
        similar: "",
      };
      state.language = "ko";
      state.associatedSeg = null;
    },
    setCurrentState(state, action: PayloadAction<undefined>) {
      const {
        imageUploadResponse,
        segTabIndex,
        criteria,
        itemSegInfo,
        request,
        similarSeg,
        useSimilarSection,
        segBest,
        useBestSection,
        htmlSource,
        userText,
        language,
        associatedSeg,
      } = state;
      state.currentState = {
        imageUploadResponse,
        segTabIndex,
        criteria,
        itemSegInfo,
        request,
        similarSeg,
        useSimilarSection,
        segBest,
        useBestSection,
        htmlSource,
        userText,
        language,
        associatedSeg,
      };
    },
    setPersistStateDraw(state, action: PayloadAction<undefined>) {
      if (!state.currentState) {
        return;
      }
      const { currentState } = state;
      state.criteria = currentState.criteria;
      state.itemSegInfo = currentState.itemSegInfo;
      state.segBest = currentState.segBest;
      state.similarSeg = currentState.similarSeg;
      state.imageUploadResponse = currentState.imageUploadResponse;
      state.useBestSection = currentState.useBestSection;
      state.useSimilarSection = currentState.useSimilarSection;
      state.htmlSource = currentState.htmlSource;
      state.request = currentState.request;
      state.segTabIndex = currentState.segTabIndex;
      state.userText = currentState?.userText;
      state.language = currentState.language;
      state.associatedSeg = currentState.associatedSeg;
    },
    setKeepPage(state, action: PayloadAction<number>) {
      state.keepPage = action.payload;
    },
    setSegTabIndex(state, action: PayloadAction<number>) {
      state.segTabIndex = action.payload;
    },
    setRequest(state, action: PayloadAction<SelectedInputOptions>) {
      state.request = action.payload;
    },
    setActiveSegBest(state, action: PayloadAction<number>) {
      if (!state.segBest) {
        return;
      }

      state.segBest?.map((el, index) => {
        if (index === action.payload) {
          if (
            state.segBest &&
            state.segBest?.filter((el) => el.active).length > 3
          ) {
            if (el.active === false) {
              return notification.error({
                message: "최대 4개까지 선택가능",
                placement: "bottomRight",
              });
            } else {
              return (el.active = !el.active);
            }
          } else {
            return (el.active = !el.active);
          }
        }
        return el;
      });
    },
    setActiveSimilarSeg(state, action: PayloadAction<number>) {
      if (!state.similarSeg) {
        return;
      }
      state.similarSeg?.map((el, index) => {
        if (index === action.payload) {
          if (
            state.similarSeg &&
            state.similarSeg?.filter((el) => el.active).length > 3
          ) {
            if (el.active === false) {
              notification.error({
                message: "최대 4개까지 선택가능",
                placement: "bottomRight",
              });
            } else {
              el.active = !el.active;
            }
          } else {
            el.active = !el.active;
          }
        }
      });
    },
    setActiveAssociatedSeg(state, action: PayloadAction<number>) {
      if (!state.associatedSeg) {
        return;
      }

      state.associatedSeg?.map((el, index) => {
        if (index === action.payload) {
          if (
            state.associatedSeg &&
            state.associatedSeg?.filter((el) => el.active).length > 3
          ) {
            if (el.active === false) {
              notification.error({
                message: "최대 4개까지 선택가능",
                placement: "bottomRight",
              });
            } else {
              el.active = !el.active;
            }
          } else {
            el.active = !el.active;
          }
        }
      });
    },
    setSimilarSegSection(state, action: PayloadAction<boolean>) {
      state.useSimilarSection = action.payload;
    },
    setBestSegSection(state, action: PayloadAction<boolean>) {
      state.useBestSection = action.payload;
    },
    setAssociatedSegSection(state, action: PayloadAction<boolean>) {
      state.useAssociatedSection = action.payload;
    },
    setHtmlSource(state, action: PayloadAction<string>) {
      state.htmlSource = action.payload;
    },
    setOpenModal(state, action: PayloadAction<"pc" | "mo" | "none">) {
      state.openModal = action.payload;
    },
    setImageUploadResponse(state, action: PayloadAction<string[]>) {
      action.payload.map((url, index) => {
        if (index === 0) {
          state.imageUploadResponse.hexaUrl.data = url;
        } else if (index === 1) {
          state.imageUploadResponse.barUrl.data = url;
        } else if (index === 2) {
          state.imageUploadResponse.segBestUrl.data = url;
        } else if (index === 3) {
          state.imageUploadResponse.similarSegUrl.data = url;
        } else if (index === 4) {
          state.imageUploadResponse.associatedSegUrl.data = url;
        }
      });
    },
    // segBest
    setSegBestVideoUseOption(
      state,
      action: PayloadAction<{
        setVideoUseOption: boolean;
        prdId: number;
      }>
    ) {
      const { prdId, setVideoUseOption } = action.payload;
      state.segBest?.map((prd, index) => {
        if (prdId === index) {
          prd.mediaUseOption.setVideoUseOption = setVideoUseOption;
        }
      });
    },
    setSegBestDubbingScript(
      state,
      action: PayloadAction<{ dubbingText: string[]; prdId: number }>
    ) {
      const { dubbingText, prdId } = action.payload;
      state.segBest?.map((el, index) => {
        if (prdId === index) {
          el.mediaUseOption.duddingText = dubbingText;
        }
      });
    },
    setSegVideoDubbingScript(
      state,
      action: PayloadAction<{
        text: string;
        prdId: number;
        dubbingIndex: number;
      }>
    ) {
      const { text, prdId, dubbingIndex } = action.payload;
      state.segBest?.map((prd, index) => {
        if (prdId === index) {
          if (text.length < 1) {
            prd.mediaUseOption.duddingText.splice(dubbingIndex, 1);
          } else {
            prd.mediaUseOption.duddingText[dubbingIndex] = text;
          }
        }
      });
    },
    // similarseg
    setSimilarSegVideoUseOption(
      state,
      action: PayloadAction<{
        setVideoUseOption: boolean;
        prdId: number;
      }>
    ) {
      const { prdId, setVideoUseOption } = action.payload;
      state.similarSeg?.map((prd, index) => {
        if (index === prdId) {
          prd.mediaUseOption.setVideoUseOption = setVideoUseOption;
        }
      });
    },
    setSimilarSegDubbingScript(
      state,
      action: PayloadAction<{ dubbingText: string[]; prdId: number }>
    ) {
      const { dubbingText, prdId } = action.payload;
      state.similarSeg?.map((el, index) => {
        if (prdId === index) {
          el.mediaUseOption.duddingText = dubbingText;
        }
      });
    },
    setSimilarSegVideoDubbingScript(
      state,
      action: PayloadAction<{
        text: string;
        prdId: number;
        dubbingIndex: number;
      }>
    ) {
      const { text, prdId, dubbingIndex } = action.payload;
      state.similarSeg?.map((prd, index) => {
        if (prdId === index) {
          if (text.length < 1) {
            prd.mediaUseOption.duddingText.splice(dubbingIndex, 1);
          } else {
            prd.mediaUseOption.duddingText[dubbingIndex] = text;
          }
        }
      });
    },
    // AssociatedSegPrd
    setAssociatedSegPrdVideoUseOption(
      state,
      action: PayloadAction<{
        setVideoUseOption: boolean;
        prdId: number;
      }>
    ) {
      const { prdId, setVideoUseOption } = action.payload;
      state.associatedSeg?.map((prd, index) => {
        if (index === prdId) {
          prd.mediaUseOption.setVideoUseOption = setVideoUseOption;
        }
      });
    },
    setAssociatedSegPrdDubbingScript(
      state,
      action: PayloadAction<{ dubbingText: string[]; prdId: number }>
    ) {
      const { dubbingText, prdId } = action.payload;
      state.associatedSeg?.map((el, index) => {
        if (prdId === index) {
          el.mediaUseOption.duddingText = dubbingText;
        }
      });
    },
    setAssociatedSegPrdVideoDubbingScript(
      state,
      action: PayloadAction<{
        text: string;
        prdId: number;
        dubbingIndex: number;
      }>
    ) {
      const { text, prdId, dubbingIndex } = action.payload;
      state.associatedSeg?.map((prd, index) => {
        if (prdId === index) {
          if (text.length < 1) {
            prd.mediaUseOption.duddingText.splice(dubbingIndex, 1);
          } else {
            prd.mediaUseOption.duddingText[dubbingIndex] = text;
          }
        }
      });
    },
    // bargraph
    setBarChartVideoUseOption(
      state,
      action: PayloadAction<{
        setVideoUseOption: boolean;
        prdId: number;
      }>
    ) {
      const { prdId, setVideoUseOption } = action.payload;
      state.itemSegInfo?.segData[0].barGraph.map((prd, index) => {
        if (index === prdId) {
          prd.mediaUseOption.setVideoUseOption = setVideoUseOption;
        }
      });
    },
    setBarChartDubbingScript(
      state,
      action: PayloadAction<{ dubbingText: string[]; prdId: number }>
    ) {
      const { dubbingText, prdId } = action.payload;
      state.itemSegInfo?.segData[0].barGraph.map((el, index) => {
        if (prdId === index) {
          el.mediaUseOption.duddingText = dubbingText;
        }
      });
    },
    setBarChartVideoDubbingScript(
      state,
      action: PayloadAction<{
        text: string;
        prdId: number;
        dubbingIndex: number;
      }>
    ) {
      const { text, prdId, dubbingIndex } = action.payload;
      state.itemSegInfo?.segData[0].barGraph.map((prd, index) => {
        if (prdId === index) {
          if (text.length < 1) {
            prd.mediaUseOption.duddingText.splice(dubbingIndex, 1);
          } else {
            prd.mediaUseOption.duddingText[dubbingIndex] = text;
          }
        }
      });
    },
    //HexaChart
    setHexaChartVideoUseOption(
      state,
      action: PayloadAction<{
        setVideoUseOption: boolean;
        prdId: number;
      }>
    ) {
      const { prdId, setVideoUseOption } = action.payload;
      state.itemSegInfo?.segData[0].hexaGraph.map((prd, index) => {
        if (index === prdId) {
          prd.mediaUseOption.setVideoUseOption = setVideoUseOption;
        }
      });
    },
    setHexaChartDubbingScript(
      state,
      action: PayloadAction<{ dubbingText: string[]; prdId: number }>
    ) {
      const { dubbingText, prdId } = action.payload;
      state.itemSegInfo?.segData[0].hexaGraph.map((el, index) => {
        if (prdId === index) {
          el.mediaUseOption.duddingText = dubbingText;
        }
      });
    },
    setHexaChartVideoDubbingScript(
      state,
      action: PayloadAction<{
        text: string;
        prdId: number;
        dubbingIndex: number;
      }>
    ) {
      const { text, prdId, dubbingIndex } = action.payload;
      state.itemSegInfo?.segData[0].hexaGraph.map((prd, index) => {
        if (prdId === index) {
          if (text.length < 1) {
            prd.mediaUseOption.duddingText.splice(dubbingIndex, 1);
          } else {
            prd.mediaUseOption.duddingText[dubbingIndex] = text;
          }
        }
      });
    },
    // itemseginfo
    setItemVideoUseOption(
      state,
      action: PayloadAction<{
        setVideoUseOption: boolean;
      }>
    ) {
      const { setVideoUseOption } = action.payload;
      if (state.itemSegInfo) {
        state.itemSegInfo.mediaUseOption.setVideoUseOption = setVideoUseOption;
      }
    },
    setItemDubbingScript(
      state,
      action: PayloadAction<{ dubbingText: string[] }>
    ) {
      const { dubbingText } = action.payload;
      if (state.itemSegInfo) {
        state.itemSegInfo.mediaUseOption.duddingText = dubbingText;
      }
    },
    editItemDubbingScript(
      state,
      action: PayloadAction<{
        text: string;
        dubbingIndex: number;
      }>
    ) {
      const { text, dubbingIndex } = { ...action.payload };

      if (state.itemSegInfo) {
        if (text.length < 1) {
          state.itemSegInfo.mediaUseOption.duddingText.splice(dubbingIndex, 1);
        } else {
          state.itemSegInfo.mediaUseOption.duddingText[dubbingIndex] = text;
        }
      }
    },
    setSegReSearchVideoUseOption(
      state,
      action: PayloadAction<{
        setVideoUseOption: boolean;
      }>
    ) {
      const { setVideoUseOption } = action.payload;
      if (state.itemSegInfo) {
        state.itemSegInfo.segData.map(
          (item) => (item.mediaUseOption.setVideoUseOption = setVideoUseOption)
        );
      }
    },
    setSegReSearchDubbingScript(
      state,
      action: PayloadAction<{ dubbingText: string[] }>
    ) {
      const { dubbingText } = action.payload;
      if (state.itemSegInfo) {
        state.itemSegInfo.segData.map(
          (item) => (item.mediaUseOption.duddingText = dubbingText)
        );
      }
    },
    editSegReSearchDubbingScript(
      state,
      action: PayloadAction<{
        text: string;
        dubbingIndex: number;
      }>
    ) {
      const { text, dubbingIndex } = action.payload;
      if (state.itemSegInfo) {
        if (text.length < 1) {
          state.itemSegInfo.segData.map((item) =>
            item.mediaUseOption.duddingText.splice(dubbingIndex, 1)
          );
        } else {
          state.itemSegInfo.mediaUseOption.duddingText[dubbingIndex] = text;
        }
      }
    },
    setVideoThumbnail(
      state,
      action: PayloadAction<{ videoThumbnail: string }>
    ) {
      const { videoThumbnail } = action.payload;
      state.videoThumbnail = videoThumbnail;
    },
    setVideoText(state, action: PayloadAction<{ videoText: string }>) {
      const { videoText } = action.payload;
      state.videoText = videoText;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        `${fetchCriteria.request}`,
        (state, action: PayloadAction<any>) => {}
      )
      .addCase(
        `${fetchCriteria.success}`,
        (state, action: PayloadAction<CriteriaResponse>) => {
          state.criteria = action.payload.list;
        }
      )
      .addCase(
        `${fetchCriteria.failure}`,
        (state, action: PayloadAction<APIError>) => {}
      )

      // segItemInfo
      .addCase(
        `${fetchItemSegInfo.request}`,
        (state, action: PayloadAction<any>) => {}
      )
      .addCase(
        `${fetchItemSegInfo.success}`,
        (state, action: PayloadAction<ItemSegInfoResponse>) => {
          action.payload.data.segData.forEach(
            (item) =>
              (item.mediaUseOption = {
                duddingText: [""],
                setVideoUseOption: false,
              })
          );
          // 스크립트 부분 추가
          action.payload.data.mediaUseOption = {
            duddingText: [""],
            setVideoUseOption: false,
          };
          // 바그래프
          action.payload.data.segData[0].barGraph.forEach(
            (prd) =>
              (prd.mediaUseOption = {
                duddingText: [""],
                setVideoUseOption: false,
              })
          );
          // hexaGraph
          action.payload.data.segData[0].hexaGraph.forEach(
            (prd) =>
              (prd.mediaUseOption = {
                duddingText: [""],
                setVideoUseOption: false,
              })
          );
          // itemUrl에 프로토콜빠진경우 예외처리(더블유컨셉에서 주로 빠짐)
          if (!action.payload.data.itemUrl.includes("http")) {
            action.payload.data.itemUrl = "https://".concat(
              action.payload.data.itemUrl
            );
          }
          action.payload.data.segData.forEach((seg) => {
            // multipleSegAvg가 50초과시 50으로 할당
            seg.barGraph.forEach((bar) => {
              if (bar.multipleSegAvg > 50) {
                bar.multipleSegAvg = 50;
              }
            });
          });
          state.itemSegInfo = action.payload.data;
        }
      )
      .addCase(
        `${fetchItemSegInfo.failure}`,
        (state, action: PayloadAction<any>) => {}
      )
      // 유사 Seg
      .addCase(
        `${fetchSimilarSeg.request}`,
        (state, action: PayloadAction<SimilarSegItemsRequest>) => {}
      )
      .addCase(
        `${fetchSimilarSeg.success}`,
        (state, action: PayloadAction<SimilarSegResponse>) => {
          action.payload.list.map(
            (el) =>
              (el.mediaUseOption = {
                duddingText: [""],
                setVideoUseOption: false,
              })
          );

          // const assciateSet = new Set(
          //   state.associatedSeg?.map(({ objectId }) => objectId)
          // );
          const similarSegRes = action.payload.list;

          state.similarSeg = similarSegRes
            // .filter(
            //   (seg) =>
            //     !assciateSet.has(seg.objectId) &&
            //     seg.objectId !== state.itemSegInfo?.objectId
            // )
            .map((seg, index) => {
              index < 4 ? (seg.active = true) : (seg.active = false);
              return seg;
            });
        }
      )
      .addCase(
        `${fetchSimilarSeg.failure}`,
        (state, action: PayloadAction<APIError>) => {}
      )
      // 연관 Seg
      .addCase(
        `${fetchAssociatedSeg.request}`,
        (state, action: PayloadAction<SimilarSegItemsRequest>) => {}
      )
      .addCase(
        `${fetchAssociatedSeg.success}`,
        (state, action: PayloadAction<AssociatedSegResponse>) => {
          action.payload.list.map(
            (el) =>
              (el.mediaUseOption = {
                duddingText: [""],
                setVideoUseOption: false,
              })
          );
          // itemSegInfo와 겹치지않게
          const associatedSegRes = action.payload.list;

          state.associatedSeg = associatedSegRes
            .filter((seg) => seg.objectId !== state.itemSegInfo?.objectId)
            .map((seg, index) => {
              index < 4 ? (seg.active = true) : (seg.active = false);
              return seg;
            });
        }
      )
      .addCase(
        `${fetchAssociatedSeg.failure}`,
        (state, action: PayloadAction<APIError>) => {}
      )
      // 베스트 Seg
      .addCase(
        `${fetchSegBest.request}`,
        (state, action: PayloadAction<SimilarSegItemsRequest>) => {}
      )
      .addCase(
        `${fetchSegBest.success}`,
        (state, action: PayloadAction<SegBestResponse>) => {
          action.payload.list.map((el, _) => {
            el.mediaUseOption = {
              duddingText: [""],
              setVideoUseOption: false,
            };
          });

          const segBestRes = action.payload.list;
          state.segBest = segBestRes.map((seg, index) => {
            index < 4 ? (seg.active = true) : (seg.active = false);
            return seg;
          });
        }
      )
      .addCase(
        `${fetchSegBest.failure}`,
        (state, action: PayloadAction<APIError>) => {}
      )
      // hexa 차트 url
      .addCase(
        `${fetchHexaImageUrl.request}`,
        (state, action: PayloadAction<any>) => {
          state.imageUploadResponse.hexaUrl.status = "request";
        }
      )
      .addCase(
        `${fetchHexaImageUrl.success}`,
        (state, action: PayloadAction<any>) => {
          state.imageUploadResponse.hexaUrl.status = "success";
          state.imageUploadResponse.hexaUrl.data = action.payload.data.url;
        }
      )
      .addCase(
        `${fetchHexaImageUrl.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.imageUploadResponse.hexaUrl.status = "failure";
          state.imageUploadResponse.hexaUrl.error = action.payload;
        }
      )
      // bar 차트 url
      .addCase(
        `${fetchBarImageUrl.request}`,
        (state, action: PayloadAction<any>) => {
          state.imageUploadResponse.barUrl.status = "request";
        }
      )
      .addCase(
        `${fetchBarImageUrl.success}`,
        (state, action: PayloadAction<any>) => {
          state.imageUploadResponse.barUrl.status = "success";
          state.imageUploadResponse.barUrl.data = action.payload.data.url;
        }
      )
      .addCase(
        `${fetchBarImageUrl.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.imageUploadResponse.barUrl.status = "failure";
          state.imageUploadResponse.barUrl.error = action.payload;
        }
      )
      // segBest url
      .addCase(
        `${fetchSegBestImageUrl.request}`,
        (state, action: PayloadAction<any>) => {
          state.imageUploadResponse.segBestUrl.status = "request";
        }
      )
      .addCase(
        `${fetchSegBestImageUrl.success}`,
        (state, action: PayloadAction<any>) => {
          state.imageUploadResponse.segBestUrl.status = "success";
          state.imageUploadResponse.segBestUrl.data = action.payload.data.url;
        }
      )
      .addCase(
        `${fetchSegBestImageUrl.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.imageUploadResponse.segBestUrl.status = "failure";
          state.imageUploadResponse.segBestUrl.error = action.payload;
        }
      )
      // Similar url
      .addCase(
        `${fetchSimilarImageUrl.request}`,
        (state, action: PayloadAction<any>) => {
          state.imageUploadResponse.similarSegUrl.status = "request";
        }
      )
      .addCase(
        `${fetchSimilarImageUrl.success}`,
        (state, action: PayloadAction<any>) => {
          state.imageUploadResponse.similarSegUrl.status = "success";
          state.imageUploadResponse.similarSegUrl.data =
            action.payload.data.url;
        }
      )
      .addCase(
        `${fetchSimilarImageUrl.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.imageUploadResponse.similarSegUrl.status = "failure";
          state.imageUploadResponse.similarSegUrl.error = action.payload;
        }
      )
      // accossiate url
      .addCase(
        `${fetchAccosiateImageUrl.request}`,
        (state, action: PayloadAction<any>) => {
          state.imageUploadResponse.associatedSegUrl.status = "request";
        }
      )
      .addCase(
        `${fetchAccosiateImageUrl.success}`,
        (state, action: PayloadAction<any>) => {
          state.imageUploadResponse.associatedSegUrl.status = "success";
          state.imageUploadResponse.associatedSegUrl.data =
            action.payload.data.url;
        }
      )
      .addCase(
        `${fetchAccosiateImageUrl.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.imageUploadResponse.associatedSegUrl.status = "failure";
          state.imageUploadResponse.associatedSegUrl.error = action.payload;
        }
      )

      // 비디오 내려받기
      .addCase(
        `${fetchVideo.request}`,
        (state, action: PayloadAction<any>) => {}
      )
      .addCase(
        `${fetchVideo.success}`,
        (state, action: PayloadAction<{ data: VideoStatus }>) => {
          state.currentVideo = action.payload.data;
        }
      )
      .addCase(
        `${fetchVideo.failure}`,
        (state, action: PayloadAction<APIError>) => {}
      );
  },
});

const selfSelector = (state: RootState) => state[BLOGV2];

export const keepPageSelector = createSelector(
  selfSelector,
  (state) => state.keepPage
);
export const requestSelector = createSelector(
  selfSelector,
  (state) => state.request
);
export const criteriaSelector = createSelector(
  selfSelector,
  (state) => state.criteria
);
export const itemSegInfoSelector = createSelector(
  selfSelector,
  (state) => state.itemSegInfo
);

export const useSimilarSectionSelector = createSelector(
  selfSelector,
  (state) => state.useSimilarSection
);
// segBest는 ItemSegInfo와 겹치지않게 filter
export const segBestSelector = createSelector(
  selfSelector,
  (state) => state.segBest
);
// similar는 segBest와 겹치지않게 filter
export const similarSegSelector = createSelector(
  selfSelector,
  (state) => state.similarSeg
);
// associated는 similar와 겹치지않게 filter
export const associatedSegSelector = createSelector(
  selfSelector,
  (state) => state.associatedSeg
);
export const useBestSectionSelector = createSelector(
  selfSelector,
  (state) => state.useBestSection
);
export const useAssociatedSectionSelector = createSelector(
  selfSelector,
  (state) => state.useAssociatedSection
);
export const imageUploadResponseSelector = createSelector(
  selfSelector,
  (state) => state.imageUploadResponse
);

export const segTabIndexSelector = createSelector(
  selfSelector,
  (state) => state.segTabIndex
);
export const htmlSourceSelector = createSelector(
  selfSelector,
  (state) => state.htmlSource
);
export const currentStateSelector = createSelector(
  selfSelector,
  (state) => state.currentState
);
export const segBestTextSelector = createSelector(
  selfSelector,
  (state) => state.userText?.segBest
);
export const similarTextSelector = createSelector(
  selfSelector,
  (state) => state.userText?.similar
);
export const languageSelector = createSelector(
  selfSelector,
  (state) => state.language
);
export const openModalSelector = createSelector(
  selfSelector,
  (state) => state.openModal
);
export const currentVideoSelector = createSelector(
  selfSelector,
  (state) => state.currentVideo
);
export const videoThumbnailSelector = createSelector(
  selfSelector,
  (state) => state.videoThumbnail
);
export const videoTextSelector = createSelector(
  selfSelector,
  (state) => state.videoText
);
export const itemDubbingTextSelector = createSelector(
  selfSelector,
  (state) => state.itemSegInfo?.mediaUseOption
);
export const blogActionV2 = blogSlice02.actions;
export const blogReducer02 = blogSlice02.reducer;
