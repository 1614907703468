import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { callWrapperSaga } from "utils/callWrapperSaga";
import { RecDemoService } from "apis/services";
import type {
  RecRequest,
  RecTypeResponse,
  RecResultResponse,
  APIError,
} from "types/api";

export const GET_RECTYPE = `rec/GET_RECTYPE`;
export const GET_RECTYPE_SUCCESS = `rec/GET_RECTYPE_SUCCESS`;
export const GET_RECTYPE_ERROR = `rec/GET_RECTYPE_ERROR`;

export const FETCH_RECRESULT = `rec/FETCH_RECRESULT`;
export const FETCH_RECRESULT_SUCCESS = `rec/FETCH_RECRESULT_SUCCESS`;
export const FETCH_RECRESULT_ERROR = `rec/FETCH_RECRESULT_ERROR`;

export const getRecTypes = createAsyncAction(
  GET_RECTYPE,
  GET_RECTYPE_SUCCESS,
  GET_RECTYPE_ERROR
)<RecRequest, { recTypeData: RecTypeResponse }, APIError>();

function* getRecTypesSaga(action: ReturnType<typeof getRecTypes.request>) {
  try {
    const { data } = yield callWrapperSaga(
      RecDemoService.postRecTypePerCuid,
      action.payload
    );
    yield put(getRecTypes.success({ recTypeData: data }));
  } catch (response) {
    const { data, status } = response as AxiosResponse;
    yield put(getRecTypes.failure({ data, status }));
  }
}

export const fetchRecResult = createAsyncAction(
  FETCH_RECRESULT,
  FETCH_RECRESULT_SUCCESS,
  FETCH_RECRESULT_ERROR
)<RecRequest, { recResult: RecResultResponse }, APIError>();

function* fetchRecResultSaga(
  action: ReturnType<typeof fetchRecResult.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      RecDemoService.fetchRecResult,
      action.payload
    );
    yield put(fetchRecResult.success({ recResult: data }));
  } catch (error: any) {
    const { data, status } = error.response
      ? (error.response as AxiosResponse)
      : { data: null, status: 0 };
    yield put(fetchRecResult.failure({ data, status }));
  }
}
export const recDemoAsyncAction = {
  getRecTypes,
  fetchRecResult,
};

export default function* recDemoSaga() {
  yield takeLatest(GET_RECTYPE, getRecTypesSaga);
  yield takeLatest(FETCH_RECRESULT, fetchRecResultSaga);
}
