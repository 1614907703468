import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  manageAction,
  searchSelector,
  statusSelector,
} from "store/modules/manage";
import { Status } from "types/manage";

const useManageData = () => {
  const dispatch = useAppDispatch();
  const [status, search] = [
    useAppSelector(statusSelector),
    useAppSelector(searchSelector),
  ];

  const setStatus = useCallback(
    ({ status }: { status: Status }) => {
      dispatch(manageAction.setStatus({ status }));
    },
    [dispatch]
  );

  const setSearch = useCallback(
    ({ search }: { search: string }) => {
      dispatch(manageAction.setSearch({ search }));
    },
    [dispatch]
  );

  return { status, search, setStatus, setSearch };
};
export default useManageData;
