import { FC, useCallback, useState } from "react";
import { Modal, Button, Divider, Form, Input, message } from "antd";
import { useIntl } from "react-intl";
import { SettingOutlined } from "@ant-design/icons";
import { useUserData } from "hooks";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

interface PasswordModifyProps {}

const PasswordModify: FC<PasswordModifyProps> = () => {
  const { formatMessage } = useIntl();
  const { pwChange, pwChangeLoading } = useUserData();
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();
  const resetFields = useCallback(() => {
    form.resetFields();
  }, [form]);

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <>
      <Button
        block
        type="text"
        onClick={showModal}
        children={
          <>
            <SettingOutlined />
            <Divider type="vertical" />
            {formatMessage({
              id: "Components.Layout.Header.UserActionDropdown.PasswordModify.text",
            })}
          </>
        }
      />
      <Modal
        title={formatMessage({
          id: "Components.Layout.Header.UserActionDropdown.PasswordModify.Form.title",
        })}
        visible={visible}
        onOk={() => {
          form.validateFields().then(() => {
            const { password } = form.getFieldsValue();
            pwChange({
              newPassword: password,
              onSuccess: () => {
                message.info("수정완료!");
                setVisible(false);
              },
              onFailure: () => {
                message.info("실패 ㅠ.. 개발팀에게 문의 주세요");
              },
            });
          });
        }}
        onCancel={onCancel}
        okText={formatMessage({
          id: "Components.Layout.Header.UserActionDropdown.PasswordModify.Form.okText",
        })}
        cancelText={formatMessage({
          id: "Components.Layout.Header.UserActionDropdown.PasswordModify.Form.cancelText",
        })}
        confirmLoading={pwChangeLoading}
        afterClose={resetFields}
      >
        <Form
          {...formItemLayout}
          name="password-modify"
          scrollToFirstError
          form={form}
          preserve={false}
        >
          <Form.Item
            name="password"
            label={formatMessage({
              id: "Components.Layout.Header.UserActionDropdown.PasswordModify.Form.passwordLabel",
            })}
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: "Components.Layout.Header.UserActionDropdown.PasswordModify.Form.passwordError",
                }),
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={formatMessage({
              id: "Components.Layout.Header.UserActionDropdown.PasswordModify.Form.confirmPasswordLabel",
            })}
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: "Components.Layout.Header.UserActionDropdown.PasswordModify.Form.confirmPasswordError",
                }),
              },
              ({ getFieldValue, validatesFields }: any) => ({
                validator(_: any, value: any) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      formatMessage({
                        id: "Components.Layout.Header.UserActionDropdown.PasswordModify.Form.notMatchedError",
                      })
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PasswordModify;
