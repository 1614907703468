import { useRef } from "react";
import { v4 as uuidv4 } from "uuid";

const useSingleton = <T>(provider: () => T): T => {
  const singleton = useRef<T>();
  const getSingleton = () => {
    if (!singleton.current) {
      singleton.current = provider();
    }
    return singleton.current;
  };
  return getSingleton();
};

const useComponentId = (): string => {
  return useSingleton(uuidv4);
};

export default useComponentId;
