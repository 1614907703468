import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { format, addDays } from "date-fns";
import { ProductState } from "types/product";
import { productReportAsyncAction } from "./saga";
import {
  BrandCategoryListResponse,
  ProductPerformanceChartResponse,
  APIError,
} from "types/api";
import { ProductDetails } from "types/product";
export const PRODUCT = "product";

const yesterday = addDays(new Date(), -1);
const [defaultStart, defaultEnd] = [
  format(addDays(yesterday, -6), "yyyy-MM-dd"),
  format(yesterday, "yyyy-MM-dd"),
];

const initialState: ProductState = {
  channel: "ALL",
  recYn: "ALL",
  startDate: defaultStart,
  endDate: defaultEnd,
  brandName: "ALL",
  category: "ALL",
  criterion: "VIEW_COUNT",
  itemId: "",
  brandCategoryList: {
    loading: false,
    result: {
      success: false,
      data: {
        brandNameList: [],
        categoryList: [],
      },
    },
    error: null,
  },
  productList: {
    loading: false,
    result: {
      success: false,
      data: [],
    },
    error: null,
  },
  productTransitionChart: {
    loading: false,
    result: {
      success: false,
      data: [],
    },
    error: null,
  },
  productPerformanceChart: {
    loading: false,
    result: {
      success: false,
      data: {
        firstWeekData: {},
        secondWeekData: {},
        thirdWeekData: {},
        fourthWeekData: {},
      },
    },
    error: null,
  },
};

const productSlice = createSlice({
  name: PRODUCT,
  initialState,
  reducers: {
    setChannel(state, action: PayloadAction<{ channel: string }>) {
      state.channel = action.payload.channel;
    },
    setRecYn(state, action: PayloadAction<{ recYn: string }>) {
      state.recYn = action.payload.recYn;
    },
    setDate(
      state,
      action: PayloadAction<Partial<{ startDate: string; endDate: string }>>
    ) {
      return { ...state, ...action.payload };
    },
    setBrand(state, action: PayloadAction<{ brandName: string }>) {
      state.brandName = action.payload.brandName;
    },
    setCategory(state, action: PayloadAction<{ category: string }>) {
      state.category = action.payload.category;
    },
    setCriterion(state, action: PayloadAction<{ criterion: string }>) {
      state.criterion = action.payload.criterion;
    },
    setClearCurrentOptions(state, action: PayloadAction) {
      const yesterday = addDays(new Date(), -1);
      state.channel = "ALL";
      state.startDate = format(addDays(yesterday, -6), "yyyy-MM-dd");
      state.endDate = format(yesterday, "yyyy-MM-dd");
      state.recYn = "ALL";
      state.brandName = "ALL";
      state.category = "ALL";
      state.criterion = "VIEW_COUNT";
      state.productList.result = {
        success: false,
        data: [],
      };
    },
    setItemId(state, action: PayloadAction<{ itemId: string }>) {
      state.itemId = action.payload.itemId;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        `${productReportAsyncAction.fetchBrandCategoryList.request}`,
        (state) => {
          state.brandCategoryList.loading = true;
        }
      )
      .addCase(
        `${productReportAsyncAction.fetchBrandCategoryList.success}`,
        (state, action: PayloadAction<{ data: BrandCategoryListResponse }>) => {
          state.brandCategoryList.loading = false;
          state.brandCategoryList.result.data = action.payload.data;
        }
      )
      .addCase(
        `${productReportAsyncAction.fetchBrandCategoryList.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.brandCategoryList.loading = false;
          state.brandCategoryList.result =
            initialState.brandCategoryList.result;
          state.brandCategoryList.error = action.payload;
        }
      )
      .addCase(
        `${productReportAsyncAction.fetchProductReportList.request}`,
        (state) => {
          state.productList.loading = true;
        }
      )
      .addCase(
        `${productReportAsyncAction.fetchProductReportList.success}`,
        (state, action: PayloadAction<{ data: ProductDetails[] }>) => {
          state.productList.loading = false;
          state.productList.result.data = action.payload.data;
        }
      )
      .addCase(
        `${productReportAsyncAction.fetchProductReportList.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.productList.loading = false;
          state.productList.result = initialState.productList.result;
          state.productList.error = action.payload;
        }
      )
      .addCase(
        `${productReportAsyncAction.fetchProductTransitionChart.request}`,
        (state) => {
          state.productTransitionChart.loading = true;
        }
      )
      .addCase(
        `${productReportAsyncAction.fetchProductTransitionChart.success}`,
        (state, action: PayloadAction<{ data: string[] }>) => {
          state.productTransitionChart.loading = false;
          state.productTransitionChart.result.data = action.payload.data;
        }
      )
      .addCase(
        `${productReportAsyncAction.fetchProductTransitionChart.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.productTransitionChart.loading = false;
          state.productTransitionChart.result =
            initialState.productTransitionChart.result;
          state.productTransitionChart.error = action.payload;
        }
      )
      .addCase(
        `${productReportAsyncAction.fetchProductPerformanceChart.request}`,
        (state) => {
          state.productPerformanceChart.loading = true;
        }
      )
      .addCase(
        `${productReportAsyncAction.fetchProductPerformanceChart.success}`,
        (
          state,
          action: PayloadAction<{ data: ProductPerformanceChartResponse }>
        ) => {
          state.productPerformanceChart.loading = false;
          state.productPerformanceChart.result.data = action.payload.data;
        }
      )
      .addCase(
        `${productReportAsyncAction.fetchProductPerformanceChart.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.productPerformanceChart.loading = false;
          state.productPerformanceChart.result =
            initialState.productPerformanceChart.result;
          state.productPerformanceChart.error = action.payload;
        }
      );
  },
});

const selfSelector = (state: RootState) => state[PRODUCT];

const brandCategoryListSelector = (state: RootState) =>
  state[PRODUCT].brandCategoryList;
const productListSelector = (state: RootState) => state[PRODUCT].productList;
const productTransitionChartSelector = (state: RootState) =>
  state[PRODUCT].productTransitionChart;
const productPerformanceChartSelector = (state: RootState) =>
  state[PRODUCT].productPerformanceChart;

export const BrandCategoryListSelector = {
  loading: createSelector(
    brandCategoryListSelector,
    (brandCategoryList) => brandCategoryList.loading
  ),
  result: createSelector(
    brandCategoryListSelector,
    (brandCategoryList) => brandCategoryList.result.data
  ),
  error: createSelector(
    brandCategoryListSelector,
    (brandCategoryList) => brandCategoryList.error
  ),
};

export const ProductListSelector = {
  loading: createSelector(
    productListSelector,
    (productList) => productList.loading
  ),
  result: createSelector(
    productListSelector,
    (productList) => productList.result.data
  ),
  error: createSelector(
    productListSelector,
    (productList) => productList.error
  ),
};

export const ProductTransitionChartSelector = {
  loading: createSelector(
    productTransitionChartSelector,
    (transitionChart) => transitionChart.loading
  ),
  result: createSelector(
    productTransitionChartSelector,
    (transitionChart) => transitionChart.result.data
  ),
  error: createSelector(
    productTransitionChartSelector,
    (transitionChart) => transitionChart.error
  ),
};

export const ProductPerformanceChartSelector = {
  loading: createSelector(
    productPerformanceChartSelector,
    (performanceChart) => performanceChart.loading
  ),
  result: createSelector(
    productPerformanceChartSelector,
    (performanceChart) => performanceChart.result.data
  ),
  error: createSelector(
    productPerformanceChartSelector,
    (performanceChart) => performanceChart.error
  ),
};

export const channelSelector = createSelector(
  selfSelector,
  (product) => product.channel
);
export const recYnSelector = createSelector(
  selfSelector,
  (product) => product.recYn
);
export const dateSelector = createSelector(
  selfSelector,
  ({ startDate, endDate }) => ({ startDate, endDate })
);
export const brandSelector = createSelector(
  selfSelector,
  (product) => product.brandName
);
export const categorySelector = createSelector(
  selfSelector,
  (product) => product.category
);
export const criterionSelector = createSelector(
  selfSelector,
  (product) => product.criterion
);
export const itemIdSelector = createSelector(
  selfSelector,
  (product) => product.itemId
);

export const productAction = productSlice.actions;
export const productReducer = productSlice.reducer;
