import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import type { RecTypeResponse, RecResultResponse, APIError } from "types/api";
import { RootState } from "store";

import type { RecState } from "types/rec";

import { recDemoAsyncAction } from "store/modules/demo/rec/saga";

export const REC = "rec";

const initialState: RecState = {
  recType: {
    loading: false,
    data: {
      cuid: "",
      using: [],
      notusing: [],
    },
    error: null,
  },
  recResult: {
    loading: false,
    data: {
      recType: "",
      iids: null,
      cids: null,
      exiids: null,
      excids: null,
      rg: null,
      minp: null,
      maxp: null,
      products: [],
      results: [],
      groupedResults: null,
      categorizedResults: null,
    },
    error: null,
  },
};

const recSlice = createSlice({
  name: REC,
  initialState,
  reducers: {
    clear: (state, action: PayloadAction) => {
      state.recResult.data = initialState.recResult.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(`${recDemoAsyncAction.getRecTypes.request}`, (state) => {
        state.recType.loading = true;
      })
      .addCase(
        `${recDemoAsyncAction.getRecTypes.success}`,
        (state, action: PayloadAction<{ recTypeData: RecTypeResponse }>) => {
          state.recType.loading = false;
          state.recType.data = action.payload.recTypeData;
        }
      )
      .addCase(
        `${recDemoAsyncAction.getRecTypes.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.recType.loading = false;
          state.recType.data = initialState.recType.data;
          state.recType.error = action.payload;
        }
      )
      .addCase(`${recDemoAsyncAction.fetchRecResult.request}`, (state) => {
        state.recResult.loading = true;
      })
      .addCase(
        `${recDemoAsyncAction.fetchRecResult.success}`,
        (state, action: PayloadAction<{ recResult: RecResultResponse }>) => {
          const recResult = action.payload.recResult;
          state.recResult.loading = false;
          state.recResult.data = recResult;
        }
      )
      .addCase(
        `${recDemoAsyncAction.fetchRecResult.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.recResult.loading = false;
          state.recResult.data = initialState.recResult.data;
          state.recResult.error = action.payload;
        }
      );
  },
});

const recTypeSelector = (state: RootState) => state[REC].recType;
const recResultSelector = (state: RootState) => state[REC].recResult;

export const RecTypeSelector = {
  loading: createSelector(recTypeSelector, (recType) => recType.loading),
  data: createSelector(recTypeSelector, (recType) => recType.data),
  error: createSelector(recTypeSelector, (recType) => recType.error),
};

export const RecResultSelector = {
  loading: createSelector(recResultSelector, (recResult) => recResult.loading),
  data: createSelector(recResultSelector, (recResult) => recResult.data),
  error: createSelector(recResultSelector, (recResult) => recResult.error),
};

export const recAction = recSlice.actions;
export const recReducer = recSlice.reducer;
