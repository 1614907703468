import Client from "apis";
import { RtsRequest } from "types/api";

class RtsDemoService {
  static fetchRtsResult = async (rtsResultRequest: RtsRequest) => {
    return await Client.publicInstance.get(`${rtsResultRequest.apiUrl}`);
  };
}

export default RtsDemoService;
