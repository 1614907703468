import { gql } from "@apollo/client";

export const CREATE_CAMPAIGN = gql`
  mutation createCampaign($data: CampaignInput) {
    createCampaign(data: $data) {
      id
      cuid
      name
      channel {
        channelName
      }
      area {
        areaName
      }
      page {
        pageName
      }
    }
  }
`;

export const CREATE_SELECTOR = gql`
  mutation createSelector($data: SelectorInput) {
    createSelector(data: $data) {
      id
      cuid
      name
      aggregateMethod
      period
      expression
      selectorParameters {
        operandSign
        Logic {
          id
        }
        InputSources {
          typeId
        }
        resultSize
        inputSourceSize
      }
    }
  }
`;

export const CREATE_OPERATOR = gql`
  mutation createOperator($data: OperatorInput) {
    createOperator(data: $data) {
      id
      cuid
      name
      filterConfig {
        list {
          type
          operator
          criteria
          elements
          action
        }
      }
      sortingConfig {
        list {
          prop
          type
          order
        }
      }
    }
  }
`;

export const CREATE_TRIGGER = gql`
  mutation createTrigger($data: TriggerInput) {
    createTrigger(data: $data) {
      id
      cuid
      extraProps
      TriggerType {
        id
      }
    }
  }
`;

export const CREATE_RANDOMIZED_SEG_GROUP = gql`
  mutation createRandomizedSegGroup($data: RandomizedSegGroupCreateInput) {
    createRandomizedSegGroup(data: $data) {
      id
      cuid
      pcidPattern
      viewRatio
      orderRatio
      revenueRatio
      basketSize
    }
  }
`;

export const CREATE_CAMPAIGN_CONFIGURATION = gql`
  mutation createCampaignConfiguration($data: CampaignConfigurationInput) {
    createCampaignConfiguration(data: $data) {
      id
      Operator {
        id
      }
      Selector {
        id
      }
      Trigger {
        id
      }
      qbotMessageSet
      AnalyticalSeg {
        id
      }
      RandomizedSegGroup {
        id
      }
    }
  }
`;

export const UPDATE_CAMPAIGN_TITLE = gql`
  mutation updateCampaignName($data: CampaignUpdateInput) {
    updateCampaign(data: $data) {
      id
      name
      status
    }
  }
`;
export const UPDATE_CAMPAIGN_CONFIGURATION_PRIORITY = gql`
  mutation bulkUpdateCampaignConfiguration(
    $campaignId: Long
    $data: [CampaignConfigurationUpdateInput]
  ) {
    bulkUpdateCampaignConfiguration(campaignId: $campaignId, data: $data) {
      id
    }
  }
`;

export const DELETE_CAMPAIGN_CONFIGURATION = gql`
  mutation deleteCampaignConfiguration($data: IdRefInput) {
    deleteCampaignConfiguration(data: $data) {
      id
    }
  }
`;

export const UPDATE_STATUS_CAMPAIGN_CONFIGURATION = gql`
  mutation updateCampaignConfiguration(
    $data: CampaignConfigurationUpdateInput
  ) {
    updateCampaignConfiguration(data: $data) {
      id
      status
    }
  }
`;

export const UPDATE_CAMPAIGN_CONFIGURATION = gql`
  mutation updateQbotMessageSet($data: CampaignConfQbotMessageUpdateInput) {
    updateQbotMessageSet(data: $data) {
      id
      qbotMessageSet
    }
  }
`;

export const UPDATE_SELECTOR_NAME = gql`
  mutation updateSelector($data: SelectorUpdateInput) {
    updateSelector(data: $data) {
      id
      name
    }
  }
`;

export const UPDATE_CUBOT_ONOFF = gql`
  mutation updateCubotOnOff($data: CubotOnOffInput) {
    updateCubotOnOff(data: $data) {
      cuid
      isOn
    }
  }
`;
