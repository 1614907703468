import { FC, Children, useMemo } from "react";
import { Dropdown, Menu, Button } from "antd";
import PasswordModify from "./PasswordModify";
import Logout from "components/layout/header/UserActionDropdown/Logout";
import _ from "lodash";
import { useUserData } from "hooks";

const UserActionDropdown: FC = () => {
  const { loginStatusData } = useUserData();
  const { displayUserName } = _.defaultTo(loginStatusData, {});
  const actionComponents = useMemo(() => [PasswordModify, Logout], []);

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu>
          {Children.toArray(
            actionComponents.map((Component) => (
              <Menu.Item>
                <Component />
              </Menu.Item>
            ))
          )}
        </Menu>
      }
    >
      <Button type="text" block children={displayUserName} />
    </Dropdown>
  );
};

export default UserActionDropdown;
