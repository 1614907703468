export const SELECTOR_NAME = "Selector Name";
export const SELECTOR_LIST = "Selector List";
export const SAVA_TO_DEMO = "저장 후 데모 보기";

export const SELECTABLE_LIST = ["A", "B", "C", "D", "E"];
export const VALID_ACTIVE_CHECK_LIST = ["B", "D", "E"];
export const VALID_DEACTIVE_CHECK_LIST = ["C"];

export const CUSTOM_TABLE_HEADER = [{}];

export const SELECTOR_TABLE_HEADER = [
  {
    title: "Type",
    dataIndex: "content",
    key: "content",
    align: "center" as "center",
  },
  {
    title: "Logic",
    dataIndex: "logicType",
    key: "logicType",
    align: "center" as "center",
  },
  {
    title: "Input",
    dataIndex: "input",
    key: "input",
    align: "center" as "center",
  },
  {
    title: "Output",
    dataIndex: "output",
    key: "output",
    align: "center" as "center",
  },
  {
    title: "Input Source",
    dataIndex: "inputSources",
    key: "inputSources",
    align: "center" as "center",
  },
  {
    title: "Input Source Size",
    dataIndex: "inputSourceSize",
    key: "inputSourceSize",
    align: "center" as "center",
  },
  {
    title: "Result Size",
    dataIndex: "resultSize",
    key: "resultSize",
    align: "center" as "center",
  },
  {
    title: "Cache N회",
    dataIndex: "cacheThresholdValue",
    key: "cacheThresholdValue",
    align: "center" as "center",
  },

  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    align: "center" as "center",
  },
  {
    title: "Input Filter",
    dataIndex: "inputFilter",
    key: "inputFilter",
    align: "center" as "center",
  },
  {
    title: "Mix기간",
    dataIndex: "mixTerm",
    key: "mixTerm",
    align: "center" as "center",
  },
  {
    title: "Mix설정",
    dataIndex: "mixMethod",
    key: "mixMethod",
    align: "center" as "center",
  },
];

export const SELECTOR_LIST_TABLE_HEADER = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    align: "center" as "center",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    align: "center" as "center",
  },
  {
    title: "Type",
    dataIndex: "content",
    key: "content",
    align: "center" as "center",
  },
  {
    title: "Input",
    dataIndex: "input",
    key: "input",
    align: "center" as "center",
  },
  {
    title: "Output Results",
    dataIndex: "output_results",
    key: "output_results",
    align: "center" as "center",
  },
  {
    title: "Input Filter",
    dataIndex: "inputFilter",
    key: "inputFilter",
    align: "center" as "center",
  },
  {
    title: "생성일자",
    dataIndex: "createdDate",
    key: "createdDate",
    align: "center" as "center",
  },
];

export const SELECTOR_LIST_TABLE_HEADER_IN_DESIGNER = [
  {
    title: "Selector ID",
    dataIndex: "id",
    key: "id",
    align: "center" as "center",
  },
  {
    title: "Selector Name",
    dataIndex: "name",
    key: "name",
    align: "center" as "center",
  },
];

export const OPERATOR_LIST_TABLE_HEADER_IN_DESIGNER = [
  {
    title: "Operator ID",
    dataIndex: "id",
    key: "id",
    align: "center" as "center",
  },
  {
    title: "Operator Name",
    dataIndex: "name",
    key: "name",
    align: "center" as "center",
  },
];
