import { createAsyncAction } from "typesafe-actions";
import { ManageService } from "apis/services";
import { AxiosResponse } from "axios";
import { callWrapperSaga } from "utils/callWrapperSaga";
import {
  APIError,
  LogCollectStatusResponse,
  ManageRequest,
  LogGuideResponse,
} from "types/api";
import { put, select, takeEvery } from "@redux-saga/core/effects";
import { currentDateSelector } from "store/modules/log";
import _ from "lodash";
import { fork, takeLatest } from "redux-saga/effects";

export const GET_LOG_GUIDE = `log/GET_LOG_GUIDE`;
export const GET_LOG_GUIDE_SUCCESS = `log/GET_LOG_GUIDE_SUCCESS`;
export const GET_LOG_GUIDE_FAILURE = `log/GET_LOG_GUIDE_FAILURE`;

export const getLogGuide = createAsyncAction(
  GET_LOG_GUIDE,
  GET_LOG_GUIDE_SUCCESS,
  GET_LOG_GUIDE_FAILURE
)<ManageRequest, { guideData: LogGuideResponse }, APIError>();

function* getLogGuideSaga(action: ReturnType<typeof getLogGuide.request>) {
  try {
    const { data }: AxiosResponse<LogGuideResponse> = yield callWrapperSaga(
      ManageService.fetchLogGuide,
      action.payload
    );
    yield put(getLogGuide.success({ guideData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getLogGuide.failure({ data, status }));
  }
}

export const ADD_LOG_GUIDE = `log/ADD_LOG_GUIDE`;
export const ADD_LOG_GUIDE_SUCCESS = `log/ADD_LOG_GUIDE_SUCCESS`;
export const ADD_LOG_GUIDE_FAILURE = `log/ADD_LOG_GUIDE_FAILURE`;

export const addLogGuide = createAsyncAction(
  ADD_LOG_GUIDE,
  ADD_LOG_GUIDE_SUCCESS,
  ADD_LOG_GUIDE_FAILURE
)<
  ManageRequest & {
    onSuccess: () => void;
    onFailure: () => void;
  },
  { guideData: LogGuideResponse },
  APIError
>();

function* addLogGuideSaga(action: ReturnType<typeof setLogGuide.request>) {
  const { onSuccess, onFailure, cuid, ...rest } = action.payload;
  try {
    yield callWrapperSaga(ManageService.postLogGuide, { cuid, ...rest });
    const { data } = yield callWrapperSaga(ManageService.fetchLogGuide, {
      cuid,
    });
    yield put(addLogGuide.success({ guideData: data }));
    yield fork(onSuccess);
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(setLogGuide.failure({ data, status }));
    yield fork(onFailure);
  }
}

export const SET_LOG_GUIDE = `log/SET_LOG_GUIDE`;
export const SET_LOG_GUIDE_SUCCESS = `log/SET_LOG_GUIDE_SUCCESS`;
export const SET_LOG_GUIDE_FAILURE = `log/SET_LOG_GUIDE_FAILURE`;

export const setLogGuide = createAsyncAction(
  SET_LOG_GUIDE,
  SET_LOG_GUIDE_SUCCESS,
  SET_LOG_GUIDE_FAILURE
)<
  ManageRequest & {
    onSuccess: () => void;
    onFailure: () => void;
  },
  { guideData: LogGuideResponse },
  APIError
>();

function* setLogGuideSaga(action: ReturnType<typeof setLogGuide.request>) {
  const { onSuccess, onFailure, cuid, ...rest } = action.payload;
  try {
    // const isCreated: string = yield select(GuideDataSelector.isCreated);
    yield callWrapperSaga(ManageService.putLogGuide, { cuid, ...rest });
    const { data } = yield callWrapperSaga(ManageService.fetchLogGuide, {
      cuid,
    });
    yield put(setLogGuide.success({ guideData: data }));
    yield fork(onSuccess);
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(setLogGuide.failure({ data, status }));
    yield fork(onFailure);
  }
}

export const GET_TOTAL_LOG_COLLECT_STATUS = `log/GET_TOTAL_LOG_COLLECT_STATUS`;
export const GET_TOTAL_LOG_COLLECT_STATUS_SUCCESS = `log/GET_TOTAL_LOG_COLLECT_STATUS_SUCCESS`;
export const GET_TOTAL_LOG_COLLECT_STATUS_FAILURE = `log/GET_TOTAL_LOG_COLLECT_STATUS_FAILURE`;

export const getTotalLogCollectStatus = createAsyncAction(
  GET_TOTAL_LOG_COLLECT_STATUS,
  GET_TOTAL_LOG_COLLECT_STATUS_SUCCESS,
  GET_TOTAL_LOG_COLLECT_STATUS_FAILURE
)<ManageRequest, { totalData: LogCollectStatusResponse }, APIError>();

function* getTotalLogCollectStatusSaga(
  action: ReturnType<typeof getTotalLogCollectStatus.request>
) {
  try {
    const { data }: AxiosResponse<LogCollectStatusResponse> =
      yield callWrapperSaga(
        ManageService.fetchAllLogCollectStatus,
        action.payload
      );
    const currentDate: string = yield select(currentDateSelector);

    yield put(
      getTotalLogCollectStatus.success({
        totalData: _.filter(
          data,
          ({ dateKey }) =>
            _.toNumber(dateKey) ===
            _.toNumber(
              _.join(
                _.filter(_.split(currentDate, "-"), (v) => v),
                ""
              )
            )
        ),
      })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getTotalLogCollectStatus.failure({ data, status }));
  }
}

export const GET_LOG_COLLECT_STATUS_OF_SITE = `log/GET_LOG_COLLECT_STATUS_OF_SITE`;
export const GET_LOG_COLLECT_STATUS_OF_SITE_SUCCESS = `log/GET_LOG_COLLECT_STATUS_OF_SITE_SUCCESS`;
export const GET_LOG_COLLECT_STATUS_OF_SITE_FAILURE = `log/GET_LOG_COLLECT_STATUS_OF_SITE_FAILURE`;

export const getLogCollectStatusOfSite = createAsyncAction(
  GET_LOG_COLLECT_STATUS_OF_SITE,
  GET_LOG_COLLECT_STATUS_OF_SITE_SUCCESS,
  GET_LOG_COLLECT_STATUS_OF_SITE_FAILURE
)<ManageRequest, { siteData: LogCollectStatusResponse }, APIError>();

function* getLogCollectStatusOfSiteSaga(
  action: ReturnType<typeof getLogCollectStatusOfSite.request>
) {
  try {
    const { data }: AxiosResponse<LogCollectStatusResponse> =
      yield callWrapperSaga(
        ManageService.fetchLogCollectStatusOfSite,
        action.payload
      );
    yield put(getLogCollectStatusOfSite.success({ siteData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getLogCollectStatusOfSite.failure({ data, status }));
  }
}

export const logAsyncAction = {
  getTotalLogCollectStatus,
  getLogCollectStatusOfSite,
  getLogGuide,
  setLogGuide,
  addLogGuide,
};

export default function* logSaga() {
  yield takeEvery(GET_TOTAL_LOG_COLLECT_STATUS, getTotalLogCollectStatusSaga);
  yield takeEvery(
    GET_LOG_COLLECT_STATUS_OF_SITE,
    getLogCollectStatusOfSiteSaga
  );
  yield takeEvery(GET_LOG_GUIDE, getLogGuideSaga);
  yield takeLatest(ADD_LOG_GUIDE, addLogGuideSaga);
  yield takeEvery(SET_LOG_GUIDE, setLogGuideSaga);
}
