import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import type {
  APIError,
  PersonaResponse,
  RecResultResponse,
  S001ResultResponse,
} from "types/api";
import { RootState } from "store";

import type { PersonaState } from "types/persona";

import { personaDemoAsyncAction } from "store/modules/demo/persona/saga";

export const PERSONA = "persona";

const initialState: PersonaState = {
  pr001: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  pr002: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  pr003: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  pr004: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  pr005: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  prc001: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  prc002: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  prc003: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  pro001: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  pro002: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  pro003: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  customer_c001: {
    loading: false,
    data: {
      recType: "",
      iids: null,
      cids: null,
      exiids: null,
      excids: null,
      rg: null,
      minp: null,
      maxp: null,
      products: [],
      results: [],
      groupedResults: null,
      categorizedResults: null,
    },
    error: null,
  },
  customer_b004: {
    loading: false,
    data: {
      recType: "",
      iids: null,
      cids: null,
      exiids: null,
      excids: null,
      rg: null,
      minp: null,
      maxp: null,
      products: [],
      results: [],
      groupedResults: null,
      categorizedResults: null,
    },
    error: null,
  },
  customer_a001: {
    loading: false,
    data: {
      recType: "",
      iids: null,
      cids: null,
      exiids: null,
      excids: null,
      rg: null,
      minp: null,
      maxp: null,
      products: [],
      results: [],
      groupedResults: null,
      categorizedResults: null,
    },
    error: null,
  },
  nh_prc001: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  nh_prc002: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  nh_prc003: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  nh_pro001: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  nh_pro002: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  nh_pro003: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  nh_customer_c001: {
    loading: false,
    data: {
      recType: "",
      iids: null,
      cids: null,
      exiids: null,
      excids: null,
      rg: null,
      minp: null,
      maxp: null,
      products: [],
      results: [],
      groupedResults: null,
      categorizedResults: null,
    },
    error: null,
  },
  nh_customer_b004: {
    loading: false,
    data: {
      recType: "",
      iids: null,
      cids: null,
      exiids: null,
      excids: null,
      rg: null,
      minp: null,
      maxp: null,
      products: [],
      results: [],
      groupedResults: null,
      categorizedResults: null,
    },
    error: null,
  },
  nh_customer_a001: {
    loading: false,
    data: {
      recType: "",
      iids: null,
      cids: null,
      exiids: null,
      excids: null,
      rg: null,
      minp: null,
      maxp: null,
      products: [],
      results: [],
      groupedResults: null,
      categorizedResults: null,
    },
    error: null,
  },
  prst001: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  prst002: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  prst003: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  prst004: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  prst005: {
    loading: false,
    data: {
      personaLogicType: "",
      target: {
        targetKey: "",
        targetValue: "",
      },
      personaResult: null,
      compensationResults: null,
      groupedResults: null,
    },
    error: null,
  },
  ranking_s001: {
    loading: false,
    data: {
      logicType: "",
      target: [],
      results: null,
      groupedResults: null,
      persona: null,
    },
    error: null,
  },
  weekly_s001: {
    loading: false,
    data: {
      logicType: "",
      target: [],
      results: null,
      groupedResults: null,
      persona: null,
    },
    error: null,
  },
  nhUserID: "",
};

const personaSlice = createSlice({
  name: PERSONA,
  initialState,
  reducers: {
    customerDataClear: (state) => {
      state.customer_c001.data = initialState.customer_c001.data;
      state.customer_b004.data = initialState.customer_b004.data;
      state.customer_a001.data = initialState.customer_a001.data;
    },
    nhCustomerDataClear: (state) => {
      state.nh_customer_c001.data = initialState.nh_customer_c001.data;
      state.nh_customer_b004.data = initialState.nh_customer_b004.data;
      state.nh_customer_a001.data = initialState.nh_customer_a001.data;
    },
    setNhUserID(state, action: PayloadAction<{ nhUserID: string }>) {
      state.nhUserID = action.payload.nhUserID;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr001Result.request}`,
        (state) => {
          state.pr001.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr001Result.success}`,
        (state, action: PayloadAction<{ pr001: PersonaResponse }>) => {
          state.pr001.loading = false;
          state.pr001.data = action.payload.pr001;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr001Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.pr001.loading = false;
          state.pr001.data = initialState.pr001.data;
          state.pr001.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr002Result.request}`,
        (state) => {
          state.pr002.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr002Result.success}`,
        (state, action: PayloadAction<{ pr002: PersonaResponse }>) => {
          state.pr002.loading = false;
          state.pr002.data = action.payload.pr002;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr002Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.pr002.loading = false;
          state.pr002.data = initialState.pr002.data;
          state.pr002.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr003Result.request}`,
        (state) => {
          state.pr003.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr003Result.success}`,
        (state, action: PayloadAction<{ pr003: PersonaResponse }>) => {
          state.pr003.loading = false;
          state.pr003.data = action.payload.pr003;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr003Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.pr003.loading = false;
          state.pr003.data = initialState.pr003.data;
          state.pr003.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr004Result.request}`,
        (state) => {
          state.pr004.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr004Result.success}`,
        (state, action: PayloadAction<{ pr004: PersonaResponse }>) => {
          state.pr004.loading = false;
          state.pr004.data = action.payload.pr004;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr004Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.pr004.loading = false;
          state.pr004.data = initialState.pr004.data;
          state.pr004.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr005Result.request}`,
        (state) => {
          state.pr005.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr005Result.success}`,
        (state, action: PayloadAction<{ pr005: PersonaResponse }>) => {
          state.pr005.loading = false;
          state.pr005.data = action.payload.pr005;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPr005Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.pr005.loading = false;
          state.pr005.data = initialState.pr005.data;
          state.pr005.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrc001Result.request}`,
        (state) => {
          state.prc001.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrc001Result.success}`,
        (state, action: PayloadAction<{ prc001: PersonaResponse }>) => {
          state.prc001.loading = false;
          state.prc001.data = action.payload.prc001;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrc001Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.prc001.loading = false;
          state.prc001.data = initialState.prc001.data;
          state.prc001.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrc002Result.request}`,
        (state) => {
          state.prc002.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrc002Result.success}`,
        (state, action: PayloadAction<{ prc002: PersonaResponse }>) => {
          state.prc002.loading = false;
          state.prc002.data = action.payload.prc002;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrc002Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.prc002.loading = false;
          state.prc002.data = initialState.prc002.data;
          state.prc002.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrc003Result.request}`,
        (state) => {
          state.prc003.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrc003Result.success}`,
        (state, action: PayloadAction<{ prc003: PersonaResponse }>) => {
          state.prc003.loading = false;
          state.prc003.data = action.payload.prc003;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrc003Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.prc003.loading = false;
          state.prc003.data = initialState.prc003.data;
          state.prc003.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPro001Result.request}`,
        (state) => {
          state.pro001.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPro001Result.success}`,
        (state, action: PayloadAction<{ pro001: PersonaResponse }>) => {
          state.pro001.loading = false;
          state.pro001.data = action.payload.pro001;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPro001Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.pro001.loading = false;
          state.pro001.data = initialState.pro001.data;
          state.pro001.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPro002Result.request}`,
        (state) => {
          state.pro002.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPro002Result.success}`,
        (state, action: PayloadAction<{ pro002: PersonaResponse }>) => {
          state.pro002.loading = false;
          state.pro002.data = action.payload.pro002;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPro002Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.pro002.loading = false;
          state.pro002.data = initialState.pro002.data;
          state.pro002.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPro003Result.request}`,
        (state) => {
          state.pro003.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPro003Result.success}`,
        (state, action: PayloadAction<{ pro003: PersonaResponse }>) => {
          state.pro003.loading = false;
          state.pro003.data = action.payload.pro003;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPro003Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.pro003.loading = false;
          state.pro003.data = initialState.pro003.data;
          state.pro003.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaCustomC001Result.request}`,
        (state) => {
          state.customer_c001.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaCustomC001Result.success}`,
        (
          state,
          action: PayloadAction<{ customer_c001: RecResultResponse }>
        ) => {
          state.customer_c001.loading = false;
          state.customer_c001.data = action.payload.customer_c001;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaCustomC001Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.customer_c001.loading = false;
          state.customer_c001.data = initialState.customer_c001.data;
          state.customer_c001.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaCustomB004Result.request}`,
        (state) => {
          state.customer_b004.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaCustomB004Result.success}`,
        (
          state,
          action: PayloadAction<{ customer_b004: RecResultResponse }>
        ) => {
          state.customer_b004.loading = false;
          state.customer_b004.data = action.payload.customer_b004;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaCustomB004Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.customer_b004.loading = false;
          state.customer_b004.data = initialState.customer_b004.data;
          state.customer_b004.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaCustomA001Result.request}`,
        (state) => {
          state.customer_a001.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaCustomA001Result.success}`,
        (
          state,
          action: PayloadAction<{ customer_a001: RecResultResponse }>
        ) => {
          state.customer_a001.loading = false;
          state.customer_a001.data = action.payload.customer_a001;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaCustomA001Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.customer_a001.loading = false;
          state.customer_a001.data = initialState.customer_a001.data;
          state.customer_a001.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPrc001Result.request}`,
        (state) => {
          state.nh_prc001.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPrc001Result.success}`,
        (state, action: PayloadAction<{ nh_prc001: PersonaResponse }>) => {
          state.nh_prc001.loading = false;
          state.nh_prc001.data = action.payload.nh_prc001;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPrc001Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.nh_prc001.loading = false;
          state.nh_prc001.data = initialState.nh_prc001.data;
          state.nh_prc001.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPrc002Result.request}`,
        (state) => {
          state.nh_prc002.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPrc002Result.success}`,
        (state, action: PayloadAction<{ nh_prc002: PersonaResponse }>) => {
          state.nh_prc002.loading = false;
          state.nh_prc002.data = action.payload.nh_prc002;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPrc002Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.nh_prc002.loading = false;
          state.nh_prc002.data = initialState.nh_prc002.data;
          state.nh_prc002.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPrc003Result.request}`,
        (state) => {
          state.nh_prc003.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPrc003Result.success}`,
        (state, action: PayloadAction<{ nh_prc003: PersonaResponse }>) => {
          state.nh_prc003.loading = false;
          state.nh_prc003.data = action.payload.nh_prc003;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPrc003Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.nh_prc003.loading = false;
          state.nh_prc003.data = initialState.nh_prc003.data;
          state.nh_prc003.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPro001Result.request}`,
        (state) => {
          state.nh_pro001.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPro001Result.success}`,
        (state, action: PayloadAction<{ nh_pro001: PersonaResponse }>) => {
          state.nh_pro001.loading = false;
          state.nh_pro001.data = action.payload.nh_pro001;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPro001Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.nh_pro001.loading = false;
          state.nh_pro001.data = initialState.nh_pro001.data;
          state.nh_pro001.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPro002Result.request}`,
        (state) => {
          state.nh_pro002.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPro002Result.success}`,
        (state, action: PayloadAction<{ nh_pro002: PersonaResponse }>) => {
          state.nh_pro002.loading = false;
          state.nh_pro002.data = action.payload.nh_pro002;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPro002Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.nh_pro002.loading = false;
          state.nh_pro002.data = initialState.nh_pro002.data;
          state.nh_pro002.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPro003Result.request}`,
        (state) => {
          state.nh_pro003.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPro003Result.success}`,
        (state, action: PayloadAction<{ nh_pro003: PersonaResponse }>) => {
          state.nh_pro003.loading = false;
          state.nh_pro003.data = action.payload.nh_pro003;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhPro003Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.nh_pro003.loading = false;
          state.nh_pro003.data = initialState.nh_pro003.data;
          state.nh_pro003.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhCustomC001Result.request}`,
        (state) => {
          state.nh_customer_c001.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhCustomC001Result.success}`,
        (
          state,
          action: PayloadAction<{ nh_customer_c001: RecResultResponse }>
        ) => {
          state.nh_customer_c001.loading = false;
          state.nh_customer_c001.data = action.payload.nh_customer_c001;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhCustomC001Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.nh_customer_c001.loading = false;
          state.nh_customer_c001.data = initialState.nh_customer_c001.data;
          state.nh_customer_c001.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhCustomB004Result.request}`,
        (state) => {
          state.nh_customer_b004.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhCustomB004Result.success}`,
        (
          state,
          action: PayloadAction<{ nh_customer_b004: RecResultResponse }>
        ) => {
          state.nh_customer_b004.loading = false;
          state.nh_customer_b004.data = action.payload.nh_customer_b004;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhCustomB004Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.nh_customer_b004.loading = false;
          state.nh_customer_b004.data = initialState.nh_customer_b004.data;
          state.nh_customer_b004.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhCustomA001Result.request}`,
        (state) => {
          state.nh_customer_a001.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhCustomA001Result.success}`,
        (
          state,
          action: PayloadAction<{ nh_customer_a001: RecResultResponse }>
        ) => {
          state.nh_customer_a001.loading = false;
          state.nh_customer_a001.data = action.payload.nh_customer_a001;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaNhCustomA001Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.nh_customer_a001.loading = false;
          state.nh_customer_a001.data = initialState.nh_customer_a001.data;
          state.nh_customer_a001.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst001Result.request}`,
        (state) => {
          state.prst001.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst001Result.success}`,
        (state, action: PayloadAction<{ prst001: PersonaResponse }>) => {
          state.prst001.loading = false;
          state.prst001.data = action.payload.prst001;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst001Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.prst001.loading = false;
          state.prst001.data = initialState.prst001.data;
          state.prst001.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaRankingS001Result.request}`,
        (state) => {
          state.ranking_s001.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaRankingS001Result.success}`,
        (
          state,
          action: PayloadAction<{ ranking_s001: S001ResultResponse }>
        ) => {
          state.ranking_s001.loading = false;
          state.ranking_s001.data = action.payload.ranking_s001;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaRankingS001Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.ranking_s001.loading = false;
          state.ranking_s001.data = initialState.ranking_s001.data;
          state.ranking_s001.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst002Result.request}`,
        (state) => {
          state.prst002.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst002Result.success}`,
        (state, action: PayloadAction<{ prst002: PersonaResponse }>) => {
          state.prst002.loading = false;
          state.prst002.data = action.payload.prst002;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst002Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.prst002.loading = false;
          state.prst002.data = initialState.prst002.data;
          state.prst002.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst003Result.request}`,
        (state) => {
          state.prst003.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst003Result.success}`,
        (state, action: PayloadAction<{ prst003: PersonaResponse }>) => {
          state.prst003.loading = false;
          state.prst003.data = action.payload.prst003;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst003Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.prst003.loading = false;
          state.prst003.data = initialState.prst003.data;
          state.prst003.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst004Result.request}`,
        (state) => {
          state.prst004.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst004Result.success}`,
        (state, action: PayloadAction<{ prst004: PersonaResponse }>) => {
          state.prst004.loading = false;
          state.prst004.data = action.payload.prst004;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst004Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.prst004.loading = false;
          state.prst004.data = initialState.prst004.data;
          state.prst004.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst005Result.request}`,
        (state) => {
          state.prst005.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst005Result.success}`,
        (state, action: PayloadAction<{ prst005: PersonaResponse }>) => {
          state.prst005.loading = false;
          state.prst005.data = action.payload.prst005;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaPrst005Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.prst005.loading = false;
          state.prst005.data = initialState.prst005.data;
          state.prst005.error = action.payload;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaWeeklyS001Result.request}`,
        (state) => {
          state.weekly_s001.loading = true;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaWeeklyS001Result.success}`,
        (state, action: PayloadAction<{ weekly_s001: S001ResultResponse }>) => {
          state.weekly_s001.loading = false;
          state.weekly_s001.data = action.payload.weekly_s001;
        }
      )
      .addCase(
        `${personaDemoAsyncAction.fetchPersonaWeeklyS001Result.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.weekly_s001.loading = false;
          state.weekly_s001.data = initialState.weekly_s001.data;
          state.weekly_s001.error = action.payload;
        }
      );
  },
});

const pr001ResultSelector = (state: RootState) => state[PERSONA].pr001;
const pr002ResultSelector = (state: RootState) => state[PERSONA].pr002;
const pr003ResultSelector = (state: RootState) => state[PERSONA].pr003;
const pr004ResultSelector = (state: RootState) => state[PERSONA].pr004;
const pr005ResultSelector = (state: RootState) => state[PERSONA].pr005;

const prc001ResultSelector = (state: RootState) => state[PERSONA].prc001;
const prc002ResultSelector = (state: RootState) => state[PERSONA].prc002;
const prc003ResultSelector = (state: RootState) => state[PERSONA].prc003;

const pro001ResultSelector = (state: RootState) => state[PERSONA].pro001;
const pro002ResultSelector = (state: RootState) => state[PERSONA].pro002;
const pro003ResultSelector = (state: RootState) => state[PERSONA].pro003;

const nhPrc001ResultSelector = (state: RootState) => state[PERSONA].nh_prc001;
const nhPrc002ResultSelector = (state: RootState) => state[PERSONA].nh_prc002;
const nhPrc003ResultSelector = (state: RootState) => state[PERSONA].nh_prc003;

const nhPro001ResultSelector = (state: RootState) => state[PERSONA].nh_pro001;
const nhPro002ResultSelector = (state: RootState) => state[PERSONA].nh_pro002;
const nhPro003ResultSelector = (state: RootState) => state[PERSONA].nh_pro003;

const prst001ResultSelector = (state: RootState) => state[PERSONA].prst001;
const prst002ResultSelector = (state: RootState) => state[PERSONA].prst002;
const prst003ResultSelector = (state: RootState) => state[PERSONA].prst003;
const prst004ResultSelector = (state: RootState) => state[PERSONA].prst004;
const prst005ResultSelector = (state: RootState) => state[PERSONA].prst005;

const rankingS001ResultSelector = (state: RootState) =>
  state[PERSONA].ranking_s001;
const weeklyS001ResultSelector = (state: RootState) =>
  state[PERSONA].weekly_s001;

const customerC001ResultSelector = (state: RootState) =>
  state[PERSONA].customer_c001;
const customerB004ResultSelector = (state: RootState) =>
  state[PERSONA].customer_b004;
const customerA001ResultSelector = (state: RootState) =>
  state[PERSONA].customer_a001;

const nhCustomerC001ResultSelector = (state: RootState) =>
  state[PERSONA].nh_customer_c001;
const nhCustomerB004ResultSelector = (state: RootState) =>
  state[PERSONA].nh_customer_b004;
const nhCustomerA001ResultSelector = (state: RootState) =>
  state[PERSONA].nh_customer_a001;

export const Pr001ResultSelector = {
  loading: createSelector(pr001ResultSelector, (pr001) => pr001.loading),
  data: createSelector(pr001ResultSelector, (pr001) => pr001.data),
  error: createSelector(pr001ResultSelector, (pr001) => pr001.error),
};

export const Pr002ResultSelector = {
  loading: createSelector(pr002ResultSelector, (pr002) => pr002.loading),
  data: createSelector(pr002ResultSelector, (pr002) => pr002.data),
  error: createSelector(pr002ResultSelector, (pr002) => pr002.error),
};

export const Pr003ResultSelector = {
  loading: createSelector(pr003ResultSelector, (pr003) => pr003.loading),
  data: createSelector(pr003ResultSelector, (pr003) => pr003.data),
  error: createSelector(pr003ResultSelector, (pr003) => pr003.error),
};

export const Pr004ResultSelector = {
  loading: createSelector(pr004ResultSelector, (pr004) => pr004.loading),
  data: createSelector(pr004ResultSelector, (pr004) => pr004.data),
  error: createSelector(pr004ResultSelector, (pr004) => pr004.error),
};

export const Pr005ResultSelector = {
  loading: createSelector(pr005ResultSelector, (pr005) => pr005.loading),
  data: createSelector(pr005ResultSelector, (pr005) => pr005.data),
  error: createSelector(pr005ResultSelector, (pr005) => pr005.error),
};

export const Prc001ResultSelector = {
  loading: createSelector(prc001ResultSelector, (prc001) => prc001.loading),
  data: createSelector(prc001ResultSelector, (prc001) => prc001.data),
  error: createSelector(prc001ResultSelector, (prc001) => prc001.error),
};

export const Prc002ResultSelector = {
  loading: createSelector(prc002ResultSelector, (prc002) => prc002.loading),
  data: createSelector(prc002ResultSelector, (prc002) => prc002.data),
  error: createSelector(prc002ResultSelector, (prc002) => prc002.error),
};

export const Prc003ResultSelector = {
  loading: createSelector(prc003ResultSelector, (prc003) => prc003.loading),
  data: createSelector(prc003ResultSelector, (prc003) => prc003.data),
  error: createSelector(prc003ResultSelector, (prc003) => prc003.error),
};

export const Pro001ResultSelector = {
  loading: createSelector(pro001ResultSelector, (pro001) => pro001.loading),
  data: createSelector(pro001ResultSelector, (pro001) => pro001.data),
  error: createSelector(pro001ResultSelector, (pro001) => pro001.error),
};

export const Pro002ResultSelector = {
  loading: createSelector(pro002ResultSelector, (pro002) => pro002.loading),
  data: createSelector(pro002ResultSelector, (pro002) => pro002.data),
  error: createSelector(pro002ResultSelector, (pro002) => pro002.error),
};

export const Pro003ResultSelector = {
  loading: createSelector(pro003ResultSelector, (pro003) => pro003.loading),
  data: createSelector(pro003ResultSelector, (pro003) => pro003.data),
  error: createSelector(pro003ResultSelector, (pro003) => pro003.error),
};

export const NhPrc001ResultSelector = {
  loading: createSelector(
    nhPrc001ResultSelector,
    (nh_prc001) => nh_prc001.loading
  ),
  data: createSelector(nhPrc001ResultSelector, (nh_prc001) => nh_prc001.data),
  error: createSelector(nhPrc001ResultSelector, (nh_prc001) => nh_prc001.error),
};

export const NhPrc002ResultSelector = {
  loading: createSelector(
    nhPrc002ResultSelector,
    (nh_prc002) => nh_prc002.loading
  ),
  data: createSelector(nhPrc002ResultSelector, (nh_prc002) => nh_prc002.data),
  error: createSelector(nhPrc002ResultSelector, (nh_prc002) => nh_prc002.error),
};

export const NhPrc003ResultSelector = {
  loading: createSelector(
    nhPrc003ResultSelector,
    (nh_prc003) => nh_prc003.loading
  ),
  data: createSelector(nhPrc003ResultSelector, (nh_prc003) => nh_prc003.data),
  error: createSelector(nhPrc003ResultSelector, (nh_prc003) => nh_prc003.error),
};

export const NhPro001ResultSelector = {
  loading: createSelector(
    nhPro001ResultSelector,
    (nh_pro001) => nh_pro001.loading
  ),
  data: createSelector(nhPro001ResultSelector, (nh_pro001) => nh_pro001.data),
  error: createSelector(nhPro001ResultSelector, (nh_pro001) => nh_pro001.error),
};

export const NhPro002ResultSelector = {
  loading: createSelector(
    nhPro002ResultSelector,
    (nh_pro002) => nh_pro002.loading
  ),
  data: createSelector(nhPro002ResultSelector, (nh_pro002) => nh_pro002.data),
  error: createSelector(nhPro002ResultSelector, (nh_pro002) => nh_pro002.error),
};

export const NhPro003ResultSelector = {
  loading: createSelector(
    nhPro003ResultSelector,
    (nh_pro003) => nh_pro003.loading
  ),
  data: createSelector(nhPro003ResultSelector, (nh_pro003) => nh_pro003.data),
  error: createSelector(nhPro003ResultSelector, (nh_pro003) => nh_pro003.error),
};

export const CustomerC001ResultSelector = {
  loading: createSelector(
    customerC001ResultSelector,
    (customer_c001) => customer_c001.loading
  ),
  data: createSelector(
    customerC001ResultSelector,
    (customer_c001) => customer_c001.data
  ),
  error: createSelector(
    customerC001ResultSelector,
    (customer_c001) => customer_c001.error
  ),
};

export const CustomerB004ResultSelector = {
  loading: createSelector(
    customerB004ResultSelector,
    (customer_b004) => customer_b004.loading
  ),
  data: createSelector(
    customerB004ResultSelector,
    (customer_b004) => customer_b004.data
  ),
  error: createSelector(
    customerB004ResultSelector,
    (customer_b004) => customer_b004.error
  ),
};

export const CustomerA001ResultSelector = {
  loading: createSelector(
    customerA001ResultSelector,
    (customer_a001) => customer_a001.loading
  ),
  data: createSelector(
    customerA001ResultSelector,
    (customer_a001) => customer_a001.data
  ),
  error: createSelector(
    customerA001ResultSelector,
    (customer_a001) => customer_a001.error
  ),
};

export const NhCustomerC001ResultSelector = {
  loading: createSelector(
    nhCustomerC001ResultSelector,
    (nh_customer_c001) => nh_customer_c001.loading
  ),
  data: createSelector(
    nhCustomerC001ResultSelector,
    (nh_customer_c001) => nh_customer_c001.data
  ),
  error: createSelector(
    nhCustomerC001ResultSelector,
    (nh_customer_c001) => nh_customer_c001.error
  ),
};

export const NhCustomerB004ResultSelector = {
  loading: createSelector(
    nhCustomerB004ResultSelector,
    (nh_customer_b004) => nh_customer_b004.loading
  ),
  data: createSelector(
    nhCustomerB004ResultSelector,
    (nh_customer_b004) => nh_customer_b004.data
  ),
  error: createSelector(
    nhCustomerB004ResultSelector,
    (nh_customer_b004) => nh_customer_b004.error
  ),
};

export const NhCustomerA001ResultSelector = {
  loading: createSelector(
    nhCustomerA001ResultSelector,
    (nh_customer_a001) => nh_customer_a001.loading
  ),
  data: createSelector(
    nhCustomerA001ResultSelector,
    (nh_customer_a001) => nh_customer_a001.data
  ),
  error: createSelector(
    nhCustomerA001ResultSelector,
    (nh_customer_a001) => nh_customer_a001.error
  ),
};

export const Prst001ResultSelector = {
  loading: createSelector(prst001ResultSelector, (prst001) => prst001.loading),
  data: createSelector(prst001ResultSelector, (prst001) => prst001.data),
  error: createSelector(prst001ResultSelector, (prst001) => prst001.error),
};

export const Prst002ResultSelector = {
  loading: createSelector(prst002ResultSelector, (prst002) => prst002.loading),
  data: createSelector(prst002ResultSelector, (prst002) => prst002.data),
  error: createSelector(prst002ResultSelector, (prst002) => prst002.error),
};

export const Prst003ResultSelector = {
  loading: createSelector(prst003ResultSelector, (prst003) => prst003.loading),
  data: createSelector(prst003ResultSelector, (prst003) => prst003.data),
  error: createSelector(prst003ResultSelector, (prst003) => prst003.error),
};

export const Prst004ResultSelector = {
  loading: createSelector(prst004ResultSelector, (prst004) => prst004.loading),
  data: createSelector(prst004ResultSelector, (prst004) => prst004.data),
  error: createSelector(prst004ResultSelector, (prst004) => prst004.error),
};

export const Prst005ResultSelector = {
  loading: createSelector(prst005ResultSelector, (prst005) => prst005.loading),
  data: createSelector(prst005ResultSelector, (prst005) => prst005.data),
  error: createSelector(prst005ResultSelector, (prst005) => prst005.error),
};

export const RankingS001ResultSelector = {
  loading: createSelector(
    rankingS001ResultSelector,
    (ranking_s001) => ranking_s001.loading
  ),
  data: createSelector(
    rankingS001ResultSelector,
    (ranking_s001) => ranking_s001.data
  ),
  error: createSelector(
    rankingS001ResultSelector,
    (ranking_s001) => ranking_s001.error
  ),
};

export const WeeklyS001ResultSelector = {
  loading: createSelector(
    weeklyS001ResultSelector,
    (weekly_s001) => weekly_s001.loading
  ),
  data: createSelector(
    weeklyS001ResultSelector,
    (weekly_s001) => weekly_s001.data
  ),
  error: createSelector(
    weeklyS001ResultSelector,
    (weekly_s001) => weekly_s001.error
  ),
};
const selfSelector = (state: RootState) => state[PERSONA];

export const nhUserIDSelector = createSelector(
  selfSelector,
  (state) => state.nhUserID
);

export const personaAction = personaSlice.actions;
export const personaReducer = personaSlice.reducer;
