import { useComponentId, useReportData } from "hooks";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { DailyDataSelector } from "store/modules/report";
import { reportAsyncAction } from "store/modules/report/saga";
import _ from "lodash";
import toFixed from "utils/toFixed";

const useReportDailyPerformanceData = () => {
  const componentId = useComponentId();
  const { startDate, endDate, channel } = useReportData();
  const dispatch = useAppDispatch();

  const [dailyDataLoading, dailyData, dailyDataError] = [
    useAppSelector(DailyDataSelector.loading),
    useAppSelector(DailyDataSelector.data),
    useAppSelector(DailyDataSelector.error),
  ];

  const getDailyPerformance = useCallback(
    ({ cuid }: { cuid: string }) => {
      dispatch(
        reportAsyncAction.getDailyPerformance.request({
          startDate,
          endDate,
          cuid,
          channel,
        })
      );
    },
    [dispatch, channel, startDate, endDate]
  );

  const { dailyPerformanceReportTableData, dailyPerformanceRatioTableData } =
    useMemo(() => {
      const dailyPerformanceReportTableData = _.map(
        dailyData,
        (
          {
            date,
            values: {
              viewCount,
              orderCount,
              revenue,
              conversion1,
              sessionCount,
              conversionCount,
              conversion2,
              noViewCount,
              noOrderCount,
              noRevenue,
              noConversion1,
              noSessionCount,
              noConversionCount,
              noConversion2,
            },
          },
          idx
        ) => {
          return {
            key: `${componentId}-daily-performance-report-table-data-${idx}`,
            date,
            viewCount,
            orderCount,
            revenue,
            conversion1,
            sessionCount,
            conversionCount,
            conversion2,
            noViewCount,
            noOrderCount,
            noRevenue,
            noConversion1,
            noSessionCount,
            noConversionCount,
            noConversion2,
          };
        }
      );

      const dailyPerformanceRatioTableData = _.map(
        dailyData,
        (
          {
            date,
            values: {
              orderCount,
              noOrderCount,
              revenue,
              noRevenue,
              sessionCount,
              noSessionCount,
              conversionCount,
              noConversionCount,
              conversion1,
              noConversion1,
              conversion2,
              noConversion2,
            },
          },
          idx
        ) => {
          return {
            key: `${componentId}-daily-performance-ratio-table-data=${idx}`,
            date,
            orderCount: {
              recRatio: _.defaultTo(
                _.toNumber(
                  toFixed(2)((orderCount / (orderCount + noOrderCount)) * 100)
                ),
                0
              ),
              normalRatio: _.defaultTo(
                _.toNumber(
                  toFixed(2)((noOrderCount / (orderCount + noOrderCount)) * 100)
                ),
                0
              ),
            },
            revenue: {
              recRatio: _.defaultTo(
                _.toNumber(toFixed(2)((revenue / (revenue + noRevenue)) * 100)),
                0
              ),
              normalRatio: _.defaultTo(
                _.toNumber(
                  toFixed(2)((noRevenue / (revenue + noRevenue)) * 100)
                ),
                0
              ),
            },
            sessionCount: {
              recRatio: _.defaultTo(
                _.toNumber(
                  toFixed(2)(
                    (sessionCount / (sessionCount + noSessionCount)) * 100
                  )
                ),
                0
              ),
              normalRatio: _.defaultTo(
                _.toNumber(
                  toFixed(2)(
                    (noSessionCount / (sessionCount + noSessionCount)) * 100
                  )
                ),
                0
              ),
            },
            conversionCount: {
              recRatio: _.defaultTo(
                _.toNumber(
                  toFixed(2)(
                    (conversionCount / (conversionCount + noConversionCount)) *
                      100
                  )
                ),
                0
              ),
              normalRatio: _.defaultTo(
                _.toNumber(
                  toFixed(2)(
                    (noConversionCount /
                      (conversionCount + noConversionCount)) *
                      100
                  )
                ),
                0
              ),
            },
            conversion1: {
              recRatio: conversion1,
              normalRatio: noConversion1,
            },
            conversion2: {
              recRatio: conversion2,
              normalRatio: noConversion2,
            },
            conversionDiffByRec: {
              conversion1Ratio: _.toNumber(
                toFixed(2)(conversion1 - noConversion1)
              ),
              conversion2Ratio: _.toNumber(
                toFixed(2)(conversion2 - noConversion2)
              ),
            },
          };
        }
      );

      return {
        dailyPerformanceReportTableData,
        dailyPerformanceRatioTableData,
      };
    }, [componentId, dailyData]);

  return {
    dailyDataLoading,
    dailyDataError,
    dailyPerformanceReportTableData,
    dailyPerformanceRatioTableData,
    getDailyPerformance,
  };
};
export default useReportDailyPerformanceData;
