import { createAsyncAction } from "typesafe-actions";
import { APIError } from "types/api";
import {
  BrandCategoryListResponse,
  BrandCategoryListRequest,
  ProductListRequest,
  ProductTransitionChartRequest,
  ProductPerformanceChartRequest,
  ProductPerformanceChartResponse,
} from "types/api";
import { AxiosResponse } from "axios";
import { put, takeLatest } from "@redux-saga/core/effects";
import { callWrapperSaga } from "utils/callWrapperSaga";
import ProductReportService from "apis/services/productReportService";

export const FETCH_BRAND_CATEGORY_LIST_RESULT =
  "product/FETCH_BRAND_CATEGORY_LIST_RESULT";
export const FETCH_BRAND_CATEGORY_LIST_RESULT_SUCCESS =
  "product/FETCH_BRAND_CATEGORY_LIST_RESULT_SUCCESS";
export const FETCH_BRAND_CATEGORY_LIST_RESULT_FAILURE =
  "product/FETCH_BRAND_CATEGORY_LIST_RESULT_FAILURE";

export const fetchBrandCategoryList = createAsyncAction(
  FETCH_BRAND_CATEGORY_LIST_RESULT,
  FETCH_BRAND_CATEGORY_LIST_RESULT_SUCCESS,
  FETCH_BRAND_CATEGORY_LIST_RESULT_FAILURE
)<BrandCategoryListRequest, { data: BrandCategoryListResponse }, APIError>();

function* fetchBrandCategoryListSaga(
  action: ReturnType<typeof fetchBrandCategoryList.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      ProductReportService.fetchBrandCategoryListResult,
      action.payload
    );

    yield put(
      fetchBrandCategoryList.success({
        data: {
          brandNameList: data.data.brandNameList,
          categoryList: data.data.categoryList,
        },
      })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchBrandCategoryList.failure({ data, status }));
  }
}

export const FETCH_PRODUCT_REPORT_LIST_RESULT =
  "product/FETCH_PRODUCT_REPORT_LIST_RESULT";
export const FETCH_PRODUCT_REPORT_LIST_RESULT_SUCCESS =
  "product/FETCH_PRODUCT_REPORT_LIST_RESULT_SUCCESS";
export const FETCH_PRODUCT_REPORT_LIST_RESULT_FAILURE =
  "product/FETCH_PRODUCT_REPORT_LIST_RESULT_FAILURE";

export const fetchProductReportList = createAsyncAction(
  FETCH_PRODUCT_REPORT_LIST_RESULT,
  FETCH_PRODUCT_REPORT_LIST_RESULT_SUCCESS,
  FETCH_PRODUCT_REPORT_LIST_RESULT_FAILURE
)<ProductListRequest, { data: string[] }, APIError>();

function* fetchProductReportListSaga(
  action: ReturnType<typeof fetchProductReportList.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      ProductReportService.fetchProductListResult,
      action.payload
    );

    yield put(
      fetchProductReportList.success({
        data: data.data,
      })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchProductReportList.failure({ data, status }));
  }
}

export const FETCH_PRODUCT_REPORT_TRANSITION_CHART_RESULT =
  "product/FETCH_PRODUCT_REPORT_TRANSITION_CHART_RESULT";
export const FETCH_PRODUCT_REPORT_TRANSITION_CHART_RESULT_SUCCESS =
  "product/FETCH_PRODUCT_REPORT_TRANSITION_CHART_RESULT_SUCCESS";
export const FETCH_PRODUCT_REPORT_TRANSITION_CHART_RESULT_FAILURE =
  "product/FETCH_PRODUCT_REPORT_TRANSITION_CHART_RESULT_FAILURE";

export const fetchProductTransitionChart = createAsyncAction(
  FETCH_PRODUCT_REPORT_TRANSITION_CHART_RESULT,
  FETCH_PRODUCT_REPORT_TRANSITION_CHART_RESULT_SUCCESS,
  FETCH_PRODUCT_REPORT_TRANSITION_CHART_RESULT_FAILURE
)<ProductTransitionChartRequest, { data: string[] }, APIError>();

function* fetchProductTransitionChartSaga(
  action: ReturnType<typeof fetchProductTransitionChart.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      ProductReportService.fetchProductTransitionChartResult,
      action.payload
    );
    yield put(
      fetchProductTransitionChart.success({
        data: data.data,
      })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchProductTransitionChart.failure({ data, status }));
  }
}

export const FETCH_PRODUCT_REPORT_PERFORMANCE_CHART_RESULT =
  "product/FETCH_PRODUCT_REPORT_PERFORMANCE_CHART_RESULT";
export const FETCH_PRODUCT_REPORT_PERFORMANCE_CHART_RESULT_SUCCESS =
  "product/FETCH_PRODUCT_REPORT_PERFORMANCE_CHART_RESULT_SUCCESS";
export const FETCH_PRODUCT_REPORT_PERFORMANCE_CHART_RESULT_FAILURE =
  "product/FETCH_PRODUCT_REPORT_PERFORMANCE_CHART_RESULT_FAILURE";

export const fetchProductPerformanceChart = createAsyncAction(
  FETCH_PRODUCT_REPORT_PERFORMANCE_CHART_RESULT,
  FETCH_PRODUCT_REPORT_PERFORMANCE_CHART_RESULT_SUCCESS,
  FETCH_PRODUCT_REPORT_PERFORMANCE_CHART_RESULT_FAILURE
)<
  ProductPerformanceChartRequest,
  { data: ProductPerformanceChartResponse },
  APIError
>();

function* fetchProductPerformanceChartSaga(
  action: ReturnType<typeof fetchProductPerformanceChart.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      ProductReportService.fetchProductPerformanceChartResult,
      action.payload
    );

    yield put(
      fetchProductPerformanceChart.success({
        data: {
          firstWeekData: data.data.firstWeekData,
          secondWeekData: data.data.secondWeekData,
          thirdWeekData: data.data.thirdWeekData,
          fourthWeekData: data.data.fourthWeekData,
        },
      })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchProductPerformanceChart.failure({ data, status }));
  }
}

export const productReportAsyncAction = {
  fetchBrandCategoryList,
  fetchProductReportList,
  fetchProductTransitionChart,
  fetchProductPerformanceChart,
};

export default function* productReportSaga() {
  yield takeLatest(
    FETCH_BRAND_CATEGORY_LIST_RESULT,
    fetchBrandCategoryListSaga
  );
  yield takeLatest(
    FETCH_PRODUCT_REPORT_LIST_RESULT,
    fetchProductReportListSaga
  );
  yield takeLatest(
    FETCH_PRODUCT_REPORT_TRANSITION_CHART_RESULT,
    fetchProductTransitionChartSaga
  );
  yield takeLatest(
    FETCH_PRODUCT_REPORT_PERFORMANCE_CHART_RESULT,
    fetchProductPerformanceChartSaga
  );
}
