import { useCallback } from "react";

import { useAppSelector, useAppDispatch } from "store";
import { recDemoAsyncAction } from "store/modules/demo/rec/saga";
import { recAction } from "store/modules/demo/rec/index";

import { RecTypeSelector, RecResultSelector } from "store/modules/demo/rec";

const useRecDemoData = () => {
  const dispatch = useAppDispatch();

  const [recTypeLoading, recType, recTypeError] = [
    useAppSelector(RecTypeSelector.loading),
    useAppSelector(RecTypeSelector.data),
    useAppSelector(RecTypeSelector.error),
  ];

  const [recResultLoading, recResult, recResultError] = [
    useAppSelector(RecResultSelector.loading),
    useAppSelector(RecResultSelector.data),
    useAppSelector(RecResultSelector.error),
  ];
  const getRecTypePerCuid = useCallback(
    ({ cuid }: { cuid: string }) => {
      dispatch(
        recDemoAsyncAction.getRecTypes.request({
          cuid,
        })
      );
    },
    [dispatch]
  );

  const clearFetchResult = useCallback(() => {
    dispatch(recAction.clear());
  }, [dispatch]);

  const fetchRecResult = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        recDemoAsyncAction.fetchRecResult.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  return {
    recTypeLoading,
    recType,
    recTypeError,
    getRecTypePerCuid,
    fetchRecResult,
    clearFetchResult,
    recResultLoading,
    recResult,
    recResultError,
  };
};

export default useRecDemoData;
