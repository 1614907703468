import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import type { RootState } from "store";
import {
  APIError,
  LogCollectStatusResponse,
  LogGuideResponse,
} from "types/api";
import { LogState, Status } from "types/log";
import { format } from "date-fns";
import { LOG_STATUS } from "assets/constants/string";
import { logAsyncAction } from "./saga";
const { ALL } = LOG_STATUS;

export const LOG = "log";

const todayDateStr = format(new Date(), "yyyy-MM-dd");

const initialState: LogState = {
  currentDate: todayDateStr,
  status: ALL,
  search: "",
  total: {
    loading: false,
    data: null,
    error: null,
  },
  guide: {
    loading: false,
    data: null,
    error: null,
  },
  site: {
    loading: false,
    data: null,
    error: null,
  },
};

const logSlice = createSlice({
  name: LOG,
  initialState,
  reducers: {
    setCurrentDate(state, action: PayloadAction<{ currentDate: string }>) {
      state.currentDate = action.payload.currentDate;
    },
    setSearch(state, action: PayloadAction<{ search: string }>) {
      state.search = action.payload.search;
    },
    setStatus(
      state,
      action: PayloadAction<{
        status: Status;
      }>
    ) {
      state.status = action.payload.status;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        `${logAsyncAction.getTotalLogCollectStatus.request}`,
        (state) => {
          state.total.loading = true;
        }
      )
      .addCase(
        `${logAsyncAction.getTotalLogCollectStatus.success}`,
        (
          state,
          action: PayloadAction<{ totalData: LogCollectStatusResponse }>
        ) => {
          state.total.loading = false;
          state.total.data = action.payload.totalData;
        }
      )
      .addCase(
        `${logAsyncAction.getTotalLogCollectStatus.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.total.loading = false;
          state.total.error = action.payload;
        }
      )
      .addCase(
        `${logAsyncAction.getLogCollectStatusOfSite.request}`,
        (state) => {
          state.site.loading = true;
        }
      )
      .addCase(
        `${logAsyncAction.getLogCollectStatusOfSite.success}`,
        (
          state,
          action: PayloadAction<{ siteData: LogCollectStatusResponse }>
        ) => {
          state.site.loading = false;
          state.site.data = action.payload.siteData;
        }
      )
      .addCase(
        `${logAsyncAction.getLogCollectStatusOfSite.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.site.loading = false;
          state.site.error = action.payload;
        }
      )
      .addCase(`${logAsyncAction.getLogGuide.request}`, (state) => {
        state.guide.loading = true;
      })
      .addCase(
        `${logAsyncAction.getLogGuide.success}`,
        (state, action: PayloadAction<{ guideData: LogGuideResponse }>) => {
          state.guide.loading = false;
          state.guide.data = action.payload.guideData;
        }
      )
      .addCase(
        `${logAsyncAction.getLogGuide.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.guide.loading = false;
          state.guide.error = action.payload;
        }
      )
      .addCase(`${logAsyncAction.addLogGuide.request}`, (state) => {
        state.guide.loading = true;
      })
      .addCase(
        `${logAsyncAction.addLogGuide.success}`,
        (state, action: PayloadAction<{ guideData: LogGuideResponse }>) => {
          state.guide.loading = false;
          state.guide.data = action.payload.guideData;
        }
      )
      .addCase(
        `${logAsyncAction.addLogGuide.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.guide.loading = false;
          state.guide.error = action.payload;
        }
      )
      .addCase(`${logAsyncAction.setLogGuide.request}`, (state) => {
        state.guide.loading = true;
      })
      .addCase(
        `${logAsyncAction.setLogGuide.success}`,
        (state, action: PayloadAction<{ guideData: LogGuideResponse }>) => {
          state.guide.loading = false;
          state.guide.data = action.payload.guideData;
        }
      )
      .addCase(
        `${logAsyncAction.setLogGuide.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.guide.loading = false;
          state.guide.error = action.payload;
        }
      ),
});

const selfSelector = (state: RootState) => state[LOG];

const totalSelector = createSelector(selfSelector, (state) => state.total);
const siteSelector = createSelector(selfSelector, (state) => state.site);
export const currentDateSelector = createSelector(
  selfSelector,
  (state) => state.currentDate
);
export const guideSelector = createSelector(
  selfSelector,
  (state) => state.guide
);
export const statusSelector = createSelector(
  selfSelector,
  (state) => state.status
);
export const searchSelector = createSelector(
  selfSelector,
  (state) => state.search
);

export const GuideDataSelector = {
  loading: createSelector(guideSelector, (guide) => guide.loading),
  data: createSelector(guideSelector, (guide) => guide.data),
  isCreated: createSelector(guideSelector, (guide) => guide.data?.createdDate),
  error: createSelector(guideSelector, (guide) => guide.error),
};

export const TotalDataSelector = {
  loading: createSelector(totalSelector, (total) => total.loading),
  data: createSelector(totalSelector, (total) => total.data),
  error: createSelector(totalSelector, (total) => total.error),
};

export const SiteDataSelector = {
  loading: createSelector(siteSelector, (site) => site.loading),
  data: createSelector(siteSelector, (site) => site.data),
  error: createSelector(siteSelector, (site) => site.error),
};

export const logAction = logSlice.actions;
export const logReducer = logSlice.reducer;
