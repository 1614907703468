import {
  CrawlingResponse,
  CrawlingState,
  KeywordRequest,
  coupangProductList,
} from "../../../types/blog";
import { createAsyncAction } from "typesafe-actions";
import {
  put,
  takeLatest,
  takeEvery,
  select,
  call,
  race,
  delay,
} from "redux-saga/effects";
import { AxiosResponse } from "axios";
import _ from "lodash";
import { callWrapperSaga } from "utils/callWrapperSaga";
import blogService from "apis/services/blogService";
import { APIError, UserResponse } from "types/api";
import {
  ProductRequest,
  ProductsResponse,
  LabelTemplateResponse,
  LabelDataRequest,
  LabelDataResponse,
  KeywordResponse,
  CriteriaRequest,
  CriteriaResponse,
  VideoRequest,
} from "types/blog";
import blogService02 from "apis/services/blogServiceV2";
import { LoginStatusSelector } from "../user";
import { addReqRequestId } from ".";
import { message } from "antd";
// CRITERIA
export const FETCH_CRITERIA = "blog/FETCH_CRITERIA";
export const FFETCH_CRITERIA_SUCESS = "blog/FETCH_CRITERIA_SUCESS";
export const FETCH_CRITERIA_FAIL = "blog/FETCH_CRITERIA_FAIL";

export const fetchCriteria = createAsyncAction(
  FETCH_CRITERIA, // request
  FFETCH_CRITERIA_SUCESS, // success
  FETCH_CRITERIA_FAIL // fail
)<CriteriaRequest, { criteria: CriteriaResponse }, APIError>();

function* fetchCriteriaSaga(action: ReturnType<typeof fetchCriteria.request>) {
  try {
    const { data }: AxiosResponse<CriteriaResponse> = yield callWrapperSaga(
      blogService.getCriteria,
      action.payload
    );
    if (!data) {
      yield;
    }
    yield put(fetchCriteria.success({ criteria: data }));
  } catch (response) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchCriteria.failure({ data, status }));
  }
}

// PRODUCTS
export const FETCH_PRODUCTS = "blog/FETCH_PRODUCTS";
export const FETCH_PRODUCTS_SUCESS = "blog/FETCH_PRODUCTS_SUCESS";
export const FETCH_PRODUCTS_FAIL = "blog/FETCH_PRODUCTS_FAIL";

export const fetchProducts = createAsyncAction(
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCESS,
  FETCH_PRODUCTS_FAIL
)<ProductRequest, { products: ProductsResponse }, APIError>(); //순서대로 "입력받는타입", "반환되는타입", "에러타입"

function* fetchBlogSaga(action: ReturnType<typeof fetchProducts.request>) {
  try {
    const { data }: AxiosResponse<ProductsResponse> = yield callWrapperSaga(
      blogService.getProducts,
      action.payload
    );
    const dataBase64: AxiosResponse<ProductsResponse> = yield callWrapperSaga(
      blogService02.getTransImageUrlLists,
      data.list.map((el) => el.itemImage)
    );
    data.list.map((el, index: number) => {
      return (el.itemImage = `data:image/*;base64,${dataBase64.data.list[index]}`);
    });
    yield put(fetchProducts.success({ products: data }));
  } catch (response) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchProducts.failure({ data, status }));
  }
}

// LABEL TEMPLATE
export const FETCH_LABEL_TEMPLATE = "blog/FETCH_LABEL_TEMPLATE";
export const FETCH_LABEL_TEMPLATE_SUCESS = "blog/FETCH_LABEL_TEMPLATE_SUCESS";
export const FETCH_LABEL_TEMPLATE_FAIL = "blog/FETCH_LABEL_TEMPLATE_FAIL";

export const fetchLabelTemplate = createAsyncAction(
  FETCH_LABEL_TEMPLATE,
  FETCH_LABEL_TEMPLATE_SUCESS,
  FETCH_LABEL_TEMPLATE_FAIL
)<undefined, { labelTemplate: LabelTemplateResponse }, APIError>();

function* fetchLabelTemplateSaga(
  action: ReturnType<typeof fetchLabelTemplate.request>
) {
  try {
    const { data }: AxiosResponse<LabelTemplateResponse> =
      yield callWrapperSaga(blogService.getLabelTemplate);

    yield put(fetchLabelTemplate.success({ labelTemplate: data }));
  } catch (response) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchLabelTemplate.failure({ data, status }));
  }
}

// LABEL DATA
export const FETCH_LABEL_DATA = "blog/FETCH_LABEL_DATA";
export const FETCH_LABEL_DATA_SUCESS = "blog/FETCH_LABEL_DATA_SUCESS";
export const FETCH_LABEL_DATA_FAIL = "blog/FETCH_LABEL_DATA_FAIL";

export const fetchLabelData = createAsyncAction(
  FETCH_LABEL_DATA,
  FETCH_LABEL_DATA_SUCESS,
  FETCH_LABEL_DATA_FAIL
)<LabelDataRequest, { labelData: LabelDataResponse }, APIError>();

function* fetchLabelDataSaga(
  action: ReturnType<typeof fetchLabelData.request>
) {
  try {
    const { data }: AxiosResponse<LabelDataResponse> = yield callWrapperSaga(
      blogService.getLabelData,
      action.payload
    );

    yield put(fetchLabelData.success({ labelData: data }));
  } catch (response) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchLabelData.failure({ data, status }));
  }
}

// KEYWORDS
export const FETCH_KEYWORDS = "blog/FETCH_KEYWORDS";
export const FETCH_KEYWORDS_SUCESS = "blog/FETCH_KEYWORDS_SUCESS";
export const FETCH_KEYWORDS_FAIL = "blog/FETCH_KEYWORDS_FAIL";

export const fetchKeyword = createAsyncAction(
  FETCH_KEYWORDS,
  FETCH_KEYWORDS_SUCESS,
  FETCH_KEYWORDS_FAIL
)<KeywordRequest, { keywordData: KeywordResponse }, APIError>();

function* fetchKeywordSaga(action: ReturnType<typeof fetchKeyword.request>) {
  try {
    const { data }: AxiosResponse<KeywordResponse> = yield callWrapperSaga(
      blogService.getKeywords,
      action.payload
    );

    yield put(fetchKeyword.success({ keywordData: data }));
  } catch (response) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchLabelData.failure({ data, status }));
  }
}

// Translate
export const FETCH_TRANSLATE = "blog/FETCH_TRANSLATE";
export const FETCH_TRANSLATE_SUCESS = "blog/FETCH_TRANSLATE_SUCESS";
export const FETCH_TRANSLATE_FAIL = "blog/FETCH_TRANSLATE_FAIL";

interface TranslatedText {
  data: {
    translatedText: string;
  };
}

export const fetchTranslate = createAsyncAction(
  FETCH_TRANSLATE,
  FETCH_TRANSLATE_SUCESS,
  FETCH_TRANSLATE_FAIL
)<string[], TranslatedText, APIError>();

function* fetchTranslateSaga(
  action: ReturnType<typeof fetchTranslate.request>
) {
  try {
    const { data }: AxiosResponse<any> = yield callWrapperSaga(
      blogService.getTranslatedText,
      action.payload
    );

    yield put(fetchTranslate.success(data));
  } catch (response) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchLabelData.failure({ data, status }));
  }
}

// 이미지 S3에 저장
export const FETCH_IMG_URL = "blog/FETCH_IMG_URL";
export const FETCH_IMG_URL_SUCESS = "blog/FETCH_IMG_URL_SUCESS";
export const FETCH_IMG_URL_FAIL = "blog/FETCH_IMG_URL_FAIL";

export const fetchImgUrl = createAsyncAction(
  FETCH_IMG_URL,
  FETCH_IMG_URL_SUCESS,
  FETCH_IMG_URL_FAIL
)<{ data: FormData; prdId: number }, { data: any; prdId: number }, APIError>();

function* fetchImgUrlSaga(action: ReturnType<typeof fetchImgUrl.request>) {
  try {
    const { data }: AxiosResponse<any> = yield callWrapperSaga(
      blogService.getUploadedImageUrl,
      action.payload.data
    );
    const { prdId } = action.payload;
    yield put(fetchImgUrl.success({ data, prdId }));
  } catch (response) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchImgUrl.failure({ data, status }));
  }
}

// 이미지, 더빙텍스트 서버에 올려서 비디오 내려받기
export const FETCH_VIDEO = "blog/FETCH_VIDEO";
export const FETCH_VIDEO_SUCESS = "blog/FETCH_VIDEO_SUCESS";
export const FETCH_VIDEO_FAIL = "blog/FETCH_VIDEO_FAIL";

export const fetchVideo = createAsyncAction(
  FETCH_VIDEO,
  FETCH_VIDEO_SUCESS,
  FETCH_VIDEO_FAIL
)<VideoRequest, any, APIError>();

function* fetchVideoSaga(action: ReturnType<typeof fetchVideo.request>) {
  try {
    const { data }: AxiosResponse<any> = yield callWrapperSaga(
      blogService.getVideoByImage,
      action.payload
    );

    yield put(fetchVideo.success(data));
  } catch (response) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchVideo.failure({ data, status }));
  }
}

// 쿠팡 파트너스 크롤링
export const CRAWLING = "blog/CRAWLING";
export const CRAWLING_SUCESS = "blog/CRAWLING_SUCESS";
export const CRAWLING_FAIL = "blog/CRAWLING_FAIL";

export const crwalingProduct = createAsyncAction(
  CRAWLING,
  CRAWLING_SUCESS,
  CRAWLING_FAIL
)<CrawlingState, { coupangPartners: coupangProductList }, APIError>();
function* waitUntilListIsReady(
  apiFunction: (
    requestIdForLambda: string
  ) => Promise<AxiosResponse<coupangProductList>>,
  requestIdForLambda: string
) {
  let elapesdTime = 0;
  while (true) {
    const { data } = yield call(apiFunction, requestIdForLambda);
    if (data.list.length > 0) {
      return data.list;
    }
    yield delay(5000); // 5초간 대기
    if (elapesdTime > 60000) {
      message.error("크롤링이 실패했습니다.");
      throw new Error("크롤링이 실패했습니다.");
    }
  }
}

function* crawlingProductSaga(
  action: ReturnType<typeof crwalingProduct.request>
) {
  try {
    const isLoggedIn: UserResponse = yield select(LoginStatusSelector.data);
    if (isLoggedIn) {
      const coupangItemName = action.payload;
      const data: AxiosResponse<CrawlingResponse> = yield callWrapperSaga(
        blogService.coupangCrawling,
        coupangItemName
      );
      const reqLambdaRequestId = data.data.data.requestIdForLambda;
      yield put(addReqRequestId({ requestIdForLambda: reqLambdaRequestId }));
      const { data: requestIdForLambda } = yield callWrapperSaga(
        blogService.getCoupangRequestId,
        reqLambdaRequestId
      );
      const { list } = yield race({
        list: callWrapperSaga(
          waitUntilListIsReady,
          blogService.getCoupangRequestId,
          reqLambdaRequestId
        ),
        timeout: delay(50000),
      });
      if (list) {
        yield put(
          crwalingProduct.success({
            coupangPartners: {
              isFetching: false,
              isSuccess: true,
              isError: false,
              errorMessage: "에러 없음",
              list: list,
            },
          })
        );
      }
    }
  } catch (response) {
    const { data, status } = response as AxiosResponse;
    yield put(crwalingProduct.failure({ data, status }));
  }
}

export default function* blogSaga() {
  yield takeLatest(FETCH_CRITERIA, fetchCriteriaSaga);
  yield takeLatest(FETCH_PRODUCTS, fetchBlogSaga);
  yield takeLatest(FETCH_LABEL_TEMPLATE, fetchLabelTemplateSaga);
  yield takeLatest(FETCH_LABEL_DATA, fetchLabelDataSaga);
  yield takeLatest(FETCH_KEYWORDS, fetchKeywordSaga);
  yield takeLatest(FETCH_TRANSLATE, fetchTranslateSaga);
  yield takeEvery(FETCH_IMG_URL, fetchImgUrlSaga);
  yield takeLatest(FETCH_VIDEO, fetchVideoSaga);
  yield takeLatest(CRAWLING, crawlingProductSaga);
}
