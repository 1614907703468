import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import type {
  APIError,
  ConversionStatsResponse,
  DailyPerformanceResponse,
  DailySectionResponse,
  RCCodeConversionStatsResponse,
  RCCodeRevenueStatsResponse,
  RCCodeViewStatsResponse,
  RevenueStatsResponse,
  ViewStatsResponse,
} from "types/api";
import { RootState } from "store";
import { DAILY, PC } from "assets/constants/string";
import { ReportState, Channel, Unit } from "types/report";
import { format, addDays } from "date-fns";
import { reportAsyncAction } from "./saga";
export const REPORT = "report";

const todayDate = new Date();
const [oneWeekBefore, todayStr] = [
  format(addDays(todayDate, -7), "yyyy-MM-dd"),
  format(todayDate, "yyyy-MM-dd"),
];

const initialState: ReportState = {
  channel: PC,
  unit: DAILY,
  startDate: oneWeekBefore,
  endDate: todayStr,
  view: {
    stats: {
      loading: false,
      data: null,
      error: null,
    },
    rcCodeStats: {
      loading: false,
      data: null,
      error: null,
    },
  },
  revenue: {
    stats: {
      loading: false,
      data: null,
      error: null,
    },
    rcCodeStats: {
      loading: false,
      data: null,
      error: null,
    },
  },
  conversion: {
    stats: {
      loading: false,
      data: null,
      error: null,
    },
    rcCodeStats: {
      loading: false,
      data: null,
      error: null,
    },
  },
  daily: {
    loading: false,
    data: null,
    error: null,
  },
  section: {
    loading: false,
    data: null,
    error: null,
  },
};

const reportSlice = createSlice({
  name: REPORT,
  initialState,
  reducers: {
    setDate(
      state,
      action: PayloadAction<Partial<{ startDate: string; endDate: string }>>
    ) {
      return { ...state, ...action.payload };
    },
    setChannel(state, action: PayloadAction<{ channel: Channel }>) {
      state.channel = action.payload.channel;
    },
    setUnit(state, action: PayloadAction<{ unit: Unit }>) {
      state.unit = action.payload.unit;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(`${reportAsyncAction.getAllViewStats.request}`, (state) => {
        state.view.stats.loading = true;
        state.view.rcCodeStats.loading = true;
      })
      .addCase(
        `${reportAsyncAction.getAllViewStats.success}`,
        (
          state,
          action: PayloadAction<{
            viewStatsData: ViewStatsResponse;
            rcCodesViewStatsData: RCCodeViewStatsResponse;
          }>
        ) => {
          state.view.stats.loading = false;
          state.view.rcCodeStats.loading = false;
          state.view.stats.data = action.payload.viewStatsData;
          state.view.rcCodeStats.data = action.payload.rcCodesViewStatsData;
        }
      )
      .addCase(
        `${reportAsyncAction.getAllViewStats.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.view.stats.error = action.payload;
          state.view.rcCodeStats.error = action.payload;
        }
      )
      .addCase(
        `${reportAsyncAction.getAllConversionStats.request}`,
        (state) => {
          state.conversion.stats.loading = true;
          state.conversion.rcCodeStats.loading = true;
        }
      )
      .addCase(
        `${reportAsyncAction.getAllConversionStats.success}`,
        (
          state,
          action: PayloadAction<{
            conversionStatsData: ConversionStatsResponse;
            rcCodesConversionStatsData: RCCodeConversionStatsResponse;
          }>
        ) => {
          state.conversion.stats.loading = false;
          state.conversion.rcCodeStats.loading = false;
          state.conversion.stats.data = action.payload.conversionStatsData;
          state.conversion.rcCodeStats.data =
            action.payload.rcCodesConversionStatsData;
        }
      )
      .addCase(
        `${reportAsyncAction.getAllConversionStats.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.conversion.stats.error = action.payload;
          state.conversion.rcCodeStats.error = action.payload;
        }
      )
      .addCase(`${reportAsyncAction.getAllRevenueStats.request}`, (state) => {
        state.revenue.stats.loading = true;
        state.revenue.rcCodeStats.loading = true;
      })
      .addCase(
        `${reportAsyncAction.getAllRevenueStats.success}`,
        (
          state,
          action: PayloadAction<{
            revenueStatsData: RevenueStatsResponse;
            rcCodesRevenueStatsData: RCCodeRevenueStatsResponse;
          }>
        ) => {
          state.revenue.stats.loading = false;
          state.revenue.rcCodeStats.loading = false;
          state.revenue.stats.data = action.payload.revenueStatsData;
          state.revenue.rcCodeStats.data =
            action.payload.rcCodesRevenueStatsData;
        }
      )
      .addCase(
        `${reportAsyncAction.getAllRevenueStats.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.revenue.stats.error = action.payload;
          state.revenue.rcCodeStats.error = action.payload;
        }
      )
      .addCase(`${reportAsyncAction.getDailyPerformance.request}`, (state) => {
        state.daily.loading = true;
      })
      .addCase(
        `${reportAsyncAction.getDailyPerformance.success}`,
        (
          state,
          action: PayloadAction<{ dailyPerfData: DailyPerformanceResponse }>
        ) => {
          state.daily.loading = false;
          state.daily.data = action.payload.dailyPerfData;
        }
      )
      .addCase(
        `${reportAsyncAction.getDailyPerformance.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.daily.loading = false;
          state.daily.error = action.payload;
        }
      )
      .addCase(`${reportAsyncAction.getDailySection.request}`, (state) => {
        state.section.loading = true;
      })
      .addCase(
        `${reportAsyncAction.getDailySection.success}`,
        (
          state,
          action: PayloadAction<{ dailySectionData: DailySectionResponse }>
        ) => {
          state.section.loading = false;
          state.section.data = action.payload.dailySectionData;
        }
      )
      .addCase(
        `${reportAsyncAction.getDailySection.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.section.loading = false;
          state.section.error = action.payload;
        }
      );
  },
});

const selfSelector = (state: RootState) => state[REPORT];
const viewStatsSelector = createSelector(
  selfSelector,
  (state) => state.view.stats
);
const viewRcCodeStatsSelector = createSelector(
  selfSelector,
  (state) => state.view.rcCodeStats
);

const conversionStatsSelector = createSelector(
  selfSelector,
  (state) => state.conversion.stats
);
const conversionRcCodeStatsSelector = createSelector(
  selfSelector,
  (state) => state.conversion.rcCodeStats
);
const revenueStatsSelector = createSelector(
  selfSelector,
  (state) => state.revenue.stats
);
const revenueRcCodeStatsSelector = createSelector(
  selfSelector,
  (state) => state.revenue.rcCodeStats
);

const dailySelector = createSelector(selfSelector, (state) => state.daily);
const sectionSelector = createSelector(selfSelector, (state) => state.section);

export const channelSelector = createSelector(
  selfSelector,
  (report) => report.channel
);
export const unitSelector = createSelector(
  selfSelector,
  (report) => report.unit
);
export const dateSelector = createSelector(
  selfSelector,
  ({ startDate, endDate }) => ({ startDate, endDate })
);

export const ViewStatsDataSelector = {
  loading: createSelector(viewStatsSelector, (stats) => stats.loading),
  data: createSelector(viewStatsSelector, (stats) => stats.data),
  error: createSelector(viewStatsSelector, (stats) => stats.error),
};

export const ViewRCCodeStatsDataSelector = {
  loading: createSelector(
    viewRcCodeStatsSelector,
    (rcCodeStats) => rcCodeStats.loading
  ),
  data: createSelector(
    viewRcCodeStatsSelector,
    (rcCodeStats) => rcCodeStats.data
  ),
  error: createSelector(
    viewRcCodeStatsSelector,
    (rcCodeStats) => rcCodeStats.error
  ),
};

export const ConversionStatsDataSelector = {
  loading: createSelector(conversionStatsSelector, (stats) => stats.loading),
  data: createSelector(conversionStatsSelector, (stats) => stats.data),
  error: createSelector(conversionStatsSelector, (stats) => stats.error),
};

export const ConversionRCCodeStatsDataSelector = {
  loading: createSelector(
    conversionRcCodeStatsSelector,
    (rcCodeStats) => rcCodeStats.loading
  ),
  data: createSelector(
    conversionRcCodeStatsSelector,
    (rcCodeStats) => rcCodeStats.data
  ),
  error: createSelector(
    conversionRcCodeStatsSelector,
    (rcCodeStats) => rcCodeStats.error
  ),
};

export const RevenueStatsDataSelector = {
  loading: createSelector(revenueStatsSelector, (stats) => stats.loading),
  data: createSelector(revenueStatsSelector, (stats) => stats.data),
  error: createSelector(revenueStatsSelector, (stats) => stats.error),
};

export const RevenueRCCodeStatsDataSelector = {
  loading: createSelector(
    revenueRcCodeStatsSelector,
    (rcCodeStats) => rcCodeStats.loading
  ),
  data: createSelector(
    revenueRcCodeStatsSelector,
    (rcCodeStats) => rcCodeStats.data
  ),
  error: createSelector(
    revenueRcCodeStatsSelector,
    (rcCodeStats) => rcCodeStats.error
  ),
};

export const DailyDataSelector = {
  loading: createSelector(dailySelector, (daily) => daily.loading),
  data: createSelector(dailySelector, (daily) => daily.data),
  error: createSelector(dailySelector, (daily) => daily.error),
};

export const SectionDataSelector = {
  loading: createSelector(sectionSelector, (section) => section.loading),
  data: createSelector(sectionSelector, (section) => section.data),
  error: createSelector(sectionSelector, (section) => section.error),
};

export const reportAction = reportSlice.actions;
export const reportReducer = reportSlice.reducer;
