import { useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";

import { GET_CAMPAIGN } from "apollo/query";
import useUserData from "./useUserData";
import { dsocDemoAsyncAction } from "store/modules/demo/dsoc/saga";
import { DsocDemoSelector } from "store/modules/demo/dsoc";
import { useApiKey } from "hooks";
import { message } from "antd";

const useDsocDemoData = () => {
  const dispatch = useAppDispatch();

  const { currentCuid } = useUserData();
  const { apiKeyList } = useApiKey();
  const [dsocResultLoading, dsocResult, dsocResultError] = [
    useAppSelector(DsocDemoSelector.loading),
    useAppSelector(DsocDemoSelector.data),
    useAppSelector(DsocDemoSelector.error),
  ];

  const {
    loading: campaignListLoading,
    data: campaignListData,
    refetch: campaignListRefetch,
  } = useQuery(GET_CAMPAIGN, {
    variables: {
      filter: {
        columns: {
          cuid: {
            eq: currentCuid,
          },
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const fetchDsocResult = useCallback(
    ({ apiUrl, triggerId }: { apiUrl: any; triggerId: string }) => {
      const { code, type, input } = apiUrl;
      if (!code) return;
      if (apiKeyList?.length < 1) {
        message.error("API KEY를 먼저 발행 해주세요!");
        return;
      }
      const [apiKey] = apiKeyList;
      let url = new URL(process.env.REACT_APP_DSOC_DEMO_API_URL as string);
      url.pathname = `/api/${currentCuid}/campaign/${code}`;
      if (type && input) {
        url.searchParams.set(type, input);
      }

      if (triggerId) {
        url.searchParams.set("trigger_id", triggerId);
      }

      dispatch(
        dsocDemoAsyncAction.fetchDsocResult.request({
          apiUrl: url.toString(),
          token: apiKey?.token || "",
        })
      );
    },
    [currentCuid, dispatch, apiKeyList]
  );

  const campaignList = useMemo(() => {
    if (campaignListData) {
      const { edges = [] } = campaignListData.campaign;
      const _campaignList = edges.map((row: any) => {
        const { node } = row;
        const { code, name, CampaignConfigurations } = node;
        // const { Trigger } = CampaignConfigurations;
        return { code, name };
      });
      return _campaignList;
    }

    return [];
  }, [campaignListData]);

  return { campaignList, fetchDsocResult, dsocResultLoading, dsocResult };
};

export default useDsocDemoData;
