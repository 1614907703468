import { useReportData, useComponentId } from "hooks";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  ConversionStatsDataSelector,
  ConversionRCCodeStatsDataSelector,
} from "store/modules/report";
import { reportAsyncAction } from "store/modules/report/saga";
import toFixed from "utils/toFixed";

const useReportConversionData = () => {
  const componentId = useComponentId();
  const { startDate, endDate, channel, unit } = useReportData();
  const dispatch = useAppDispatch();
  const [statsDataLoading, statsData, statsDataError] = [
    useAppSelector(ConversionStatsDataSelector.loading),
    useAppSelector(ConversionStatsDataSelector.data),
    useAppSelector(ConversionStatsDataSelector.error),
  ];

  const [rcCodeStatsDataLoading, rcCodeStatsData, rcCodeStatsDataError] = [
    useAppSelector(ConversionRCCodeStatsDataSelector.loading),
    useAppSelector(ConversionRCCodeStatsDataSelector.data),
    useAppSelector(ConversionRCCodeStatsDataSelector.error),
  ];

  const getAllConversionStats = useCallback(
    ({ cuid }: { cuid: string }) => {
      dispatch(
        reportAsyncAction.getAllConversionStats.request({
          startDate,
          endDate,
          cuid,
          channel,
          unit,
        })
      );
    },
    [dispatch, channel, startDate, endDate, unit]
  );

  const {
    conversionByCategoryData,
    conversionRatioTransitionData,
    conversionByRCCodeData,
    conversionTableData,
    rcCodesList,
  } = useMemo(() => {
    const conversionTableData = _.map(
      statsData,
      (
        {
          date,
          values: {
            conversionCount,
            noConversionCount,
            sessionCount,
            noSessionCount,
          },
        },
        idx
      ) => {
        return {
          key: `${componentId}-conversion-table-data-${idx}`,
          date,
          rcCodes: _.reduce(
            _.filter(rcCodeStatsData, (rcCodeStat) => rcCodeStat.date === date),
            (rcCodes, { values }) => {
              rcCodes[values.rcCode] =
                _.defaultTo(_.get(rcCodes, values.rcCode), 0) +
                _.defaultTo(
                  _.toNumber(
                    toFixed(1)(
                      (values.conversionCount / values.sessionCount) * 100
                    )
                  ),
                  0
                );
              return rcCodes;
            },
            {} as { [key: string]: number }
          ),
          recConversionRatio: _.defaultTo(
            _.toNumber(toFixed(1)((conversionCount / sessionCount) * 100)),
            0
          ),
          normalConversionRatio: _.defaultTo(
            _.toNumber(toFixed(1)((noConversionCount / noSessionCount) * 100)),
            0
          ),
        };
      }
    );

    const rcCodesList = _.map(
      _.sortBy(
        _.map(
          _.entries(
            _.reduce(
              conversionTableData,
              (rcCodesData, { rcCodes }) => {
                return _.mergeWith(
                  rcCodesData,
                  rcCodes,
                  (originValue, incomeValue) => {
                    originValue = (originValue || 0) + incomeValue;
                    return originValue;
                  }
                );
              },

              {} as { [key: string]: number }
            )
          ),
          ([key, value]) => ({ name: key, value })
        ),
        ["name"]
      ).sort((a, b) => {
        const { name: aName } = a;
        const { name: bName } = b;
        if (aName && bName) {
          const aNum = Number(aName.match(/(\d+)/g));
          const bNum = Number(bName.match(/(\d+)/g));
          return aNum - bNum;
        }
        return (a.name as any) - (b.name as any);
      }),
      ({ name }) => name
    );

    const conversionRatioTransitionData = _.map(
      statsData,
      ({
        date,
        values: {
          sessionCount,
          noSessionCount,
          conversionCount,
          noConversionCount,
        },
      }) => {
        return {
          name: date,
          recConversionRatio: _.defaultTo(
            _.toNumber(toFixed(1)((conversionCount / sessionCount) * 100)),
            0
          ),
          normalConversionRatio: _.defaultTo(
            _.toNumber(toFixed(1)((noConversionCount / noSessionCount) * 100)),
            0
          ),
        };
      }
    );
    const conversionByCategoryData = _.map(
      _.entries(
        _.reduce(
          statsData,
          (
            conversionByCategoryData,
            {
              values: {
                sessionCount,
                conversionCount,
                noSessionCount,
                noConversionCount,
              },
            }
          ) => {
            return {
              Rec: {
                sessionCount:
                  _.get(conversionByCategoryData, ["Rec", "sessionCount"], 0) +
                  sessionCount,
                conversionCount:
                  _.get(
                    conversionByCategoryData,
                    ["Rec", "conversionCount"],
                    0
                  ) + conversionCount,
              },
              No_Rec: {
                sessionCount:
                  _.get(
                    conversionByCategoryData,
                    ["No_Rec", "sessionCount"],
                    0
                  ) + noSessionCount,
                conversionCount:
                  _.get(
                    conversionByCategoryData,
                    ["No_Rec", "conversionCount"],
                    0
                  ) + noConversionCount,
              },
            };
          },
          {} as { [key: string]: any }
        )
      ),
      ([key, { sessionCount, conversionCount }], idx) => {
        return {
          key: `${componentId}-conversion-by-category-data-column-${idx}`,
          category: key,
          sessionCount,
          conversionCount,
          conversionRatio: _.defaultTo(
            _.toNumber(toFixed(1)((conversionCount / sessionCount) * 100)),
            0
          ),
        };
      }
    );
    const conversionByRCCodeData = _.sortBy(
      _.map(
        _.entries(
          _.reduce(
            rcCodeStatsData,
            (
              conversionByRCCodeData,
              { values: { rcCode, conversionCount, sessionCount } }
            ) => {
              return {
                ...conversionByRCCodeData,
                [rcCode]: {
                  sessionCount:
                    _.defaultTo(
                      _.get(conversionByRCCodeData, [rcCode, "sessionCount"]),
                      0
                    ) + sessionCount,
                  conversionCount:
                    _.defaultTo(
                      _.get(conversionByRCCodeData, [
                        rcCode,
                        "conversionCount",
                      ]),
                      0
                    ) + conversionCount,
                },
              };
            },
            {} as { [key: string]: any }
          )
        ),
        ([key, { sessionCount, conversionCount }], idx) => {
          return {
            key: `${componentId}-conversion-by-rc-code-data-column-${idx}`,
            section: key,
            sessionCount,
            conversionCount,
            conversionRatio: _.defaultTo(
              _.toNumber(toFixed(1)((conversionCount / sessionCount) * 100)),
              0
            ),
          };
        }
      ),
      ["section"]
    ).sort((a, b) => {
      const { section: aName } = a;
      const { section: bName } = b;
      if (aName && bName) {
        const aNum = Number(aName.match(/(\d+)/g));
        const bNum = Number(bName.match(/(\d+)/g));
        return aNum - bNum;
      }
      return (a.section as any) - (b.section as any);
    });

    return {
      conversionByCategoryData,
      conversionRatioTransitionData,
      conversionByRCCodeData,
      conversionTableData,
      rcCodesList,
    };
  }, [statsData, componentId, rcCodeStatsData]);

  return {
    statsDataLoading,
    statsData,
    statsDataError,
    rcCodeStatsDataLoading,
    rcCodeStatsData,
    rcCodeStatsDataError,
    conversionByCategoryData,
    conversionRatioTransitionData,
    conversionByRCCodeData,
    conversionTableData,
    rcCodesList,
    getAllConversionStats,
  };
};
export default useReportConversionData;
