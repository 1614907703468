import { FC, useCallback, MouseEvent } from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import * as xlsx from "xlsx";
import _ from "lodash";
import { useIntl } from "react-intl";
interface ExcelDownloadButtonProps {
  tableData: any[];
  fileName: string;
  columnOrder: string[];
  size?: "large" | "middle" | "small";
}

const ExcelDownloadButton: FC<ExcelDownloadButtonProps> = ({
  tableData,
  fileName = "empty",
  columnOrder,
  size = "small",
}) => {
  const { formatMessage } = useIntl();
  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      let allKeySet = new Set<string>();

      const tempTableData = _.map(tableData, ({ key, ...rest }) => {
        const result = _.reduce(
          _.entries(rest),
          (result, [key, value]) => {
            return _.isPlainObject(value)
              ? { ...result, ...(value as { [key: string]: any }) }
              : { ...result, ...{ [key]: value } };
          },
          {}
        );
        return { ...result };
      });
      for (let el of tempTableData) {
        allKeySet = new Set([...Array.from(allKeySet), ...Object.keys(el)]);
      }

      const paredTableData = _.map(tempTableData, (row: any) => {
        [...Array.from(allKeySet)].forEach((el) => {
          if (!row[el]) {
            row[el] = 0;
          }
        });
        return row;
      });

      const ws = xlsx.utils.json_to_sheet(paredTableData, {
        header: columnOrder,
      });
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws);
      xlsx.writeFile(wb, `${fileName}.xlsx`);
    },
    [tableData, fileName, columnOrder]
  );
  return (
    <Button
      icon={<DownloadOutlined />}
      block
      size={size}
      onClick={handleClick}
      children={formatMessage({
        id: "Components.Common.ExcelDownloadButton.text",
      })}
    />
  );
};
export default ExcelDownloadButton;
