import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { callWrapperSaga } from "utils/callWrapperSaga";
import { RandomDemoService } from "apis/services";

import type {
  RandomRequest,
  RandomRecRequest,
  RandomInputResponse,
  APIError,
} from "types/api";

export const FETCH_RANDOM_INPUT = `random/FETCH_RANDOM_INPUT`;
export const FETCH_RANDOM_INPUT_SUCCESS = `random/FETCH_RANDOM_INPUT_SUCCESS`;
export const FETCH_RANDOM_INPUT_ERROR = `random/FETCH_RANDOM_INPUT_ERROR`;

export const FETCH_RANDOM_REC_INPUT = `random/FETCH_RANDOM_REC_INPUT`;
export const FETCH_RANDOM_REC_INPUT_SUCCESS = `random/FETCH_RANDOM_INPUT_REC_SUCCESS`;
export const FETCH_RANDOM_REC_INPUT_ERROR = `random/FETCH_RANDOM_INPUT_REC_ERROR`;

export const fetchRandomInput = createAsyncAction(
  FETCH_RANDOM_INPUT,
  FETCH_RANDOM_INPUT_SUCCESS,
  FETCH_RANDOM_INPUT_ERROR
)<RandomRequest, { randomInput: RandomInputResponse }, APIError>();

function* fetchRandomInputSaga(
  action: ReturnType<typeof fetchRandomInput.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      RandomDemoService.fetchRandomInput,
      action.payload
    );

    yield put(fetchRandomInput.success({ randomInput: data }));
  } catch (error) {
    const response = (error as { response: AxiosResponse }).response;
    const { data, status } = response as AxiosResponse;
    yield put(fetchRandomInput.failure({ data, status }));
  }
}

export const fetchRandomRecInput = createAsyncAction(
  FETCH_RANDOM_REC_INPUT,
  FETCH_RANDOM_REC_INPUT_SUCCESS,
  FETCH_RANDOM_REC_INPUT_ERROR
)<RandomRecRequest, { randomInput: RandomInputResponse }, APIError>();

function* fetchRandomRecInputSaga(
  action: ReturnType<typeof fetchRandomRecInput.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      RandomDemoService.fetchRandomRecInput,
      action.payload
    );

    yield put(fetchRandomRecInput.success({ randomInput: data }));
  } catch (error) {
    const response = (error as { response: AxiosResponse }).response;
    const { data, status } = response as AxiosResponse;
    yield put(fetchRandomRecInput.failure({ data, status }));
  }
}

export const randomInputAsyncAction = {
  fetchRandomInput,
  fetchRandomRecInput,
};

export default function* recDemoSaga() {
  yield takeLatest(FETCH_RANDOM_INPUT, fetchRandomInputSaga);
  yield takeLatest(FETCH_RANDOM_REC_INPUT, fetchRandomRecInputSaga);
}
