import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { errorCodes } from "routes/config";
import { userAsyncAction } from "store/modules/user/saga";

export function* callWrapperSaga(fn: any, ...args: any[]): Generator {
  try {
    return yield call(fn, ...args);
  } catch (e: any) {
    if (e?.response) {
      const { response }: { response: AxiosResponse } = e;
      const { data, status } = response;
      if (errorCodes.includes(data?.code)) {
        yield put(userAsyncAction.signIn.failure({ data, status }));
      }
      throw e;
    } else {
      const UnknownError = {
        response: { data: { error: "UNKNOWN", code: 0 }, status: 400 },
      };
      throw UnknownError;
    }
  }
}
