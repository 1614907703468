import {
  CoupangProductDetail,
  CrawlingState,
  CriteriaRequest,
  Product,
  UpdatedProducts,
  VideoStatus,
  coupangProductList,
} from "./../../../types/blog";
import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import _, { concat, result } from "lodash";
import { RootState } from "store";
import {
  BlogState,
  ProductRequest,
  CriteriaResponse,
  KeywordResponse,
  ProductsResponse,
  LabelDataResponse,
  LabelDataRequest,
  LabelTemplateResponse,
} from "types/blog";
import { APIError } from "types/api";
import {
  fetchCriteria,
  fetchProducts,
  fetchKeyword,
  fetchLabelData,
  fetchLabelTemplate,
  fetchTranslate,
  fetchImgUrl,
  fetchVideo,
  crwalingProduct,
} from "./saga";
import { message } from "antd";

export const BLOG = "blog";

const initialState: BlogState = {
  currentVideo: null,
  videoThumbnail: "",
  videoText: "",
  coverTitle: "이트리 픽",
  coverImage: null,
  translatedTxt: [],
  language: "ko",
  UserText: {
    text_1: "",
    text_2: "",
    text_3: "",
  },
  products: null,
  coupangPartners: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    list: [],
  },
  currentProductId: null,
  criteria: {
    list: [
      {
        date: "",
        targetValue: "",
        targetType: "",
        crossType: "",
      },
    ],
  },
  keywords: null,
  labelData: null,
  labelTemplate: null,
  request: {
    cuid: "",
    date: undefined,
    targetType: undefined,
    targetValue: undefined,
    crossType: undefined,
  },
  currentState: {
    language: "",
    UserText: {
      text_1: "",
      text_2: "",
      text_3: "",
    },

    products: null,
    currentProductId: null,
    criteria: {
      list: [
        {
          date: "",
          targetValue: "",
          targetType: "",
          crossType: "",
        },
      ],
    },
    keywords: null,
    labelData: null,
    labelTemplate: null,

    request: {
      cuid: "",
      date: undefined,
      targetType: undefined,
      targetValue: undefined,
      crossType: undefined,
    },
    htmlSource: "",
  },
  htmlSource: "",
  openModal: "none",
  requestIdForLambda: [],
};

const blogSlice = createSlice({
  name: BLOG,
  initialState,
  reducers: {
    // 실제데이터
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
    },
    setHtmlSource(state, action: PayloadAction<string>) {
      state.htmlSource = action.payload;
    },
    setRequestAction(state, action: PayloadAction<ProductRequest>) {
      state.request = action.payload;
    },
    setKeywordPick(state, action: PayloadAction<{ targetKeyword: string }>) {
      state.keywords &&
        state.keywords?.list.forEach((el) => {
          el.keyword === action.payload.targetKeyword
            ? (el.todaysPick = true)
            : (el.todaysPick = false);
        });
    },
    setDeleteItem(state, action: PayloadAction<{ prdId: string }>) {
      if (state.products === null) {
        return;
      }

      state.products.list = state.products?.list.filter(
        (prd) => prd.id !== action.payload.prdId
      );
    },
    setUserInputTitle(state, action: PayloadAction<string>) {
      state.UserText.text_1 = action.payload;
    },
    setUserInputTrandInfo(state, action: PayloadAction<string>) {
      state.UserText.text_2 = action.payload;
    },
    setUserInputHeadCopy(state, action: PayloadAction<string>) {
      state.UserText.text_3 = action.payload;
    },
    setLabelMode(
      state,
      action: PayloadAction<{ prdId: string; mode: 0 | 1 | null }>
    ) {
      const { prdId, mode } = action.payload;
      state.products &&
        state.products?.list
          ?.filter((el) => el.id === prdId)
          .map((el) => {
            return (el.selectMode = mode);
          });
    },
    setLeftLabelClear(state, action: PayloadAction<{ prdId: string }>) {
      const { prdId } = action.payload;
      state.products &&
        state.products?.list
          ?.filter((el) => el.selectedLabel && el.id === prdId)
          .map((el) => {
            return (el.selectedLabel[0] = null);
          });
    },
    setRightLabelClear(state, action: PayloadAction<{ prdId: string }>) {
      const { prdId } = action.payload;
      state.products &&
        state.products?.list
          ?.filter((el) => el.selectedLabel && el.id === prdId)
          .map((el) => {
            return (el.selectedLabel[1] = null);
          });
    },
    setLeftLabel(state, action: PayloadAction<{ prdId: string; label: any }>) {
      const { prdId, label } = action.payload;
      state.products &&
        state.products.list
          .filter((el) => el.selectedLabel && el.id === prdId)
          .map((prd) => {
            return (prd.selectedLabel[0] = label);
          });
    },
    setRightLabel(state, action: PayloadAction<{ prdId: string; label: any }>) {
      const { prdId, label } = action.payload;
      state.products &&
        state.products.list
          .filter((prd) => prd.selectedLabel && prd.id === prdId)
          .map((prd) => {
            return (prd.selectedLabel[1] = label);
          });
    },
    setUploadImage(state, action: PayloadAction<{ prdId: string; img: any }>) {
      const { prdId, img } = action.payload;
      state.products &&
        state.products.list
          .filter((el) => el.id === prdId)
          .map((prd) => {
            return (prd.itemImage = img);
          });
    },
    setCoupang(
      state,
      action: PayloadAction<{
        prdId: string;
        url?: string;
        itemName?: string;
        brandName?: string;
      }>
    ) {
      const { prdId, url, itemName, brandName } = action.payload;
      state.products &&
        state.products.list
          .filter((prd) => prd.id === prdId)
          .map((prd) => {
            prd.itemUrl = url || prd.itemUrl;
            prd.brandName = brandName || prd.brandName;
            prd.itemName = itemName || prd.itemName;
          });
    },
    setBrandPriceUse(state, action: PayloadAction<{ prdId: string }>) {
      const { prdId } = action.payload;
      state.products &&
        state.products.list
          .filter((prd) => prd.selectedLabel && prd.id === prdId)
          .map((prd) => {
            return (prd.brandPriceUse = !prd.brandPriceUse);
          });
    },
    setCategoryPriceUse(state, action: PayloadAction<{ prdId: string }>) {
      const { prdId } = action.payload;
      state.products &&
        state.products.list
          .filter((prd) => prd.selectedLabel && prd.id === prdId)
          .map((prd) => {
            return (prd.categoryPriceUse = !prd.categoryPriceUse);
          });
    },
    setClearProducts(state, action: PayloadAction<undefined>) {
      state.products = null;
    },
    setcurrentProductId(state, action: PayloadAction<string | null>) {
      state.currentProductId = action.payload;
    },

    setCurrentState(state, action: PayloadAction<undefined>) {
      const {
        language,
        UserText,
        products,
        criteria,
        keywords,
        labelData,
        labelTemplate,
        request,
        currentProductId,
        htmlSource,
      } = state;
      state.currentState = {
        language,
        UserText,
        products,
        criteria,
        keywords,
        labelData,
        labelTemplate,
        request,
        currentProductId,
        htmlSource,
      };
    },
    setClearCurrentState(state, action: PayloadAction<undefined>) {
      state.language = "ko";
      state.UserText.text_1 = "";
      state.UserText.text_2 = "";
      state.UserText.text_3 = "";
      state.request.date = undefined;
      state.request.targetType = undefined;
      state.request.targetValue = undefined;
      state.request.crossType = undefined;
      state.products = null;
      state.keywords = null;
      state.labelData = null;
      state.currentProductId = null;
      state.htmlSource = "";
      state.currentState = null;
    },
    setPersistStateDraw(state, action: PayloadAction<undefined>) {
      if (!state.currentState) {
        return;
      }
      const { currentState } = state;
      state.criteria = currentState?.criteria;
      state.UserText = currentState.UserText;
      state.keywords = currentState.keywords;
      state.labelData = currentState.labelData;
      state.labelTemplate = currentState.labelTemplate;
      state.htmlSource = currentState.htmlSource;
      state.request = currentState.request;
      state.products = currentState.products;
      state.currentProductId = currentState.currentProductId;
    },
    setImageURL(state, action: PayloadAction<{ prdId: number; imgUrl: any }>) {
      const { prdId, imgUrl } = action.payload;
      state.products &&
        state.products.list
          .filter((_, index) => prdId === index)
          .map((prd) => {
            return (prd.itemImage = imgUrl);
          });
    },
    setImageUseOption(
      state,
      action: PayloadAction<{ prdId: number; isChangeImage: boolean }>
    ) {
      const { prdId, isChangeImage } = action.payload;
      state.products &&
        state.products.list
          .filter((_, index) => prdId === index)
          .map((prd) => {
            return (prd.media.isChangeImage = isChangeImage);
          });
    },
    setVideoUseOption(
      state,
      action: PayloadAction<{ prdId: number; isChangeDubbing: boolean }>
    ) {
      const { prdId, isChangeDubbing } = action.payload;
      state.products &&
        state.products.list
          .filter((_, index) => prdId === index)
          .map((prd) => {
            return (prd.media.isChangeDubbing = isChangeDubbing);
          });
    },
    setVideoDubbingScript(
      state,
      action: PayloadAction<{ dubbingText: string[]; prdId: number }>
    ) {
      // index번째 prd의 prd.media.imageUrl에 imgUrl를 할당
      const { dubbingText, prdId } = action.payload;
      state.products &&
        state.products.list
          .filter((_, index) => prdId === index)
          .map((prd, index) => {
            return (prd.media.dubbingScript = dubbingText);
          });
    },
    setSelectVideo(
      state,
      action: PayloadAction<{ prdId: number; selectedVideo: boolean }>
    ) {
      const { prdId, selectedVideo } = action.payload;
      state.products &&
        state.products.list
          .filter((_, index) => prdId === index)
          .map((prd, index) => {
            return (prd.selectedVideo = selectedVideo);
          });
    },
    allCheckVideo(state, action: PayloadAction<{ selectedVideo: boolean }>) {
      const { selectedVideo } = action.payload;
      state.products &&
        state.products.list.map((prd, _) => {
          return (prd.selectedVideo = selectedVideo);
        });
    },
    setSelectProduct(
      state,
      action: PayloadAction<{ prdId: number; selected: boolean }>
    ) {
      const { prdId, selected } = action.payload;
      state.products?.list
        .filter((_, index) => prdId === index)
        .map((prd, index) => {
          return (prd.selected = selected);
        });
    },
    setCurrentVideo(state, action: PayloadAction<VideoStatus>) {
      state.currentVideo = action.payload;
    },
    setClearCurrentVideo(state, action: PayloadAction<undefined>) {
      state.currentVideo = null;
    },
    setCoverTitle(state, action: PayloadAction<string>) {
      state.coverTitle = action.payload;
    },
    setCoverImage(state, action: PayloadAction<any>) {
      state.coverImage = action.payload;
    },
    setVideoThumbnail(
      state,
      action: PayloadAction<{
        videoThumbnail: string;
      }>
    ) {
      const { videoThumbnail } = action.payload;
      state.videoThumbnail = videoThumbnail;
    },
    setVideoText(state, action: PayloadAction<{ videoText: any }>) {
      const { videoText } = action.payload;
      state.videoText = videoText;
    },
    setOpenModal(state, action: PayloadAction<"pc" | "mo" | "none">) {
      state.openModal = action.payload;
    },
    setBase64Url(
      state,
      action: PayloadAction<{ base64Images: string[] | undefined }>
    ) {
      const { base64Images } = action.payload;
      state.products &&
        state.products.list.map((el, index) => {
          return (el.itemImage = `data:image/*;base64,${
            base64Images && base64Images[index]
          }`);
        });
    },
    addReqRequestId(
      state,
      action: PayloadAction<{ requestIdForLambda: string | undefined }>
    ) {
      const { requestIdForLambda } = action.payload;
      if (requestIdForLambda)
        state.requestIdForLambda?.push(requestIdForLambda);
    },
    setUpdatedCoupangList(state, action: PayloadAction<coupangProductList>) {
      const { list } = action.payload;
      const selectedProducts = _.filter(
        state.products?.list,
        (product) => product.selected
      );
      const coupangOriginalNmaes = _.map(list, "originProductName");
      const newCoupangList = list.map((el, index) => {
        const newEl = { ...el };
        if (coupangOriginalNmaes[index]) {
          const newSelectedLabel = selectedProducts[index].selectedLabel;
          const newHashTag = selectedProducts[index].hashTag;
          const newTargetValue = selectedProducts[index].targetValue;
          newEl.hashTag = [...newHashTag];
          newEl.selectedLabel = [...newSelectedLabel];
          newEl.targetValue = newTargetValue;
        }
        newEl.cuid = index.toString();
        newEl.id = index.toString();
        newEl.productOwnerCuid = selectedProducts[index].productOwnerCuid;
        newEl.labels = selectedProducts[index].labels;
        newEl.itemName = el.productName;
        newEl.itemUrl = el.partnersUrl;
        newEl.itemImage = `data:image/*;base64,${el.image}`;
        newEl.selected = false;
        newEl.brandName = selectedProducts[index].brandName;
        newEl.brandPriceDiff = selectedProducts[index].brandPriceDiff;
        newEl.brandPriceUse = selectedProducts[index].brandPriceUse;
        newEl.rank = selectedProducts[index].rank;
        newEl.media = selectedProducts[index].media;
        newEl.selectMode = selectedProducts[index].selectMode;
        newEl.rating = selectedProducts[index].rating;
        newEl.selectedVideo = selectedProducts[index].selectedVideo;
        return newEl;
      });
      if (state.products?.list)
        state.products.list = newCoupangList as unknown as Product[];
    },
  },
  extraReducers: (builder) => {
    builder
      // criteria
      .addCase(
        `${fetchCriteria.request}`,
        (state, action: PayloadAction<CriteriaRequest>) => {}
      )
      .addCase(
        `${fetchCriteria.success}`,
        (state, action: PayloadAction<{ criteria: CriteriaResponse }>) => {
          if (!state.criteria) {
            return;
          }
          const list = action.payload.criteria.list;
          state.criteria.list = list;
        }
      )
      .addCase(
        `${fetchCriteria.failure}`,
        (state, action: PayloadAction<APIError>) => {}
      )
      // products
      .addCase(
        `${fetchProducts.request}`,
        (state, action: PayloadAction<any>) => {}
      )
      .addCase(
        `${fetchProducts.success}`,
        (state, action: PayloadAction<{ products: ProductsResponse }>) => {
          // 모든 상품 selectMode null로 초기화 && 첫번째, 두번쨰상품으로 초기화ㄴ
          action.payload.products.list.map((el, index) => {
            el.hashTag = _.uniq(el.hashTag).filter((hash, index) => index < 30);
            el.selectMode = null;
            el.selectedLabel = [el.labels[0], el.labels[1]];
            el.brandPriceUse = true;
            el.categoryPriceUse = true;
            el.media = {
              isChangeImage: false,
              isChangeDubbing: false,
              imageUrl: "",
              dubbingScript: [""],
            };
            el.itemUrl = el.itemUrl.includes("http")
              ? el.itemUrl
              : "https://" + el.itemUrl;
            el.selectedVideo = true;
            el.selected = false;
          });
          state.products = action.payload.products;
        }
      )
      .addCase(
        `${fetchProducts.failure}`,
        (state, action: PayloadAction<APIError>) => {}
      )
      // keyword
      .addCase(
        `${fetchKeyword.request}`,
        (state, action: PayloadAction<any>) => {}
      )
      .addCase(
        `${fetchKeyword.success}`,
        (state, action: PayloadAction<{ keywordData: KeywordResponse }>) => {
          action.payload.keywordData.list.map((el) => (el.todaysPick = false));
          state.keywords = action.payload.keywordData;
        }
      )
      .addCase(
        `${fetchKeyword.failure}`,
        (state, action: PayloadAction<APIError>) => {}
      )
      // label data
      .addCase(
        `${fetchLabelData.request}`,
        (state, action: PayloadAction<LabelDataRequest>) => {}
      )
      .addCase(
        `${fetchLabelData.success}`,
        (state, action: PayloadAction<{ labelData: LabelDataResponse }>) => {
          action.payload.labelData.list.map((el) => (el.selected = false));
          state.labelData = action.payload.labelData;
        }
      )
      .addCase(
        `${fetchLabelData.failure}`,
        (state, action: PayloadAction<APIError>) => {}
      )
      // label template
      .addCase(
        `${fetchLabelTemplate.request}`,
        (state, action: PayloadAction<undefined>) => {}
      )
      .addCase(
        `${fetchLabelTemplate.success}`,
        (
          state,
          action: PayloadAction<{ labelTemplate: LabelTemplateResponse }>
        ) => {
          state.labelTemplate = action.payload.labelTemplate;
        }
      )
      .addCase(
        `${fetchLabelTemplate.failure}`,
        (state, action: PayloadAction<APIError>) => {}
      )
      // Translate
      .addCase(
        `${fetchTranslate.request}`,
        (state, action: PayloadAction<any>) => {}
      )
      .addCase(
        `${fetchTranslate.success}`,
        (state, action: PayloadAction<any>) => {
          state.translatedTxt = [
            ...state.translatedTxt,
            action.payload.data.translatedText,
          ];
        }
      )
      .addCase(
        `${fetchTranslate.failure}`,
        (state, action: PayloadAction<APIError>) => {}
      )
      // 이미지 내려받기
      .addCase(
        `${fetchImgUrl.request}`,
        (state, action: PayloadAction<any>) => {}
      )
      .addCase(
        `${fetchImgUrl.success}`,
        (state, action: PayloadAction<{ data: any; prdId: number }>) => {
          const { data, prdId } = action.payload;

          state.products &&
            state.products?.list.map((prd, index) => {
              if (prdId === index) {
                prd.media.imageUrl = data.data.url;
              }
            });
        }
      )
      .addCase(
        `${fetchImgUrl.failure}`,
        (state, action: PayloadAction<APIError>) => {}
      )
      // 비디오 내려받기
      .addCase(
        `${fetchVideo.request}`,
        (state, action: PayloadAction<any>) => {}
      )
      .addCase(
        `${fetchVideo.success}`,
        (state, action: PayloadAction<{ data: VideoStatus }>) => {
          state.currentVideo = action.payload.data;
        }
      )
      .addCase(
        `${fetchVideo.failure}`,
        (state, action: PayloadAction<APIError>) => {}
      )
      .addCase(
        `${crwalingProduct.request}`,
        (state, action: PayloadAction<CrawlingState>) => {
          state.coupangPartners.isFetching = true;
        }
      )
      .addCase(
        `${crwalingProduct.success}`,
        (
          state,
          action: PayloadAction<{ coupangPartners: coupangProductList }>
        ) => {
          state.coupangPartners.isFetching = false;
          action.payload.coupangPartners.list.map((el) => {
            el.id = "";
            el.cuid = "";
            el.productOwnerCuid = "";
            el.itemName = "";
            el.itemImage = "";
            el.itemUrl = "";
            el.brandName = "";
            el.targetValue = "";
            el.rank = 0;
            el.rating = 0;
            el.originalPrice = 0;
            el.categoryPriceDiff = 0;
            el.categoryPriceUse = undefined;
            el.brandPriceDiff = 0;
            el.brandPriceUse = false;
            el.priceCompareToLastWeek = 0;
            el.priceCompareToLastMonth = 0;
            el.labels = [];
            el.hashTag = [];
            el.selected = false;
            el.selectMode = 0;
          });
          state.coupangPartners = action.payload.coupangPartners;
        }
      )
      .addCase(
        `${crwalingProduct.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.coupangPartners.isFetching = false;
        }
      );
  },
});

const selfSelector = (state: RootState) => state[BLOG];

export const criteriaSelector = createSelector(
  selfSelector,
  (state) => state.criteria && state.criteria.list
);
export const productsSelector = createSelector(
  selfSelector,
  (state) => state.products
);
export const keywordsSelector = createSelector(
  selfSelector,
  (state) => state.keywords
);
export const labelDataSelector = createSelector(
  selfSelector,
  (state) => state.labelData
);
export const labelTemplateSelector = createSelector(
  selfSelector,
  (state) => state.labelTemplate
);
export const userTextSelector = createSelector(
  selfSelector,
  (state) => state.UserText
);
// 기타
export const requestSelector = createSelector(
  selfSelector,
  (state) => state.request
);
export const htmlSelector = createSelector(
  selfSelector,
  (state) => state.htmlSource
);
export const currentStateSelector = createSelector(
  selfSelector,
  (state) => state.currentState
);
export const currentPrdIdSelector = createSelector(
  selfSelector,
  (state) => state.currentProductId
);
export const languageSelector = createSelector(
  selfSelector,
  (state) => state.language
);
export const translatedTxtSelector = createSelector(
  selfSelector,
  (state) => state.translatedTxt
);
export const currentVideoSelector = createSelector(
  selfSelector,
  (state) => state.currentVideo
);
export const coverTitleSelector = createSelector(
  selfSelector,
  (state) => state.coverTitle
);
export const coverImageSelector = createSelector(
  selfSelector,
  (state) => state.coverImage
);
export const videoThumbnailSelector = createSelector(
  selfSelector,
  (state) => state.videoThumbnail
);
export const videoTextSelector = createSelector(
  selfSelector,
  (state) => state.videoText
);
export const selectedVideoSelector = createSelector(selfSelector, (state) =>
  state.products?.list.filter((prd) => prd.selectedVideo)
);

export const openModalSelector = createSelector(
  selfSelector,
  (state) => state.openModal
);
export const selectedProductSelector = createSelector(selfSelector, (state) =>
  state.products?.list.filter((prd) => prd.selected === true)
);
export const coupangPartnerSelector = createSelector(
  selfSelector,
  (state) => state.coupangPartners
);
export const CoupangRequestIdSelector = createSelector(
  selfSelector,
  (state) => state.requestIdForLambda
);
export const CoupangLoadingSelector = createSelector(
  selfSelector,
  (state) => state.coupangPartners.isFetching
);

export const { addReqRequestId } = blogSlice.actions;
export const blogAction = blogSlice.actions;
export const blogReducer = blogSlice.reducer;
