import _ from "lodash";

export const convertToMonthStartDate = (startDate?: string) =>
  _.defaultTo(
    _.join(_.concat(_.dropRight(_.split(startDate, "-")), ["01"]), "-"),
    ""
  );

export const convertDateFormat = (date: string) => {
  const paredDate = new Date(Date.parse(date));
  let year = paredDate.getFullYear();
  let month: any = paredDate.getMonth() + 1;
  month = month >= 10 ? month : "0" + month;
  let day: any = paredDate.getDate();
  day = day >= 10 ? day : "0" + day;
  return [year, month, day].join("-");
};
