import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  selectedProductSelector,
  coupangPartnerSelector,
  CoupangRequestIdSelector,
  CoupangLoadingSelector,
  blogAction,
} from "store/modules/blog";
import useBlog from "./useBlog";
import { RcFile } from "antd/lib/upload";
import { coupangProductList } from "types/blog";

const useCoupangPartenrs = () => {
  const dispatch = useAppDispatch();
  const selectedProduct = useAppSelector(selectedProductSelector);
  const coupangPartnersReducer = useAppSelector(coupangPartnerSelector);
  const CoupangRequestIdValue = useAppSelector(CoupangRequestIdSelector);
  const CoupangFetching = useAppSelector(CoupangLoadingSelector);
  const { productsReducer, currentPrdIdReducer } = useBlog();

  const productListName = useMemo(() => {
    return _.map(selectedProduct?.filter(Boolean), "itemName");
  }, [selectedProduct]);

  const selectProductCuid = useCallback((products, id) => {
    return _.find(products, { id });
  }, []);
  const updateCoupang = useCallback(() => {
    dispatch(
      blogAction.setUpdatedCoupangList(
        coupangPartnersReducer as coupangProductList
      )
    );
  }, [coupangPartnersReducer, dispatch]);
  const {
    brandName: selectBrandName,
    itemName: selectItemName,
    itemUrl: selectOriginalUrl,
  } = selectProductCuid(productsReducer?.list, currentPrdIdReducer) || {};

  const uploadImage = (file: RcFile, prdId: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const imageUrl = URL.createObjectURL(file);
      const image = document.createElement("img");
      image.addEventListener("load", () => {
        dispatch(
          blogAction.setUploadImage({
            prdId: prdId,
            img: image.src,
          })
        );
        URL.revokeObjectURL(imageUrl);
        resolve(true);
      });
      image.addEventListener("error", (error) => {
        URL.revokeObjectURL(imageUrl);
        reject(error);
      });
      image.src = imageUrl;
    });
  };

  return {
    selectProductCuid,
    uploadImage,
    updateCoupang,
    CoupangRequestIdValue,
    selectedProduct,
    coupangPartnersReducer,
    productListName,
    selectBrandName,
    selectItemName,
    selectOriginalUrl,
    CoupangFetching,
  };
};

export default useCoupangPartenrs;
