import { useAppSelector, useAppDispatch } from "store";
import type { ManageRequest } from "types/api";
import { useCallback } from "react";
import {
  APIStatusSelector,
  ContractStatusSelector,
  LoggerStatusSelector,
  ReportStatusSelector,
} from "store/modules/home";
import { homeAsyncAction } from "store/modules/home/saga";
import { format } from "date-fns";

const useHomeData = () => {
  const dispatch = useAppDispatch();
  const todayStr = format(new Date(), "yyyyMMdd");
  const [contractLoading, contractStatus, contractError] = [
    useAppSelector(ContractStatusSelector.loading),
    useAppSelector(ContractStatusSelector.status),
    useAppSelector(ContractStatusSelector.error),
  ];

  const [loggerLoading, loggerStatus, loggerError] = [
    useAppSelector(LoggerStatusSelector.loading),
    useAppSelector(LoggerStatusSelector.status),
    useAppSelector(LoggerStatusSelector.error),
  ];

  const [apiLoading, apiStatus, apiError] = [
    useAppSelector(APIStatusSelector.loading),
    useAppSelector(APIStatusSelector.status),
    useAppSelector(APIStatusSelector.error),
  ];

  const [reportLoading, reportStatus, reportError] = [
    useAppSelector(ReportStatusSelector.loading),
    useAppSelector(ReportStatusSelector.status),
    useAppSelector(ReportStatusSelector.error),
  ];

  const getContractStatus = useCallback(
    ({ cuid }: ManageRequest) => {
      dispatch(homeAsyncAction.getContractStatus.request({ cuid }));
    },
    [dispatch]
  );

  const getLoggerStatus = useCallback(
    ({ cuid }: ManageRequest) => {
      dispatch(homeAsyncAction.getLoggerStatus.request({ cuid, todayStr }));
    },
    [dispatch, todayStr]
  );

  const getAPIStatus = useCallback(
    ({ cuid }: ManageRequest) => {
      dispatch(homeAsyncAction.getAPIStatus.request({ cuid }));
    },
    [dispatch]
  );

  const getReportStatus = useCallback(
    ({ cuid }: ManageRequest) => {
      dispatch(homeAsyncAction.getReportStatus.request({ cuid, todayStr }));
    },
    [dispatch, todayStr]
  );

  return {
    contractLoading,
    contractStatus,
    contractError,
    loggerLoading,
    loggerStatus,
    loggerError,
    apiLoading,
    apiStatus,
    apiError,
    reportLoading,
    reportStatus,
    reportError,
    getContractStatus,
    getLoggerStatus,
    getAPIStatus,
    getReportStatus,
  };
};

export default useHomeData;
