import Client from "apis";
import type { UserRequest, UserResponse, PWChangeRequest } from "types/api";

class UserService {
  static async signIn(userRequest: UserRequest) {
    return await Client.credentialsInstance.post<UserResponse>(
      `${Client.path.auth}/signIn`,
      userRequest
    );
  }

  static async pwChange(pwChangeRequest: PWChangeRequest) {
    const { newPassword } = pwChangeRequest;
    return await Client.credentialsInstance.put(
      `${Client.path.api}/user/password`,
      {
        newPassword,
      }
    );
  }

  static async logout() {
    return await Client.credentialsInstance.post(`${Client.path.auth}/logout`);
  }
}
export default UserService;
