import _ from "lodash";

import { default as Seg_1 } from "assets/img/persona/click.svg";
import { default as Seg_2 } from "assets/img/persona/add_to_favorites.svg";
import { default as Seg_3 } from "assets/img/persona/octopus.svg";
import { default as Seg_4 } from "assets/img/persona/love.svg";
import { default as Seg_5 } from "assets/img/persona/map.svg";
import { default as Seg_6_a } from "assets/img/persona/target.svg";
import { default as Seg_6_b } from "assets/img/persona/hot_stones.svg";
import { default as Seg_7_a } from "assets/img/persona/alarm.svg";
import { default as Seg_7_b } from "assets/img/persona/ocean.svg";
import { default as Seg_7_c } from "assets/img/persona/half_moon.svg";

import { default as BestProduct } from "assets/img/persona/podium.svg";
import { default as NewProduct } from "assets/img/persona/new.svg";

import { default as Morning } from "assets/img/persona/sunrise.svg";
import { default as Lunch } from "assets/img/persona/ocean.svg";
import { default as Night } from "assets/img/persona/half_moon.svg";

import { default as LongTail } from "assets/img/persona/treasure.svg";
import { default as Repeat } from "assets/img/persona/buy.svg";

import { default as Bag } from "assets/img/persona/Bag.svg";
import { default as Heart } from "assets/img/persona/heart.svg";

import { default as DarkBag } from "assets/img/persona/Bag_dark.svg";
import { default as DarkHeart } from "assets/img/persona/heart_dark.svg";

import { default as Star } from "assets/img/persona/star.svg";
import { default as BrandLogo } from "assets/img/persona/brand-logo.svg";
import { default as CategoryLogo } from "assets/img/persona/categoryimage.svg";

import { default as Clock } from "assets/img/persona/clock.svg";
import { default as Apps } from "assets/img/persona/apps.svg";
import { default as Grocery } from "assets/img/persona/grocery-cart.svg";
import { default as Label } from "assets/img/persona/label.svg";

import { default as Community } from "assets/img/persona/community.svg";

import { default as Bag_with_sale } from "assets/img/persona/Bag_with_sale.svg";

type UrlInfo = {
  schem: string;
  host: string;
  path: string;
  query: string;
  queryCuid: string;
  queryUserId: string;
};

const itemDeduplication = (result: any[]) => {
  const dedupleResult = _.uniqBy(result, "itemId");
  return dedupleResult;
};

const getEncodedUserId = (userId?: string) =>
  userId ? encodeURIComponent(userId) : "";

const convertToApiUrl = (urlInfo: UrlInfo, type: string, input?: string) => {
  if (
    type === "pr004" ||
    type === "prst001" ||
    type === "prst003" ||
    type === "prst004"
  )
    return `${urlInfo.schem}${urlInfo.host}${urlInfo.path}/${type}${urlInfo.query}${urlInfo.queryCuid}&seg=${input}`;
  else if (type === "prst002")
    return `${urlInfo.schem}${urlInfo.host}${urlInfo.path}/${type}${urlInfo.query}${urlInfo.queryCuid}&itemid=${input}`;
  else {
    return `${urlInfo.schem}${urlInfo.host}${urlInfo.path}/${type}${
      urlInfo.query
    }${urlInfo.queryCuid}&userid=${getEncodedUserId(input)}`;
  }
};

const convertFetchUrl = (type: string, cuid: string, input?: string) => {
  if (
    type === "pr004" ||
    type === "prst001" ||
    type === "prst003" ||
    type === "prst004"
  )
    return `https://persona-api.eigene.io/v1/${type}?&size=10&cuid=${cuid}&seg=${input}`;
  else if (type === "pr005")
    return `https://persona-api.eigene.io/v1/${type}?&size=10&cuid=${cuid}`;
  else if (type === "prst005") {
    return `https://persona-api.eigene.io/v1/${type}?&size=10&cuid=${cuid}`;
  } else if (type === "prst002")
    return `https://persona-api.eigene.io/v1/${type}?&size=10&cuid=${cuid}&itemid=${input}`;
  return `https://persona-api.eigene.io/v1/${type}?size=10&cuid=${cuid}&userid=${getEncodedUserId(
    input
  )}`;
};

const convertFetchRecUrl = (recType: string, cuid: string, input: string) => {
  switch (recType) {
    case "c001":
      return `https://api.eigene.io/rec/${recType}?size=10&cuid=${cuid}&cids=${input}`;
    case "b004":
      return `https://api.eigene.io/rec/${recType}?size=10&cuid=${cuid}&bids=${input}`;
    case "s001":
      return `https://api.eigene.io/rec/${recType}?&size=10&cuid=${cuid}&st=${encodeURIComponent(
        input
      )}`;
    default:
      return "";
  }
};

const convertFetchATypeRecUrl = (cuid: string) => {
  return `https://api.eigene.io/rec/a001?size=10&cuid=${cuid}&iids=`;
};

const getLevel = (level: number) => {
  return `Lv. ${Math.floor(level)}`;
};

const getSegImg = (segCode: string) => {
  switch (segCode) {
    case "etype_seg_1":
      return Seg_1;
    case "etype_seg_2":
      return Seg_2;
    case "etype_seg_3":
      return Seg_3;
    case "etype_seg_4":
      return Seg_4;
    case "etype_seg_5":
      return Seg_5;
    case "etype_seg_6_a":
      return Seg_6_a;
    case "etype_seg_6_b":
      return Seg_6_b;
    case "etype_seg_7_a":
      return Seg_7_a;
    case "etype_seg_7_b":
      return Seg_7_b;
    case "etype_seg_7_c":
      return Seg_7_c;
  }
};

const getPrecedencePercent = (loc: number) => {
  if (Number.isInteger(loc)) {
    // 정수
    return 100 - loc >= 0.1 ? 100 - loc : 0.1;
  } else {
    return Number((100.0 - loc).toFixed(1)) >= 0.1
      ? Number((100.0 - loc).toFixed(1))
      : 0.1;
  }
};

const getLabelContents = (type: string | undefined) => {
  switch (type) {
    case "category": {
      return {
        content: "내가 자주 찾는 카테고리는?",
        tooltipContent:
          "고객별 개인 행동 이력(최빈 클릭 또는 최빈 구매)을 기반으로 연관 상품을 추천합니다.",
      };
    }

    case "brand": {
      return {
        content: "내가 자주 찾는 브랜드는?",
        tooltipContent:
          "고객이 최빈 클릭 또는 구매한(좌측에서 기준 선택 가능) 브랜드 순서와 해당 브랜드의 베스트 상품입니다.",
      };
    }

    case "item": {
      return {
        content: "나만을 위한 추천 상품",
        tooltipContent:
          "고객이 최빈 클릭 또는 구매한(좌측에서 기준 선택 가능) 상품의 연관 상품입니다.",
      };
    }
    default:
      return {
        content: "",
        tooltipContent: "",
      };
  }
};

const getNhLabelContents = (type: string | undefined) => {
  switch (type) {
    case "category": {
      return {
        content: "나와 비슷한 고객들이 선호하는 카테고리 인기 상품",
        tooltipContent:
          "고객과 행동 이력이 유사한 고객들이 최빈 클릭 또는 구매한(좌측에서 기준 선택 가능) 카테고리 순서와 해당 카테고리의 베스트 상품입니다.",
      };
    }

    case "brand": {
      return {
        content: "나와 비슷한 고객들이 선호하는 브랜드 인기 상품",
        tooltipContent:
          "고객과 행동 이력이 유사한 고객들이 최빈 클릭 또는 구매한(좌측에서 기준 선택 가능) 브랜드 순서와 해당 브랜드의 베스트 상품입니다.",
      };
    }

    case "item": {
      return {
        content: "나와 비슷한 고객들이 선호하는 인기 상품",
        tooltipContent:
          "고객과 행동 이력이 유사한 고객들이 최빈 클릭 또는 구매한(좌측에서 기준 선택 가능) 상품의 연관 상품입니다.",
      };
    }
    default:
      return {
        content: "",
        tooltipContent: "",
      };
  }
};

const getDiagonalList = () => [
  {
    top: "119px",
    left: "195px",
    transform: "matrix(-0.8, -0.6, -0.6, 0.8, 0, 0)",
  },
  {
    top: "119px",
    left: "316px",
    transform: "matrix(0.8, -0.6, 0.6, 0.8, 0, 0)",
  },
  {
    top: "239px",
    left: "195px",
    transform: "matrix(-0.8, 0.6, -0.6, -0.8, 0, 0)",
  },
  {
    top: "239px",
    left: "316px",
    transform: "matrix(0.8, 0.6, 0.6, -0.8, 0, 0)",
  },
];

const getStraightLineList = (currentUser: any) => [
  {
    top: "111px",
    left: "109px",
    transform: "matrix(-1, 0, 0, 1, 0, 0);",
    title: "평균 구매주기",
    scale: -1,
    content:
      currentUser?.["order_avg_interval"] < 90 &&
      currentUser?.["order_avg_interval"] > 1
        ? `${currentUser?.["order_avg_interval"]}일`
        : currentUser?.["order_avg_interval"] > 90
        ? "90일 이상"
        : "1일 미만",
  },
  {
    top: "111px",
    left: "341px",
    transform: "matrix(1, 0, 0, 1, 0, 0);",
    title: "선호카테고리",
    scale: 1,
    content: `${currentUser?.["fav_category_ratio"]}%`,
  },
  {
    top: "247px",
    left: "109px",
    transform: "matrix(-1, 0, 0, 1, 0, 0);",
    title: "활동 시간대",
    scale: -1,
    content: `${currentUser?.["view_time_slot_cd"]}`,
  },
  {
    top: "247px",
    left: "341px",
    transform: "matrix(1, 0, 0, 1, 0, 0);",
    title: "선호브랜드",
    scale: 1,
    content: `${currentUser?.["fav_brand_ratio"]}%`,
  },
];

const getNhImageList = () => [
  {
    top: "84px",
    left: "50px",
    image: Grocery,
  },
  {
    top: "84px",
    left: "434px",
    image: Apps,
  },
  {
    top: "220px",
    left: "50px",
    image: Clock,
  },
  {
    top: "220px",
    left: "434px",
    image: Label,
  },
];

const getUrlPath = (unit: string, option: string | undefined) => {
  switch (option) {
    case "category": {
      if (unit === "view") return "prc002";
      else return "pro002";
    }
    case "brand": {
      if (unit === "view") return "prc003";
      else return "pro003";
    }
    case "item": {
      if (unit === "view") return "prc001";
      else return "pro001";
    }
    default: {
      return "";
    }
  }
};

const getRadarChartData = (
  user: number = 0,
  avg: number = 0,
  max: number = 0,
  type: string
) => {
  let user_p = (user / max) * 100;
  let avg_p = (avg / max) * 100;

  const line = 60;
  if (user_p < line && avg_p < line) {
    let dumpNum = 60;

    switch (type) {
      case "주문수":
        dumpNum = 60;
        break;
      case "평균 객단가":
        dumpNum = 70;
        break;
      case "구매 브랜드 수":
        dumpNum = 80;
        break;
      case "구매 카데고리 수":
        dumpNum = 90;
        break;
      default:
        dumpNum = 60;
    }
    const max = Math.max(user_p, avg_p);
    const quotient = dumpNum / max;
    user_p *= quotient;
    avg_p *= quotient;
  }
  return {
    type,
    user,
    avg,
    user_p,
    avg_p,
    fullMark: 100,
  };
};

export {
  convertToApiUrl,
  convertFetchUrl,
  getLevel,
  getSegImg,
  getPrecedencePercent,
  BestProduct,
  NewProduct,
  Morning,
  Lunch,
  Night,
  LongTail,
  Repeat,
  getLabelContents,
  Bag,
  Heart,
  Bag_with_sale,
  DarkHeart,
  DarkBag,
  Star,
  BrandLogo,
  CategoryLogo,
  convertFetchRecUrl,
  convertFetchATypeRecUrl,
  getDiagonalList,
  getStraightLineList,
  getNhImageList,
  Clock,
  Apps,
  Grocery,
  Label,
  Community,
  getUrlPath,
  getNhLabelContents,
  getRadarChartData,
  itemDeduplication,
};
