import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input, message } from "antd";
import { MailTwoTone, LockTwoTone } from "@ant-design/icons";
import * as S from "pages/login/style";
import { useUserData } from "hooks";
import { userAction } from "store/modules/user";
import { useAppDispatch } from "store";
import type { UserRequest } from "types/api";
import { getErrorMessage } from "utils/userUtil";

const LoginPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    loginStatusLoading,
    loginStatusData,
    loginStatusError,
    isLogin,
    signIn,
  } = useUserData();

  const catchErrorAfterAlert = useCallback(() => {
    console.log("Error Catched");
    dispatch(userAction.clearLoginStatus());
  }, [dispatch]);

  useEffect(() => {
    if (loginStatusError) {
      const errorMessage = getErrorMessage(loginStatusError);
      message.error(errorMessage);

      catchErrorAfterAlert();
      return;
    }
    isLogin && navigate({ pathname: "/home" });
  }, [
    loginStatusError,
    catchErrorAfterAlert,
    loginStatusData,
    navigate,
    isLogin,
  ]);

  const onFinished = useCallback(
    ({ username, password }: any) => {
      signIn({ username, password } as UserRequest);
    },
    [signIn]
  );

  return (
    <S.LoginPageContainer>
      <S.LoginFormContainer title="EIGENE">
        <Form onFinish={onFinished}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please Input your id" }]}
            children={
              <Input
                autoComplete="username"
                placeholder="Enter ID"
                suffix={<MailTwoTone twoToneColor="#6F68B5" />}
              />
            }
          />
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please Input your password" }]}
            children={
              <Input
                autoComplete="current-password"
                type="password"
                placeholder="Password"
                suffix={<LockTwoTone twoToneColor="#6F68B5" />}
              />
            }
          />
          <Form.Item
            name="remember"
            valuePropName="checked"
            initialValue="true"
            children={<Checkbox children={"Remember Me"} />}
          />
          <Form.Item
            children={
              <Button
                loading={loginStatusLoading}
                block
                htmlType="submit"
                type="primary"
                children={"Login"}
              />
            }
          />
        </Form>
      </S.LoginFormContainer>
    </S.LoginPageContainer>
  );
};

export default LoginPage;
