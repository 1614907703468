import {
  createSlice,
  PayloadAction,
  createSelector,
  AnyAction,
} from "@reduxjs/toolkit";
import { RootState } from "store";
import type { UserState } from "types/user";
import { userAsyncAction, LOGOUT } from "store/modules/user/saga";
import { getGlobalState } from "utils/getGlobalState";
import type { UserResponse } from "types/api";
import { APIError, SitesResponse } from "types/api";
import _ from "lodash";

export const USER = "user";

const initialState: UserState = {
  ...getGlobalState(),
  locale: "ko_KR",
  todayStr: "",
  loginStatus: {
    loading: false,
    data: null,
    error: null,
  },
  cuid: {
    loading: false,
    data: null,
    error: null,
    current: null,
  },
  pwChange: {
    loading: false,
    data: null,
    error: null,
  },
};

const userSlice = createSlice({
  name: USER,
  initialState,
  reducers: {
    setUserItem(state, action: PayloadAction<Partial<UserState>>) {
      return { ...state, ...action.payload };
    },
    setCurrentCUID(state, action: PayloadAction<string>) {
      state.cuid.current = action.payload;
    },
    clearLoginStatus(state) {
      state.loginStatus = initialState.loginStatus;
      state.cuid = initialState.cuid;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(`${userAsyncAction.signIn.request}`, (state) => {
        state.loginStatus.loading = true;
      })
      .addCase(
        `${userAsyncAction.signIn.success}`,
        (state, action: PayloadAction<UserResponse>) => {
          state.loginStatus.loading = false;
          state.loginStatus.data = action.payload;
        }
      )
      .addCase(
        `${userAsyncAction.signIn.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.loginStatus.loading = false;
          state.loginStatus.error = action.payload;
        }
      )
      .addCase(`${userAsyncAction.pwChange.request}`, (state) => {
        state.pwChange.loading = true;
      })
      .addCase(
        `${userAsyncAction.pwChange.success}`,
        (state, action: PayloadAction<UserResponse>) => {
          state.pwChange.loading = false;
          state.pwChange.data = null;
        }
      )
      .addCase(
        `${userAsyncAction.pwChange.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.pwChange.loading = false;
          state.pwChange.error = action.payload;
        }
      )
      .addCase(`${userAsyncAction.fetchAdminCUIDs.request}`, (state) => {
        state.cuid.loading = true;
      })
      .addCase(
        `${userAsyncAction.fetchAdminCUIDs.success}`,
        (state, action: PayloadAction<SitesResponse>) => {
          state.cuid.loading = false;
          const sortedSites = _.sortBy(
            action.payload,
            (site) => site.name
          ).sort((a, b) => {
            const { name: aName } = a;
            const { name: bName } = b;

            if (aName.includes("test") || aName.includes("TEST")) return 1;
            if (bName.includes("test") || bName.includes("TEST")) return -1;

            return 0;
          });
          state.cuid.data = sortedSites;
          state.cuid.current = _.get(_.head(sortedSites), "cuid");
        }
      )
      .addCase(
        `${userAsyncAction.fetchAdminCUIDs.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.cuid.loading = false;
          state.cuid.error = action.payload;
        }
      )
      .addCase(`${userAsyncAction.fetchCustomerCUIDs.request}`, (state) => {
        state.cuid.loading = true;
      })
      .addCase(
        `${userAsyncAction.fetchCustomerCUIDs.success}`,
        (state, action: PayloadAction<SitesResponse>) => {
          state.cuid.loading = false;

          const sortedSites = _.sortBy(
            action.payload,
            (site) => site.name
          ).sort((a, b) => {
            const { name: aName } = a;
            const { name: bName } = b;

            if (aName.includes("test") || aName.includes("TEST")) return 1;
            if (bName.includes("test") || bName.includes("TEST")) return -1;

            return 0;
          });
          // LGCNS일때 cuid 5개 예외처리
          // 8897ed81-db33-40ba-be39-ccdaf294ac5f B2E/B2C
          // 3ecb07ca-f824-4d77-ae6f-01ef8b74e642 B2B
          // b27e1ed9-9ac4-4a59-8b8c-bb0f10a3c193 B2C_중
          // e1cf4515-a517-42f5-bd1f-ae8389929aea TEST
          // 96a10161-f0f1-441d-a353-c02cf0e9e0fe stage

          if (sortedSites.some((el) => el.customerId === 37)) {
            sortedSites
              .sort((after) => {
                return after.cuid === "96a10161-f0f1-441d-a353-c02cf0e9e0fe"
                  ? -1
                  : 0;
              })
              .sort((after) => {
                return after.cuid === "e1cf4515-a517-42f5-bd1f-ae8389929aea"
                  ? -1
                  : 0;
              })
              .sort((after) => {
                return after.cuid === "b27e1ed9-9ac4-4a59-8b8c-bb0f10a3c193"
                  ? -1
                  : 0;
              })
              .sort((after) => {
                return after.cuid === "3ecb07ca-f824-4d77-ae6f-01ef8b74e642"
                  ? -1
                  : 0;
              })
              .sort((after) => {
                return after.cuid === "8897ed81-db33-40ba-be39-ccdaf294ac5f"
                  ? -1
                  : 0;
              });
          }

          state.cuid.data = sortedSites;
          state.cuid.current = _.get(_.head(sortedSites), "cuid");
        }
      )
      .addCase(
        `${userAsyncAction.fetchCustomerCUIDs.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.cuid.loading = false;
          state.cuid.error = action.payload;
        }
      )
      .addMatcher(
        (action: AnyAction) => _.includes(_.get(action, "type"), LOGOUT),
        (state) => state
      );
  },
});

const selfSelector = (state: RootState) => state[USER];
export const deviceSelector = createSelector(
  selfSelector,
  (user) => user.device
);

export const collapsedSelector = createSelector(
  selfSelector,
  (user) => user.collapsed
);

export const localeSelector = createSelector(
  selfSelector,
  (user) => user.locale
);
const loginStatusSelector = createSelector(
  selfSelector,
  (state) => state.loginStatus
);
export const LoginStatusSelector = {
  loading: createSelector(
    loginStatusSelector,
    (loginStatus) => loginStatus.loading
  ),
  data: createSelector(loginStatusSelector, (loginStatus) => loginStatus.data),
  error: createSelector(
    loginStatusSelector,
    (loginStatus) => loginStatus.error
  ),
};

const cuidSelector = createSelector(selfSelector, (state) => state.cuid);
export const CUIDSelector = {
  loading: createSelector(cuidSelector, (cuid) => _.get(cuid, "loading")),
  data: createSelector(cuidSelector, (cuid) => _.get(cuid, "data")),
  error: createSelector(cuidSelector, (cuid) => _.get(cuid, "error")),
  current: createSelector(cuidSelector, (cuid) => _.get(cuid, "current")),
};
const PWChangeSelector = createSelector(
  selfSelector,
  (state) => state.pwChange
);
export const pwChangeSelector = {
  loading: createSelector(PWChangeSelector, (pwChange) =>
    _.get(pwChange, "loading")
  ),
  data: createSelector(PWChangeSelector, (pwChange) => _.get(pwChange, "data")),
  error: createSelector(PWChangeSelector, (pwChange) =>
    _.get(pwChange, "error")
  ),
};

export const userAction = userSlice.actions;
export const userReducer = userSlice.reducer;
