import { MANAGE_STATUS } from "assets/constants/string";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { SitesDataSelector } from "store/modules/manage";
import { manageAsyncAction } from "store/modules/manage/saga";
import { useManageData, useComponentId } from "hooks";

const useManageSiteData = () => {
  const componentId = useComponentId();
  const dispatch = useAppDispatch();
  const { status, search } = useManageData();
  const [sitesDataLoading, sitesData, sitesDataError] = [
    useAppSelector(SitesDataSelector.loading),
    useAppSelector(SitesDataSelector.data),
    useAppSelector(SitesDataSelector.error),
  ];

  const getAllSites = useCallback(() => {
    dispatch(manageAsyncAction.getAllSites.request({}));
  }, [dispatch]);

  const getSite = useCallback(
    ({ cuid }: { cuid: string }) => {
      dispatch(manageAsyncAction.getSite.request({ cuid }));
    },
    [dispatch]
  );

  const { sitesTableData } = useMemo(() => {
    const sitesTableOriginalData = _.map(
      sitesData,
      ({ cuid, customerName, url, name, category, useService }, idx) => ({
        idx,
        key: `${componentId}-sites-table-data-${idx}`,
        cuid,
        customerName,
        url,
        siteName: name,
        category,
        useService,
      })
    );

    const sitesTableData = _.filter(
      _.filter(sitesTableOriginalData, ({ useService }) => {
        if (status === MANAGE_STATUS.ALL) return true;
        if (status === MANAGE_STATUS.ENABLE) return useService;
        if (status === MANAGE_STATUS.DISABLE) return !useService;
      }),
      ({
        customerName,
        siteName,
      }: {
        customerName: string;
        siteName: string;
      }) => {
        return _.includes(customerName, search) || _.includes(siteName, search);
      }
    );
    return { sitesTableData };
  }, [search, status, sitesData, componentId]);

  const getContractsDate = (service: any) => {
    return {
      contractDate: service?.contractDate
        ? new Date(service.contractDate)
        : null,
      contractTerm: [
        service?.contractStart ? new Date(service.contractStart) : null,
        service?.contractEnd ? new Date(service.contractEnd) : null,
      ],
    };
  };

  const siteDetailFormData = useMemo(() => {
    return _.head(
      _.map(
        sitesData,
        ({
          customerId,
          url,
          uname,
          name,
          customer,
          category,
          services,
          dumpMethod,
          dumpName,
          dumpMemo,
        }) => ({
          customerId,
          customerName: _.get(customer, "name", ""),
          address: _.get(customer, "address", ""),
          category,
          url,
          uname,
          siteName: name,
          recService: _.filter(services, { type: "rec" })[0],
          recContract: getContractsDate(_.filter(services, { type: "rec" })[0]),

          pushService: _.filter(services, { type: "push" })[0],
          pushContract: getContractsDate(
            _.filter(services, { type: "push" })[0]
          ),

          onSiteService: _.filter(services, { type: "onSite" })[0],
          onSiteContract: getContractsDate(
            _.filter(services, { type: "onSite" })[0]
          ),

          streamingService: _.filter(services, { type: "streaming" })[0],
          streamingContract: getContractsDate(
            _.filter(services, { type: "streaming" })[0]
          ),

          etcService: _.filter(services, { type: "etc" })[0],
          etcContract: getContractsDate(_.filter(services, { type: "etc" })[0]),

          dumpMethod,
          dumpName,
          dumpMemo,
        })
      )
    );
  }, [sitesData]);

  const addSite = useCallback(
    ({
      customerId,
      customerName,
      url,
      uname,
      siteName,
      category,
      dumpMethod,
      dumpName,
      dumpMemo,
      services,
      onSuccess,
      onFailure,
    }: {
      customerId: number;
      customerName: string;
      url: string;
      uname: string;
      siteName: string;
      category: string;
      dumpMethod: string;
      dumpName: string;
      dumpMemo: string;
      services: Array<any>;
      onSuccess: () => void;
      onFailure: () => void;
    }) => {
      dispatch(
        manageAsyncAction.addSite.request({
          customerId,
          customerName,
          url,
          uname,
          siteName,
          category,
          dumpMethod,
          dumpName,
          dumpMemo,
          services,
          onSuccess,
          onFailure,
        })
      );
    },
    [dispatch]
  );

  const setSite = useCallback(
    ({
      cuid,
      customerId,
      customerName,
      url,
      uname,
      siteName,
      category,
      dumpMethod,
      dumpName,
      dumpMemo,
      services,
      onSuccess,
      onFailure,
    }: {
      cuid: string;
      customerId: number;
      customerName: string;
      url: string;
      uname: string;
      siteName: string;
      category: string;
      dumpMethod: string;
      dumpName: string;
      dumpMemo: string;
      services: Array<any>;
      onSuccess: () => void;
      onFailure: () => void;
    }) => {
      dispatch(
        manageAsyncAction.setSite.request({
          cuid,
          customerId,
          customerName,
          url,
          uname,
          siteName,
          category,
          dumpMethod,
          dumpName,
          dumpMemo,
          services,
          onSuccess,
          onFailure,
        })
      );
    },
    [dispatch]
  );

  return {
    sitesDataLoading,
    sitesTableData,
    getAllSites,
    getSite,
    siteDetailFormData,
    setSite,
    addSite,
  };
};
export default useManageSiteData;
