import { gql } from "@apollo/client";

export const GET_CHANNEL = gql`
  query channel {
    channel {
      edges {
        node {
          id
          cuid
          channelName
        }
      }
    }
  }
`;

export const GET_PAGES = gql`
  query page {
    page {
      edges {
        node {
          id
          cuid
          pageName
        }
      }
    }
  }
`;

export const GET_AREA = gql`
  query area {
    area {
      edges {
        node {
          id
          cuid
          areaName
        }
      }
    }
  }
`;

export const GET_CAMPAIGN = gql`
  query campaign($filter: JSON) {
    campaign(filter: $filter) {
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          cuid
          name
          code
          version
          status
          page {
            pageName
          }
          channel {
            channelName
          }
          area {
            areaName
          }
          CampaignConfigurations {
            id
            priority
            status
            Selector {
              id
              cuid
              name
              aggregateMethod
              period
              expression
              selectorParameters {
                id
                operandSign
                Logic {
                  id
                  code
                  shortDesc
                  inputs
                  outputs
                  description
                }
                resultSize
                inputSourceSize

                InputSources {
                  id
                  action
                  resultSize
                  InputSourceType {
                    source
                    elementType
                    type
                    name
                    value
                  }
                }
              }
            }
            Operator {
              id
              cuid
              name
              filterConfig {
                list {
                  type
                  operator
                  criteria
                  elements
                  action
                }
              }
              sortingConfig {
                list {
                  prop
                  type
                  order
                }
              }
            }
            Trigger {
              id
              TriggerType {
                id
                rule
              }
            }
            qbotMessageSet
            AnalyticalSeg {
              id
              code
            }
            RandomizedSegGroup {
              id
              pcidPattern
              viewRatio
              orderRatio
              revenueRatio
              basketSize
            }
            createdAt
          }
        }
      }
    }
  }
`;

export const GET_FILTERED_CONFIGURATION = gql`
  query campaign($filter: JSON) {
    campaign(filter: $filter) {
      edges {
        node {
          CampaignConfigurations {
            id
            priority
            Selector {
              id
              cuid
              name
              aggregateMethod
              period
              expression
            }
            Operator {
              id
              cuid
              name
            }
            Trigger {
              id
              TriggerType {
                id
                rule
              }
            }
            qbotMessageSet
            AnalyticalSeg {
              id
              code
            }
            RandomizedSegGroup {
              id
              pcidPattern
              viewRatio
              orderRatio
              revenueRatio
              basketSize
            }
            createdAt
          }
        }
      }
    }
  }
`;

export const GET_DESIGNER_SEG = gql`
  query analyticalSegmentation {
    analyticalSegmentation {
      edges {
        node {
          id
          tag
          division
          code
          definition
          description
        }
      }
    }
  }
`;

export const GET_SELECTOR_LOGIC = gql`
  query logic {
    logic(first: 1000) {
      edges {
        node {
          id
          code
          shortDesc
          inputs
          outputs
          description
        }
      }
    }
  }
`;

export const GET_SELECTOR_INPUT_SOURCE_TYPE = gql`
  query inputSourceType {
    inputSourceType(first: 1000) {
      edges {
        node {
          id
          source
          elementType
          name
          type
          value
          description
        }
      }
    }
  }
`;

export const GET_SELECTOR_LIST = gql`
  query selector {
    selector(first: 1000) {
      edges {
        node {
          id
          cuid
          name
          expression
        }
      }
    }
  }
`;

export const GET_SELECTOR_DETAIL_LIST = gql`
  query selector {
    selector(first: 1000) {
      edges {
        node {
          id
          cuid
          name
          expression
          createdAt
          selectorParameters {
            id
            operandSign
            Logic {
              id
              code
              inputs
              outputs
              description
            }
            InputSources {
              id
              InputSourceType {
                source
                elementType
                type
                name
                value
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_OPERATOR_SIMPLE_LIST = gql`
  query operator($filter: JSON) {
    operator(filter: $filter) {
      edges {
        node {
          id
          cuid
          name
          createdAt
        }
      }
    }
  }
`;

export const GET_OPERATOR_LIST = gql`
  query operator($filter: JSON) {
    operator(filter: $filter) {
      edges {
        node {
          id
          cuid
          name
          createdAt
          filterConfig {
            list {
              type
              operator
              criteria
              elements
              action
            }
          }
          sortingConfig {
            list {
              prop
              type
              order
            }
          }
        }
      }
    }
  }
`;

export const GET_TRIGGER_TYPE = gql`
  query triggerType {
    triggerType {
      edges {
        node {
          id
          rule
          defaultMessage
          parameter
        }
      }
    }
  }
`;

export const GET_TRIGGER = gql`
  query trigger($filter: JSON) {
    trigger(first: 2000, filter: $filter) {
      edges {
        node {
          id
          TriggerType {
            id
            rule
          }
          extraProps
        }
      }
    }
  }
`;

export const GET_CUBOT_ONOFF = gql`
  query cubotOnOff($cuid: String) {
    cubotOnOff(cuid: $cuid) {
      cuid
      isOn
    }
  }
`;
