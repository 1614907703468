const NORMAL_ERROR = 1001;
const USER_NOT_FOUND = 1002;
const SESSION_EXPIRED = 1003; //세션 만료, 중복로그인 code
const NEED_TO_LOGIN = 1004;
const INVALID_LOGIN_INFO = 1005;
const PERMISSION_REQUIRED = 1006;
const DEACTIVATED_USER = 1007;
const ALREADY_EXISTS_USER = 1008;
const NEED_TO_CHANGE_PASSWORD = 1009;
const LOCKED_ACCOUNT = 1010;

const TO_COLLAPSE_WIDTH = 1134;
const XS = 576;
const SM = 768;
const MD = 992;
const LG = 1200;
const XL = 1600;

export {
  TO_COLLAPSE_WIDTH,
  NORMAL_ERROR,
  USER_NOT_FOUND,
  SESSION_EXPIRED,
  NEED_TO_LOGIN,
  INVALID_LOGIN_INFO,
  PERMISSION_REQUIRED,
  DEACTIVATED_USER,
  ALREADY_EXISTS_USER,
  NEED_TO_CHANGE_PASSWORD,
  LOCKED_ACCOUNT,
  XS,
  SM,
  MD,
  LG,
  XL,
};
