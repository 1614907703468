import { useCallback, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store";

import { useMutation, useQuery } from "@apollo/client";

import { GET_AREA, GET_CAMPAIGN, GET_CHANNEL, GET_PAGES } from "apollo/query";
import {
  DELETE_CAMPAIGN_CONFIGURATION,
  UPDATE_CAMPAIGN_CONFIGURATION,
  UPDATE_CAMPAIGN_CONFIGURATION_PRIORITY,
  UPDATE_CAMPAIGN_TITLE,
  UPDATE_STATUS_CAMPAIGN_CONFIGURATION,
} from "apollo/mutation";
import {
  CampaignDataSelector,
  areaStatusSelector,
  channelStatusSelector,
  currentCampaignIdSelector,
  dsocAction,
  pageStatusSelector,
  sectionStatusSelector,
} from "store/modules/dsoc";
import useUserData from "./useUserData";
import {
  DSOC_ALL,
  DSOC_NOT_SELECTED,
  DSOC_STATUS,
} from "assets/constants/string";
import {
  CAMPAIGN_CHANNEL_MAP,
  CAMPAIGN_PAGE_MAP,
  CAMPAIGN_SECTION_MAP,
} from "assets/constants/campaign";

const { ALL, NORMAL, CUBOT } = DSOC_STATUS;

const useCampaignData = () => {
  const dispatch = useAppDispatch();
  const { currentCuid } = useUserData();
  const currentCampaignId = useAppSelector(currentCampaignIdSelector);
  const sectionStatus = useAppSelector(sectionStatusSelector);
  const pageStatus = useAppSelector(pageStatusSelector);
  const channelStatus = useAppSelector(channelStatusSelector);
  const areaStatus = useAppSelector(areaStatusSelector);
  const { loading: channelLoading, data: channelData } = useQuery(GET_CHANNEL);
  const { loading: pageLoading, data: pageData } = useQuery(GET_PAGES);
  const { loading: areaLoading, data: areaData } = useQuery(GET_AREA);
  const {
    loading: campaignListLoading,
    data: campaignListData,
    refetch: campaignListRefetch,
  } = useQuery(GET_CAMPAIGN, {
    variables: {
      limit: 20,
      filter: {
        columns: {
          cuid: {
            eq: currentCuid,
          },
          ...(pageStatus !== DSOC_ALL
            ? {
                pageId: {
                  eq: Object.values(CAMPAIGN_PAGE_MAP).indexOf(pageStatus),
                },
              }
            : null),
          ...(channelStatus !== ALL
            ? {
                channelId: {
                  eq: Object.values(CAMPAIGN_CHANNEL_MAP).indexOf(
                    channelStatus
                  ),
                },
              }
            : null),
          ...(areaStatus !== DSOC_NOT_SELECTED
            ? {
                areaId: {
                  eq: Object.values(CAMPAIGN_SECTION_MAP).indexOf(areaStatus),
                },
              }
            : null),
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const [updateCampaignName, { data, loading }] = useMutation(
    UPDATE_CAMPAIGN_TITLE
  );

  const [
    updateCampaignConfiguration,
    {
      data: campaignConfigurationUpdatedData,
      loading: campaignConfigurationUpdatedDataLoading,
    },
  ] = useMutation(UPDATE_CAMPAIGN_CONFIGURATION_PRIORITY);

  const [
    deleteCampaignConfiguration,
    {
      data: campaignConfigurationDeletedData,
      loading: campaignConfigurationDeletedDataLoading,
    },
  ] = useMutation(DELETE_CAMPAIGN_CONFIGURATION);

  const [
    updateStatusCampaignConfiguration,
    {
      data: campaignConfigurationStatusData,
      loading: campaignConfigurationStatusDataLoading,
    },
  ] = useMutation(UPDATE_STATUS_CAMPAIGN_CONFIGURATION);

  const [
    updateQbotMessageCampaignConfiguration,
    {
      data: updateQbotMessageCampaignConfResData,
      loading: loadingQbotMessageUpdateReq,
    },
  ] = useMutation(UPDATE_CAMPAIGN_CONFIGURATION);

  const [campaignLoading, campaignData, campaignError] = [
    useAppSelector(CampaignDataSelector.loading),
    useAppSelector(CampaignDataSelector.data),
    useAppSelector(CampaignDataSelector.error),
  ];

  const setCurrentCampaignId = useCallback(
    ({ id }: { id: string }) => {
      dispatch(dsocAction.setCurrentCampaignId({ id }));
    },
    [dispatch]
  );

  const setCampaignName = useCallback(
    ({ name }: { name: string }) => {
      dispatch(dsocAction.setCampaignName({ name }));
    },
    [dispatch]
  );

  const setSectionStatus = useCallback(
    ({ status }: { status: string }) => {
      dispatch(dsocAction.setSectionStatus({ status }));
    },
    [dispatch]
  );

  const setPageStatus = useCallback(
    ({ status }: { status: string }) => {
      dispatch(dsocAction.setPageStatus({ status }));
    },
    [dispatch]
  );

  const setChannelStatus = useCallback(
    ({ status }: { status: string }) => {
      dispatch(dsocAction.setChannelStatus({ status }));
    },
    [dispatch]
  );

  const setAreaStatus = useCallback(
    ({ status }: { status: string }) => {
      dispatch(dsocAction.setAreaStatus({ status }));
    },
    [dispatch]
  );

  const setCampaignChannel = useCallback(
    ({ channel }: { channel: { id: string; channelName: string } }) => {
      dispatch(dsocAction.setCampaignChannel({ channel }));
    },
    [dispatch]
  );

  const setCampaignPage = useCallback(
    ({ page }: { page: { id: string; pageName: string } }) => {
      dispatch(dsocAction.setCampaignPage({ page }));
    },
    [dispatch]
  );

  const setCampaignArea = useCallback(
    ({ area }: { area: { id: string; areaName: string } }) => {
      dispatch(dsocAction.setCampaignArea({ area }));
    },
    [dispatch]
  );

  const clearCampaignData = useCallback(() => {
    dispatch(dsocAction.clearCampaignData());
  }, [dispatch]);

  const campaignList = useMemo(() => {
    return (
      campaignListData?.campaign?.edges
        ?.map((row: any) => {
          const { node } = row;
          return node;
        })
        ?.filter((row: any) => {
          if (!row?.area?.areaName) return false;
          const { area } = row;
          const { areaName } = area;
          if (sectionStatus === ALL) return true;
          else if (sectionStatus === NORMAL) return areaName !== "cubot";
          else if (sectionStatus === CUBOT) return areaName === "cubot";
          return false;
        }) || []
    );
  }, [campaignListData?.campaign?.edges, sectionStatus, pageStatus]);

  const getCampaignFilterById = useCallback(
    ({ id }: { id: number }) => {
      const filterByIdLiveList = campaignList?.filter((row: any) => {
        const { id: campaignId } = row;
        return campaignId === id;
      });

      return filterByIdLiveList?.[0] ?? {};
    },
    [campaignList]
  );

  const campaignName = useMemo(() => campaignData["name"], [campaignData]);
  const channel = useMemo(() => campaignData["channel"], [campaignData]);
  const area = useMemo(() => campaignData["area"], [campaignData]);
  const page = useMemo(() => campaignData["page"], [campaignData]);

  return {
    campaignLoading,
    campaignData,
    campaignError,
    setCampaignName,
    setCampaignChannel,
    setCampaignPage,
    setCampaignArea,
    campaignName,
    channel,
    area,
    page,
    currentCampaignId,
    setCurrentCampaignId,
    channelLoading,
    channelData,
    pageLoading,
    pageData,
    areaLoading,
    areaData,
    campaignList,
    campaignListLoading,
    campaignListRefetch,
    getCampaignFilterById,
    clearCampaignData,
    sectionStatus,
    setSectionStatus,
    pageStatus,
    setPageStatus,
    channelStatus,
    areaStatus,
    setAreaStatus,
    setChannelStatus,
    updateCampaignName,
    updateCampaignConfiguration,
    deleteCampaignConfiguration,
    updateQbotMessageCampaignConfiguration,
    updateQbotMessageCampaignConfResData,
    updateStatusCampaignConfiguration,
  };
};

export default useCampaignData;
