import Client from "apis";
import { RecRequest } from "types/api";

class RecDemoService {
  static async postRecTypePerCuid(recTypePerCuidRequest: RecRequest) {
    return await Client.credentialsInstance.post(
      `${Client.path.api}/rec-demo/rectype`,
      {
        cuid: recTypePerCuidRequest.cuid,
      }
    );
  }

  static fetchRecResult = async (recResultRequest: RecRequest) => {
    if (recResultRequest?.apiUrl) {
      //Pxxx에 대한 예외처리
      const [url, query] = recResultRequest.apiUrl.split("?");
      if (url.includes("pxxx")) {
        const [, cuidArr, userIdArr] = query.split("&");
        const [, cuid] = cuidArr.split("=");
        const [, userId] = userIdArr.split("=");
        if (cuid && userId) {
          return await Client.credentialsInstance.post(
            `${Client.path.api}/rec-demo/pxxx`,
            {
              cuid,
              nh: "14",
              pcId: "",
              recType: "p015",
              size: "10",
              userId,
            }
          );
        }
      }
    }

    return await Client.publicInstance.get(`${recResultRequest.apiUrl}`);
  };
}

export default RecDemoService;
