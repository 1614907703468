import { FC, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Switch } from "antd";

import { ReactComponent as Moon } from "assets/img/moon.svg";
import { ReactComponent as Sun } from "assets/img/sun.svg";

const useTheme = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("theme") === "dark" ? true : false
  );
  const { switcher, currentTheme, status, themes } = useThemeSwitcher();

  const toggleTheme = (isChecked: any) => {
    setIsDarkMode(isChecked);
    switcher({ theme: isChecked ? themes.dark : themes.light });
    localStorage.setItem("theme", isChecked ? "dark" : "light");
  };

  const ThemeButton: FC = () => {
    return (
      <>
        <Switch
          // checkedChildren={
          //   <Sun style={{ width: "16px", height: "16px", marginTop: "3px" }} />
          // }
          // unCheckedChildren={
          //   <Moon style={{ width: "16px", height: "16px", marginTop: "3px" }} />
          // }
          checked={isDarkMode}
          onChange={toggleTheme}
        />
      </>
    );
  };

  return {
    ThemeButton,
    currentTheme,
    useThemeSwitcher,
    themes,
  };
};

export default useTheme;
