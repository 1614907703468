import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import type { APIError, RandomInputResponse } from "types/api";
import { RootState } from "store";

import type { RandomState } from "types/random";

import { randomInputAsyncAction } from "store/modules/demo/random/saga";

export const RANDOM = "random";

const initialState: RandomState = {
  randomInput: {
    loading: false,
    data: {
      cuid: "",
      result: "",
      type: "",
    },
    error: null,
  },
  randomRecInput: {
    loading: false,
    data: {
      cuid: "",
      result: "",
      type: "",
    },
    error: null,
  },
};

const randomSlice = createSlice({
  name: RANDOM,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        `${randomInputAsyncAction.fetchRandomInput.request}`,
        (state) => {
          state.randomInput.loading = true;
        }
      )
      .addCase(
        `${randomInputAsyncAction.fetchRandomInput.success}`,
        (
          state,
          action: PayloadAction<{ randomInput: RandomInputResponse }>
        ) => {
          state.randomInput.loading = false;
          state.randomInput.data = action.payload.randomInput;
        }
      )
      .addCase(
        `${randomInputAsyncAction.fetchRandomInput.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.randomInput.loading = false;
          state.randomInput.data = initialState.randomInput.data;
          state.randomInput.error = action.payload;
        }
      )
      .addCase(
        `${randomInputAsyncAction.fetchRandomRecInput.request}`,
        (state) => {
          state.randomRecInput.loading = true;
        }
      )
      .addCase(
        `${randomInputAsyncAction.fetchRandomRecInput.success}`,
        (
          state,
          action: PayloadAction<{ randomInput: RandomInputResponse }>
        ) => {
          state.randomRecInput.loading = false;
          state.randomRecInput.data = action.payload.randomInput;
        }
      )
      .addCase(
        `${randomInputAsyncAction.fetchRandomRecInput.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.randomRecInput.loading = false;
          state.randomRecInput.data = initialState.randomInput.data;
          state.randomRecInput.error = action.payload;
        }
      );
  },
});

const randomInputSelector = (state: RootState) => state[RANDOM].randomInput;
const randomRecInputSelector = (state: RootState) =>
  state[RANDOM].randomRecInput;

export const RandomInputSelector = {
  loading: createSelector(
    randomInputSelector,
    (randomInput) => randomInput.loading
  ),
  data: createSelector(randomInputSelector, (randomInput) => randomInput.data),
  error: createSelector(
    randomInputSelector,
    (randomInput) => randomInput.error
  ),
};

export const RandomInputRecSelector = {
  loading: createSelector(
    randomRecInputSelector,
    (randomRecInput) => randomRecInput.loading
  ),
  data: createSelector(
    randomRecInputSelector,
    (randomRecInput) => randomRecInput.data
  ),
  error: createSelector(
    randomRecInputSelector,
    (randomRecInput) => randomRecInput.error
  ),
};

export const randomAction = {
  ...randomSlice.actions,
};

export const randomReducer = randomSlice.reducer;
