import { Children, FC, useCallback, useMemo } from "react";
import type { Locale, LocaleMap } from "types/user";
import { useAppDispatch, useAppSelector } from "store";
import { Button, Dropdown, Menu } from "antd";
import { localeSelector, userAction } from "store/modules/user";
import { ReactComponent as KoKrSvg } from "assets/img/ko_KR.svg";

interface LocaleSelectDropdownProps {
  localeMap: LocaleMap;
  currentLocale: Locale;
}

const LocaleSelectDropdown: FC<LocaleSelectDropdownProps> = ({
  localeMap,
  currentLocale,
}) => {
  const locale = useAppSelector(localeSelector);
  const { SvgComponent: CurrentLocaleSvgComponent } = useMemo(() => {
    let result = localeMap[currentLocale] || { SvgComponent: KoKrSvg };
    return result;
  }, [currentLocale, localeMap]);

  const dispatch = useAppDispatch();

  const changeCurrentLocale = useCallback(
    (key: Locale) => () => {
      dispatch(userAction.setUserItem({ locale: key }));
    },
    [dispatch]
  );

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu>
          {Children.toArray(
            Object.entries(localeMap)
              .filter(([key, { SvgComponent }]) => key !== locale)
              .map(([key, { label, SvgComponent }]) => {
                return (
                  <Menu.Item
                    style={{ textAlign: "left" }}
                    onClick={changeCurrentLocale(key as Locale)}
                  >
                    <SvgComponent
                      width="16px"
                      height="16px"
                      style={{
                        verticalAlign: "middle",
                        marginRight: "6px",
                      }}
                    />
                    {label}
                  </Menu.Item>
                );
              })
          )}
        </Menu>
      }
    >
      <Button
        type="text"
        block
        children={
          <CurrentLocaleSvgComponent
            width="20px"
            height="20px"
            style={{ verticalAlign: "middle" }}
          />
        }
      />
    </Dropdown>
  );
};

export default LocaleSelectDropdown;
