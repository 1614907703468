import { useState, useCallback, useMemo } from "react";
import blogService from "apis/services/blogService";
import { useAppDispatch, useAppSelector } from "store";
import moment from "moment";
import {
  keywordsSelector,
  criteriaSelector,
  productsSelector,
  htmlSelector,
  currentStateSelector,
  currentPrdIdSelector,
  requestSelector,
  labelTemplateSelector,
  userTextSelector,
  labelDataSelector,
  languageSelector,
  translatedTxtSelector,
  currentVideoSelector,
  coverTitleSelector,
  coverImageSelector,
  videoThumbnailSelector,
  videoTextSelector,
  blogAction,
  selectedVideoSelector,
  openModalSelector,
} from "store/modules/blog";
import { fetchTranslate, fetchVideo } from "store/modules/blog/saga";
import { ProductRequest } from "types/blog";
import JSZip from "jszip";
import FileSaver from "file-saver";
import html2canvas from "html2canvas";
import { notification } from "antd";
import _ from "lodash";

const useBlog = () => {
  const dispatch = useAppDispatch();
  const currentStateReducer = useAppSelector(currentStateSelector);
  const criteriaReducer = useAppSelector(criteriaSelector);
  const productsReducer = useAppSelector(productsSelector);
  const keywordsReducer = useAppSelector(keywordsSelector);
  const requestReducer = useAppSelector(requestSelector);
  const currentPrdIdReducer = useAppSelector(currentPrdIdSelector);
  const htmlReducer = useAppSelector(htmlSelector);
  const currentState = useAppSelector(currentStateSelector);
  const userTextReducer = useAppSelector(userTextSelector);
  const labelTemplateReducer = useAppSelector(labelTemplateSelector);
  const labelData = useAppSelector(labelDataSelector);
  const language = useAppSelector(languageSelector);
  const translatedTxt = useAppSelector(translatedTxtSelector);
  const currentVideo = useAppSelector(currentVideoSelector);
  const coverTitle = useAppSelector(coverTitleSelector);
  const coverImage = useAppSelector(coverImageSelector);
  const videoThumbnail = useAppSelector(videoThumbnailSelector);
  const videoText = useAppSelector(videoTextSelector);
  const selectedVideos = useAppSelector(selectedVideoSelector);
  const openModal = useAppSelector(openModalSelector);

  const productExist = useMemo(() => {
    return productsReducer && productsReducer?.list.length > 0 ? true : false;
  }, [productsReducer]);
  const setPersistDraw = useCallback(() => {
    dispatch(blogAction.setPersistStateDraw());
  }, [dispatch]);

  const setRequestAction = useCallback(
    (request: ProductRequest) => {
      dispatch(blogAction.setRequestAction(request));
    },
    [dispatch]
  );
  const setCurrentStateAction = useCallback(() => {
    dispatch(blogAction.setCurrentState());
  }, [dispatch]);
  const setClearCurrentStateAction = useCallback(() => {
    dispatch(blogAction.setClearCurrentState());
  }, [dispatch]);

  const setClearProductsAction = useCallback(() => {
    dispatch(blogAction.setClearProducts());
  }, [dispatch]);
  const setcurrentProductId = useCallback(
    (id: string | null) => {
      dispatch(blogAction.setcurrentProductId(id));
    },
    [dispatch]
  );

  const setUserInputTitle = useCallback(
    (value: string) => {
      dispatch(blogAction.setUserInputTitle(value));
    },
    [dispatch]
  );
  const setUserInputHeadCopy = useCallback(
    (value: string) => {
      dispatch(blogAction.setUserInputHeadCopy(value));
    },
    [dispatch]
  );
  const setUserInputTrandInfo = useCallback(
    (value: string) => {
      dispatch(blogAction.setUserInputTrandInfo(value));
    },
    [dispatch]
  );
  const setHtmlSource = useCallback(
    (html: string) => {
      dispatch(blogAction.setHtmlSource(html));
    },
    [dispatch]
  );

  const setTranslatedText = useCallback(
    (texts: string[]) => {
      dispatch(fetchTranslate.request(texts));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translatedTxt]
  );
  const setCoverTitle = useCallback(
    (text: string) => {
      dispatch(blogAction.setCoverTitle(text));
    },
    [dispatch]
  );
  const setCoverImage = useCallback(
    (url: any) => {
      dispatch(blogAction.setCoverImage(url));
    },
    [dispatch]
  );
  const setVideoThumbnail = useCallback(
    (url: any) => {
      dispatch(
        blogAction.setVideoThumbnail({
          videoThumbnail: url,
        })
      );
    },
    [dispatch]
  );
  const setVideoText = useCallback(
    (text: any) => {
      dispatch(
        blogAction.setVideoText({
          videoText: text,
        })
      );
    },
    [dispatch]
  );
  const getHtmlSource = () => {
    const htmlSource = document.querySelector(".blogContainer")?.innerHTML;
    if (!htmlSource) {
      return;
    }
    return htmlSource;
  };

  const saveImgFileToLocal = (blob: Blob, fileName: string) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
  };

  const [mediaLoading, setMediaLoading] = useState<
    "NONE" | "IMG_LOADING" | "VIDEO_LOADING"
  >("NONE");

  const getImageBlob = (className: string) => {
    const canvas = document.querySelector(className) as HTMLElement;

    const canvasElementPromise = html2canvas(canvas, {
      allowTaint: true,
      useCORS: true,
      proxy: "*",
    });
    return canvasElementPromise
      .then((canvas) => new Promise<any>((resolve) => canvas.toBlob(resolve)))
      .then(async (blob) => {
        if (!blob) throw new Error("blob error");

        return blob;
      });
  };

  const DownloadImages = () => {
    const zip = new JSZip();
    const P1 = getImageBlob(".Instagram-Cover");
    const P2 = getImageBlob(".Instagram-Keywords");
    const P3 = productsReducer?.list
      .filter((prd) => prd.selectedVideo === true)
      .map((_, prdId) => {
        return getImageBlob(`.Instagram-Image-${prdId}`);
      });

    const P4 = getImageBlob(".Instagram-Finish");

    Promise.all([P1, P2, ...(P3 ?? []), P4]).then((results: Blob[]) => {
      setMediaLoading("NONE");
      console.log(results);
      results.forEach((res, index) => {
        if (index === 0) {
          zip.file(`Cover.jpeg`, res);
        } else if (index === 1) {
          zip.file(`Keyword.jpeg`, res);
        } else if (index === results.length - 1) {
          zip.file(`Finish.jpeg`, res);
        } else if (res === undefined) {
          console.log("선택되지 않은 이미지 입니다.");
        } else {
          zip.file(`Product-${index}.jpeg`, res);
        }
      });

      zip.generateAsync({ type: "blob" }).then((content) => {
        FileSaver.saveAs(content, "Instagram-Images");
      });
    });
  };
  const uploadImageByClassName = (className: string) => {
    const canvas = document.querySelector(className) as HTMLElement;
    const canvasElementPromise = html2canvas(canvas, {
      allowTaint: true,
      useCORS: true,
      proxy: "*",
    });
    return canvasElementPromise
      .then((canvas) => new Promise<any>((resolve) => canvas.toBlob(resolve)))
      .then(async (blob) => {
        if (!blob) throw new Error("blob error");
        //S3에 업로드
        const formData = new FormData();
        formData.append("file", blob, `${className}.png`);
        formData.append("keyPrefix", "blog/video/img/");
        const res = await blogService.getUploadedImageUrl(formData);
        if (res) {
          return res.data.data.url;
        } else if (res === undefined) {
          console.log("이미지를 선택하지 않았습니다.");
        }
      });
  };

  const getDubbingScripts = () => {
    const productsDubbingScript = selectedVideos?.map(
      (product) => product.media.dubbingScript
    );
    const firstKeyword = keywordsReducer?.list[0].keyword;
    const lastKeyword =
      keywordsReducer?.list[keywordsReducer.list.length - 1].keyword;
    const selectedKeyword = keywordsReducer?.list.filter(
      (keyword) => keyword.todaysPick
    )[0].keyword;

    return [
      [
        "안녕하세요. 이유 있는 트랜드 리포트 Korea Pick 입니다. 오늘의 이트리 Pick은 바로,",
        `${coverTitle} 입니다`,
      ],
      [
        "실시간 인기 키워드로 쇼핑 트랜드를 알아볼까요? ",
        `${firstKeyword}부터 ${lastKeyword}까지`,
        "다양한 키워드가 순위권에 올라왔는데요, 오늘의 Korea Pick 키워드는 바로 ",
        `${selectedKeyword} 입니다!`,
        "이트리가 Pick한 ",
        `${selectedKeyword} 상품들 함께 살펴보실까요?`,
      ],
      ...(productsDubbingScript ?? []),
      [
        "오늘 소개해 드린 추천 상품 구매링크는, 프로필 링크에서 Korea Pick을 클릭하시거나, Korea Pick 공식 블로그를 방문하면 확인하실 수 있어요. ",
      ],
    ];
  };

  const onSubmitContentsMaker = async () => {
    // 여기서 일단 전체 비디오를 받아오자

    const videoSourceList: any[] = [];

    const coverPromise = uploadImageByClassName(`.Instagram-Cover`);
    const keywordPromise = uploadImageByClassName(`.Instagram-Keywords`);
    const productPromises = productsReducer?.list
      .filter((el) => el.selectedVideo === true)
      .map((prd, prdId) => {
        return uploadImageByClassName(`.Instagram-Image-${prdId}`);
      });
    const finishPromise = uploadImageByClassName(`.Instagram-Finish`);
    const reg = /[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim;
    const dubbingScript = getDubbingScripts();
    // 선택된 언디파인드 값 제거
    const productPromisesNullCheck = productPromises?.filter(
      (res) => res !== undefined && res !== null
    );
    if (
      productPromisesNullCheck &&
      productPromisesNullCheck?.length > 0 &&
      productPromisesNullCheck.length < 8
    ) {
      notification.info({
        message: "비디오 생성중 입니다.",
        description: "비디오 상태 탭을 확인해주세요.",
        placement: "bottomRight",
      });
    }
    const imageUrls = await Promise.all(
      [
        coverPromise,
        keywordPromise,
        ...(productPromisesNullCheck ?? []),
        finishPromise,
      ] ?? []
    ).then((results) => {
      setMediaLoading("NONE");
      return results;
    });

    imageUrls.forEach((el, index) => {
      videoSourceList.push({
        imageUrl: el,
        dubbingScript: dubbingScript[index],
      });
    });
    videoSourceList.length > 0 &&
      dispatch(
        fetchVideo.request({
          thumbnailImageUrl:
            videoThumbnail ??
            "https://avatars.githubusercontent.com/u/72783237?v=4",
          videoSourceList,
          templateVersion: 1,
          videoFileName: videoText?.replace(reg, "") ?? "설정오류",
        })
      );
  };

  const replaceDubbingScriptByLabelId = (id: number) => {
    switch (id) {
      case 0:
        return "";
      case 1:
        return "이 브랜드의 베스트셀러에요! ";
      case 2:
        return "다시 찾게 되는 마약 같은 아이템이에요!";
      case 3:
        return "다시 찾게 되는 마약 같은 아이템이에요!";
      case 4:
        return "이거 아니면 안쓸 만큼 재구매 고객수가 많은 제품이에요!";
      case 5:
        return "이거 아니면 안쓸 만큼 재구매 고객수가 많은 제품이에요!";
      case 6:
        return "치킨을 참으면 구매할 수 있는 가성비 갑 제품이에요!";
      case 8:
        return "계속 찾게 되는 중독적인 아이템이에요! ";
      case 9:
        return "계속 찾게 되는 중독적인 아이템이에요! ";
      case 10:
        return "재구매율이 높은 제품이에요! ";
      case 11:
        return "구매 버튼을 누를 수 밖에 없는 인기 제품이에요! ";
      case 12:
        return "할인율이 높은 제품이에요! ";
      case 18:
        return "당장 구매해야 할 할인율 높은 제품이에요! ";
      case 19:
        return "이가격은 지금만! 할인율 최고인 제품이에요! ";
      case 21:
        return "판매량이 높은 제품이에요! ";
      case 29:
        return "손해보고 파는 아이템! 할인율이 높은 제품이에요! ";
      case 31:
        return "지금이 구매 타이밍! 지금 최저가인 제품이에요! ";
      default:
        return "";
    }
  };

  const replaceLabelByTokens = (template: string, tokenObj: any) => {
    if (!template) {
      return;
    }
    for (let v in tokenObj) {
      // template = template.replaceAll(`|${v}|`, tokenObj[v]); // |brand_name| => nike
      if (typeof tokenObj[v] === "number") {
        template = template.replaceAll(
          `|${v}|`,
          Math.round(tokenObj[v]).toLocaleString("ko-KR")
        );
      } else if (typeof tokenObj[v] === "string") {
        if (v === "brand_name" && tokenObj[v].length > 10) {
          language === "en"
            ? (template = template.replaceAll(`|${v}|`, "same brand"))
            : (template = template.replaceAll(`|${v}|`, "동일 브랜드"));
        } else {
          if (language === "en") {
            template = template.replaceAll(`|${v}|`, tokenObj[v]);
          } else {
            template = template.replaceAll(`|${v}|`, tokenObj[v]);
          }
        }
      }
    }
    // /([|])(?:(?=(\\?))\2.)*?\1/g
    template = template.includes("|")
      ? template.replaceAll("#f3f2f2", "#cd8686") // label property가 없어서 |가 추출되는 레이블은 red처리
      : template;
    template = template.replaceAll("\\n", "");
    return template;
  };

  const transformDateFormat = (date: string) => {
    return moment(date).format("YYYY.MM.DD - hh:mm");
  };

  return {
    getHtmlSource,
    replaceLabelByTokens,
    currentStateReducer,
    currentState,
    userTextReducer,
    labelTemplateReducer,
    criteriaReducer,
    productsReducer,
    keywordsReducer,
    requestReducer,
    currentPrdIdReducer,
    labelData,
    htmlReducer,
    language,
    translatedTxt,
    mediaLoading,
    currentVideo,
    coverTitle,
    coverImage,
    productExist,
    videoThumbnail,
    videoText,
    selectedVideos,
    openModal,
    setPersistDraw,
    setRequestAction,
    setHtmlSource,
    setCurrentStateAction,
    setClearCurrentStateAction,
    setClearProductsAction,
    setcurrentProductId,
    setUserInputTitle,
    setUserInputHeadCopy,
    setUserInputTrandInfo,
    setTranslatedText,
    uploadImageByClassName,
    onSubmitContentsMaker,
    saveImgFileToLocal,
    DownloadImages,
    setMediaLoading,
    transformDateFormat,
    replaceDubbingScriptByLabelId,
    setCoverTitle,
    setCoverImage,
    setVideoThumbnail,
    setVideoText,
  };
};

export default useBlog;
