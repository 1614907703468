import { createAsyncAction } from "typesafe-actions";
import { ServiceService } from "apis/services";
import { AxiosResponse } from "axios";
import { callWrapperSaga } from "utils/callWrapperSaga";
import {
  APIError,
  IgnoreItemResponse,
  ServiceRequest,
  IgnoreCategoryResponse,
  IgnoreBrandResponse,
} from "types/api";
import { put, takeEvery, takeLatest } from "@redux-saga/core/effects";

export const GET_IGNORE_ITEMS = `service/GET_IGNORE_ITEMS`;
export const GET_IGNORE_ITEMS_SUCCESS = `service/GET_IGNORE_ITEMS_SUCCESS`;
export const GET_IGNORE_ITEMS_FAILURE = `service/GET_IGNORE_ITEMS_FAILURE`;

export const getIgnoreItems = createAsyncAction(
  GET_IGNORE_ITEMS,
  GET_IGNORE_ITEMS_SUCCESS,
  GET_IGNORE_ITEMS_FAILURE
)<ServiceRequest, { itemsData: IgnoreItemResponse }, APIError>();

function* getIgnoreItemsSaga(
  action: ReturnType<typeof getIgnoreItems.request>
) {
  try {
    const { data }: AxiosResponse<IgnoreItemResponse> = yield callWrapperSaga(
      ServiceService.fetchIgnoreItems,
      action.payload
    );
    yield put(getIgnoreItems.success({ itemsData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getIgnoreItems.failure({ data, status }));
  }
}

export const GET_IGNORE_CATEGORIES = `service/GET_IGNORE_CATEGORIES`;
export const GET_IGNORE_CATEGORIES_SUCCESS = `service/GET_IGNORE_CATEGORIES_SUCCESS`;
export const GET_IGNORE_CATEGORIES_FAILURE = `service/GET_IGNORE_CATEGORIES_FAILURE`;

export const getIgnoreCategories = createAsyncAction(
  GET_IGNORE_CATEGORIES,
  GET_IGNORE_CATEGORIES_SUCCESS,
  GET_IGNORE_CATEGORIES_FAILURE
)<ServiceRequest, { categoriesData: IgnoreCategoryResponse }, APIError>();

function* getIgnoreCategoriesSaga(
  action: ReturnType<typeof getIgnoreCategories.request>
) {
  try {
    const { data }: AxiosResponse<IgnoreCategoryResponse> =
      yield callWrapperSaga(
        ServiceService.fetchIgnoreCategories,
        action.payload
      );
    yield put(getIgnoreCategories.success({ categoriesData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getIgnoreCategories.failure({ data, status }));
  }
}

export const GET_IGNORE_BRANDS = `service/GET_IGNORE_BRANDS`;
export const GET_IGNORE_BRANDS_SUCCESS = `service/GET_IGNORE_BRANDS_SUCCESS`;
export const GET_IGNORE_BRANDS_FAILURE = `service/GET_IGNORE_BRANDS_FAILURE`;

export const getIgnoreBrands = createAsyncAction(
  GET_IGNORE_BRANDS,
  GET_IGNORE_BRANDS_SUCCESS,
  GET_IGNORE_BRANDS_FAILURE
)<ServiceRequest, { brandsData: IgnoreBrandResponse }, APIError>();

function* getIgnoreBrandsSaga(
  action: ReturnType<typeof getIgnoreBrands.request>
) {
  try {
    const { data }: AxiosResponse<IgnoreBrandResponse> = yield callWrapperSaga(
      ServiceService.fetchIgnoreBrands,
      action.payload
    );
    yield put(getIgnoreBrands.success({ brandsData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getIgnoreBrands.failure({ data, status }));
  }
}

export const POST_IGNORE_BRANDS = `service/POST_IGNORE_BRANDS`;
export const POST_IGNORE_BRANDS_SUCCESS = `service/POST_IGNORE_BRANDS_SUCCESS`;
export const POST_IGNORE_BRANDS_FAILURE = `service/POST_IGNORE_BRANDS_FAILURE`;

export const postIgnoreBrands = createAsyncAction(
  POST_IGNORE_BRANDS,
  POST_IGNORE_BRANDS_SUCCESS,
  POST_IGNORE_BRANDS_FAILURE
)<ServiceRequest, { brandsData: IgnoreBrandResponse }, APIError>();

function* postIgnoreBrandsSaga(
  action: ReturnType<typeof postIgnoreBrands.request>
) {
  try {
    yield callWrapperSaga(ServiceService.postIgnoreBrands, action.payload);
    const { data }: AxiosResponse<IgnoreBrandResponse> = yield callWrapperSaga(
      ServiceService.fetchIgnoreBrands,
      action.payload
    );
    yield put(postIgnoreBrands.success({ brandsData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(postIgnoreBrands.failure({ data, status }));
  }
}

export const POST_IGNORE_CATEGORIES = `service/POST_IGNORE_CATEGORIES`;
export const POST_IGNORE_CATEGORIES_SUCCESS = `service/POST_IGNORE_CATEGORIES_SUCCESS`;
export const POST_IGNORE_CATEGORIES_FAILURE = `service/POST_IGNORE_CATEGORIES_FAILURE`;

export const postIgnoreCategories = createAsyncAction(
  POST_IGNORE_CATEGORIES,
  POST_IGNORE_CATEGORIES_SUCCESS,
  POST_IGNORE_CATEGORIES_FAILURE
)<ServiceRequest, { categoriesData: IgnoreCategoryResponse }, APIError>();

function* postIgnoreCategoriesSaga(
  action: ReturnType<typeof postIgnoreCategories.request>
) {
  try {
    yield callWrapperSaga(ServiceService.postIgnoreCategories, action.payload);
    const { data }: AxiosResponse<IgnoreCategoryResponse> =
      yield callWrapperSaga(
        ServiceService.fetchIgnoreCategories,
        action.payload
      );
    yield put(postIgnoreCategories.success({ categoriesData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(postIgnoreCategories.failure({ data, status }));
  }
}

export const POST_IGNORE_ITEMS = `service/POST_IGNORE_ITEMS`;
export const POST_IGNORE_ITEMS_SUCCESS = `service/POST_IGNORE_ITEMS_SUCCESS`;
export const POST_IGNORE_ITEMS_FAILURE = `service/POST_IGNORE_ITEMS_FAILURE`;

export const postIgnoreItems = createAsyncAction(
  POST_IGNORE_ITEMS,
  POST_IGNORE_ITEMS_SUCCESS,
  POST_IGNORE_ITEMS_FAILURE
)<ServiceRequest, { itemsData: IgnoreItemResponse }, APIError>();

function* postIgnoreItemsSaga(
  action: ReturnType<typeof postIgnoreItems.request>
) {
  try {
    yield callWrapperSaga(ServiceService.postIgnoreItems, action.payload);
    const { data }: AxiosResponse<IgnoreItemResponse> = yield callWrapperSaga(
      ServiceService.fetchIgnoreItems,
      action.payload
    );
    yield put(postIgnoreItems.success({ itemsData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(postIgnoreItems.failure({ data, status }));
  }
}

export const serviceAsyncAction = {
  getIgnoreItems,
  getIgnoreCategories,
  getIgnoreBrands,
  postIgnoreBrands,
  postIgnoreCategories,
  postIgnoreItems,
};

export default function* serviceSaga() {
  yield takeEvery(GET_IGNORE_ITEMS, getIgnoreItemsSaga);
  yield takeEvery(GET_IGNORE_CATEGORIES, getIgnoreCategoriesSaga);
  yield takeEvery(GET_IGNORE_BRANDS, getIgnoreBrandsSaga);
  yield takeLatest(POST_IGNORE_BRANDS, postIgnoreBrandsSaga);
  yield takeLatest(POST_IGNORE_CATEGORIES, postIgnoreCategoriesSaga);
  yield takeLatest(POST_IGNORE_ITEMS, postIgnoreItemsSaga);
}
