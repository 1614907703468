import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import LoginPage from "pages/login";
import { AuthenticatedComponent } from "./config";
import LayoutPage from "../pages/layout";

const HomePage = lazy(() => import("pages/home"));
const NotFoundPage = lazy(() => import("pages/404"));
const ReportViewPage = lazy(() => import("pages/report/view"));
const ReportConversionPage = lazy(() => import("pages/report/conversion"));
const ReportRevenuePage = lazy(() => import("pages/report/revenue"));
const ReportSectionPage = lazy(() => import("pages/report/section"));
const ReportDailyPage = lazy(() => import("pages/report/daily"));

const ProductReportPage = lazy(() => import("pages/product"));

const ServiceRecExcludePage = lazy(() => import("pages/service/rec-exclude"));
const DsocApiKeyPage = lazy(() => import("pages/service/api-key"));

const DSOCMainPage = lazy(() => import("pages/dsoc/campaign/board"));
const DSOCCampaignSettingPage = lazy(
  () => import("pages/dsoc/campaign/setting")
);

const DSOCDesignerBoardPage = lazy(() => import("pages/dsoc/designer/board"));
const DSOCDesignerSettingPage = lazy(
  () => import("pages/dsoc/designer/setting")
);

const DSOCSelectorListPage = lazy(() => import("pages/dsoc/selector/board"));
const DSOCSelectorSettingPage = lazy(
  () => import("pages/dsoc/selector/setting")
);

const DSOCOperatorBoardPage = lazy(() => import("pages/dsoc/operator/board"));
const DSOCOperatorSettingPage = lazy(
  () => import("pages/dsoc/operator/setting")
);

const SODetailPage = lazy(() => import("pages/dsoc/sodetail"));

const ManageSitePage = lazy(() => import("pages/manage/root/site"));
const ManageNewSitePage = lazy(() => import("pages/manage/new/site"));
const ManageSiteDetailPage = lazy(() => import("pages/manage/detail/site"));

const ManageCustomerPage = lazy(() => import("pages/manage/root/customer"));
const ManageNewCustomerPage = lazy(() => import("pages/manage/new/customer"));
const ManageCustomerDetailPage = lazy(
  () => import("pages/manage/detail/customer")
);
const ManageAccountPage = lazy(() => import("pages/manage/root/account"));
const ManageNewAccountPage = lazy(() => import("pages/manage/new/account"));
const ManageAccountDetailPage = lazy(
  () => import("pages/manage/detail/account")
);
const LogGuidePage = lazy(() => import("pages/log/guide"));
const LogStatusPage = lazy(() => import("pages/log/status"));
const LogStatusDetailPage = lazy(() => import("pages/log/status/detail"));

const RecDemoPage = lazy(() => import("pages/demo/rec"));
const RtsDemoPage = lazy(() => import("pages/demo/rts"));
const PersonaDemoPage = lazy(() => import("pages/demo/persona"));
const DsocDemoPage = lazy(() => import("pages/demo/dsoc"));

const BlogAutomationPage = lazy(() => import("pages/blog/tools"));
const BlogLabelTemplate = lazy(() => import("pages/blog/labelTemplate"));

const CubotControlSettings = lazy(() => import("pages/cubot/controlsettings"));
const CubotPerformanceGeneralServicePage = lazy(
  () => import("pages/cubot/performances/general/service")
);
const CubotPerformanceGeneralViewPage = lazy(
  () => import("pages/cubot/performances/general/view")
);
const CubotPerformanceGeneralRevenuePage = lazy(
  () => import("pages/cubot/performances/general/revenue")
);
const CubotPerformanceGeneralConversionPage = lazy(
  () => import("pages/cubot/performances/general/conversion")
);
const CubotPerformanceFaqServicePage = lazy(
  () => import("pages/cubot/performances/faq/service")
);
const CubotPerformanceFaqContentsPage = lazy(
  () => import("pages/cubot/performances/faq/contents")
);

const homeRoute = [
  {
    path: "home",
    element: (
      <AuthenticatedComponent auth>
        <HomePage />
      </AuthenticatedComponent>
    ),
  },
];

const productRoute = [
  {
    path: "product-report",
    element: (
      <AuthenticatedComponent admin>
        <ProductReportPage />
      </AuthenticatedComponent>
    ),
  },
];

const reportRoute = [
  {
    path: "report",
    element: <Navigate to="view" />,
  },
  {
    path: "report/view",
    element: (
      <AuthenticatedComponent auth>
        <ReportViewPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "report/revenue",
    element: (
      <AuthenticatedComponent auth>
        <ReportRevenuePage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "report/conversion",
    element: (
      <AuthenticatedComponent auth>
        <ReportConversionPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "report/daily",
    element: (
      <AuthenticatedComponent auth>
        <ReportDailyPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "report/section",
    element: (
      <AuthenticatedComponent auth>
        <ReportSectionPage />
      </AuthenticatedComponent>
    ),
  },
];

const demoRoute = [
  {
    path: "demo",
    element: <Navigate to="rec" />,
  },
  {
    path: "demo/rec",
    element: (
      <AuthenticatedComponent auth>
        <RecDemoPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "demo/rts",
    element: (
      <AuthenticatedComponent auth>
        <RtsDemoPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "demo/persona",
    element: (
      <AuthenticatedComponent auth>
        <PersonaDemoPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "demo/dsoc",
    element: (
      <AuthenticatedComponent auth admin>
        <DsocDemoPage />
      </AuthenticatedComponent>
    ),
  },
];

const blogRoute = [
  {
    path: "blog-automation",
    element: <Navigate to="tools" />,
  },
  {
    path: "blog-automation/tools",
    element: (
      <AuthenticatedComponent auth admin>
        <BlogAutomationPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "blog-automation/labelTemplate/",
    element: (
      <AuthenticatedComponent auth admin>
        <BlogLabelTemplate />
      </AuthenticatedComponent>
    ),
  },
];

const serviceRoute = [
  {
    path: "service",
    element: <Navigate to="api-key" />,
  },
  {
    path: "service/rec-exclude",
    element: (
      <AuthenticatedComponent auth admin>
        <ServiceRecExcludePage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "service/api-key",
    element: (
      <AuthenticatedComponent auth>
        <DsocApiKeyPage />
      </AuthenticatedComponent>
    ),
  },
];

const dsocRoute = [
  {
    path: "dsoc",
    element: <Navigate to="campaign" />,
  },
  {
    path: "dsoc/campaign",
    element: (
      <AuthenticatedComponent auth admin>
        <DSOCMainPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "dsoc/campaign/:cuid",
    element: (
      <AuthenticatedComponent auth admin>
        <DSOCCampaignSettingPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "dsoc/selector",
    element: (
      <AuthenticatedComponent auth admin>
        <DSOCSelectorListPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "dsoc/selector/new",
    element: (
      <AuthenticatedComponent auth admin>
        <DSOCSelectorSettingPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "dsoc/designer",
    element: (
      <AuthenticatedComponent auth admin>
        <DSOCDesignerBoardPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "dsoc/designer/:cuid",
    element: (
      <AuthenticatedComponent auth admin>
        <DSOCDesignerSettingPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "dsoc/operator",
    element: (
      <AuthenticatedComponent auth admin>
        <DSOCOperatorBoardPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "dsoc/operator/:cuid",
    element: (
      <AuthenticatedComponent auth admin>
        <DSOCOperatorSettingPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "dsoc/sodetail",
    element: (
      <AuthenticatedComponent auth admin>
        <SODetailPage />
      </AuthenticatedComponent>
    ),
  },
];

const logRoute = [
  {
    path: "log",
    element: <Navigate to="guide" />,
  },
  {
    path: "log/guide",
    element: (
      <AuthenticatedComponent auth>
        <LogGuidePage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "log/status",
    element: (
      <AuthenticatedComponent auth admin>
        <LogStatusPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "log/status/:cuid",
    element: (
      <AuthenticatedComponent auth admin>
        <LogStatusDetailPage />
      </AuthenticatedComponent>
    ),
  },
];

const manageRoute = [
  {
    path: "manage",
    element: <Navigate to="customer" />,
  },
  {
    path: "manage/customer",
    element: (
      <AuthenticatedComponent auth admin>
        <ManageCustomerPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "manage/customer/:customer_id",
    element: (
      <AuthenticatedComponent auth admin>
        <ManageCustomerDetailPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "manage/customer/new",
    element: (
      <AuthenticatedComponent auth admin>
        <ManageNewCustomerPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "manage/site",
    element: (
      <AuthenticatedComponent auth admin>
        <ManageSitePage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "manage/site/:site_id",
    element: (
      <AuthenticatedComponent auth admin>
        <ManageSiteDetailPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "manage/site/new",
    element: (
      <AuthenticatedComponent auth admin>
        <ManageNewSitePage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "manage/account",
    element: (
      <AuthenticatedComponent auth admin>
        <ManageAccountPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "manage/account/:account_id",
    element: (
      <AuthenticatedComponent auth admin>
        <ManageAccountDetailPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "manage/account/new",
    element: (
      <AuthenticatedComponent auth admin>
        <ManageNewAccountPage />
      </AuthenticatedComponent>
    ),
  },
];

const cubotRoute = [
  {
    path: "cubot",
    element: <Navigate to="control" />,
  },
  {
    path: "cubot/control",
    element: (
      <AuthenticatedComponent auth>
        <CubotControlSettings />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "cubot/performances",
    element: <Navigate to="general" />,
  },
  {
    path: "cubot/performances/general",
    element: <Navigate to="service" />,
  },
  {
    path: "cubot/performances/general/service",
    element: (
      <AuthenticatedComponent auth>
        <CubotPerformanceGeneralServicePage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "cubot/performances/general/view",
    element: (
      <AuthenticatedComponent auth>
        <CubotPerformanceGeneralViewPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "cubot/performances/general/revenue",
    element: (
      <AuthenticatedComponent auth>
        <CubotPerformanceGeneralRevenuePage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "cubot/performances/general/conversion",
    element: (
      <AuthenticatedComponent auth>
        <CubotPerformanceGeneralConversionPage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "cubot/performances/faq/service",
    element: (
      <AuthenticatedComponent auth>
        <CubotPerformanceFaqServicePage />
      </AuthenticatedComponent>
    ),
  },
  {
    path: "cubot/performances/faq/contents",
    element: (
      <AuthenticatedComponent auth>
        <CubotPerformanceFaqContentsPage />
      </AuthenticatedComponent>
    ),
  },
];

const RenderRouter = () =>
  useRoutes([
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "/",
      element: <LayoutPage />,
      children: [
        ...homeRoute,
        ...reportRoute,
        ...demoRoute,
        ...blogRoute,
        ...serviceRoute,
        ...dsocRoute,
        ...manageRoute,
        ...logRoute,
        ...productRoute,
        ...cubotRoute,
        {
          path: "*",
          element: (
            <AuthenticatedComponent>
              <NotFoundPage />
            </AuthenticatedComponent>
          ),
        },
      ],
    },
  ]);

export default RenderRouter;
