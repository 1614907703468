import { useAppSelector, useAppDispatch } from "store";
import { useCallback, useMemo } from "react";
import {
  ViewStatsDataSelector,
  ViewRCCodeStatsDataSelector,
} from "store/modules/report";
import { reportAsyncAction } from "store/modules/report/saga";
import _ from "lodash";
import { useComponentId, useReportData } from "hooks";
import toFixed from "utils/toFixed";
import { useIntl } from "react-intl";

const useReportViewData = () => {
  const { startDate, endDate, channel, unit } = useReportData();
  const { formatMessage } = useIntl();
  const componentId = useComponentId();
  const dispatch = useAppDispatch();

  const [statsDataLoading, statsData, statsDataError] = [
    useAppSelector(ViewStatsDataSelector.loading),
    useAppSelector(ViewStatsDataSelector.data),
    useAppSelector(ViewStatsDataSelector.error),
  ];

  const [rcCodeStatsDataLoading, rcCodeStatsData, rcCodeStatsDataError] = [
    useAppSelector(ViewRCCodeStatsDataSelector.loading),
    useAppSelector(ViewRCCodeStatsDataSelector.data),
    useAppSelector(ViewRCCodeStatsDataSelector.error),
  ];

  const getAllViewStats = useCallback(
    ({ cuid }: { cuid: string }) => {
      dispatch(
        reportAsyncAction.getAllViewStats.request({
          startDate,
          endDate,
          cuid,
          channel,
          unit,
        })
      );
    },
    [dispatch, channel, startDate, endDate, unit]
  );

  const {
    clickCountTableData,
    clickCountRatioData,
    clickCountRatioByRCCodeData,
    clickCountTransitionData,
    rcCodesList,
  } = useMemo(() => {
    const clickCountTableData = _.map(
      statsData,
      (
        {
          date,
          values: { viewCount, noViewCount, totalViewCount, noTotalViewCount },
        },
        idx
      ) => {
        return {
          key: `${componentId}-click-count-table-data-${idx}`,
          date,
          rcCodes: _.reduce(
            _.filter(rcCodeStatsData, (rcCodeStat) => rcCodeStat.date === date),
            (rcCodes, { values }) => {
              rcCodes[values.rcCode] =
                (rcCodes[values.rcCode] || 0) + values.viewCount;
              return rcCodes;
            },
            {} as { [key: string]: number }
          ),
          rec: viewCount,
          normal: noViewCount,
          total: totalViewCount + noTotalViewCount,
        };
      }
    );

    const clickCountRatioData = _.map(
      _.entries(
        _.reduce(
          clickCountTableData,
          (types, { rec, normal }) => {
            return {
              ...types,
              rec: (types.rec || 0) + rec,
              normal: (types.normal || 0) + normal,
            };
          },
          {} as { [key: string]: number }
        )
      ),
      ([key, value]) => ({ name: key, value })
    );

    const rcCodesWithValue = _.sortBy(
      _.map(
        _.entries(
          _.reduce(
            clickCountTableData,
            (rcCodesData, { rcCodes }) => {
              return _.mergeWith(
                rcCodesData,
                rcCodes,
                (originValue, incomeValue) => {
                  originValue = (originValue || 0) + incomeValue;
                  return originValue;
                }
              );
            },

            {} as { [key: string]: number }
          )
        ),
        ([key, value]) => ({ name: key, value })
      ),
      ["name"]
    ).sort((a, b) => {
      const { name: aName } = a;
      const { name: bName } = b;
      if (aName && bName) {
        const aNum = Number(aName.match(/(\d+)/g));
        const bNum = Number(bName.match(/(\d+)/g));
        return aNum - bNum;
      }
      return (a.name as any) - (b.name as any);
    });

    const rcCodesWithValueSortByValue = _.reverse(
      _.sortBy(
        _.map(
          _.entries(
            _.reduce(
              clickCountTableData,
              (rcCodesData, { rcCodes }) => {
                return _.mergeWith(
                  rcCodesData,
                  rcCodes,
                  (originValue, incomeValue) => {
                    originValue = (originValue || 0) + incomeValue;
                    return originValue;
                  }
                );
              },

              {} as { [key: string]: number }
            )
          ),
          ([key, value]) => ({ name: key, value })
        ),
        ["value"]
      )
    );

    const rcCodesList = _.map(rcCodesWithValue, ({ name }) => name);

    const clickCountRatioByRCCodeData = _.filter(
      [
        ..._.slice(rcCodesWithValueSortByValue, 0, 5),
        _.reduce(
          _.slice(rcCodesWithValueSortByValue, 5),
          (etc, { value }) => {
            etc["value"] = _.get(etc, "value", 0) + value;
            return { name: "ETC", value: etc["value"] };
          },
          {} as { [key: string]: any }
        ),
      ],
      _.negate(_.isEmpty)
    );

    const clickCountTransitionData = _.map(
      clickCountTableData,
      ({ date, rec, normal, total }) => ({ name: date, rec, normal, total })
    );

    return {
      clickCountTableData,
      clickCountRatioData,
      clickCountRatioByRCCodeData,
      rcCodesList,
      clickCountTransitionData,
    };
  }, [rcCodeStatsData, statsData, componentId]);

  const {
    sessionCountTableData,
    sessionCountRatioData,
    viewCountBySessionData,
  } = useMemo(() => {
    const sessionCountTableData = _.map(
      statsData,
      (
        {
          date,
          values: {
            totalViewCount,
            noTotalViewCount,
            sessionCount,
            noSessionCount,
          },
        },
        idx
      ) => {
        return {
          key: `${componentId}-session-count-table-data-${idx}`,
          date,
          recSessionCount: sessionCount,
          normalSessionCount: noSessionCount,
          totalSessionCount: sessionCount + noSessionCount,
          viewCountInRecSession:
            _.toNumber(toFixed(1)(totalViewCount / sessionCount)) || 0,
          viewCountInNormalSession:
            _.toNumber(toFixed(1)(noTotalViewCount / noSessionCount)) || 0,
          viewCountInTotalSession:
            _.toNumber(
              toFixed(1)(
                (totalViewCount + noTotalViewCount) /
                  (sessionCount + noSessionCount)
              )
            ) || 0,
        };
      }
    );
    const sessionCountRatioData = _.map(
      _.entries(
        _.reduce(
          sessionCountTableData,
          (types, { recSessionCount, normalSessionCount }) => {
            return {
              ...types,
              recSessionCount:
                _.get(types, "recSessionCount", 0) + recSessionCount,
              normalSessionCount:
                _.get(types, "normalSessionCount", 0) + normalSessionCount,
            };
          },
          {} as { [key: string]: number }
        )
      ),
      ([key, value]) => ({ name: key, value })
    );

    const viewCountBySessionData = _.map(
      _.entries(
        _.reduce(
          sessionCountTableData,
          (
            types,
            {
              viewCountInRecSession,
              viewCountInNormalSession,
              viewCountInTotalSession,
            }
          ) => {
            return {
              ...types,
              [formatMessage({
                id: "Components.Report.View.ViewCountBySessionCard.xAxis.total", // 전체
              })]: _.toNumber(
                _.get(
                  types,
                  formatMessage({
                    id: "Components.Report.View.ViewCountBySessionCard.xAxis.total",
                  }),
                  0
                ) + viewCountInTotalSession
              ),
              [formatMessage({
                id: "Components.Report.View.ViewCountBySessionCard.xAxis.rec", // 추천
              })]: _.toNumber(
                _.get(
                  types,
                  formatMessage({
                    id: "Components.Report.View.ViewCountBySessionCard.xAxis.rec",
                  }),
                  0
                ) + viewCountInRecSession
              ),
              [formatMessage({
                id: "Components.Report.View.ViewCountBySessionCard.xAxis.normal", // 일반
              })]: _.toNumber(
                _.get(
                  types,
                  formatMessage({
                    id: "Components.Report.View.ViewCountBySessionCard.xAxis.normal",
                  }),
                  0
                ) + viewCountInNormalSession
              ),
            };
          },
          {}
        )
      ),
      ([key, value]) => ({
        name: key,
        value: toFixed(1)(Number(value) / sessionCountTableData.length),
      })
    );

    return {
      sessionCountTableData,
      sessionCountRatioData,
      viewCountBySessionData,
    };
  }, [statsData, componentId, formatMessage]);

  return {
    statsDataLoading,
    statsData,
    statsDataError,
    rcCodeStatsDataLoading,
    rcCodeStatsData,
    rcCodeStatsDataError,
    clickCountTableData,
    clickCountRatioData,
    clickCountRatioByRCCodeData,
    clickCountTransitionData,
    rcCodesList,
    sessionCountTableData,
    sessionCountRatioData,
    viewCountBySessionData,
    getAllViewStats,
  };
};

export default useReportViewData;
