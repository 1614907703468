import { useState, useCallback, useMemo } from "react";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "store";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import FileSaver from "file-saver";
import {
  keepPageSelector,
  requestSelector,
  criteriaSelector,
  itemSegInfoSelector,
  similarSegSelector,
  segBestSelector,
  associatedSegSelector,
  imageUploadResponseSelector,
  useAssociatedSectionSelector,
  useSimilarSectionSelector,
  useBestSectionSelector,
  segTabIndexSelector,
  htmlSourceSelector,
  currentStateSelector,
  segBestTextSelector,
  similarTextSelector,
  languageSelector,
  openModalSelector,
  currentVideoSelector,
  blogActionV2,
  itemDubbingTextSelector,
  videoThumbnailSelector,
} from "store/modules/blogV2";
import blogService02 from "apis/services/blogServiceV2";
import { fetchVideo } from "store/modules/blogV2/saga";
import { videoTextSelector } from "store/modules/blog";

const useBlogV2 = () => {
  const dispatch = useAppDispatch();
  const keepPage = useAppSelector(keepPageSelector);
  const request = useAppSelector(requestSelector);
  const criteria = useAppSelector(criteriaSelector);
  const itemSegInfo = useAppSelector(itemSegInfoSelector);
  const segBest = useAppSelector(segBestSelector);
  const similarSeg = useAppSelector(similarSegSelector);
  const associatedSeg = useAppSelector(associatedSegSelector);
  const imageUploadResponse = useAppSelector(imageUploadResponseSelector);
  const useSimilarSection = useAppSelector(useSimilarSectionSelector);
  const useBestSection = useAppSelector(useBestSectionSelector);
  const useAssociatedSection = useAppSelector(useAssociatedSectionSelector);
  const segTabIndex = useAppSelector(segTabIndexSelector);
  const htmlSource = useAppSelector(htmlSourceSelector);
  const currentState = useAppSelector(currentStateSelector);
  const segBestText = useAppSelector(segBestTextSelector);
  const similarText = useAppSelector(similarTextSelector);
  const language = useAppSelector(languageSelector);
  const openModal = useAppSelector(openModalSelector);
  const currentVideo = useAppSelector(currentVideoSelector);
  const itemDubbingText = useAppSelector(itemDubbingTextSelector);
  const videoThumbnail = useAppSelector(videoThumbnailSelector);
  const videoText = useAppSelector(videoTextSelector);
  const segBestFilter = segBest?.filter((el) => el.active);
  const similarSegFilter = similarSeg?.filter((el) => el.active);
  const associatedSegFilter = associatedSeg?.filter((el) => el.active);
  const uploadImageByClassName = (className: string, prdId?: number) => {
    const canvas = document.querySelector(className) as HTMLElement;
    const canvasElementPromise = html2canvas(canvas, {
      allowTaint: true,
      useCORS: true,
      scale: 1,
      proxy: "*",
    });

    return canvasElementPromise
      .then(
        (canvas) =>
          new Promise<any>((resolve) => {
            canvas.toBlob(resolve);
          })
      )
      .then(async (blob) => {
        if (!blob) return;
        const formData = new FormData();
        formData.append("file", blob, `${className}.png`);
        formData.append("keyPrefix", "blogV2/video/img/");
        const res = await blogService02.getUploadImageUrl({ file: formData });
        return res.data.data.url;
      });
  };

  const getDubbingScripts = (tab: string) => {
    const SegBestPrdSegDataDubbingScript = segBest?.map(
      (prd) => prd.mediaUseOption.duddingText
    );
    const BarChartDataDubbingScript = barGraphData
      ?.map((prd) => prd.mediaUseOption.duddingText)
      .splice(0, 1);
    const SimilarSegPrdDataDubbingScript = similarSeg
      ?.filter((seg) => seg.active)
      .map((prd) => prd.mediaUseOption.duddingText)
      .splice(0, 1);
    const AssociatedSegPrdDataDubbingScript = associatedSeg
      ?.map((prd) => prd.mediaUseOption.duddingText)
      .splice(0, 1);
    const HexaChartDataDubbingScript = hexaGraphData
      .map((prd) => prd.mediaUseOption.duddingText)
      .splice(0, 1);
    const AssociatedSegReSearchDubbingScript = itemSegInfo?.segData
      .map((prd) => prd.mediaUseOption.duddingText)
      .splice(0, 1);
    if (tab === "blog") {
      return [
        [
          `오늘은 ${itemSegInfo?.brandName}의 ${itemSegInfo?.itemName} 에 대해서 item pick이 집중 탐구해보겠습니다. `,
        ],
        ...(SegBestPrdSegDataDubbingScript?.splice(0, 1) ?? []),
        ...(BarChartDataDubbingScript?.splice(0, 1) ?? []),
        ...(AssociatedSegReSearchDubbingScript?.splice(0, 1) ?? []),
        ...(SimilarSegPrdDataDubbingScript ?? []),
        ...(AssociatedSegPrdDataDubbingScript?.splice(0, 1) ?? []),
        ...(HexaChartDataDubbingScript?.splice(0, 1) ?? []),
        [
          "오늘 소개한 상품의 구매링크는 프로필 링크의 ITEM PICK 공식 블로그에서 확인할 수 있습니다.",
          "감사합니다. ",
        ],
      ];
    }
    if (tab === "insta") {
      return [
        [`아이템 프로파일링 ITEM PICK, OTZ LOW SUEDE 연관 검색어 찾기`],
        [
          `오늘은 ${itemSegInfo?.brandName}의 ${itemSegInfo?.itemName} 에 대해서 item pick이 집중 탐구해보겠습니다. `,
        ],
        ...(SegBestPrdSegDataDubbingScript?.splice(0, 1) ?? []),
        ...(BarChartDataDubbingScript?.splice(0, 1) ?? []),
        ...(AssociatedSegReSearchDubbingScript?.splice(0, 1) ?? []),
        ...(SimilarSegPrdDataDubbingScript ?? []),
        ...(AssociatedSegPrdDataDubbingScript?.splice(0, 1) ?? []),
        ...(HexaChartDataDubbingScript?.splice(0, 1) ?? []),
        [
          "오늘 소개한 상품의 구매링크는 프로필 링크의 ITEM PICK 공식 블로그에서 확인할 수 있습니다.",
          "감사합니다. ",
        ],
      ];
    }
  };

  const onSubmitContentsMaker = async (tab: string) => {
    const videoSourceList: any[] = [];
    const dubbingScript = getDubbingScripts(tab);
    const reg = /[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim;
    if (tab === "blog") {
      const imageUrls = await Promise.all([
        uploadImageByClassName(".Instagram-Item-V2"),
        uploadImageByClassName(".Instagram-SegBestPrd-V2"),
        uploadImageByClassName(".Instagram-BarChart-V2"),
        uploadImageByClassName(".Instagram-AssociatedSegReSearch-V2"),
        uploadImageByClassName(".Instagram-SimilarSegPrd-V2"),
        uploadImageByClassName(".Instagram-AssociatedSegPrd-V2"),
        uploadImageByClassName(".Instagram-HexaChart-V2"),
      ]).then((results) => {
        setMediaLoading("NONE");
        return results;
      });

      imageUrls.forEach((imageUrl, index) => {
        videoSourceList.push({
          imageUrl,
          dubbingScript: dubbingScript && dubbingScript[index],
        });
      });

      if (videoSourceList.length > 0) {
        dispatch(
          fetchVideo.request({
            thumbnailImageUrl:
              videoThumbnail ??
              "https://avatars.githubusercontent.com/u/72783237?v=4",
            videoSourceList,
            templateVersion: 2,
            videoFileName: videoText?.replace(reg, "") ?? "설정오류",
          })
        );
      }
    }
    if (tab === "insta") {
      const imageUrls = await Promise.all([
        uploadImageByClassName(".Instagram-Cover-V2"),
        uploadImageByClassName(".Instagram-Item-V2"),
        uploadImageByClassName(".Instagram-SegBestPrd-V2"),
        uploadImageByClassName(".Instagram-BarChart-V2"),
        uploadImageByClassName(".Instagram-AssociatedSegReSearch-V2"),
        uploadImageByClassName(".Instagram-SimilarSegPrd-V2"),
        uploadImageByClassName(".Instagram-AssociatedSegPrd-V2"),
        uploadImageByClassName(".Instagram-HexaChart-V2"),
        uploadImageByClassName(".Instagram-Finish-V2"),
      ]).then((results) => {
        setMediaLoading("NONE");
        return results;
      });
      imageUrls.forEach((imageUrl, index) => {
        videoSourceList.push({
          imageUrl,
          dubbingScript: dubbingScript && dubbingScript[index],
        });
      });
      if (videoSourceList.length > 0) {
        dispatch(
          fetchVideo.request({
            thumbnailImageUrl:
              videoThumbnail ??
              "https://avatars.githubusercontent.com/u/72783237?v=4",
            videoSourceList,
            templateVersion: 2,
            videoFileName: videoText?.replace(reg, "") ?? "설정오류",
          })
        );
      }
    }
    if (tab === "videoBoard") {
      return;
    }
  };

  const firstSeg = useMemo(() => {
    return itemSegInfo?.segData[0];
  }, [itemSegInfo]);

  const secondSeg = useMemo(() => {
    return itemSegInfo?.segData[1];
  }, [itemSegInfo]);

  const thirdSeg = useMemo(() => {
    return itemSegInfo?.segData[2];
  }, [itemSegInfo]);

  const fourthSeg = useMemo(() => {
    return itemSegInfo?.segData[3];
  }, [itemSegInfo]);

  const setActiveSimilarSeg = (index: number) => {
    dispatch(blogActionV2.setActiveSimilarSeg(index));
  };
  const setSimilarSegSection = (bool: boolean) => {
    dispatch(blogActionV2.setSimilarSegSection(bool));
  };
  const setActiveSegBest = (index: number) => {
    dispatch(blogActionV2.setActiveSegBest(index));
  };
  const setBestSegSection = (bool: boolean) => {
    dispatch(blogActionV2.setBestSegSection(bool));
  };
  const setActiveAssociatedSeg = (index: number) => {
    dispatch(blogActionV2.setActiveAssociatedSeg(index));
  };

  const setAssociatedSegSection = (bool: boolean) => {
    dispatch(blogActionV2.setAssociatedSegSection(bool));
  };

  const setVideoThumbnail = useCallback(
    (url: string) => {
      dispatch(
        blogActionV2.setVideoThumbnail({
          videoThumbnail: url,
        })
      );
    },
    [dispatch]
  );

  const setVideoText = useCallback(
    (text: any) => {
      dispatch(
        blogActionV2.setVideoText({
          videoText: text,
        })
      );
    },
    [dispatch]
  );

  const replaceIndex = (str: string) => {
    if (str === "daily_click") {
      return "일간 클릭 수";
    } else if (str === "daily_order") {
      return "일간 구매 수";
    } else if (str === "weekly_click") {
      return "주간 클릭 수";
    } else if (str === "weekly_order") {
      return "주간 구매 수";
    } else if (str === "monthly_click") {
      return "월간 클릭 수";
    } else if (str === "monthly_order") {
      return "월간 주문 수";
    } else if (str === "rise_click") {
      return "급상승 클릭 수";
    } else if (str === "rise_order") {
      return "급상승 구매 수";
    } else if (str === "sale") {
      return "할인율";
    } else if (str === "rebuy") {
      return "재구매 수";
    } else {
      return str;
    }
  };

  const replaceMultipleText = (value: number | undefined) => {
    if (value === undefined) {
      return;
    }
    if (value > 49) {
      return `${value}배 이상`;
    } else {
      return `${value}배로`;
    }
  };

  const replaceMultipleTextType2 = (value: number | undefined) => {
    if (value === undefined) {
      return;
    }
    if (value > 49) {
      return `${value}배 이상`;
    } else {
      return `${value}배`;
    }
  };

  const hexaGraphData = useMemo(() => {
    // indicatorName이 중복되면 프론트에서 중복제거
    return _.uniqBy(
      itemSegInfo?.segData[0].hexaGraph
        .map((hexa) =>
          _.pick(hexa, [
            "indicatorName",
            "itemGraph",
            "avgGraph",
            "mediaUseOption",
          ])
        )
        .map(
          (hexa) =>
            (hexa = {
              ...hexa,
              indicatorName: replaceIndex(hexa.indicatorName),
            })
        ),
      "indicatorName"
    );
  }, [itemSegInfo]);

  const hexaDomainMax = useMemo(() => {
    const itemGraphs = hexaGraphData.map((hexa) => hexa.itemGraph);
    const avgGraphs = hexaGraphData.map((hexa) => hexa.avgGraph);
    return Math.ceil(Math.max(...[...itemGraphs, ...avgGraphs]) + 10);
  }, [hexaGraphData]);

  const hexaIndexText = useMemo(() => {
    return itemSegInfo?.segData[0].hexaGraph
      .filter((hexa) => hexa.graphY === "Y")
      .sort((a, b) => a.indicatorRank - b.indicatorRank)
      .filter((hexa, index) => index < 2);
  }, [itemSegInfo]);

  const barGraphData = useMemo(() => {
    return itemSegInfo?.segData[0].barGraph
      .map((bar) =>
        _.pick(bar, [
          "indicatorName",
          "itemGraph",
          "avgGraph",
          "mediaUseOption",
        ])
      )
      .map(
        (bar) =>
          (bar = {
            ...bar,
            indicatorName: replaceIndex(bar.indicatorName),
          })
      );
  }, [itemSegInfo]);

  const barIndexText = useMemo(() => {
    return itemSegInfo?.segData[0].barGraph
      .filter((bar) => bar.graphY === "Y")
      .sort((a, b) => a.indicatorRank - b.indicatorRank)
      .filter((bar, index) => index < 2);
  }, [itemSegInfo]);

  const [mediaLoading, setMediaLoading] = useState<
    "NONE" | "IMG_LOADING" | "VIDEO_LOADING"
  >("NONE");

  // 인스타 이미지, 비디오 자동화 로직
  const getImageBlob = (className: string) => {
    const canvas = document.querySelector(className) as HTMLElement;
    const canvasElementPromise = html2canvas(canvas, {
      allowTaint: true,
      useCORS: true,
      proxy: "*",
      ignoreElements: (node) => {
        return node.nodeName === "IFRAME";
      },
    });
    return canvasElementPromise
      .then((canvas) => new Promise<any>((resolve) => canvas.toBlob(resolve)))
      .catch((error) => console.log(error))
      .then(async (blob) => {
        return blob;
      });
  };
  const DownloadImages = async (tab: string) => {
    const zip = new JSZip();
    const BP1 = getImageBlob(".blogVersion_Cover");
    const BP2 = getImageBlob(".blogVersion_Item");
    const BP3 = getImageBlob(".blogVersion_SegBestPrd");
    const BP4 = getImageBlob(".blogVersion_BarChart");
    const BP5 = getImageBlob(".blogVersion_AssociatedSegReSearch");
    const BP6 = getImageBlob(".blogVersion_SimilarSegPrd");
    const BP7 = getImageBlob(".blogVersion_AssociatedSegPrd");
    const BP8 = getImageBlob(".blogVersion_HexaChart");
    const BP9 = getImageBlob(".blogVersion_Finish");
    if (tab === "insta") {
      const result = [BP1, BP2, BP3, BP4, BP5, BP6, BP7, BP8, BP9];
      Promise.all(result).then((results: Blob[]) => {
        setMediaLoading("NONE");
        results.forEach((res, index) => {
          zip.file(`${index}.jpeg`, res);
        });
        zip.generateAsync({ type: "blob" }).then((content) => {
          FileSaver.saveAs(content, "Instagram-Images");
        });
      });
    }
    if (tab === "blog") {
      const result = [BP2, BP3, BP4, BP5, BP6, BP7, BP8, BP9];
      Promise.all(result).then((results: Blob[]) => {
        setMediaLoading("NONE");
        results.forEach((res, index) => {
          zip.file(`${index}.jpeg`, res);
        });
        zip.generateAsync({ type: "blob" }).then((content) => {
          FileSaver.saveAs(content, "Blogs-Images");
        });
      });
    }
  };

  const setHtmlSource = useCallback(
    (html: string) => {
      dispatch(blogActionV2.setHtmlSource(html));
    },
    [dispatch]
  );

  const getHtmlSource = () => {
    const htmlSource = document.querySelector(".blogContainerV2")?.innerHTML;
    if (!htmlSource) {
      return;
    }
    return htmlSource;
  };

  return {
    keepPage,
    criteria,
    currentState,
    request,
    itemSegInfo,
    firstSeg,
    secondSeg,
    thirdSeg,
    fourthSeg,
    similarSeg,
    segBest,
    associatedSeg,
    imageUploadResponse,
    useSimilarSection,
    useBestSection,
    useAssociatedSection,
    segTabIndex,
    htmlSource,
    hexaGraphData,
    hexaDomainMax,
    hexaIndexText,
    barGraphData,
    barIndexText,
    segBestText,
    mediaLoading,
    similarText,
    language,
    openModal,
    currentVideo,
    itemDubbingText,
    videoText,
    segBestFilter,
    similarSegFilter,
    associatedSegFilter,
    setActiveSegBest,
    setActiveSimilarSeg,
    setActiveAssociatedSeg,
    setAssociatedSegSection,
    setSimilarSegSection,
    setBestSegSection,
    replaceIndex,
    uploadImageByClassName,
    replaceMultipleText,
    replaceMultipleTextType2,
    DownloadImages,
    onSubmitContentsMaker,
    setMediaLoading,
    getHtmlSource,
    setHtmlSource,
    setVideoThumbnail,
    setVideoText,
  };
};

export default useBlogV2;
