import { FC } from "react";
import { Drawer } from "antd";
import * as S from "pages/layout/sider/style";
import { LayoutPageSiderMenu } from "components/layout/sider";
interface LayoutPageSiderProps {
  isDesktop: boolean;
  collapsed: boolean;
  toggleCollapsed: () => void;
}

const LayoutPageSider: FC<LayoutPageSiderProps> = ({
  isDesktop,
  collapsed,
  toggleCollapsed,
}) => {
  return isDesktop ? (
    <S.LayoutPageSider
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint="md"
      children={<LayoutPageSiderMenu />}
    />
  ) : (
    <Drawer
      width="200"
      placement="left"
      bodyStyle={{ padding: 0, height: "100%" }}
      closable={false}
      onClose={toggleCollapsed}
      visible={!collapsed}
      children={<LayoutPageSiderMenu />}
    />
  );
};
export default LayoutPageSider;
