import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import type { RootState } from "store";
import {
  IgnoreItemResponse,
  APIError,
  IgnoreBrandResponse,
  IgnoreCategoryResponse,
} from "types/api";
import { ServiceState } from "types/service";
import { serviceAsyncAction } from "./saga";

export const SERVICE = "service";

const initialState: ServiceState = {
  recExclude: {
    brand: {
      loading: false,
      data: null,
      error: null,
    },
    category: {
      loading: false,
      data: null,
      error: null,
    },
    item: {
      loading: false,
      data: null,
      error: null,
    },
  },
};

const serviceSlice = createSlice({
  name: SERVICE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(`${serviceAsyncAction.getIgnoreItems.request}`, (state) => {
        state.recExclude.item.loading = true;
      })
      .addCase(
        `${serviceAsyncAction.getIgnoreItems.success}`,
        (state, action: PayloadAction<{ itemsData: IgnoreItemResponse }>) => {
          state.recExclude.item.loading = false;
          state.recExclude.item.data = action.payload.itemsData;
        }
      )
      .addCase(
        `${serviceAsyncAction.getIgnoreItems.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.recExclude.item.loading = false;
          state.recExclude.item.error = action.payload;
        }
      )
      .addCase(`${serviceAsyncAction.getIgnoreBrands.request}`, (state) => {
        state.recExclude.brand.loading = true;
      })
      .addCase(
        `${serviceAsyncAction.getIgnoreBrands.success}`,
        (state, action: PayloadAction<{ brandsData: IgnoreBrandResponse }>) => {
          state.recExclude.brand.loading = false;
          state.recExclude.brand.data = action.payload.brandsData;
        }
      )
      .addCase(
        `${serviceAsyncAction.getIgnoreBrands.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.recExclude.brand.loading = false;
          state.recExclude.brand.error = action.payload;
        }
      )
      .addCase(`${serviceAsyncAction.getIgnoreCategories.request}`, (state) => {
        state.recExclude.category.loading = true;
      })
      .addCase(
        `${serviceAsyncAction.getIgnoreCategories.success}`,
        (
          state,
          action: PayloadAction<{ categoriesData: IgnoreCategoryResponse }>
        ) => {
          state.recExclude.category.loading = false;
          state.recExclude.category.data = action.payload.categoriesData;
        }
      )
      .addCase(
        `${serviceAsyncAction.getIgnoreCategories.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.recExclude.category.loading = false;
          state.recExclude.category.error = action.payload;
        }
      )
      .addCase(`${serviceAsyncAction.postIgnoreBrands.request}`, (state) => {
        state.recExclude.brand.loading = true;
      })
      .addCase(
        `${serviceAsyncAction.postIgnoreBrands.success}`,
        (state, action: PayloadAction<{ brandsData: IgnoreBrandResponse }>) => {
          state.recExclude.brand.loading = false;
          state.recExclude.brand.data = action.payload.brandsData;
        }
      )
      .addCase(
        `${serviceAsyncAction.postIgnoreBrands.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.recExclude.brand.loading = false;
          state.recExclude.brand.error = action.payload;
        }
      )
      .addCase(
        `${serviceAsyncAction.postIgnoreCategories.request}`,
        (state) => {
          state.recExclude.category.loading = true;
        }
      )
      .addCase(
        `${serviceAsyncAction.postIgnoreCategories.success}`,
        (
          state,
          action: PayloadAction<{ categoriesData: IgnoreCategoryResponse }>
        ) => {
          state.recExclude.category.loading = false;
          state.recExclude.category.data = action.payload.categoriesData;
        }
      )
      .addCase(
        `${serviceAsyncAction.postIgnoreCategories.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.recExclude.category.loading = false;
          state.recExclude.category.error = action.payload;
        }
      )
      .addCase(`${serviceAsyncAction.postIgnoreItems.request}`, (state) => {
        state.recExclude.item.loading = true;
      })
      .addCase(
        `${serviceAsyncAction.postIgnoreItems.success}`,
        (state, action: PayloadAction<{ itemsData: IgnoreItemResponse }>) => {
          state.recExclude.item.loading = false;
          state.recExclude.item.data = action.payload.itemsData;
        }
      )
      .addCase(
        `${serviceAsyncAction.postIgnoreItems.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.recExclude.item.loading = false;
          state.recExclude.item.error = action.payload;
        }
      );
  },
});

const selfSelector = (state: RootState) => state[SERVICE];

const recExcludeSelector = createSelector(
  selfSelector,
  (state) => state.recExclude
);

export const brandSelector = createSelector(
  recExcludeSelector,
  (recExclude) => recExclude.brand
);

export const categorySelector = createSelector(
  recExcludeSelector,
  (recExclude) => recExclude.category
);

export const itemSelector = createSelector(
  recExcludeSelector,
  (recExclude) => recExclude.item
);

export const BrandDataSelector = {
  loading: createSelector(brandSelector, (brand) => brand.loading),
  data: createSelector(brandSelector, (brand) => brand.data),
  error: createSelector(brandSelector, (brand) => brand.error),
};

export const CategoryDataSelector = {
  loading: createSelector(categorySelector, (category) => category.loading),
  data: createSelector(categorySelector, (category) => category.data),
  error: createSelector(categorySelector, (category) => category.error),
};

export const ItemDataSelector = {
  loading: createSelector(itemSelector, (item) => item.loading),
  data: createSelector(itemSelector, (item) => item.data),
  error: createSelector(itemSelector, (item) => item.error),
};

export const serviceAction = serviceSlice.actions;
export const serviceReducer = serviceSlice.reducer;
