import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { callWrapperSaga } from "utils/callWrapperSaga";
import { RtsDemoService } from "apis/services";
import type { RtsRequest, RtsResultResponse, APIError } from "types/api";

export const FETCH_RTSRESULT = `rts/FETCH_RTSRESULT`;
export const FETCH_RTSRESULT_SUCCESS = `rts/FETCH_RTSRESULT_SUCCESS`;
export const FETCH_RTSRESULT_ERROR = `rts/FETCH_RTSRESULT_ERROR`;

export const fetchRtsResult = createAsyncAction(
  FETCH_RTSRESULT,
  FETCH_RTSRESULT_SUCCESS,
  FETCH_RTSRESULT_ERROR
)<RtsRequest, { rtsResult: RtsResultResponse }, APIError>();

function* fetchRtsResultSaga(
  action: ReturnType<typeof fetchRtsResult.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      RtsDemoService.fetchRtsResult,
      action.payload
    );

    yield put(fetchRtsResult.success({ rtsResult: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchRtsResult.failure({ data, status }));
  }
}

export const rtsDemoAsyncAction = { fetchRtsResult };

export default function* rtsDemoSaga() {
  yield takeLatest(FETCH_RTSRESULT, fetchRtsResultSaga);
}
