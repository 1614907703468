import _ from "lodash";
import { createTransform } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import { userReducer, USER } from "store/modules/user";
import { homeReducer, HOME } from "store/modules/home";
import { reportReducer, REPORT } from "store/modules/report";
import { serviceReducer, SERVICE } from "store/modules/service";
import { logReducer, LOG } from "store/modules/log";
import { manageReducer, MANAGE } from "store/modules/manage";
import { recReducer, REC } from "store/modules/demo/rec";
import { rtsReducer, RTS } from "store/modules/demo/rts";
import { randomReducer, RANDOM } from "store/modules/demo/random";
import { personaReducer, PERSONA } from "store/modules/demo/persona";
import { dsocDemoReducer, DSOCDEMO } from "store/modules/demo/dsoc";
import { blogReducer, BLOG } from "store/modules/blog";
import { blogReducer02, BLOGV2 } from "store/modules/blogV2";
import { productReducer, PRODUCT } from "./product";
import { dsocReducer, DSOC } from "store/modules/dsoc";
import { CUBOT, cubotReducer } from "store/modules/cubot";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { all } from "@redux-saga/core/effects";
import userSaga from "store/modules/user/saga";
import homeSaga from "store/modules/home/saga";
import reportSaga from "store/modules/report/saga";
import serviceSaga from "store/modules/service/saga";
import logSaga from "store/modules/log/saga";
import manageSaga from "store/modules/manage/saga";
import recSaga from "store/modules/demo/rec/saga";
import rtsSaga from "store/modules/demo/rts/saga";
import randomSaga from "store/modules/demo/random/saga";
import personaDemoSaga from "store/modules/demo/persona/saga";
import dsocDemoSaga from "store/modules/demo/dsoc/saga";
import blogSaga from "store/modules/blog/saga";
import blogSagaV2 from "store/modules/blogV2/saga";
import productReportSaga from "store/modules/product/saga";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: [
    USER,
    HOME,
    REPORT,
    SERVICE,
    LOG,
    MANAGE,
    REC,
    RTS,
    RANDOM,
    PERSONA,
    DSOC,
    DSOCDEMO,
    BLOG,
    BLOGV2,
    PRODUCT,
    CUBOT,
  ],
};

const userPersistConfig = {
  key: USER,
  storage,
  blacklist: ["todayStr", "pwChange"],
  transforms: [
    createTransform(
      (inboundState: any, key: any) => {
        return _.omit(inboundState, ["loading", "error"]);
      },
      null,
      { whitelist: ["loginStatus", "cuid"] }
    ),
  ],
};

const blogPersistConfig = {
  key: BLOG,
  storage,
  blacklist: [
    "customerName",
    "products",
    "criteria",
    "keywords",
    "labelData",
    "labelTemplate",
    "request",
    "htmlSource",
  ],
  whitelist: ["currentState", "currentVideo"],
};
const blogV2PersistConfig = {
  key: BLOGV2,
  storage,
  blacklist: [
    "imageUploadResponse",
    "userText",
    "language",
    "segTabIndex",
    "criteria",
    "itemSegInfo",
    "request",
    "similarSeg",
    "useSimilarSection",
    "segBest",
    "associatedSeg",
    "useBestSection",
    "htmlSource",
    "openModal",
  ],
  whiteList: ["keepPage", "currentState"],
};

const rootReducer = combineReducers({
  [USER]: persistReducer(userPersistConfig, userReducer),
  [HOME]: homeReducer,
  [REPORT]: reportReducer,
  [SERVICE]: serviceReducer,
  [LOG]: logReducer,
  [MANAGE]: manageReducer,
  [REC]: recReducer,
  [RTS]: rtsReducer,
  [RANDOM]: randomReducer,
  [PERSONA]: personaReducer,
  [DSOC]: dsocReducer,
  [DSOCDEMO]: dsocDemoReducer,
  [BLOG]: persistReducer(blogPersistConfig, blogReducer),
  [BLOGV2]: persistReducer(blogV2PersistConfig, blogReducer02),
  [PRODUCT]: productReducer,
  [CUBOT]: cubotReducer,
});

export function* rootSaga() {
  yield all([
    userSaga(),
    homeSaga(),
    reportSaga(),
    serviceSaga(),
    logSaga(),
    manageSaga(),
    recSaga(),
    rtsSaga(),
    randomSaga(),
    personaDemoSaga(),
    dsocDemoSaga(),
    blogSaga(),
    blogSagaV2(),
    productReportSaga(),
  ]);
}

export default persistReducer(rootPersistConfig, rootReducer);
