import { FC } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";

interface CopyableButtonProps {
  color?: string;
  toCopyText: string;
  buttonText?: string;
  buttonType?: "primary" | "text";
  isIconNeed?: boolean;
}

const CopyableButton: FC<CopyableButtonProps> = ({
  color = "darkgrey",
  toCopyText,
  buttonText,
  buttonType,
  isIconNeed = true,
}) => {
  const { formatMessage } = useIntl();
  return (
    <CopyToClipboard
      text={toCopyText}
      onCopy={() => {
        message.success(
          formatMessage({ id: "Components.Common.CopyableButton.message" })
        );
      }}
    >
      {!isIconNeed ? (
        <Button {...(buttonType && { type: buttonType })}>{buttonText}</Button>
      ) : (
        <Button
          {...(buttonType && { type: buttonType })}
          icon={<CopyOutlined style={{ color }} />}
        >
          {buttonText}
        </Button>
      )}
    </CopyToClipboard>
  );
};

export default CopyableButton;
