import Client from "apis";
import { DAILY, MONTHLY } from "assets/constants/string";
import { AxiosResponse } from "axios";
import type {
  ReportRequest,
  ViewStatsResponse,
  RCCodeViewStatsResponse,
  ConversionStatsResponse,
  RevenueStatsResponse,
  RCCodeRevenueStatsResponse,
  DailyPerformanceResponse,
  DailySectionResponse,
} from "types/api";
import { convertToMonthStartDate } from "utils/convertToDate";
class ReportService {
  static async fetchViewStats(reportRequest: ReportRequest) {
    const { unit, startDate } = reportRequest;
    const response: AxiosResponse<ViewStatsResponse> =
      await Client.credentialsInstance.post(
        `${Client.path.api}/report/view/${unit === DAILY ? DAILY : ""}${
          unit === MONTHLY ? MONTHLY : ""
        }`,
        {
          ...reportRequest,
          ...{
            startDate:
              unit === MONTHLY ? convertToMonthStartDate(startDate) : startDate,
          },
        }
      );
    return response;
  }
  static async fetchConversionStats(reportRequest: ReportRequest) {
    const { unit, startDate } = reportRequest;
    const response: AxiosResponse<ConversionStatsResponse> =
      await Client.credentialsInstance.post(
        `${Client.path.api}/report/conversion/${unit === DAILY ? DAILY : ""}${
          unit === MONTHLY ? MONTHLY : ""
        }`,
        {
          ...reportRequest,
          ...{
            startDate:
              unit === MONTHLY ? convertToMonthStartDate(startDate) : startDate,
          },
        }
      );
    return response;
  }

  static async fetchRevenueStats(reportRequest: ReportRequest) {
    const { unit, startDate } = reportRequest;
    const response: AxiosResponse<RevenueStatsResponse> =
      await Client.credentialsInstance.post(
        `${Client.path.api}/report/revenue/${unit === DAILY ? DAILY : ""}${
          unit === MONTHLY ? MONTHLY : ""
        }`,
        {
          ...reportRequest,
          ...{
            startDate:
              unit === MONTHLY ? convertToMonthStartDate(startDate) : startDate,
          },
        }
      );
    return response;
  }

  static async fetchRCCodeConversionStats(reportRequest: ReportRequest) {
    const { unit, startDate } = reportRequest;
    const response: AxiosResponse<RCCodeViewStatsResponse> =
      await Client.credentialsInstance.post(
        `${Client.path.api}/report/conversion/rc-${
          unit === DAILY ? DAILY : ""
        }${unit === MONTHLY ? MONTHLY : ""}`,
        {
          ...reportRequest,
          ...{
            startDate:
              unit === MONTHLY ? convertToMonthStartDate(startDate) : startDate,
          },
        }
      );
    return response;
  }

  static async fetchRCCodeViewStats(reportRequest: ReportRequest) {
    const { unit, startDate } = reportRequest;
    const response: AxiosResponse<RCCodeViewStatsResponse> =
      await Client.credentialsInstance.post(
        `${Client.path.api}/report/view/rc-${unit === DAILY ? DAILY : ""}${
          unit === MONTHLY ? MONTHLY : ""
        }`,
        {
          ...reportRequest,
          ...{
            startDate:
              unit === MONTHLY ? convertToMonthStartDate(startDate) : startDate,
          },
        }
      );
    return response;
  }

  static async fetchRCCodeRevenueStats(reportRequest: ReportRequest) {
    const { unit, startDate } = reportRequest;
    const response: AxiosResponse<RCCodeRevenueStatsResponse> =
      await Client.credentialsInstance.post(
        `${Client.path.api}/report/revenue/rc-${unit === DAILY ? DAILY : ""}${
          unit === MONTHLY ? MONTHLY : ""
        }`,
        {
          ...reportRequest,
          ...{
            startDate:
              unit === MONTHLY ? convertToMonthStartDate(startDate) : startDate,
          },
        }
      );
    return response;
  }

  static async fetchDailyPerformance(reportRequest: ReportRequest) {
    const response: AxiosResponse<DailyPerformanceResponse> =
      await Client.credentialsInstance.post(
        `${Client.path.api}/report/daily-perf`,
        { ...reportRequest }
      );
    return response;
  }

  static async fetchDailySection(reportRequest: ReportRequest) {
    const response: AxiosResponse<DailySectionResponse> =
      await Client.credentialsInstance.post(
        `${Client.path.api}/report/section`,
        { ...reportRequest }
      );
    return response;
  }
}
export default ReportService;
