import styled from "styled-components";
import { Menu } from "antd";
const { SubMenu, Item } = Menu;

const LayoutPageSiderMenu = styled(Menu)`
  height: 100%;
`;

const LayoutPageSiderMenuItem = styled(Item)``;

const LayoutPageSiderSubMenu = styled(SubMenu)``;

export { LayoutPageSiderMenu, LayoutPageSiderMenuItem, LayoutPageSiderSubMenu };
