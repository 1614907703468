import { useComponentId, useReportData } from "hooks";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { SectionDataSelector } from "store/modules/report";
import { reportAsyncAction } from "store/modules/report/saga";
import _ from "lodash";
import toFixed from "utils/toFixed";
import { eachDayOfInterval, format } from "date-fns";

const useReportDailySectionData = () => {
  const componentId = useComponentId();
  const { startDate, endDate, channel } = useReportData();
  const dispatch = useAppDispatch();

  const [dailySectionDataLoading, dailySectionData, dailySectionDataError] = [
    useAppSelector(SectionDataSelector.loading),
    useAppSelector(SectionDataSelector.data),
    useAppSelector(SectionDataSelector.error),
  ];

  const getDailySection = useCallback(
    ({ cuid }: { cuid: string }) => {
      dispatch(
        reportAsyncAction.getDailySection.request({
          startDate,
          endDate,
          cuid,
          channel,
        })
      );
    },
    [dispatch, channel, startDate, endDate]
  );

  const { sectionPerformanceTableData, sectionPerformanceWithRatioTableData } =
    useMemo(() => {
      const dailySectionDataSummary = _.reduce(
        dailySectionData,
        (
          dailySectionDataSummary,
          {
            date,
            values: {
              viewCount,
              orderCount,
              revenue,
              sessionCount,
              conversionCount,
            },
          }
        ) => {
          return {
            ...dailySectionDataSummary,
            [date]: {
              viewCount:
                _.get(dailySectionDataSummary, [date, "viewCount"], 0) +
                viewCount,
              orderCount:
                _.get(dailySectionDataSummary, [date, "orderCount"], 0) +
                orderCount,
              revenue:
                _.get(dailySectionDataSummary, [date, "revenue"], 0) + revenue,
              sessionCount:
                _.get(dailySectionDataSummary, [date, "sessionCount"], 0) +
                sessionCount,
              conversionCount:
                _.get(dailySectionDataSummary, [date, "conversionCount"], 0) +
                conversionCount,
            },
          };
        },
        {}
      );

      const dateList = _.map(
        eachDayOfInterval({
          start: new Date(startDate),
          end: new Date(endDate),
        }),
        (date) => format(date, "yyyy-MM-dd")
      );

      const sectionPerformanceWithRatioTableData = _.flatMap(
        _.map(dateList, (date) =>
          _.filter(
            dailySectionData,
            (data) => data.date === date
            // && !_.includes(["no_rec", "noclick"], data.values.rcCode)
          )
        ),
        (dataArray) =>
          _.map(
            _.sortBy(dataArray, [(o) => o.values.rcCode]).sort((a, b) => {
              const { values: aValues } = a;
              const { values: bValues } = b;
              const { rcCode: aRcCode } = aValues;
              const { rcCode: bRcCode } = bValues;

              if (aRcCode && bRcCode) {
                if (aRcCode === "no_rec" || aRcCode === "noclick") return 1;
                if (bRcCode === "no_rec" || bRcCode === "noclick") return -1;
                const aNum = Number(aRcCode.match(/(\d+)/g));
                const bNum = Number(bRcCode.match(/(\d+)/g));
                return aNum - bNum;
              }
              return -1;
            }),
            (
              {
                date,
                values: {
                  rcCode,
                  viewCount,
                  orderCount,
                  revenue,
                  sessionCount,
                  conversionCount,
                },
              },
              idx
            ) => {
              return {
                key: `${componentId}-${date}-section-performance-with-ratio-table-data-${idx}`,
                date,
                section: rcCode,
                viewCount: _.defaultTo(
                  _.toNumber(
                    toFixed(2)(
                      100 *
                        (viewCount /
                          _.get(dailySectionDataSummary, [date, "viewCount"]))
                    )
                  ),
                  0
                ),
                orderCount: _.defaultTo(
                  _.toNumber(
                    toFixed(2)(
                      100 *
                        (orderCount /
                          _.get(dailySectionDataSummary, [date, "orderCount"]))
                    )
                  ),
                  0
                ),
                revenue: _.defaultTo(
                  _.toNumber(
                    toFixed(2)(
                      100 *
                        (revenue /
                          _.get(dailySectionDataSummary, [date, "revenue"]))
                    )
                  ),
                  0
                ),
                sessionCount: _.defaultTo(
                  _.toNumber(
                    toFixed(2)(
                      100 *
                        (sessionCount /
                          _.get(dailySectionDataSummary, [
                            date,
                            "sessionCount",
                          ]))
                    )
                  ),
                  0
                ),
                conversionCount: _.defaultTo(
                  _.toNumber(
                    toFixed(2)(
                      100 *
                        (conversionCount /
                          _.get(dailySectionDataSummary, [
                            date,
                            "conversionCount",
                          ]))
                    )
                  ),
                  0
                ),
                idx,
                dateLength: dataArray.length,
              };
            }
          )
      );

      const sectionPerformanceTableData = _.flatMap(
        _.map(dateList, (date) =>
          _.filter(
            dailySectionData,
            (data) => data.date === date
            // && !_.includes(["no_rec", "noclick"], data.values.rcCode)
          )
        ),
        (dataArray) =>
          _.map(
            _.sortBy(dataArray, [(o) => o.values.rcCode]).sort((a, b) => {
              const { values: aValues } = a;
              const { values: bValues } = b;
              const { rcCode: aRcCode } = aValues;
              const { rcCode: bRcCode } = bValues;

              if (aRcCode && bRcCode) {
                if (aRcCode === "no_rec" || aRcCode === "noclick") return 1;
                if (bRcCode === "no_rec" || bRcCode === "noclick") return -1;
                const aNum = Number(aRcCode.match(/(\d+)/g));
                const bNum = Number(bRcCode.match(/(\d+)/g));
                return aNum - bNum;
              }
              return -1;
            }),
            (
              {
                date,
                values: {
                  rcCode,
                  conversion1,
                  conversion2,
                  conversionCount,
                  orderCount,
                  revenue,
                  sessionCount,
                  totalViewCount,
                  viewCount,
                },
              },
              idx
            ) => {
              return {
                key: `${componentId}-${date}-section-performance-table-data-${idx}`,
                date,
                section: rcCode,
                viewCount,
                orderCount,
                revenue,
                conversion1,
                sessionCount,
                conversionCount,
                conversion2,
                totalViewCount,
                idx,
                dateLength: dataArray.length,
              };
            }
          )
      );

      return {
        sectionPerformanceTableData,
        sectionPerformanceWithRatioTableData,
      };
    }, [dailySectionData, componentId, startDate, endDate]);

  return {
    dailySectionDataLoading,
    dailySectionData,
    dailySectionDataError,
    sectionPerformanceTableData,
    sectionPerformanceWithRatioTableData,
    getDailySection,
  };
};
export default useReportDailySectionData;
