import generatePicker from "antd/lib/date-picker/generatePicker";
import {
  addDays,
  addMonths,
  addYears,
  format,
  getWeek,
  isAfter,
  isValid,
  parse,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setSeconds,
  setYear,
} from "date-fns";
import { enUS, ja, ko } from "date-fns/locale";
import { GenerateConfig } from "rc-picker/lib/generate";
import type { Locale } from "types/user";

type DateFnsLocale = typeof enUS | typeof ko | typeof ja;

type DateFnsLocaleMap = {
  [k in Locale]: DateFnsLocale;
};

const dateFnsLocaleMap: DateFnsLocaleMap = {
  ko_KR: ko,
  en_US: enUS,
  ja_JP: ja,
  dev: ko,
};

const generateConfig: GenerateConfig<Date> = {
  getNow: () => new Date(),
  getWeekDay: (date) => date.getDay(),
  getYear: (date) => date.getFullYear(),
  getMonth: (date) => date.getMonth(),
  getDate: (date) => date.getDate(),
  getHour: (date) => date.getHours(),
  getMinute: (date) => date.getMinutes(),
  getSecond: (date) => date.getSeconds(),
  getFixedDate: (fixed: string) => new Date(),
  getEndDate: (value: Date) => new Date(),

  addYear: (date, diff) => addYears(date, diff),
  addMonth: (date, diff) => addMonths(date, diff),
  addDate: (date, diff) => addDays(date, diff),
  setYear: (date, year) => setYear(date, year),
  setMonth: (date, month) => setMonth(date, month),
  setDate: (date, num) => setDate(date, num),
  setHour: (date, hour) => setHours(date, hour),
  setMinute: (date, minute) => setMinutes(date, minute),
  setSecond: (date, second) => setSeconds(date, second),

  isAfter: (date1, date2) => isAfter(date1, date2),
  isValidate: (date) => isValid(date),

  locale: {
    getWeekFirstDay: (locale) => 1,
    getWeekFirstDate: (locale: string, value: Date) => new Date(value),
    getWeek: (locale, date) => getWeek(date),
    getShortWeekDays: (locale) => {
      const d = Array.from({ length: 7 }).map((_, day) => {
        return dateFnsLocaleMap[locale as Locale]?.localize?.day(day, {
          width: "abbreviated",
        });
      });
      return d;
    },
    getShortMonths: (locale) => {
      const m = Array.from({ length: 12 }).map((_, month) =>
        dateFnsLocaleMap[locale as Locale]?.localize?.month(month, {
          width: "abbreviated",
        })
      );
      return m;
    },
    format: (locale, date, fmt) => {
      fmt = fmt.replace("YYYY", "yyyy");
      fmt = fmt.replace("DD", "dd");
      return format(date, fmt, { locale: dateFnsLocaleMap[locale as Locale] });
    },
    parse: (locale, text, formats) => {
      const fmt = formats[0].toLowerCase();
      return parse(text, fmt, new Date());
    },
  },
};

const DatePicker = generatePicker<Date>(generateConfig);
const { RangePicker } = DatePicker;

export { DatePicker, RangePicker };
