import { FC, Suspense, useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router";

import { useLocation } from "react-router-dom";
import { SuspendFallbackLoading } from "components/common";
import LayoutPageHeader from "pages/layout/header";
import LayoutPageSider from "pages/layout/sider";
import LayoutPageFooter from "pages/layout/footer";
import * as S from "pages/layout/style";
import { MOBILE } from "assets/constants/string";
import { useUserData } from "hooks";

const LayoutPage: FC = () => {
  const { device, collapsed, toggleCollapsed, onWindowResize } = useUserData();

  const isDesktop = useMemo(() => device !== MOBILE, [device]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    location.pathname === "/" && navigate("/home");
  }, [navigate, location]);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [onWindowResize]);

  return (
    <S.LayoutPageContainer>
      <LayoutPageHeader
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
      />
      <S.LayoutPageSiderAndContentContainer>
        <LayoutPageSider
          isDesktop={isDesktop}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <S.LayoutPageContent>
          <Suspense fallback={<SuspendFallbackLoading />}>
            <Outlet />
          </Suspense>
        </S.LayoutPageContent>
      </S.LayoutPageSiderAndContentContainer>
      <LayoutPageFooter />

    </S.LayoutPageContainer>
  );
};

export default LayoutPage;
