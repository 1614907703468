import Client from "apis";
import type { ApiKeyCreateRequest, ApiKeysGetRequest } from "types/api";

class ApiKeyService {
  static createApiKey = async (apiKeyCreateRequest: ApiKeyCreateRequest) => {
    return await Client.credentialsInstance.post(
      `${Client.path.api}/tokens/`,
      apiKeyCreateRequest
    );
  };

  static getApiKeys = async (apiKeygetRequest: ApiKeysGetRequest) => {
    const { cuid } = apiKeygetRequest;
    return await Client.credentialsInstance.get(
      `${Client.path.api}/tokens/${cuid}`
    );
  };
}

export default ApiKeyService;
