import { useCallback, useMemo } from "react";
import { useQuery } from "@apollo/client";

import { useAppSelector, useAppDispatch } from "store";
import type { LogicNode, InputNode } from "apollo/type";

import {
  GET_SELECTOR_LOGIC,
  GET_SELECTOR_INPUT_SOURCE_TYPE,
  GET_SELECTOR_LIST,
  GET_SELECTOR_DETAIL_LIST,
} from "apollo/query";

import {
  selecterBoardSelector,
  selecterNameSelector,
  selecterSelectedOperandSelector,
  dsocAction,
} from "store/modules/dsoc";

import { SELECTABLE_LIST } from "assets/constants/selector";
import { convertDateFormat } from "utils/convertToDate";
import { debounce } from "lodash";
const useSelectorData = () => {
  const dispatch = useAppDispatch();
  const { loading: logicLoading, data: logicData } =
    useQuery(GET_SELECTOR_LOGIC);

  const { loading: inputSourceLoading, data: inputSourceTypeData } = useQuery(
    GET_SELECTOR_INPUT_SOURCE_TYPE
  );

  const {
    loading: selectorListLoading,
    data: selectorListData,
    refetch: selectorListRefetch,
  } = useQuery(GET_SELECTOR_LIST, {
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: selectorDetailListLoading,
    data: selectorDetailListData,
    refetch: selectorDetailListRefetch,
  } = useQuery(GET_SELECTOR_DETAIL_LIST, {
    notifyOnNetworkStatusChange: true,
  });

  const board = useAppSelector(selecterBoardSelector);
  const selectorName = useAppSelector(selecterNameSelector);
  const currentSelectedOperand = useAppSelector(
    selecterSelectedOperandSelector
  );

  const setCurrentSelectorId = useCallback(
    ({ id }: { id: string }) => {
      dispatch(dsocAction.setCurrentSelectorId({ id }));
    },
    [dispatch]
  );

  const setSelectedOperand = useCallback(
    ({ operand }: { operand: string }) => {
      dispatch(dsocAction.setSelectedOperand({ operand }));
    },
    [dispatch]
  );

  const addInputSource = useCallback(
    (selectedIndex: number) => {
      dispatch(dsocAction.setSelecterInputSourceAdd({ selectedIndex }));
    },
    [dispatch]
  );
  const deleteInputSource = useCallback(
    (selectedIndex: number, rowId: number) => {
      dispatch(
        dsocAction.setSelecterInputSourceDelete({ selectedIndex, rowId })
      );
    },
    [dispatch]
  );

  const onChangeAction = useCallback(
    ({
      index,
      type,
      selectedIndex,
      value,
    }: {
      index: number;
      type: any;
      selectedIndex: any;
      value: any;
    }) => {
      dispatch(
        dsocAction.setSelecterBoardOnChage({
          index,
          type,
          selectedIndex,
          value,
        })
      );
    },
    [dispatch]
  );
  const setNameOnChange = useCallback(
    debounce(({ name }: { name: string }) => {
      dispatch(dsocAction.setSelecterNameOnChage({ name }));
    }, 100),
    [dispatch]
  );

  const setRelationActivate = useCallback(
    ({ currentIndex, status }: { currentIndex: number; status: boolean }) => {
      dispatch(dsocAction.setRelationActivate({ currentIndex, status }));
    },
    [dispatch]
  );

  const clearSelectorData = useCallback(() => {
    dispatch(dsocAction.clearSelectorData());
  }, [dispatch]);

  const expression = useMemo(
    () =>
      board
        .filter((row: any) => row.isActivated === true)
        .map((row: any) => row.content)
        .join(""),
    [board]
  );

  const logicList = useMemo(() => {
    return (
      logicData?.logic?.edges?.map((logicNode: LogicNode) => {
        const { node } = logicNode;
        return (
          node ?? {
            id: "",
            code: "",
            description: "",
            inputs: "",
            outputs: "",
            shortDesc: "",
          }
        );
      }) ?? []
    );
  }, [logicData?.logic?.edges]);

  const inputSourceTypeList = useMemo(() => {
    //  not title
    return (
      inputSourceTypeData?.inputSourceType?.edges.map(
        (inputNode: InputNode) => {
          const { node } = inputNode;
          return node;
        }
      ) ?? []
    );
  }, [inputSourceTypeData?.inputSourceType?.edges]);

  const selectorParam = useMemo(() => {
    const selectorParamList = board
      .filter(
        (row: any) =>
          row.isActivated === true && SELECTABLE_LIST.includes(row.content)
      )
      .map((row: any) => ({
        operandSign: row.content,
        Logic: {
          id: row["logicType"]["id"],
        },
        inputSources: row.inputSources,
        inputSourceSize: row.inputSource?.map(
          (data: any) => data.inputSourceSize
        ),
        resultSize: row.inputSource?.map((data: any) => data.resultSize),
      }));
    return selectorParamList;
  }, [board]);

  const selectorList = useMemo(
    () =>
      selectorListData?.selector?.edges.map((row: any) => {
        const { node } = row;
        const { id, name } = node;
        return { key: id, id, name };
      }),
    [selectorListData]
  );

  const selectorDetailList = useMemo(
    () =>
      selectorDetailListData?.selector?.edges.map((row: any, index: number) => {
        const { node } = row;
        const { id, name, expression, selectorParameters, createdAt } = node;

        if (!selectorParameters || selectorParameters?.length < 1) {
          return {
            key: index,
            id,
            name,
            content: "-",
            input: "-",
            output_results: "-",
            inputFilter: "-",
            createdDate: convertDateFormat(createdAt),
          };
        } else {
          const { InputSource } = selectorParameters[0];
          let inputName = "-";

          let lastOutput =
            selectorParameters[selectorParameters?.length - 1]["Logic"][
              "outputs"
            ] || "-";
          let logicSet = expression;
          selectorParameters?.forEach((element: any) => {
            const { operandSign, Logic } = element;
            const { code } = Logic;
            if (logicSet) {
              logicSet = logicSet.replace(operandSign, code);
            }
          });

          if (InputSource) {
            const { name: inputSourceName } = InputSource;
            inputName = inputSourceName;
          }

          return {
            key: index,
            id,
            name,
            content: `${expression} \n ${logicSet}`,
            input: inputName,
            output_results: lastOutput,
            inputFilter: "-",
            createdDate: convertDateFormat(createdAt),
          };
        }
      }),
    [selectorDetailListData?.selector?.edges]
  );

  const selectedOperandIndex = useMemo(() => {
    const index = board.findIndex(
      (ele: any) => ele.content === currentSelectedOperand
    );
    return index;
  }, [board, currentSelectedOperand]);

  const selectedBoardOperandData = useMemo(() => {
    return board[selectedOperandIndex];
  }, [board, selectedOperandIndex]);

  return {
    board,
    expression,
    currentSelectedOperand,
    setSelectedOperand,
    logicLoading,
    inputSourceLoading,
    onChangeAction,
    logicList,
    inputSourceTypeList,
    setRelationActivate,
    selectorParam,
    setCurrentSelectorId,
    selectorListLoading,
    selectorListRefetch,
    selectorListData,
    selectorDetailListData,
    selectorList,
    setNameOnChange,
    selectorName,
    selectorDetailListLoading,
    selectorDetailListRefetch,
    selectorDetailList,
    clearSelectorData,
    selectedBoardOperandData,
    selectedOperandIndex,
    addInputSource,
    deleteInputSource,
  };
};

export default useSelectorData;
