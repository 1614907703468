import { useMemo } from "react";
import { message } from "antd";

import { ApiKeyService } from "apis/services";
import useUserData from "hooks/useUserData";
import { useMutation, useQuery } from "@tanstack/react-query";

const useApiKey = () => {
  const { currentCuid } = useUserData();
  const {
    isLoading: isGetApiKeysLoading,
    data: rawGetApiKeysData,
    refetch: refetchApiKeyList,
  } = useQuery({
    queryKey: ["api-key-list", currentCuid],
    queryFn: () => ApiKeyService.getApiKeys({ cuid: currentCuid }),
    refetchOnWindowFocus: false,
    enabled: !!currentCuid,
    retry: 1,
  });

  const createApiKey = useMutation({
    mutationFn: () =>
      ApiKeyService.createApiKey({ cuid: currentCuid, services: ["dsoc"] }),
    onSuccess: () => {
      message.success("키 생성을 완료하였습니다");
      refetchApiKeyList();
    },
    onError: (err: any) => {
      if (err.message) {
        message.error(err);
      }
    },
  });

  const apiKeyList = useMemo(() => {
    const { data } = rawGetApiKeysData || { data: [] };
    return (
      data?.map((value: any) => {
        return {
          ...value,
          services: value?.services?.join(",") || "",
        };
      }) || []
    );
  }, [rawGetApiKeysData]);

  return {
    isGetApiKeysLoading,
    apiKeyList,
    createApiKey,
  };
};

export default useApiKey;
