import { useCallback, useMemo } from "react";
import { useQuery } from "@apollo/client";

import { useAppSelector, useAppDispatch } from "store";
import { GET_OPERATOR_LIST, GET_OPERATOR_SIMPLE_LIST } from "apollo/query";
import {
  operatorFilteringListSelector,
  operatorMarkingListSelector,
  operatorSortingListSelector,
  operatorNameSelector,
  dsocAction,
} from "store/modules/dsoc";
import { useUserData } from "hooks";
import { convertDateFormat } from "utils/convertToDate";

const useOperatorData = () => {
  const dispatch = useAppDispatch();
  const filteringList = useAppSelector(operatorFilteringListSelector);
  const sortingList = useAppSelector(operatorSortingListSelector);
  const markingList = useAppSelector(operatorMarkingListSelector);

  const operatorName = useAppSelector(operatorNameSelector);
  const { currentCuid } = useUserData();

  const {
    loading: operatorListLoading,
    data: operatorListData,
    refetch: operatorListRefetch,
  } = useQuery(GET_OPERATOR_LIST, {
    variables: {
      filter: {
        columns: {
          cuid: {
            eq: currentCuid,
          },
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: operatorSimpleListLoading,
    data: operatorSimpleListData,
    refetch: operatorSimpleListRefetch,
  } = useQuery(GET_OPERATOR_SIMPLE_LIST, {
    variables: {
      filter: {
        columns: {
          cuid: {
            eq: currentCuid,
          },
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const setOperatorName = useCallback(
    ({ name }: { name: string }) => {
      dispatch(dsocAction.settOperatorNameOnChage({ name }));
    },
    [dispatch]
  );
  const setFilteringList = useCallback(
    ({ filteringList }: { filteringList: any }) => {
      dispatch(dsocAction.setOperatorFilteringList({ filteringList }));
    },
    [dispatch]
  );

  const setSortingList = useCallback(
    ({ sortingList }: { sortingList: any }) => {
      dispatch(dsocAction.setOperatorSortingList({ sortingList }));
    },
    [dispatch]
  );

  const setMarkingList = useCallback(
    ({ markingList }: { markingList: any }) => {
      dispatch(dsocAction.setOperatorMarkingList({ markingList }));
    },
    [dispatch]
  );
  const clearOperatorName = useCallback(() => {
    dispatch(dsocAction.clearOperatorName());
  }, [dispatch]);

  const filterCreateList = useMemo(() => {
    const { product, kpi } = filteringList;
    const productList = Object.entries(product).map((row: any) => {
      const [key, value] = row;
      const { name, type, input, operator, action } = value;

      return {
        type,
        operator,
        elements: input?.split(","),
        criteria: name,
        action,
      };
    });

    const kpiList = Object.entries(kpi).map((row: any) => {
      const [key, value] = row;
      const { name, type, input, operator, action } = value;

      return {
        type,
        operator,
        elements: input?.split(","),
        criteria: name,
        action,
      };
    });

    return [...productList, ...kpiList];
  }, [filteringList]);

  const sortCreateList = useMemo(() => {
    const { product, kpi } = sortingList;
    const productList = Object.entries(product).map((row: any) => {
      const [key, value] = row;
      const { name, type, order } = value;

      return {
        prop: name,
        type,
        order,
      };
    });

    const kpiList = Object.entries(kpi).map((row: any) => {
      const [key, value] = row;
      const { name, type, order } = value;

      return {
        prop: name,
        type,
        order,
      };
    });

    return [...productList, ...kpiList];
  }, [sortingList]);

  const operatorList = useMemo(
    () =>
      operatorListData?.operator?.edges.map((row: any) => {
        const { node } = row;
        const { id, name, filterConfig, sortingConfig, createdAt } = node;
        const { list: filterList } = filterConfig ?? { list: [] };
        const { list: sortingList } = sortingConfig ?? { list: [] };

        const filter = filterList.reduce((acc: string, value: any) => {
          const { type, operator, criteria, elements, action } = value;
          return (acc += `${type} ${criteria} ${operator} ${elements.toString()} ${action} | `);
        }, "");

        const sort = sortingList.reduce((acc: string, value: any) => {
          const { type, prop, order } = value;
          return (acc += `${type} ${prop} ${order} | `);
        }, "");

        return {
          key: id,
          id,
          name,
          filter,
          sort,
          mark: "-",
          createdDate: convertDateFormat(createdAt),
        };
      }),
    [operatorListData]
  );

  const operatorSimpleList = useMemo(
    () =>
      operatorSimpleListData?.operator?.edges.map((row: any) => {
        const { node } = row;
        const { id, name } = node;

        return {
          key: id,
          id,
          name,
        };
      }),
    [operatorSimpleListData]
  );

  return {
    filteringList,
    sortingList,
    markingList,
    setFilteringList,
    setSortingList,
    setMarkingList,
    setOperatorName,
    filterCreateList,
    sortCreateList,
    operatorName,
    operatorList,
    operatorListRefetch,
    operatorListLoading,
    operatorSimpleListLoading,
    operatorSimpleListRefetch,
    operatorSimpleList,
    clearOperatorName,
  };
};

export default useOperatorData;
