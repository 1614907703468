import {
  createSelector,
  createSlice,
  PayloadAction,
  current,
} from "@reduxjs/toolkit";
import { RootState } from "store";

import {
  DSOC_ALL,
  DSOC_NOT_SELECTED,
  DSOC_STATUS,
} from "assets/constants/string";
import type { DsocState } from "types/dsoc";
import { CubotExtraProps } from "types/dsoc";
import _ from "lodash";

import { message } from "antd";

export const DSOC = "dsoc";
const { ALL } = DSOC_STATUS;

const defaultInputSourceState = {
  InputSourceType: {
    id: "",
    title: "",
    name: "",
    resultSize: "",
    inputSourceSize: "",
    cacheThresholdValue: "",
  },
  resultSize: "",
  inputSourceSize: "",
  cacheThresholdValue: "",
  action: "add",
  selectorParamId: "",
  typeId: "",
};

const initialState: DsocState = {
  currentCampaignId: "",
  currentDesingerId: "",
  currentSelecterId: "",
  sectionStatus: ALL,
  pageStatus: DSOC_ALL,
  channelStatus: ALL,
  areaStatus: DSOC_NOT_SELECTED,
  campaign: {
    loading: false,
    data: {
      name: "",
      channel: {
        id: "",
        channelName: "",
      },
      page: {
        id: "",
        pageName: "",
      },
      area: {
        id: "",
        areaName: "",
      },
    },
    error: null,
  },
  designer: {
    loading: false,
    data: {
      cubotActionTrigger: null,
      cubotExtraProps: {
        param: null,
        // needRTSStat?:
      },
      cubotTriggerId: null, //
      cubotSpeechMessage: "",
      cubotMessageDeps: [],
      cubotRecTitle: "",
      currentPhase: "D2",
      seg: {
        id: null,
        code: "",
      },
      traffic: "",
      pcidTraffic: "",
      viewRatio: 0,
      orderRatio: 0,
      revenueRatio: 0,
      basketSize: 0,
      selector: {
        id: null,
        name: "",
      },
      operator: {
        id: null,
        name: "",
      },
    },
    error: null,
  },
  selector: {
    loading: false,
    data: {
      currentSelectedOperand: "A",
      name: "",
      board: [
        { content: "(", isActivated: false, relation: [] }, //0
        {
          content: "A",
          isActivated: true,
          relation: [],
          inputSources: [defaultInputSourceState],
          inputSourceSize: "",
          resultSize: "",
          logicType: {},
          inputFilter: "",
          mixTerm: "",
          mixMethod: "",
        }, //1
        { content: "*", isActivated: false, relation: [] }, // 2
        {
          content: "B",
          isActivated: false,
          relation: [0, 2, 3, 4],
          inputSources: [defaultInputSourceState],
          inputSourceSize: "",
          resultSize: "",
          logicType: {},
          inputFilter: "",
          mixTerm: "",
          mixMethod: "",
        }, //3
        { content: ")", isActivated: false, relation: [] }, //4
        { content: "+", isActivated: false, relation: [] }, //5
        { content: "(", isActivated: false, relation: [] }, //6
        {
          content: "C",
          isActivated: false,
          relation: [5, 7],
          inputSources: [defaultInputSourceState],
          inputSourceSize: "",
          logicType: {},
          inputFilter: "",
          mixTerm: "",
          mixMethod: "",
        }, //7
        { content: "*", isActivated: false, relation: [] }, //8
        {
          content: "D",
          isActivated: false,
          relation: [6, 8, 9, 10],
          inputSources: [defaultInputSourceState],
          inputSourceSize: "",
          logicType: {},
          inputFilter: "",
          mixTerm: "",
          mixMethod: "",
        }, //9
        { content: ")", isActivated: false, relation: [] }, //10
        { content: "+", isActivated: false, relation: [] }, //11
        { content: "(", isActivated: false, relation: [] }, //12
        {
          content: "E",
          isActivated: false,
          relation: [11, 13],
          inputSources: [defaultInputSourceState],
          inputSourceSize: "",
          logicType: {},
          inputFilter: "",
          mixTerm: "",
          mixMethod: "",
        }, //13
        { content: "*", isActivated: false, relation: [] }, //14
        {
          content: "F",
          isActivated: false,
          // relation: [12, 14, 15, 16],
        }, //15
        { content: ")", isActivated: false, relation: [] }, //16
      ],
    },
    error: null,
  },
  operator: {
    name: "",
    filteringList: {
      product: {},
      kpi: {},
    },
    sortingList: {
      product: {},
      kpi: {},
    },
    markingList: {
      product: {},
      kpi: {},
    },
  },
};

const dsocSlice = createSlice({
  name: DSOC,
  initialState,
  reducers: {
    setCurrentCampaignId(state, action: PayloadAction<{ id: string }>) {
      state.currentCampaignId = action.payload.id;
    },
    setCurrentSelectorId(state, action: PayloadAction<{ id: string }>) {
      state.currentSelecterId = action.payload.id;
    },
    setSectionStatus(state, action: PayloadAction<{ status: string }>) {
      state.sectionStatus = action.payload.status;
    },
    setPageStatus(state, action: PayloadAction<{ status: string }>) {
      state.pageStatus = action.payload.status;
    },
    setChannelStatus(state, action: PayloadAction<{ status: string }>) {
      state.channelStatus = action.payload.status;
    },
    setAreaStatus(state, action: PayloadAction<{ status: string }>) {
      state.areaStatus = action.payload.status;
    },
    setCampaignName(state, action: PayloadAction<{ name: string }>) {
      state.campaign.data.name = action.payload.name;
    },
    setCampaignChannel(
      state,
      action: PayloadAction<{ channel: { id: string; channelName: string } }>
    ) {
      state.campaign.data.channel = action.payload.channel;
    },
    setCampaignPage(
      state,
      action: PayloadAction<{ page: { id: string; pageName: string } }>
    ) {
      state.campaign.data.page = action.payload.page;
    },
    setCampaignArea(
      state,
      action: PayloadAction<{ area: { id: string; areaName: string } }>
    ) {
      state.campaign.data.area = action.payload.area;
    },
    setDesignerCubotActionTrigger(
      state,
      action: PayloadAction<{ cubotActionTrigger: string }>
    ) {
      state.designer.data.cubotActionTrigger =
        action.payload.cubotActionTrigger;
    },
    setDesignerCubotExtraProps(state, action: PayloadAction<CubotExtraProps>) {
      state.designer.data.cubotExtraProps = action.payload;
    },
    setDesignerCubotSpeechMessage(
      state,
      action: PayloadAction<{ cubotSpeechMessage: string }>
    ) {
      state.designer.data.cubotSpeechMessage =
        action.payload.cubotSpeechMessage;
    },
    setDesignerCubotMessageDeps(state, action: PayloadAction<any[] | null>) {
      state.designer.data.cubotMessageDeps = action.payload;
    },
    setDesignerCubotRecTitle(
      state,
      action: PayloadAction<{ cubotRecTitle: string }>
    ) {
      state.designer.data.cubotRecTitle = action.payload.cubotRecTitle;
    },
    setDesignerCurrentPhase(
      state,
      action: PayloadAction<{ phase: "D2" | "D3" | "D4" }>
    ) {
      state.designer.data.currentPhase = action.payload.phase;
    },
    setDesignerSeg(
      state,
      action: PayloadAction<{ seg: { id: string; code: string } }>
    ) {
      state.designer.data.seg = action.payload.seg;
    },
    setDesignerTraffic(state, action: PayloadAction<{ traffic: string }>) {
      state.designer.data.traffic = action.payload.traffic;
    },
    setDesignerPcidTraffic(
      state,
      action: PayloadAction<{ pcidTraffic: string }>
    ) {
      state.designer.data.pcidTraffic = action.payload.pcidTraffic;
    },
    setDesignerExtraTraffic(
      state,
      action: PayloadAction<{
        type: "viewRatio" | "orderRatio" | "revenueRatio" | "basketSize";
        value: number;
      }>
    ) {
      const { type, value } = action.payload;
      if (type === ("viewRatio" as "viewRatio"))
        state.designer.data.viewRatio = value;
      if (type === ("orderRatio" as "orderRatio"))
        state.designer.data.orderRatio = value;
      if (type === ("revenueRatio" as "revenueRatio"))
        state.designer.data.revenueRatio = value;
      if (type === ("basketSize" as "basketSize"))
        state.designer.data.basketSize = value;
    },
    setDesignerSelector(
      state,
      action: PayloadAction<{
        selector: {
          id: number | null;
          name: string;
        };
      }>
    ) {
      state.designer.data.selector = action.payload.selector;
    },
    setDesignerOperator(
      state,
      action: PayloadAction<{
        operator: {
          id: number | null;
          name: string;
        };
      }>
    ) {
      state.designer.data.operator = action.payload.operator;
    },
    setSelectedOperand(state, action: PayloadAction<{ operand: string }>) {
      state.selector.data.currentSelectedOperand = action.payload.operand;
    },
    setSelecterNameOnChage(state, action: PayloadAction<{ name: string }>) {
      state.selector.data.name = action.payload.name;
    },
    setSelecterInputSourceAdd(
      state,
      action: PayloadAction<{ selectedIndex: number }>
    ) {
      const { selectedIndex } = action.payload;
      state.selector.data.board[selectedIndex]["inputSources"] = [
        ...state.selector.data.board[selectedIndex]["inputSources"],
        defaultInputSourceState,
      ];
    },
    setSelecterInputSourceDelete(
      state,
      action: PayloadAction<{ selectedIndex: number; rowId: number }>
    ) {
      const { selectedIndex, rowId } = action.payload;
      const deletedBoard = state.selector.data.board[selectedIndex][
        "inputSources"
      ].filter((el: any, index: number) => index !== rowId);
      state.selector.data.board[selectedIndex]["inputSources"] = deletedBoard;
    },

    setSelecterBoardOnChage(
      state,
      action: PayloadAction<{
        index: number; // 현재 inputSource가 몇번째인지?
        type: any;
        selectedIndex: any;
        value: any;
      }>
    ) {
      const { selectedIndex, type, value, index } = action.payload;
      console.log(action.payload);
      if (type === "logicType") {
        state.selector.data.board[selectedIndex][type] = value;
        //logicType 변경시 input Source, size, result Size, N 입력 초기화 -> redux에서 함
        state.selector.data.board[selectedIndex]["inputSources"] = [
          defaultInputSourceState,
        ];
      } else if (type === "inputSource") {
        //inputSource 변경시 size, result Size, N 입력 초기화 -> redux에서 함

        const { elementType, id, name, source, title, type, __typename } =
          value.InputSourceType;

        state.selector.data.board[selectedIndex]["inputSources"][index][
          "InputSourceType"
        ]["id"] = id;

        state.selector.data.board[selectedIndex]["inputSources"][index][
          "InputSourceType"
        ]["title"] = title;
        state.selector.data.board[selectedIndex]["inputSources"][index][
          "InputSourceType"
        ]["name"] = name;
        state.selector.data.board[selectedIndex]["inputSources"][index][
          "InputSourceType"
        ]["resultSize"] = ""; // 초기화
        state.selector.data.board[selectedIndex]["inputSources"][index][
          "InputSourceType"
        ]["inputSourceSize"] = ""; // 초기화
        state.selector.data.board[selectedIndex]["inputSources"][index][
          "InputSourceType"
        ]["cacheThresholdValue"] = ""; // 초기화

        state.selector.data.board[selectedIndex]["inputSources"][index][
          "resultSize"
        ] = "";
        state.selector.data.board[selectedIndex]["inputSources"][index][
          "inputSourceSize"
        ] = "";
        state.selector.data.board[selectedIndex]["inputSources"][index][
          "action"
        ] = "add"; // 초기화
        state.selector.data.board[selectedIndex]["inputSources"][index][
          "selectorParamId"
        ] = "";
        state.selector.data.board[selectedIndex]["inputSources"][index][
          "typeId"
        ] = id;
        console.log(id);
      } else if (
        type === "inputSourceSize" ||
        type === "resultSize" ||
        type === "cacheThresholdValue"
      ) {
        state.selector.data.board[selectedIndex]["inputSources"][index][type] =
          value;
        state.selector.data.board[selectedIndex]["inputSources"][index][
          "InputSourceType"
        ][type] = value;
      } else if (type === "action") {
        if (value === "add") {
          state.selector.data.board[selectedIndex]["inputSources"][index][
            type
          ] = value;
        } else if (value === "exclude") {
          // exclude로 선택된 인풋의 resultSIze가 가장 크다면
          if (
            current(
              state.selector.data.board[selectedIndex]["inputSources"]
            ).every(
              (el: any) =>
                Number(el["resultSize"]) <=
                Number(
                  state.selector.data.board[selectedIndex]["inputSources"][
                    index
                  ]["resultSize"]
                )
            )
          ) {
            message.error({ content: "추가보다 제외가 클 수 없습니다" });
          } else {
            // 또 resultSize가 비어있다면
            current(
              state.selector.data.board[selectedIndex]["inputSources"]
            ).every(
              (el: any) =>
                state.selector.data.board[selectedIndex]["inputSources"][index][
                  "resultSize"
                ] === ""
            )
              ? message.error({ content: "resultSize 값을 입력해주세요" })
              : (state.selector.data.board[selectedIndex]["inputSources"][
                  index
                ][type] = value);
          }
        }
      }
    },
    setRelationActivate(
      state,
      action: PayloadAction<{ currentIndex: number; status: boolean }>
    ) {
      const { currentIndex, status } = action.payload;

      state.selector.data.board[currentIndex].relation.forEach(
        // 작동 : 비활성
        (relationIndex: number) => {
          state.selector.data.board[relationIndex].isActivated = status;
        }
      );
    },
    settOperatorNameOnChage(state, action: PayloadAction<{ name: string }>) {
      state.operator.name = action.payload.name;
    },
    setOperatorFilteringList(
      state,
      action: PayloadAction<{ filteringList: any }>
    ) {
      state.operator.filteringList = action.payload.filteringList;
    },
    setOperatorSortingList(state, action: PayloadAction<{ sortingList: any }>) {
      state.operator.sortingList = action.payload.sortingList;
    },
    setOperatorMarkingList(state, action: PayloadAction<{ markingList: any }>) {
      state.operator.markingList = action.payload.markingList;
    },
    setCubotTriggerId(state, action: PayloadAction<{ triggerId: any }>) {
      state.designer.data.cubotTriggerId = action.payload.triggerId;
    },
    clearCampaignData(state) {
      state.campaign.data = initialState.campaign.data;
    },
    clearCubotTrigger(state) {
      state.designer.data.cubotActionTrigger = null;
      state.designer.data.cubotExtraProps = { param: null };
      state.designer.data.cubotTriggerId = null;
    },
    clearDesignerData(state) {
      state.designer.data = initialState.designer.data;
    },
    clearSelectorData(state) {
      state.selector.data = initialState.selector.data;
    },
    clearOperatorName(state) {
      state.operator.name = initialState.operator.name;
    },
  },
});

const selfSelector = (state: RootState) => state[DSOC];

const campaignSelector = createSelector(
  selfSelector,
  (state) => state.campaign
);

const designerSelector = createSelector(
  selfSelector,
  (state) => state.designer
);

const selectorSelector = createSelector(
  selfSelector,
  (state) => state.selector
);

const operatorSelector = createSelector(
  selfSelector,
  (state) => state.operator
);
export const currentCampaignIdSelector = createSelector(
  selfSelector,
  (state) => state.currentCampaignId
);

export const sectionStatusSelector = createSelector(
  selfSelector,
  (state) => state.sectionStatus
);

export const pageStatusSelector = createSelector(
  selfSelector,
  (state) => state.pageStatus
);

export const channelStatusSelector = createSelector(
  selfSelector,
  (state) => state.channelStatus
);

export const areaStatusSelector = createSelector(
  selfSelector,
  (state) => state.areaStatus
);

export const selecterBoardSelector = createSelector(
  selectorSelector,
  (selector) => selector.data.board
);

export const selecterNameSelector = createSelector(
  selectorSelector,
  (selector) => selector.data.name
);

export const selecterSelectedOperandSelector = createSelector(
  selectorSelector,
  (selector) => selector.data.currentSelectedOperand
);

export const operatorNameSelector = createSelector(
  operatorSelector,
  (operator) => operator.name
);

export const operatorFilteringListSelector = createSelector(
  operatorSelector,
  (operator) => operator.filteringList
);

export const operatorSortingListSelector = createSelector(
  operatorSelector,
  (operator) => operator.sortingList
);

export const operatorMarkingListSelector = createSelector(
  operatorSelector,
  (operator) => operator.markingList
);

export const CampaignDataSelector = {
  loading: createSelector(campaignSelector, (campaign) => campaign.loading),
  data: createSelector(campaignSelector, (campaign) => campaign.data),
  error: createSelector(campaignSelector, (campaign) => campaign.error),
};

export const DesignerDataSelector = {
  loading: createSelector(designerSelector, (designer) => designer.loading),
  data: createSelector(designerSelector, (designer) => designer.data),
  error: createSelector(designerSelector, (designer) => designer.error),
};

export const dsocAction = dsocSlice.actions;
export const dsocReducer = dsocSlice.reducer;
