import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import type { APIError } from "types/api";
import type {
  APIStatus,
  ContractStatus,
  LoggerStatus,
  ReportStatus,
} from "types/home";
import { HomeState } from "types/home";
import { RootState } from "store";
import { INCOMPLETE } from "assets/constants/string";
import { homeAsyncAction } from "store/modules/home/saga";

export const HOME = "home";
const initialState: HomeState = {
  contractStatus: {
    loading: false,
    status: {
      service: INCOMPLETE,
      rec: INCOMPLETE,
      push: INCOMPLETE,
      onSite: INCOMPLETE,
      rts: INCOMPLETE,
      etc: INCOMPLETE,
    },
    error: null,
  },
  loggerStatus: {
    loading: false,
    status: INCOMPLETE,
    error: null,
  },
  apiStatus: {
    loading: false,
    status: INCOMPLETE,
    error: null,
  },
  reportStatus: {
    loading: false,
    status: INCOMPLETE,
    error: null,
  },
  firstVisit: false,
};
export const setFirstVisit = createAction<boolean>("home/setFirstVisit");
const homeSlice = createSlice({
  name: HOME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(`${setFirstVisit.type}`, (state, action: any) => {
        state.firstVisit = action.payload;
      })
      .addCase(`${homeAsyncAction.getContractStatus.request}`, (state) => {
        state.contractStatus.loading = true;
      })
      .addCase(
        `${homeAsyncAction.getContractStatus.success}`,
        (state, action: PayloadAction<Pick<ContractStatus, "status">>) => {
          state.contractStatus.loading = false;
          state.contractStatus.status = action.payload.status;
        }
      )
      .addCase(
        `${homeAsyncAction.getContractStatus.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.contractStatus.loading = false;
          state.contractStatus.error = action.payload;
        }
      )
      .addCase(`${homeAsyncAction.getLoggerStatus.request}`, (state) => {
        state.loggerStatus.loading = true;
      })
      .addCase(
        `${homeAsyncAction.getLoggerStatus.success}`,
        (state, action: PayloadAction<Pick<LoggerStatus, "status">>) => {
          state.loggerStatus.loading = false;
          state.loggerStatus.status = action.payload.status;
        }
      )
      .addCase(
        `${homeAsyncAction.getLoggerStatus.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.loggerStatus.loading = false;
          state.loggerStatus.error = action.payload;
        }
      )
      .addCase(`${homeAsyncAction.getAPIStatus.request}`, (state) => {
        state.apiStatus.loading = true;
      })
      .addCase(
        `${homeAsyncAction.getAPIStatus.success}`,
        (state, action: PayloadAction<Pick<APIStatus, "status">>) => {
          state.apiStatus.loading = false;
          state.apiStatus.status = action.payload.status;
        }
      )
      .addCase(
        `${homeAsyncAction.getAPIStatus.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.apiStatus.loading = false;
          state.apiStatus.error = action.payload;
        }
      )
      .addCase(`${homeAsyncAction.getReportStatus.request}`, (state) => {
        state.reportStatus.loading = true;
      })
      .addCase(
        `${homeAsyncAction.getReportStatus.success}`,
        (state, action: PayloadAction<Pick<ReportStatus, "status">>) => {
          state.reportStatus.loading = false;
          state.reportStatus.status = action.payload.status;
        }
      )
      .addCase(
        `${homeAsyncAction.getReportStatus.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.reportStatus.loading = false;
          state.reportStatus.error = action.payload;
        }
      );
  },
});

export const firstVisitSelector = (state: RootState) => state[HOME].firstVisit;
const contractStatusSelector = (state: RootState) => state[HOME].contractStatus;
export const ContractStatusSelector = {
  loading: createSelector(
    contractStatusSelector,
    (contractStatus) => contractStatus.loading
  ),
  status: createSelector(
    contractStatusSelector,
    (contractStatus) => contractStatus.status
  ),
  error: createSelector(
    contractStatusSelector,
    (contractStatus) => contractStatus.error
  ),
};

const loggerStatusSelector = (state: RootState) => state[HOME].loggerStatus;
export const LoggerStatusSelector = {
  loading: createSelector(
    loggerStatusSelector,
    (loggerStatus) => loggerStatus.loading
  ),
  status: createSelector(
    loggerStatusSelector,
    (loggerStatus) => loggerStatus.status
  ),
  error: createSelector(
    loggerStatusSelector,
    (loggerStatus) => loggerStatus.error
  ),
};

const apiStatusSelector = (state: RootState) => state[HOME].apiStatus;
export const APIStatusSelector = {
  loading: createSelector(apiStatusSelector, (apiStatus) => apiStatus.loading),
  status: createSelector(apiStatusSelector, (apiStatus) => apiStatus.status),
  error: createSelector(apiStatusSelector, (apiStatus) => apiStatus.error),
};

const reportStatusSelector = (state: RootState) => state[HOME].reportStatus;
export const ReportStatusSelector = {
  loading: createSelector(
    reportStatusSelector,
    (reportStatus) => reportStatus.loading
  ),
  status: createSelector(
    reportStatusSelector,
    (reportStatus) => reportStatus.status
  ),
  error: createSelector(
    reportStatusSelector,
    (reportStatus) => reportStatus.error
  ),
};

export const homeAction = homeSlice.actions;
export const homeReducer = homeSlice.reducer;
