import { createAsyncAction } from "typesafe-actions";
import { ReportService } from "apis/services";
import { AxiosResponse } from "axios";
import { put, all, takeEvery } from "@redux-saga/core/effects";
import type {
  APIError,
  ConversionStatsResponse,
  DailyPerformanceResponse,
  DailySectionResponse,
  RCCodeConversionStatsResponse,
  RCCodeRevenueStatsResponse,
  RCCodeViewStatsResponse,
  ReportRequest,
  RevenueStatsResponse,
  ViewStatsResponse,
} from "types/api";
import { callWrapperSaga } from "utils/callWrapperSaga";

export const GET_ALL_VIEW_STATS = `report/GET_ALL_VIEW_STATS`;
export const GET_ALL_VIEW_STATS_SUCCESS = `report/GET_ALL_VIEW_STATS_SUCCESS`;
export const GET_ALL_VIEW_STATS_FAILURE = `report/GET_ALL_VIEW_STATS_FAILURE`;

export const getAllViewStats = createAsyncAction(
  GET_ALL_VIEW_STATS,
  GET_ALL_VIEW_STATS_SUCCESS,
  GET_ALL_VIEW_STATS_FAILURE
)<
  ReportRequest,
  {
    viewStatsData: ViewStatsResponse;
    rcCodesViewStatsData: RCCodeViewStatsResponse;
  },
  APIError
>();

function* getAllViewStatsSaga(
  action: ReturnType<typeof getAllViewStats.request>
) {
  try {
    const [viewStats, rcCodeViewStats]: [
      AxiosResponse<ViewStatsResponse>,
      AxiosResponse<RCCodeViewStatsResponse>
    ] = yield all([
      callWrapperSaga(ReportService.fetchViewStats, action.payload),
      callWrapperSaga(ReportService.fetchRCCodeViewStats, action.payload),
    ]);
    yield put(
      getAllViewStats.success({
        viewStatsData: viewStats.data,
        rcCodesViewStatsData: rcCodeViewStats.data,
      })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getAllViewStats.failure({ data, status }));
  }
}

const GET_ALL_CONVERSION_STATS = `report/GET_ALL_CONVERSION_STATS`;
const GET_ALL_CONVERSION_STATS_SUCCESS = `report/GET_ALL_CONVERSION_STATS_SUCCESS`;
const GET_ALL_CONVERSION_STATS_FAILURE = `report/GET_ALL_CONVERSION_STATS_FAILURE`;

export const getAllConversionStats = createAsyncAction(
  GET_ALL_CONVERSION_STATS,
  GET_ALL_CONVERSION_STATS_SUCCESS,
  GET_ALL_CONVERSION_STATS_FAILURE
)<
  ReportRequest,
  {
    conversionStatsData: ConversionStatsResponse;
    rcCodesConversionStatsData: RCCodeConversionStatsResponse;
  },
  APIError
>();

function* getAllConversionStatsSaga(
  action: ReturnType<typeof getAllConversionStats.request>
) {
  try {
    const [conversionStats, rcCodeConversionStats]: [
      AxiosResponse<ConversionStatsResponse>,
      AxiosResponse<RCCodeConversionStatsResponse>
    ] = yield all([
      callWrapperSaga(ReportService.fetchConversionStats, action.payload),
      callWrapperSaga(ReportService.fetchRCCodeConversionStats, action.payload),
    ]);
    yield put(
      getAllConversionStats.success({
        conversionStatsData: conversionStats.data,
        rcCodesConversionStatsData: rcCodeConversionStats.data,
      })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getAllConversionStats.failure({ data, status }));
  }
}

export const GET_ALL_REVENUE_STATS = `report/GET_ALL_REVENUE_STATS`;
export const GET_ALL_REVENUE_STATS_SUCCESS = `report/GET_ALL_REVENUE_STATS_SUCCESS`;
export const GET_ALL_REVENUE_STATS_FAILURE = `report/GET_ALL_REVENUE_STATS_FAILURE`;

const getAllRevenueStats = createAsyncAction(
  GET_ALL_REVENUE_STATS,
  GET_ALL_REVENUE_STATS_SUCCESS,
  GET_ALL_REVENUE_STATS_FAILURE
)<
  ReportRequest,
  {
    revenueStatsData: RevenueStatsResponse;
    rcCodesRevenueStatsData: RCCodeRevenueStatsResponse;
  },
  APIError
>();

function* getAllRevenueStatsSaga(
  action: ReturnType<typeof getAllRevenueStats.request>
) {
  try {
    const [revenueStats, rcCodesRevenueStats]: [
      AxiosResponse<RevenueStatsResponse>,
      AxiosResponse<RCCodeRevenueStatsResponse>
    ] = yield all([
      callWrapperSaga(ReportService.fetchRevenueStats, action.payload),
      callWrapperSaga(ReportService.fetchRCCodeRevenueStats, action.payload),
    ]);
    yield put(
      getAllRevenueStats.success({
        revenueStatsData: revenueStats.data,
        rcCodesRevenueStatsData: rcCodesRevenueStats.data,
      })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getAllRevenueStats.failure({ data, status }));
  }
}

export const GET_DAILY_PERFORMANCE = `report/GET_DAILY_PERFORMANCE`;
export const GET_DAILY_PERFORMANCE_SUCCESS = `report/GET_DAILY_PERFORMANCE_SUCCESS`;
export const GET_DAILY_PERFORMANCE_FAILURE = `report/GET_DAILY_PERFORMANCE_FAILURE`;

const getDailyPerformance = createAsyncAction(
  GET_DAILY_PERFORMANCE,
  GET_DAILY_PERFORMANCE_SUCCESS,
  GET_DAILY_PERFORMANCE_FAILURE
)<ReportRequest, { dailyPerfData: DailyPerformanceResponse }, APIError>();

function* getDailyPerformanceSaga(
  action: ReturnType<typeof getDailyPerformance.request>
) {
  try {
    const { data }: AxiosResponse<DailyPerformanceResponse> =
      yield callWrapperSaga(
        ReportService.fetchDailyPerformance,
        action.payload
      );
    yield put(getDailyPerformance.success({ dailyPerfData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getDailyPerformance.failure({ data, status }));
  }
}

export const GET_DAILY_SECTION = `report/GET_DAILY_SECTION`;
export const GET_DAILY_SECTION_SUCCESS = `report/GET_DAILY_SECTION_SUCCESS`;
export const GET_DAILY_SECTION_FAILURE = `report/GET_DAILY_SECTION_FAILURE`;

const getDailySection = createAsyncAction(
  GET_DAILY_SECTION,
  GET_DAILY_SECTION_SUCCESS,
  GET_DAILY_SECTION_FAILURE
)<ReportRequest, { dailySectionData: DailySectionResponse }, APIError>();

function* getDailySectionSaga(
  action: ReturnType<typeof getDailySection.request>
) {
  try {
    const { data }: AxiosResponse<DailySectionResponse> = yield callWrapperSaga(
      ReportService.fetchDailySection,
      action.payload
    );
    yield put(getDailySection.success({ dailySectionData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getDailySection.failure({ data, status }));
  }
}

export const reportAsyncAction = {
  getAllViewStats,
  getAllConversionStats,
  getAllRevenueStats,
  getDailyPerformance,
  getDailySection,
};

export default function* reportSaga() {
  yield takeEvery(GET_ALL_VIEW_STATS, getAllViewStatsSaga);
  yield takeEvery(GET_ALL_CONVERSION_STATS, getAllConversionStatsSaga);
  yield takeEvery(GET_ALL_REVENUE_STATS, getAllRevenueStatsSaga);
  yield takeEvery(GET_DAILY_PERFORMANCE, getDailyPerformanceSaga);
  yield takeEvery(GET_DAILY_SECTION, getDailySectionSaga);
}
