import Client from "apis";
import { PersonaRequest } from "types/api";

class PersonaDemoService {
  static fetchPersonaResult = async (personaRequest: PersonaRequest) => {
    if (personaRequest?.apiUrl) {
      return await Client.publicInstance.get(`${personaRequest.apiUrl}`);
    }
  };
}

export default PersonaDemoService;
