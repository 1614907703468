import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import type { RtsResultResponse, APIError } from "types/api";
import { RootState } from "store";

import type { RtsState } from "types/rts";

import { rtsDemoAsyncAction } from "store/modules/demo/rts/saga";

export const RTS = "rts";

const initialState: RtsState = {
  rtsResult: {
    loading: false,
    data: {
      nextSeqNum: null,
      lastSeqNum: null,
      firstSeqNum: null,
      querySeg: null,
      size: 0,
      type: "",
      dateTime: null,
      items: [],
    },
    error: null,
  },
  rtsInputs: {
    rtsType: "stream",
    optionType: "view",
    categoryId: "",
    brandId: "",
    withStat: false,
    withRankDiff: true,
    speed: 700,
    interval: 30,
    span: 30,
    offset: 0,
  },
  rtsUrlInfo: {
    schem: "https://",
    host: "rts-api.eigene.io/",
    path: "api/",
    query: "?",
  },
};

const rtsSlice = createSlice({
  name: RTS,
  initialState,
  reducers: {
    changeInputState: (
      state,
      action: PayloadAction<{
        key: string;
        value: any;
      }>
    ) => {
      const { key, value } = action.payload;
      state.rtsInputs[key] = value;
    },
    changeUrlState: (
      state,
      action: PayloadAction<{ key: string[]; value: string[] }>
    ) => {
      const { key, value } = action.payload;
      const [path, query] = key;
      const [pathValue, queryValue] = value;
      state.rtsUrlInfo[path] = pathValue;
      state.rtsUrlInfo[query] = queryValue;
    },
    clear: (state, action: PayloadAction) => {
      state.rtsResult.data = initialState.rtsResult.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(`${rtsDemoAsyncAction.fetchRtsResult.request}`, (state) => {
        state.rtsResult.loading = true;
      })
      .addCase(
        `${rtsDemoAsyncAction.fetchRtsResult.success}`,
        (state, action: PayloadAction<{ rtsResult: RtsResultResponse }>) => {
          state.rtsResult.loading = false;
          state.rtsResult.data = action.payload.rtsResult;
        }
      )
      .addCase(
        `${rtsDemoAsyncAction.fetchRtsResult.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.rtsResult.loading = false;
          state.rtsResult.data = initialState.rtsResult.data;
          state.rtsResult.error = action.payload;
        }
      );
  },
});

const rtsResultSelector = (state: RootState) => state[RTS].rtsResult;
const rtsInputsSelector = (state: RootState) => state[RTS].rtsInputs;
const rtsUrlInfoSelector = (state: RootState) => state[RTS].rtsUrlInfo;

export const RtsResultSelector = {
  loading: createSelector(rtsResultSelector, (rtsResult) => rtsResult.loading),
  data: createSelector(rtsResultSelector, (rtsResult) => rtsResult.data),
  error: createSelector(rtsResultSelector, (rtsResult) => rtsResult.error),
};

export const RtsInputsSelector = {
  inputs: createSelector(rtsInputsSelector, (rtsInputs) => rtsInputs),
};

export const RtsUrlInfoSelector = {
  urlInfo: createSelector(rtsUrlInfoSelector, (rtsUrlInfo) => rtsUrlInfo),
};

export const rtsAction = rtsSlice.actions;
export const rtsReducer = rtsSlice.reducer;
