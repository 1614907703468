import ReactDOM from "react-dom";
import "antd/dist/antd.less";
import { store, persistor } from "store";
import { Provider as StoreProvider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { client } from "apollo/client";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

// import reportWebVitals from "./reportWebVitals";
import { GlobalStyle, GlobalFonts } from "styles";
import { PersistGate } from "redux-persist/integration/react";
import { SuspendFallbackLoading } from "components/common";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import App from "./App";

const themes = {
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
};

const queryClient = new QueryClient();

ReactDOM.render(
  <ThemeSwitcherProvider
    defaultTheme={localStorage.getItem("theme") || "light"}
    themeMap={themes}
    insertionPoint="styles-insertion-point"
  >
    <QueryClientProvider client={queryClient}>
      <StoreProvider store={store}>
        <PersistGate loading={<SuspendFallbackLoading />} persistor={persistor}>
          <GlobalFonts />
          <GlobalStyle />
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </PersistGate>
      </StoreProvider>
    </QueryClientProvider>
  </ThemeSwitcherProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
