import { useComponentId } from "hooks";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  BrandDataSelector,
  CategoryDataSelector,
  ItemDataSelector,
} from "store/modules/service";
import { serviceAsyncAction } from "store/modules/service/saga";
import { BrandList, CategoryList, ItemList } from "types/api";

const useServiceRecExcludeData = () => {
  const componentId = useComponentId();
  const dispatch = useAppDispatch();

  const [brandsDataLoading, brandsData, brandsDataError] = [
    useAppSelector(BrandDataSelector.loading),
    useAppSelector(BrandDataSelector.data),
    useAppSelector(BrandDataSelector.error),
  ];

  const [categoriesDataLoading, categoriesData, categoriesDataError] = [
    useAppSelector(CategoryDataSelector.loading),
    useAppSelector(CategoryDataSelector.data),
    useAppSelector(CategoryDataSelector.error),
  ];

  const [itemsDataLoading, itemsData, itemsDataError] = [
    useAppSelector(ItemDataSelector.loading),
    useAppSelector(ItemDataSelector.data),
    useAppSelector(ItemDataSelector.error),
  ];

  const {
    brandsExcludeTableData,
    categoriesExcludeTableData,
    itemsExcludeTableData,
  } = useMemo(() => {
    const brandsExcludeTableData = _.map(
      brandsData,
      ({ brandId, createdAt, recType }, idx) => {
        return {
          key: `${componentId}-brands-exclude-table-data-${idx}`,
          brandId,
          createdAt,
          recType,
        };
      }
    );

    const categoriesExcludeTableData = _.map(
      categoriesData,
      ({ categoryId, createdAt, recType }, idx) => {
        return {
          key: `${componentId}-categories-exclude-table-data-${idx}`,
          categoryId,
          createdAt,
          recType,
        };
      }
    );

    const itemsExcludeTableData = _.map(
      itemsData,
      ({ itemId, createdAt, recType }, idx) => {
        return {
          key: `${componentId}-items-exclude-table-data-${idx}`,
          itemId,
          createdAt,
          recType,
        };
      }
    );
    return {
      brandsExcludeTableData,
      categoriesExcludeTableData,
      itemsExcludeTableData,
    };
  }, [brandsData, categoriesData, itemsData, componentId]);

  const getIgnoreBrands = useCallback(
    ({ cuid }: { cuid: string }) => {
      dispatch(serviceAsyncAction.getIgnoreBrands.request({ cuid }));
    },
    [dispatch]
  );

  const getIgnoreCategories = useCallback(
    ({ cuid }: { cuid: string }) => {
      dispatch(serviceAsyncAction.getIgnoreCategories.request({ cuid }));
    },
    [dispatch]
  );

  const getIgnoreItems = useCallback(
    ({ cuid }: { cuid: string }) => {
      dispatch(serviceAsyncAction.getIgnoreItems.request({ cuid }));
    },
    [dispatch]
  );

  const postIgnoreBrands = useCallback(
    ({ cuid, brandList }: { cuid: string; brandList: BrandList }) => {
      dispatch(
        serviceAsyncAction.postIgnoreBrands.request({ cuid, brandList })
      );
    },
    [dispatch]
  );

  const postIgnoreCategories = useCallback(
    ({ cuid, categoryList }: { cuid: string; categoryList: CategoryList }) => {
      dispatch(
        serviceAsyncAction.postIgnoreCategories.request({ cuid, categoryList })
      );
    },
    [dispatch]
  );

  const postIgnoreItems = useCallback(
    ({ cuid, itemList }: { cuid: string; itemList: ItemList }) => {
      dispatch(serviceAsyncAction.postIgnoreItems.request({ cuid, itemList }));
    },
    [dispatch]
  );

  return {
    brandsExcludeTableData,
    categoriesExcludeTableData,
    itemsExcludeTableData,
    brandsDataLoading,
    categoriesDataLoading,
    itemsDataLoading,
    getIgnoreBrands,
    getIgnoreCategories,
    getIgnoreItems,
    postIgnoreBrands,
    postIgnoreCategories,
    postIgnoreItems,
  };
};
export default useServiceRecExcludeData;
