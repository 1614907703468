import { FC } from "react";
import { Spin } from "antd";
import * as S from "components/common/SuspendFallbackLoading/style";

interface SuspendFallbackLoadingProps {}

const SuspendFallbackLoading: FC<SuspendFallbackLoadingProps> = () => {
  return (
    <S.SuspendedFallbackLoadingContainer>
      <Spin tip="Loading..." />
    </S.SuspendedFallbackLoadingContainer>
  );
};
export default SuspendFallbackLoading;
