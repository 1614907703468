import styled from "styled-components";
import { Layout } from "antd";
const { Content } = Layout;

const LayoutPageContainer = styled(Layout)`
  height: 100%;
`;

const LayoutPageSiderAndContentContainer = styled(Layout)``;

const LayoutPageContent = styled(Content)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export {
  LayoutPageContainer,
  LayoutPageSiderAndContentContainer,
  LayoutPageContent,
};
