import Client from "apis";
import type { ManageRequest } from "types/api";

class ManageService {
  static async fetchAllCustomers(manageRequest: ManageRequest) {
    return await Client.credentialsInstance.get(`${Client.path.api}/customer`);
  }
  static async fetchCustomer(manageRequest: ManageRequest) {
    return await Client.credentialsInstance.get(
      `${Client.path.api}/customer/${manageRequest.customerId}`
    );
  }
  static async fetchAllSites(manageRequest: ManageRequest) {
    return await Client.credentialsInstance.get(`${Client.path.api}/site`);
  }
  static async fetchSite(manageRequest: ManageRequest) {
    return await Client.credentialsInstance.get(
      `${Client.path.api}/site/${manageRequest.cuid}`
    );
  }
  static async fetchAllAccount(manageRequest: ManageRequest) {
    return await Client.credentialsInstance.get(`${Client.path.api}/user`);
  }
  static async fetchAccount(manageRequest: ManageRequest) {
    return await Client.credentialsInstance.get(
      `${Client.path.api}/user/${manageRequest.accountId}`
    );
  }
  static async fetchSitesOfCustomer(manageRequest: ManageRequest) {
    return await Client.credentialsInstance.get(
      `${Client.path.api}/customer/${manageRequest.customerId}/site`
    );
  }
  static async fetchSitesOfAccount(manageRequest: ManageRequest) {
    return await Client.credentialsInstance.get(
      `${Client.path.api}/account/${manageRequest.accountId}/site`
    );
  }
  static async fetchAllLogCollectStatus(manageRequest: ManageRequest) {
    return await Client.credentialsInstance.get(
      `${Client.path.api}/log-collect-status/total`
    );
  }
  static async fetchLogCollectStatusOfSite(manageRequest: ManageRequest) {
    return await Client.credentialsInstance.get(
      `${Client.path.api}/log-collect-status/total?cuid=${manageRequest.cuid}`
    );
  }
  static async fetchReportStatusOfSite(manageRequest: ManageRequest) {
    return await Client.credentialsInstance.get(
      `${Client.path.api}/log-collect-status/sites/${manageRequest.cuid}`
    );
  }
  static async fetchAllTasks(manageRequest: ManageRequest) {
    return await Client.credentialsInstance.get(`${Client.path.api}/task`);
  }
  static async fetchLogGuide(manageRequest: ManageRequest) {
    return await Client.credentialsInstance.get(
      `${Client.path.api}/logger-guide/${manageRequest.cuid}`
    );
  }
  static async putLogGuide(manageRequest: ManageRequest) {
    const { ...rest } = manageRequest;
    return await Client.credentialsInstance.put(
      `${Client.path.api}/logger-guide`,
      {
        ...rest,
      }
    );
  }

  static async postLogGuide(manageRequest: ManageRequest) {
    const { ...rest } = manageRequest;
    return await Client.credentialsInstance.post(
      `${Client.path.api}/logger-guide`,
      {
        ...rest,
      }
    );
  }

  static async addCustomer(manageRequest: ManageRequest) {
    const { customerName, ...rest } = manageRequest;
    return await Client.credentialsInstance.post(
      `${Client.path.api}/customer`,
      {
        ...rest,
        name: customerName,
      }
    );
  }

  static async putCustomer(manageRequest: ManageRequest) {
    const { customerId, customerName, ...rest } = manageRequest;
    return await Client.credentialsInstance.put(
      `${Client.path.api}/customer/${customerId}`,
      {
        ...rest,
        id: customerId,
        name: customerName,
      }
    );
  }

  static async addSite(manageRequest: ManageRequest) {
    const { siteName, ...rest } = manageRequest;
    return await Client.credentialsInstance.post(`${Client.path.api}/site`, {
      ...rest,
      name: siteName,
    });
  }

  static async putSite(manageRequest: ManageRequest) {
    const { cuid, siteName, ...rest } = manageRequest;
    return await Client.credentialsInstance.put(
      `${Client.path.api}/site/${cuid}`,
      {
        ...rest,
        cuid,
        name: siteName,
      }
    );
  }

  static async addAccount(manageRequest: ManageRequest) {
    const { userName, ...rest } = manageRequest;
    return await Client.credentialsInstance.post(`${Client.path.auth}/signUp`, {
      ...rest,
      name: userName,
    });
  }

  static async putAccount(manageRequest: ManageRequest) {
    const { accountId, userName, ...rest } = manageRequest;
    return await Client.credentialsInstance.put(
      `${Client.path.api}/user/${accountId}`,
      {
        ...rest,
        id: accountId,
        name: userName,
      }
    );
  }
}
export default ManageService;
