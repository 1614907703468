import { useAppSelector, useAppDispatch } from "store";
import { useCallback } from "react";
import {
  channelSelector,
  unitSelector,
  dateSelector,
  reportAction,
} from "store/modules/report";
import { Channel, Unit } from "types/report";

const useReportData = () => {
  const dispatch = useAppDispatch();

  const [channel, unit, { startDate, endDate }] = [
    useAppSelector(channelSelector),
    useAppSelector(unitSelector),
    useAppSelector(dateSelector),
  ];

  const setChannel = useCallback(
    ({ channel }: { channel: Channel }) => {
      dispatch(reportAction.setChannel({ channel }));
    },
    [dispatch]
  );

  const setUnit = useCallback(
    ({ unit }: { unit: Unit }) => {
      dispatch(reportAction.setUnit({ unit }));
    },
    [dispatch]
  );

  const setDate = useCallback(
    ({
      startDate,
      endDate,
    }: Partial<{ startDate: string; endDate: string }>) => {
      dispatch(
        reportAction.setDate({
          ...(startDate && { startDate }),
          ...(endDate && { endDate }),
        })
      );
    },
    [dispatch]
  );

  return {
    channel,
    unit,
    startDate,
    endDate,
    setChannel,
    setUnit,
    setDate,
  };
};

export default useReportData;
