import { useComponentId, useManageData } from "hooks";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { AccountsDataSelector } from "store/modules/manage";
import { manageAsyncAction } from "store/modules/manage/saga";
import { MANAGE_STATUS } from "assets/constants/string";

const useManageAccountData = () => {
  const componentId = useComponentId();
  const { status, search } = useManageData();
  const dispatch = useAppDispatch();

  const [accountsDataLoading, accountsData, accountsDataError] = [
    useAppSelector(AccountsDataSelector.loading),
    useAppSelector(AccountsDataSelector.data),
    useAppSelector(AccountsDataSelector.error),
  ];

  const getAllAccounts = useCallback(() => {
    dispatch(manageAsyncAction.getAllAccounts.request({}));
  }, [dispatch]);

  const getAccount = useCallback(
    ({ accountId }: { accountId: number }) => {
      dispatch(manageAsyncAction.getAccount.request({ accountId }));
    },
    [dispatch]
  );

  const addAccount = useCallback(
    ({
      customerId,
      login,
      userName,
      password,
      email,
      cellPhone,
      authority,
      langKey,
      activated,
      onSuccess,
      onFailure,
    }: {
      customerId: number;
      login: string;
      userName: string;
      password: string;
      email: string;
      cellPhone: string;
      authority: string;
      langKey: string;
      activated: boolean;
      onSuccess: () => void;
      onFailure: () => void;
    }) => {
      dispatch(
        manageAsyncAction.addAccount.request({
          customerId,
          login,
          userName,
          password,
          email,
          cellPhone,
          authority,
          langKey,
          activated,
          onSuccess,
          onFailure,
        })
      );
    },
    [dispatch]
  );

  const setAccount = useCallback(
    (values: {
      accountId: number;
      customerId: number;
      password?: string;
      activated: boolean;
      cellPhone: string;
      email: string;
      userName: string;
      langKey: string;
      authority: string;
      onSuccess: () => void;
      onFailure: () => void;
    }) => {
      dispatch(
        manageAsyncAction.setAccount.request({
          ...values,
        })
      );
    },
    [dispatch]
  );

  const { accountsTableData, accountDetailFormData } = useMemo(() => {
    const accountsTableOriginalData = _.map(
      accountsData,
      (
        { activated, authority, id, createdDate, customer, login, name },
        idx
      ) => ({
        idx,
        key: `${componentId}-accounts-table-data-${idx}`,
        authority,
        activated,
        accountId: id,
        createdDate,
        customerName: customer.name,
        loginId: login,
        customerManagerName: name,
      })
    );

    const accountsTableData = _.filter(
      _.filter(accountsTableOriginalData, ({ activated }) => {
        if (status === MANAGE_STATUS.ALL) return true;
        if (status === MANAGE_STATUS.ENABLE) return activated;
        if (status === MANAGE_STATUS.DISABLE) return !activated;
      }),
      ({
        customerName,
        customerManagerName,
      }: {
        customerName: string;
        customerManagerName: string;
      }) => {
        return (
          _.includes(customerName, search) ||
          _.includes(customerManagerName, search)
        );
      }
    );

    const accountDetailFormData = _.head(
      _.map(
        accountsData,
        ({
          name: userName,
          activated,
          authority,
          email,
          langKey,
          cellPhone,
          customer: { id, address, name, maxAccount, numUsers },
          login,
        }) => ({
          userName,
          activated,
          authority,
          email,
          langKey,
          cellPhone,
          customerId: id,
          address,
          accountsCount: `${numUsers} / ${maxAccount}`,
          customerName: name,
          login,
        })
      )
    );

    return { accountsTableData, accountDetailFormData };
  }, [accountsData, componentId, search, status]);

  return {
    accountsDataLoading,
    accountsTableData,
    accountDetailFormData,
    getAllAccounts,
    getAccount,
    setAccount,
    addAccount,
  };
};
export default useManageAccountData;
