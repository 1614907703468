import { FC, useState, useCallback, useEffect, Key, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAppDispatch } from "store";
import { userAction } from "store/modules/user";
import type { MenuList } from "types/menu";
import getMenuListByAuthority, { iconMap } from "assets/menu";
import * as S from "components/layout/sider/LayoutPageSiderMenu/style";
import { DESKTOP } from "assets/constants/string";
import { useUserData } from "hooks";
import { useIntl } from "react-intl";
import { overflow } from "html2canvas/dist/types/css/property-descriptors/overflow";

type TitleMenu = MenuList[0];

const LayoutPageSiderMenu: FC = () => {
  const { formatMessage } = useIntl();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const { collapsed, device, authority } = useUserData();

  const menuList = useMemo(
    () => getMenuListByAuthority(authority),
    [authority]
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getTitleMenu = useCallback(
    (menu: TitleMenu) => {
      const IconComponent = iconMap[menu.icon!];
      return (
        <span style={{ display: "flex", alignItems: "center" }}>
          <IconComponent />
          <span>
            {formatMessage({ id: `Components.Layout.Sider.Menu.${menu.name}` })}
          </span>
        </span>
      );
    },
    [formatMessage]
  );

  const onMenuClick = useCallback(
    (menu: TitleMenu) => {
      if (menu.path === pathname) return;
      const { key, path } = menu;

      setSelectedKeys([key]);
      if (device !== DESKTOP) {
        dispatch(userAction.setUserItem({ collapsed: true }));
      }
      navigate(path);
    },
    [pathname, device, navigate, dispatch]
  );

  const onOpenChange = useCallback((keys: any) => {
    setOpenKeys(keys);
  }, []);

  useEffect(() => {
    const paths = pathname.split("/").filter((v) => v);
    const [titlePath, subPath, param, subPath3] = paths;
    console.log(subPath3);
    setSelectedKeys([
      subPath3
        ? `/${titlePath}/${subPath}/${param}/${subPath3}`
        : param
        ? `/${titlePath}/${subPath}`
        : pathname,
    ]);

    // setOpenKeys(collapsed ? [] : [`/${titlePath}`]);
    setOpenKeys(
      subPath3
        ? [
            `/${titlePath}`,
            `/${titlePath}/${subPath}`,
            `/${titlePath}/${subPath}/${param}`,
            `/${titlePath}/${subPath}/${param}/${subPath3}`,
          ]
        : subPath
        ? [`/${titlePath}`, `/${titlePath}/${subPath}`]
        : [`/${titlePath}`]
    );
  }, [collapsed, pathname]);

  return (
    <S.LayoutPageSiderMenu
      style={{ overflowY: "scroll", overflowX: "hidden" }}
      mode="inline"
      theme="light"
      selectedKeys={selectedKeys}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
    >
      {menuList.map((menu) =>
        menu.children ? (
          <S.LayoutPageSiderSubMenu key={menu.path} title={getTitleMenu(menu)}>
            {menu.children.map((child) =>
              child.children ? (
                <S.LayoutPageSiderSubMenu
                  key={child.path}
                  title={"큐봇 성과"}
                  // onTitleClick={(e) => onMenuClick(child)}
                >
                  {child.children.map((subMenu) => {
                    return subMenu.children ? (
                      <S.LayoutPageSiderSubMenu
                        key={subMenu.path}
                        title={formatMessage({
                          id: `Components.Layout.Sider.Menu${subMenu.name.replace(
                            /\//g,
                            "."
                          )}`,
                        })}
                        //   onTitleClick={(e) => onMenuClick(subMenu)}
                      >
                        {subMenu.children.map((cihld2) => {
                          return (
                            <S.LayoutPageSiderMenuItem
                              key={cihld2.path}
                              onClick={() => onMenuClick(cihld2)}
                            >
                              {formatMessage({
                                id: `Components.Layout.Sider.Menu${cihld2.name.replace(
                                  /\//g,
                                  "."
                                )}`,
                              })}
                            </S.LayoutPageSiderMenuItem>
                          );
                        })}
                      </S.LayoutPageSiderSubMenu>
                    ) : (
                      <S.LayoutPageSiderMenuItem
                        key={subMenu.path}
                        title={"test2"}
                        onClick={() => onMenuClick(subMenu)}
                      >
                        FAQ 성과
                      </S.LayoutPageSiderMenuItem>
                    );
                  })}
                </S.LayoutPageSiderSubMenu>
              ) : (
                <S.LayoutPageSiderMenuItem
                  key={child.path}
                  onClick={() => onMenuClick(child)}
                >
                  {formatMessage({
                    id: `Components.Layout.Sider.Menu.${child.name}`,
                  })}
                </S.LayoutPageSiderMenuItem>
              )
            )}
          </S.LayoutPageSiderSubMenu>
        ) : (
          <S.LayoutPageSiderMenuItem
            key={menu.path}
            onClick={() => onMenuClick(menu)}
          >
            {getTitleMenu(menu)}
          </S.LayoutPageSiderMenuItem>
        )
      )}
    </S.LayoutPageSiderMenu>
  );
};

export default LayoutPageSiderMenu;
