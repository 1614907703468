import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { MANAGE_STATUS } from "assets/constants/string";
import type { RootState } from "store";

import {
  AccountResponse,
  AccountsResponse,
  APIError,
  CustomerResponse,
  CustomersResponse,
  SiteResponse,
  SitesResponse,
} from "types/api";
import { ManageState, Status } from "types/manage";
import { manageAsyncAction } from "./saga";
import _ from "lodash";
const { ALL } = MANAGE_STATUS;
export const MANAGE = "manage";

const initialState: ManageState = {
  search: "",
  status: ALL,
  sites: {
    loading: false,
    data: null,
    error: null,
  },
  accounts: {
    loading: false,
    data: null,
    error: null,
  },
  customers: {
    loading: false,
    data: null,
    error: null,
    current: "",
  },
};

const manageSlice = createSlice({
  name: MANAGE,
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<{ search: string }>) {
      state.search = action.payload.search;
    },
    setStatus(state, action: PayloadAction<{ status: Status }>) {
      state.status = action.payload.status;
    },
    setCurrentCustomer(state, action: PayloadAction<string>) {
      state.customers.current = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(`${manageAsyncAction.getAllSites.request}`, (state) => {
        state.sites.loading = true;
      })
      .addCase(
        `${manageAsyncAction.getAllSites.success}`,
        (state, action: PayloadAction<{ sitesData: SitesResponse }>) => {
          state.sites.loading = false;
          state.sites.data = action.payload.sitesData;
        }
      )
      .addCase(
        `${manageAsyncAction.getAllSites.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.sites.loading = false;
          state.sites.error = action.payload;
        }
      )
      .addCase(`${manageAsyncAction.getAllAccounts.request}`, (state) => {
        state.accounts.loading = true;
      })
      .addCase(
        `${manageAsyncAction.getAllAccounts.success}`,
        (state, action: PayloadAction<{ accountsData: AccountsResponse }>) => {
          state.accounts.loading = false;
          state.accounts.data = action.payload.accountsData;
        }
      )
      .addCase(
        `${manageAsyncAction.getAllAccounts.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.accounts.loading = false;
          state.accounts.error = action.payload;
        }
      )
      .addCase(`${manageAsyncAction.getAllCustomers.request}`, (state) => {
        state.customers.loading = true;
      })
      .addCase(
        `${manageAsyncAction.getAllCustomers.success}`,
        (
          state,
          action: PayloadAction<{ customersData: CustomersResponse }>
        ) => {
          state.customers.loading = false;
          state.customers.data = action.payload.customersData;
        }
      )
      .addCase(
        `${manageAsyncAction.getAllCustomers.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.customers.loading = false;
          state.customers.error = action.payload;
        }
      )
      .addCase(`${manageAsyncAction.getCustomerWithSites.request}`, (state) => {
        state.customers.loading = true;
        state.sites.loading = true;
      })
      .addCase(
        `${manageAsyncAction.getCustomerWithSites.success}`,
        (
          state,
          action: PayloadAction<{
            customerData: CustomerResponse;
            sitesData: SitesResponse;
          }>
        ) => {
          state.customers.loading = false;
          state.sites.loading = false;
          state.customers.data = _.castArray(action.payload.customerData);
          state.sites.data = action.payload.sitesData;
        }
      )
      .addCase(
        `${manageAsyncAction.getCustomerWithSites.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.customers.loading = false;
          state.sites.loading = false;
          state.customers.error = action.payload;
          state.sites.error = action.payload;
        }
      )
      .addCase(`${manageAsyncAction.addCustomer.request}`, (state) => {
        state.customers.loading = true;
      })
      .addCase(`${manageAsyncAction.addCustomer.success}`, (state) => {
        state.customers.loading = false;
        state.customers.data = null;
      })
      .addCase(
        `${manageAsyncAction.addCustomer.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.customers.loading = false;
          state.customers.error = action.payload;
        }
      )
      .addCase(`${manageAsyncAction.setCustomer.request}`, (state) => {
        state.customers.loading = true;
      })
      .addCase(
        `${manageAsyncAction.setCustomer.success}`,
        (state, action: PayloadAction<{ customerData: CustomerResponse }>) => {
          state.customers.loading = false;
          state.customers.data = _.castArray(action.payload.customerData);
        }
      )
      .addCase(
        `${manageAsyncAction.setCustomer.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.customers.loading = false;
          state.customers.error = action.payload;
        }
      )
      .addCase(`${manageAsyncAction.getAccount.request}`, (state) => {
        state.accounts.loading = true;
      })
      .addCase(
        `${manageAsyncAction.getAccount.success}`,
        (state, action: PayloadAction<{ accountData: AccountResponse }>) => {
          state.accounts.loading = false;
          state.accounts.data = _.castArray(action.payload.accountData);
        }
      )
      .addCase(
        `${manageAsyncAction.getAccount.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.accounts.loading = false;
          state.accounts.error = action.payload;
        }
      )
      .addCase(`${manageAsyncAction.addAccount.request}`, (state) => {
        state.accounts.loading = true;
      })
      .addCase(`${manageAsyncAction.addAccount.success}`, (state) => {
        state.accounts.loading = false;
        state.accounts.data = null;
      })
      .addCase(
        `${manageAsyncAction.addAccount.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.accounts.loading = false;
          state.accounts.error = action.payload;
        }
      )
      .addCase(`${manageAsyncAction.setAccount.request}`, (state) => {
        state.accounts.loading = true;
      })
      .addCase(
        `${manageAsyncAction.setAccount.success}`,
        (state, action: PayloadAction<{ accountData: AccountResponse }>) => {
          state.accounts.loading = false;
          state.accounts.data = _.castArray(action.payload.accountData);
        }
      )
      .addCase(
        `${manageAsyncAction.setAccount.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.accounts.loading = false;
          state.accounts.error = action.payload;
        }
      )
      .addCase(`${manageAsyncAction.getSite.request}`, (state) => {
        state.sites.loading = true;
      })
      .addCase(
        `${manageAsyncAction.getSite.success}`,
        (state, action: PayloadAction<{ siteData: SiteResponse }>) => {
          state.sites.loading = false;
          state.sites.data = _.castArray(action.payload.siteData);
        }
      )
      .addCase(
        `${manageAsyncAction.getSite.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.sites.loading = false;
          state.sites.error = action.payload;
        }
      )
      .addCase(`${manageAsyncAction.addSite.request}`, (state) => {
        state.sites.loading = true;
      })
      .addCase(`${manageAsyncAction.addSite.success}`, (state) => {
        state.sites.loading = false;
        state.sites.data = null;
      })
      .addCase(
        `${manageAsyncAction.addSite.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.sites.loading = false;
          state.sites.error = action.payload;
        }
      )
      .addCase(`${manageAsyncAction.setSite.request}`, (state) => {
        state.sites.loading = true;
      })
      .addCase(
        `${manageAsyncAction.setSite.success}`,
        (state, action: PayloadAction<{ siteData: SiteResponse }>) => {
          state.sites.loading = false;
          state.sites.data = _.castArray(action.payload.siteData);
        }
      )
      .addCase(
        `${manageAsyncAction.setSite.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.sites.loading = false;
          state.sites.error = action.payload;
        }
      ),
});

const selfSelector = (state: RootState) => state[MANAGE];
const sitesSelector = createSelector(selfSelector, (state) => state.sites);
const accountsSelector = createSelector(
  selfSelector,
  (state) => state.accounts
);
const customersSelector = createSelector(
  selfSelector,
  (state) => state.customers
);
export const statusSelector = createSelector(
  selfSelector,
  (state) => state.status
);
export const searchSelector = createSelector(
  selfSelector,
  (state) => state.search
);

export const currentCustomerSelector = createSelector(
  customersSelector,
  (customers) => customers.current
);

export const SitesDataSelector = {
  loading: createSelector(sitesSelector, (sites) => sites.loading),
  data: createSelector(sitesSelector, (sites) => sites.data),
  error: createSelector(sitesSelector, (sites) => sites.error),
};

export const AccountsDataSelector = {
  loading: createSelector(accountsSelector, (accounts) => accounts.loading),
  data: createSelector(accountsSelector, (accounts) => accounts.data),
  error: createSelector(accountsSelector, (accounts) => accounts.error),
};

export const CustomersDataSelector = {
  loading: createSelector(customersSelector, (customers) => customers.loading),
  data: createSelector(customersSelector, (customers) => customers.data),
  error: createSelector(customersSelector, (customers) => customers.error),
};

export const manageAction = manageSlice.actions;
export const manageReducer = manageSlice.reducer;
