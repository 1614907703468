import { createAsyncAction } from "typesafe-actions";
import { ManageService } from "apis/services";
import { AxiosResponse } from "axios";
import { callWrapperSaga } from "utils/callWrapperSaga";
import {
  APIError,
  ManageRequest,
  SiteResponse,
  SitesResponse,
  CustomerResponse,
  CustomersResponse,
  AccountsResponse,
  AccountResponse,
} from "types/api";
import { put, all, takeEvery, takeLatest } from "@redux-saga/core/effects";
import { fork } from "redux-saga/effects";

export const GET_ALL_SITES = `manage/GET_ALL_SITES`;
export const GET_ALL_SITES_SUCCESS = `manage/GET_ALL_SITES_SUCCESS`;
export const GET_ALL_SITES_FAILURE = `manage/GET_ALL_SITES_FAILURE`;

export const getAllSites = createAsyncAction(
  GET_ALL_SITES,
  GET_ALL_SITES_SUCCESS,
  GET_ALL_SITES_FAILURE
)<ManageRequest, { sitesData: SitesResponse }, APIError>();

function* getAllSitesSaga(action: ReturnType<typeof getAllSites.request>) {
  try {
    const { data }: AxiosResponse<SitesResponse> = yield callWrapperSaga(
      ManageService.fetchAllSites,
      action.payload
    );
    yield put(getAllSites.success({ sitesData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getAllSites.failure({ data, status }));
  }
}

export const GET_ALL_CUSTOMERS = `manage/GET_ALL_CUSTOMERS`;
export const GET_ALL_CUSTOMERS_SUCCESS = `manage/GET_ALL_CUSTOMERS_SUCCESS`;
export const GET_ALL_CUSTOMERS_FAILURE = `manage/GET_ALL_CUSTOMERS_FAILURE`;

export const getAllCustomers = createAsyncAction(
  GET_ALL_CUSTOMERS,
  GET_ALL_CUSTOMERS_SUCCESS,
  GET_ALL_CUSTOMERS_FAILURE
)<ManageRequest, { customersData: CustomersResponse }, APIError>();

function* getAllCustomersSaga(
  action: ReturnType<typeof getAllCustomers.request>
) {
  try {
    const { data }: AxiosResponse<CustomersResponse> = yield callWrapperSaga(
      ManageService.fetchAllCustomers,
      action.payload
    );
    yield put(getAllCustomers.success({ customersData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getAllCustomers.failure({ data, status }));
  }
}

export const GET_ALL_ACCOUNTS = `manage/GET_ALL_ACCOUNTS`;
export const GET_ALL_ACCOUNTS_SUCCESS = `manage/GET_ALL_ACCOUNTS_SUCCESS`;
export const GET_ALL_ACCOUNTS_FAILURE = `manage/GET_ALL_ACCOUNTS_FAILURE`;

export const getAllAccounts = createAsyncAction(
  GET_ALL_ACCOUNTS,
  GET_ALL_ACCOUNTS_SUCCESS,
  GET_ALL_ACCOUNTS_FAILURE
)<ManageRequest, { accountsData: AccountsResponse }, APIError>();

function* getAllAccountsSaga(
  action: ReturnType<typeof getAllAccounts.request>
) {
  try {
    const { data }: AxiosResponse<AccountsResponse> = yield callWrapperSaga(
      ManageService.fetchAllAccount,
      action.payload
    );
    yield put(getAllAccounts.success({ accountsData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getAllAccounts.failure({ data, status }));
  }
}

export const GET_CUSTOMER_WITH_SITES = `manage/GET_CUSTOMER_WITH_SITES`;
export const GET_CUSTOMER_WITH_SITES_SUCCESS = `manage/GET_CUSTOMER_WITH_SITES_SUCCESS`;
export const GET_CUSTOMER_WITH_SITES_FAILURE = `manage/GET_CUSTOMER_WITH_SITES_FAILURE`;

export const getCustomerWithSites = createAsyncAction(
  GET_CUSTOMER_WITH_SITES,
  GET_CUSTOMER_WITH_SITES_SUCCESS,
  GET_CUSTOMER_WITH_SITES_FAILURE
)<
  ManageRequest,
  { customerData: CustomerResponse; sitesData: SitesResponse },
  APIError
>();

function* getCustomerWithSitesSaga(
  action: ReturnType<typeof getCustomerWithSites.request>
) {
  try {
    const [{ data: customerData }, { data: sitesData }] = yield all([
      callWrapperSaga(ManageService.fetchCustomer, action.payload),
      callWrapperSaga(ManageService.fetchSitesOfCustomer, action.payload),
    ]);
    yield put(getCustomerWithSites.success({ customerData, sitesData }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getCustomerWithSites.failure({ data, status }));
  }
}

export const ADD_CUSTOMER = `manage/ADD_CUSTOMER`;
export const ADD_CUSTOMER_SUCCESS = `manage/ADD_CUSTOMER_SUCCESS`;
export const ADD_CUSTOMER_FAILURE = `manage/ADD_CUSTOMER_FAILURE`;

export const addCustomer = createAsyncAction(
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAILURE
)<
  ManageRequest & { onSuccess: () => void; onFailure: () => void },
  void,
  APIError
>();

function* addCustomerSaga(action: ReturnType<typeof addCustomer.request>) {
  const { onSuccess, onFailure, ...rest } = action.payload;
  try {
    yield callWrapperSaga(ManageService.addCustomer, { ...rest });

    yield put(addCustomer.success());
    yield fork(onSuccess);
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(addCustomer.failure({ data, status }));
    yield fork(onFailure);
  }
}

export const SET_CUSTOMER = `manage/SET_CUSTOMER`;
export const SET_CUSTOMER_SUCCESS = `manage/SET_CUSTOMER_SUCCESS`;
export const SET_CUSTOMER_FAILURE = `manage/SET_CUSTOMER_FAILURE`;

export const setCustomer = createAsyncAction(
  SET_CUSTOMER,
  SET_CUSTOMER_SUCCESS,
  SET_CUSTOMER_FAILURE
)<
  ManageRequest & { onSuccess: () => void; onFailure: () => void },
  { customerData: CustomerResponse },
  APIError
>();

function* setCustomerSaga(action: ReturnType<typeof setCustomer.request>) {
  const { onSuccess, onFailure, ...rest } = action.payload;
  try {
    yield callWrapperSaga(ManageService.putCustomer, { ...rest });
    const { data }: AxiosResponse<CustomerResponse> = yield callWrapperSaga(
      ManageService.fetchCustomer,
      { ...rest }
    );
    yield put(setCustomer.success({ customerData: data }));
    yield fork(onSuccess);
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(setCustomer.failure({ data, status }));
    yield fork(onFailure);
  }
}

export const GET_SITE = `manage/GET_SITE`;
export const GET_SITE_SUCCESS = `manage/GET_SITE_SUCCESS`;
export const GET_SITE_FAILURE = `manage/GET_SITE_FAILURE`;

export const getSite = createAsyncAction(
  GET_SITE,
  GET_SITE_SUCCESS,
  GET_SITE_FAILURE
)<ManageRequest, { siteData: SiteResponse }, APIError>();

function* getSiteSaga(action: ReturnType<typeof getSite.request>) {
  try {
    const { data }: AxiosResponse<SiteResponse> = yield callWrapperSaga(
      ManageService.fetchSite,
      action.payload
    );
    yield put(getSite.success({ siteData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getSite.failure({ data, status }));
  }
}

export const ADD_SITE = `manage/ADD_SITE`;
export const ADD_SITE_SUCCESS = `manage/ADD_SITE_SUCCESS`;
export const ADD_SITE_FAILURE = `manage/ADD_SITE_FAILURE`;

export const addSite = createAsyncAction(
  ADD_SITE,
  ADD_SITE_SUCCESS,
  ADD_SITE_FAILURE
)<
  ManageRequest & { onSuccess: () => void; onFailure: () => void },
  void,
  APIError
>();

function* addSiteSaga(action: ReturnType<typeof addSite.request>) {
  const { onSuccess, onFailure, ...rest } = action.payload;
  try {
    yield callWrapperSaga(ManageService.addSite, { ...rest });

    yield put(addSite.success());
    yield fork(onSuccess);
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(addSite.failure({ data, status }));
    yield fork(onFailure);
  }
}

export const SET_SITE = `manage/SET_SITE`;
export const SET_SITE_SUCCESS = `manage/SET_SITE_SUCCESS`;
export const SET_SITE_FAILURE = `manage/SET_SITE_FAILURE`;

export const setSite = createAsyncAction(
  SET_SITE,
  SET_SITE_SUCCESS,
  SET_SITE_FAILURE
)<
  ManageRequest & { onSuccess: () => void; onFailure: () => void },
  { siteData: SiteResponse },
  APIError
>();

function* setSiteSaga(action: ReturnType<typeof setSite.request>) {
  const { onSuccess, onFailure, ...rest } = action.payload;
  try {
    yield callWrapperSaga(ManageService.putSite, { ...rest });
    const { data }: AxiosResponse<SiteResponse> = yield callWrapperSaga(
      ManageService.fetchSite,
      { ...rest }
    );
    yield put(setSite.success({ siteData: data }));
    yield fork(onSuccess);
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(setSite.failure({ data, status }));
    yield fork(onFailure);
  }
}

export const GET_ACCOUNT = `manage/GET_ACCOUNT`;
export const GET_ACCOUNT_SUCCESS = `manage/GET_ACCOUNT_SUCCESS`;
export const GET_ACCOUNT_FAILURE = `manage/GET_ACCOUNT_FAILURE`;

export const getAccount = createAsyncAction(
  GET_ACCOUNT,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILURE
)<ManageRequest, { accountData: AccountResponse }, APIError>();

function* getAccountSaga(action: ReturnType<typeof getAccount.request>) {
  try {
    const { data }: AxiosResponse<AccountResponse> = yield callWrapperSaga(
      ManageService.fetchAccount,
      action.payload
    );
    yield put(getAccount.success({ accountData: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(getAccount.failure({ data, status }));
  }
}

export const ADD_ACCOUNT = `manage/ADD_ACCOUNT`;
export const ADD_ACCOUNT_SUCCESS = `manage/ADD_ACCOUNT_SUCCESS`;
export const ADD_ACCOUNT_FAILURE = `manage/ADD_ACCOUNT_FAILURE`;

export const addAccount = createAsyncAction(
  ADD_ACCOUNT,
  ADD_ACCOUNT_SUCCESS,
  ADD_ACCOUNT_FAILURE
)<
  ManageRequest & { onSuccess: () => void; onFailure: () => void },
  void,
  APIError
>();

function* addAccountSaga(action: ReturnType<typeof addAccount.request>) {
  const { onSuccess, onFailure, ...rest } = action.payload;
  try {
    yield callWrapperSaga(ManageService.addAccount, { ...rest });

    yield put(addAccount.success());
    yield fork(onSuccess);
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(addAccount.failure({ data, status }));
    yield fork(onFailure);
  }
}

export const SET_ACCOUNT = `manage/SET_ACCOUNT`;
export const SET_ACCOUNT_SUCCESS = `manage/SET_ACCOUNT_SUCCESS`;
export const SET_ACCOUNT_FAILURE = `manage/SET_ACCOUNT_FAILURE`;

export const setAccount = createAsyncAction(
  SET_ACCOUNT,
  SET_ACCOUNT_SUCCESS,
  SET_ACCOUNT_FAILURE
)<
  ManageRequest & { onSuccess: () => void; onFailure: () => void },
  { accountData: AccountResponse },
  APIError
>();

function* setAccountSaga(action: ReturnType<typeof setAccount.request>) {
  const { onSuccess, onFailure, accountId, ...rest } = action.payload;
  try {
    yield callWrapperSaga(ManageService.putAccount, { ...rest, accountId });
    const { data }: AxiosResponse<AccountResponse> = yield callWrapperSaga(
      ManageService.fetchAccount,
      {
        accountId,
      }
    );
    yield put(setAccount.success({ accountData: data }));
    yield fork(onSuccess);
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(setAccount.failure({ data, status }));
    yield fork(onFailure);
  }
}

export const manageAsyncAction = {
  getAllSites,
  getAllAccounts,
  getAllCustomers,
  getCustomerWithSites,
  getAccount,
  getSite,
  addCustomer,
  setCustomer,
  addSite,
  setSite,
  addAccount,
  setAccount,
};

export default function* manageSaga() {
  yield takeEvery(GET_ALL_SITES, getAllSitesSaga);
  yield takeEvery(GET_ALL_ACCOUNTS, getAllAccountsSaga);
  yield takeEvery(GET_ALL_CUSTOMERS, getAllCustomersSaga);
  yield takeEvery(GET_CUSTOMER_WITH_SITES, getCustomerWithSitesSaga);
  yield takeEvery(SET_CUSTOMER, setCustomerSaga);
  yield takeEvery(GET_ACCOUNT, getAccountSaga);
  yield takeEvery(ADD_ACCOUNT, addAccountSaga);
  yield takeEvery(SET_ACCOUNT, setAccountSaga);
  yield takeEvery(GET_SITE, getSiteSaga);
  yield takeEvery(ADD_SITE, addSiteSaga);
  yield takeEvery(SET_SITE, setSiteSaga);
  yield takeLatest(ADD_CUSTOMER, addCustomerSaga);
}
