export const CHANNEL_MAP: Record<string, string> = {
  pc: "PC",
  mo: "MO",
  all: "ALL",
};

export const PAGE_MAP: Record<string, string> = {
  all: "ALL",
  main: "Main",
  search: "Search",
  detail: "Detail",
  best: "Best",
  plan: "Plan",
  category1: "Category-대카",
  category2: "Category-중카",
  category3: "Category-소카",
  brand: "Brand",
  cart: "Cart",
  mypage: "Mypage",
  order: "Order",
  recommend: "Recommend",
  info: "Information",
  gnb: "GNB",
  column: "Column",
};

export const SECTION_MAP: Record<string, string> = {
  cubot: "Cu-bot",
  a11: "a11",
  a12: "a12",
  a13: "a13",
  a14: "a14",
  a21: "a21",
  a22: "a22",
  a23: "a23",
  a24: "a24",
  a31: "a31",
  a32: "a32",
  a33: "a33",
  a34: "a34",
  a41: "a41",
  a42: "a42",
  a43: "a43",
  a44: "a44",
};
export const CAMPAIGN_PAGE_MAP: Record<string, string> = {
  entire: "전체",
  all: "ALL",
  main: "Main",
  search: "Search",
  detail: "Detail",
  best: "Best",
  plan: "Plan",
  category1: "Category-대카",
  category2: "Category-중카",
  category3: "Category-소카",
  brand: "Brand",
  cart: "Cart",
  mypage: "Mypage",
  order: "Order",
  recommend: "Recommend",
  gnb: "GNB",
};

export const CAMPAIGN_CHANNEL_MAP: Record<string, string> = {
  all: "전체",
  pc: "pc",
  mo: "mo",
};

export const CAMPAIGN_SECTION_MAP: Record<string, string> = {
  notSelected: "선택안함",
  cubot: "cubot",
  a11: "a11",
  a12: "a12",
  a13: "a13",
  a14: "a14",
  a21: "a21",
  a22: "a22",
  a23: "a23",
  a24: "a24",
  a31: "a31",
  a32: "a32",
  a33: "a33",
  a34: "a34",
  a41: "a41",
  a42: "a42",
  a43: "a43",
  a44: "a44",
};

export const CAMPAIGN_NAME = "Campaign Name";

export const DSO_LIST_TABLE_HEADER = [
  {
    title: "우선순위",
    dataIndex: "priority",
    key: "priority",
    align: "center" as "center",
  },
  {
    title: "DSO",
    dataIndex: "dso",
    key: "dso",
    align: "center" as "center",
  },
  {
    title: "Trigger",
    dataIndex: "trigger",
    key: "trigger",
    align: "center" as "center",
  },
  {
    title: "Seg",
    dataIndex: "seg",
    key: "seg",
    align: "center" as "center",
  },
  {
    title: "Target PCID",
    dataIndex: "target",
    key: "target",
    align: "center" as "center",
  },
  {
    title: "SO",
    dataIndex: "so",
    key: "so",
    align: "center" as "center",
  },
  {
    title: "생성일자",
    dataIndex: "createdDate",
    key: "createdDate",
    align: "center" as "center",
  },
];
