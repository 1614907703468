import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { callWrapperSaga } from "utils/callWrapperSaga";
import { DsocDemoService } from "apis/services";

import type { DsocDemoRequest, DsocDemoResponse, APIError } from "types/api";

export const FETCH_DSOC_DEMO = `dsocdemo/FETCH_DSOC_DEMO`;
export const FETCH_DSOC_DEMO_SUCCESS = `dsocdemo/FETCH_DSOC_DEMO_SUCCESS`;
export const FETCH_DSOC_DEMO_ERROR = `dsocdemo/FETCH_DSOC_DEMO_ERROR`;

export const fetchDsocResult = createAsyncAction(
  FETCH_DSOC_DEMO,
  FETCH_DSOC_DEMO_SUCCESS,
  FETCH_DSOC_DEMO_ERROR
)<DsocDemoRequest, { dsocResult: DsocDemoResponse }, APIError>();

function* fetchDsocResultSaga(
  action: ReturnType<typeof fetchDsocResult.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      DsocDemoService.fetchDsocResult,
      action.payload
    );

    yield put(fetchDsocResult.success({ dsocResult: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchDsocResult.failure({ data, status }));
  }
}

export const dsocDemoAsyncAction = {
  fetchDsocResult,
};

export default function* dsocDemoSaga() {
  yield takeLatest(FETCH_DSOC_DEMO, fetchDsocResultSaga);
}
