import styled from "styled-components";
import { Layout } from "antd";
const { Sider } = Layout;

const LayoutPageSider = styled(Sider)`
  box-sizing: content-box !important;
  margin-bottom: 10px !important;
`;

export { LayoutPageSider };
