import { FC, useCallback } from "react";
import { LogoutOutlined } from "@ant-design/icons";
import { Divider, Button } from "antd";
import { useIntl } from "react-intl";
import useUserData from "hooks/useUserData";
import { useNavigate } from "react-router";

interface LogoutProps {}
const Logout: FC<LogoutProps> = () => {
  const { formatMessage } = useIntl();
  const { logout } = useUserData();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    logout({
      onSuccess: () => {
        navigate("login");
      },
    });
  }, [logout, navigate]);

  return (
    <Button
      onClick={handleClick}
      block
      type="text"
      style={{ textAlign: "start" }}
    >
      <LogoutOutlined />
      <Divider type="vertical" />
      {formatMessage({
        id: "Components.Layout.Header.UserActionDropdown.Logout.text",
      })}
    </Button>
  );
};
export default Logout;
