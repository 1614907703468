import Client from "apis";
import type { ServiceRequest } from "types/api";
import _ from "lodash";

class ServiceService {
  static async fetchIgnoreItems(serviceRequest: ServiceRequest) {
    const { cuid } = serviceRequest;
    return await Client.credentialsInstance.get(
      `${Client.path.api}/ignore/item/${cuid}`
    );
  }

  static async fetchIgnoreBrands(serviceRequest: ServiceRequest) {
    const { cuid } = serviceRequest;
    return await Client.credentialsInstance.get(
      `${Client.path.api}/ignore/brand/${cuid}`
    );
  }

  static async fetchIgnoreCategories(serviceRequest: ServiceRequest) {
    const { cuid } = serviceRequest;
    return await Client.credentialsInstance.get(
      `${Client.path.api}/ignore/category/${cuid}`
    );
  }

  static async postIgnoreBrands(serviceRequest: ServiceRequest) {
    const { cuid, brandList } = serviceRequest;
    return await Client.credentialsInstance.post(
      `${Client.path.api}/ignore/brand/${cuid}`,
      _.map(brandList, ({ brandId, cuid, recType }) => ({
        brandId,
        cuid,
        recType,
        createdAt: new Date(),
      }))
    );
  }

  static async postIgnoreCategories(serviceRequest: ServiceRequest) {
    const { cuid, categoryList } = serviceRequest;
    return await Client.credentialsInstance.post(
      `${Client.path.api}/ignore/category/${cuid}`,
      _.map(categoryList, ({ categoryId, cuid, recType }) => ({
        categoryId,
        cuid,
        recType,
        createdAt: new Date(),
      }))
    );
  }

  static async postIgnoreItems(serviceRequest: ServiceRequest) {
    const { cuid, itemList } = serviceRequest;
    return await Client.credentialsInstance.post(
      `${Client.path.api}/ignore/item/${cuid}`,
      _.map(itemList, ({ itemId, cuid, recType }) => ({
        itemId,
        cuid,
        recType,
        createdAt: new Date(),
      }))
    );
  }
}
export default ServiceService;
