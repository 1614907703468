import { useCallback } from "react";

import { useAppSelector, useAppDispatch } from "store";
import { rtsDemoAsyncAction } from "store/modules/demo/rts/saga";
import { rtsAction } from "store/modules/demo/rts/index";

import {
  RtsResultSelector,
  RtsInputsSelector,
  RtsUrlInfoSelector,
} from "store/modules/demo/rts";

const useRtsDemoData = () => {
  const dispatch = useAppDispatch();

  const [rtsResultLoading, rtsResult, rtsResultError, rtsInputs, rtsUrlInfo] = [
    useAppSelector(RtsResultSelector.loading),
    useAppSelector(RtsResultSelector.data),
    useAppSelector(RtsResultSelector.error),
    useAppSelector(RtsInputsSelector.inputs),
    useAppSelector(RtsUrlInfoSelector.urlInfo),
  ];

  const fetchRtsResult = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        rtsDemoAsyncAction.fetchRtsResult.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const dispatchInputs = useCallback(
    ({ key, value }: { key: any; value: any }) => {
      dispatch(rtsAction.changeInputState({ key, value }));
    },
    [dispatch]
  );

  const dispatchUrlInfo = useCallback(
    ({ key, value }: { key: any; value: any }) => {
      dispatch(rtsAction.changeUrlState({ key, value }));
    },
    [dispatch]
  );

  const clearFetchResult = useCallback(() => {
    dispatch(rtsAction.clear());
  }, [dispatch]);

  return {
    fetchRtsResult,
    dispatchInputs,
    rtsInputs,
    rtsResultLoading,
    rtsResult,
    rtsResultError,
    clearFetchResult,
    dispatchUrlInfo,
    rtsUrlInfo,
  };
};

export default useRtsDemoData;
