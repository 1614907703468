import { useCallback } from "react";

import { useAppSelector, useAppDispatch } from "store";
import { randomInputAsyncAction } from "store/modules/demo/random/saga";

import {
  RandomInputSelector,
  RandomInputRecSelector,
} from "store/modules/demo/random";

const useRandomInput = () => {
  const dispatch = useAppDispatch();

  const [randomInputLoading, randomInput, randomInputError] = [
    useAppSelector(RandomInputSelector.loading),
    useAppSelector(RandomInputSelector.data),
    useAppSelector(RandomInputSelector.error),
  ];

  const fetchRandomInput = useCallback(
    ({ cuid, type }: { cuid: string; type: string }) => {
      dispatch(
        randomInputAsyncAction.fetchRandomInput.request({
          cuid,
          type,
        })
      );
    },
    [dispatch]
  );

  const [randomRecInputLoading, randomRecInput, randomRecInputError] = [
    useAppSelector(RandomInputRecSelector.loading),
    useAppSelector(RandomInputRecSelector.data),
    useAppSelector(RandomInputRecSelector.error),
  ];

  const fetchRandomRecInput = useCallback(
    ({ cuid, param }: { cuid: string; param: string }) => {
      dispatch(
        randomInputAsyncAction.fetchRandomRecInput.request({
          cuid,
          param,
        })
      );
    },
    [dispatch]
  );

  return {
    randomInputLoading,
    randomInput,
    randomInputError,
    fetchRandomInput,
    randomRecInputLoading,
    randomRecInput,
    randomRecInputError,
    fetchRandomRecInput,
  };
};

export default useRandomInput;
