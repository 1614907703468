import { FC } from "react";
import { Select } from "antd";

type Options = {
  label: string;
  value: string;
}[];
interface SearchableSelectProps {
  loading: boolean;
  placeholder: string;
  options: Options;
  value: string;
  onChange: (value: string) => void;
}
const SearchableSelect: FC<SearchableSelectProps> = ({
  placeholder,
  loading,
  options,
  value,
  onChange,
}) => {
  return (
    <Select
      // optionFilterProp="label"
      optionFilterProp="children"
      filterOption={(input, option: any) =>
        option?.props?.label?.toLowerCase()?.indexOf(input.toLowerCase()) >=
          0 ||
        option?.props?.value?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
      }
      {...(value && { value })}
      loading={loading}
      showSearch
      defaultActiveFirstOption
      placeholder={placeholder}
      onChange={onChange}
      style={{ width: "200px" }}
      options={options}
    />
  );
};
export default SearchableSelect;
