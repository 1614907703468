import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { ALL, DAILY } from "assets/constants/string";
import { format, addDays } from "date-fns";
import { RootState } from "store";
import { Channel, CubotState, GroupBy, Unit } from "types/cubot/cubot";

export const CUBOT = "cubot";

const todayDate = new Date();
const [oneWeekBefore, todayStr] = [
  format(addDays(todayDate, -7), "yyyy-MM-dd"),
  format(todayDate, "yyyy-MM-dd"),
];

const initialState: CubotState = {
  channel: ALL,
  unit: DAILY,
  startDate: oneWeekBefore,
  endDate: todayStr,
  page: "all",
  scenario: "all",
  groupBy: "rc_yn_cd",
};

const cubotSlice = createSlice({
  name: CUBOT,
  initialState,
  reducers: {
    setDate(
      state,
      action: PayloadAction<Partial<{ startDate: string; endDate: string }>>
    ) {
      return { ...state, ...action.payload };
    },
    setChannel(state, action: PayloadAction<{ channel: Channel }>) {
      state.channel = action.payload.channel;
    },
    setUnit(state, action: PayloadAction<{ unit: Unit }>) {
      state.unit = action.payload.unit;
    },
    setPage(state, action: PayloadAction<{ page: string }>) {
      state.page = action.payload.page;
    },
    setScenario(state, action: PayloadAction<{ scenario: string }>) {
      state.scenario = action.payload.scenario;
    },
    setGroupBy(state, action: PayloadAction<{ groupBy: GroupBy }>) {
      state.groupBy = action.payload.groupBy;
    },
  },
});

const selfSelector = (state: RootState) => state.cubot;

export const channelSelector = createSelector(
  selfSelector,
  (cubot) => cubot.channel
);
export const unitSelector = createSelector(selfSelector, (cubot) => cubot.unit);
export const dateSelector = createSelector(
  selfSelector,
  ({ startDate, endDate }) => ({ startDate, endDate })
);
export const pageSelector = createSelector(selfSelector, (cubot) => cubot.page);
export const scenarioSelector = createSelector(
  selfSelector,
  (cubot) => cubot.scenario
);
export const groupBySelector = createSelector(
  selfSelector,
  (cubot) => cubot.groupBy
);

export const cubotAction = cubotSlice.actions;
export const cubotReducer = cubotSlice.reducer;
