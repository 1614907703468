import styled from "styled-components";
import { Link, LinkProps } from "react-router-dom";
import { Layout, Button } from "antd";

import Logo from "assets/img/logo.png";
import DarkLogo from "assets/img/logo_dark.png";
import Symbol from "assets/img/symbol.png";
import DarkSymbol from "assets/img/symbol_dark.png";

const { Header } = Layout;

const LayoutPageHeader = styled(Header)`
  padding: 0px !important;
  line-height: unset !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
  border-width: 0px 0px 4px;
  box-shadow: 0 4px 4px
    ${(props) => (props.theme === "light" ? "#dddddd" : "darkslategrey")};
  border-image: linear-gradient(
      to right,
      rgb(148, 87, 149) 0%,
      rgb(175, 120, 176) 25%,
      rgb(186, 139, 187) 50%,
      rgb(137, 188, 210) 75%
    )
    1 / 1 / 0 stretch;
  border-style: solid;
`;

const LayoutPageHeaderMain = styled.div`
  padding: 0 16px;
  border-left: 1px solid
    ${(props) => (props.theme === "light" ? "gainsboro" : "darkslategrey")};
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled(Link)<{ $collapsed?: boolean } & LinkProps>`
  width: ${(props) => (props.$collapsed ? 80 : 200)}px;
  height: 64px;
  padding: 14px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  position: relative;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ToggleCollapseButton = styled(Button)`
  padding: 0;
`;

const UserManagementContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  LayoutPageHeader,
  LayoutPageHeaderMain,
  LogoContainer,
  ToggleCollapseButton,
  UserManagementContainer,
  Logo,
  DarkLogo,
  Symbol,
  DarkSymbol,
};
