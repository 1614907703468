import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { GuideDataSelector } from "store/modules/log";
import { logAsyncAction } from "store/modules/log/saga";

const useLogGuideData = () => {
  const dispatch = useAppDispatch();
  const [guideDataLoading, guideData, guideDataError] = [
    useAppSelector(GuideDataSelector.loading),
    useAppSelector(GuideDataSelector.data),
    useAppSelector(GuideDataSelector.error),
  ];

  const {
    orderScript,
    productScript,
    searchScript,
    userScript,
    viewScript,
    visitScript,
    memoScript,
  } = useMemo(() => {
    const [
      orderScript,
      productScript,
      searchScript,
      userScript,
      viewScript,
      visitScript,
      memoScript,
    ] = [
      _.get(guideData, "orderScript", ""),
      _.get(guideData, "productScript", ""),
      _.get(guideData, "searchScript", ""),
      _.get(guideData, "userScript", ""),
      _.get(guideData, "viewScript", ""),
      _.get(guideData, "visitScript", ""),
      _.get(guideData, "memoScript", ""),
    ];

    return {
      orderScript,
      productScript,
      searchScript,
      userScript,
      viewScript,
      visitScript,
      memoScript,
    };
  }, [guideData]);

  const getLogGuide = useCallback(
    ({ cuid }: { cuid: string }) => {
      dispatch(logAsyncAction.getLogGuide.request({ cuid }));
    },
    [dispatch]
  );

  const addLogGuide = useCallback(
    ({
      cuid,
      onSuccess,
      onFailure,
    }: {
      cuid: string;
      onSuccess: () => void;
      onFailure: () => void;
    }) => {
      dispatch(
        logAsyncAction.addLogGuide.request({
          cuid,
          onSuccess,
          onFailure,
        })
      );
    },
    [dispatch]
  );

  const setLogGuide = useCallback(
    ({
      cuid,
      script,
      onSuccess,
      onFailure,
    }: {
      cuid: string;
      script: { [key: string]: string };
      onSuccess: () => void;
      onFailure: () => void;
    }) => {
      dispatch(
        logAsyncAction.setLogGuide.request({
          cuid,
          onSuccess,
          onFailure,
          visitScript,
          viewScript,
          orderScript,
          productScript,
          searchScript,
          userScript,
          memoScript,
          ...script,
        })
      );
    },
    [
      dispatch,
      visitScript,
      viewScript,
      orderScript,
      productScript,
      userScript,
      searchScript,
      memoScript,
    ]
  );

  return {
    guideData,
    guideDataLoading,
    orderScript,
    productScript,
    searchScript,
    userScript,
    viewScript,
    visitScript,
    memoScript,
    getLogGuide,
    addLogGuide,
    setLogGuide,
  };
};
export default useLogGuideData;
