import { useCallback, useMemo } from "react";
import _ from "lodash";
import { personaAction, nhUserIDSelector } from "store/modules/demo/persona";

import { useAppSelector, useAppDispatch } from "store";
import { personaDemoAsyncAction } from "store/modules/demo/persona/saga";

import {
  Pr001ResultSelector,
  Pr002ResultSelector,
  Pr003ResultSelector,
  Pr004ResultSelector,
  Pr005ResultSelector,
  Prc001ResultSelector,
  Prc002ResultSelector,
  Prc003ResultSelector,
  Pro001ResultSelector,
  Pro002ResultSelector,
  Pro003ResultSelector,
  NhPrc001ResultSelector,
  NhPrc002ResultSelector,
  NhPrc003ResultSelector,
  NhPro001ResultSelector,
  NhPro002ResultSelector,
  NhPro003ResultSelector,
  CustomerC001ResultSelector,
  CustomerB004ResultSelector,
  CustomerA001ResultSelector,
  NhCustomerC001ResultSelector,
  NhCustomerB004ResultSelector,
  NhCustomerA001ResultSelector,
  Prst001ResultSelector,
  Prst002ResultSelector,
  Prst003ResultSelector,
  Prst004ResultSelector,
  Prst005ResultSelector,
  RankingS001ResultSelector,
  WeeklyS001ResultSelector,
} from "store/modules/demo/persona";

const usePersonaDemoData = () => {
  const dispatch = useAppDispatch();

  const nhUserID = useAppSelector(nhUserIDSelector);
  const setNhUserID = useCallback(
    ({ nhUserID }: { nhUserID: string }) => {
      dispatch(personaAction.setNhUserID({ nhUserID }));
    },
    [dispatch]
  );
  const [pr001ResultLoading, pr001RawResult, pr001ResultError] = [
    useAppSelector(Pr001ResultSelector.loading),
    useAppSelector(Pr001ResultSelector.data),
    useAppSelector(Pr001ResultSelector.error),
  ];

  const [pr002ResultLoading, pr002RawResult, pr002ResultError] = [
    useAppSelector(Pr002ResultSelector.loading),
    useAppSelector(Pr002ResultSelector.data),
    useAppSelector(Pr002ResultSelector.error),
  ];

  const [pr003ResultLoading, pr003RawResult, pr003ResultError] = [
    useAppSelector(Pr003ResultSelector.loading),
    useAppSelector(Pr003ResultSelector.data),
    useAppSelector(Pr003ResultSelector.error),
  ];

  const [pr004ResultLoading, pr004RawResult, pr004ResultError] = [
    useAppSelector(Pr004ResultSelector.loading),
    useAppSelector(Pr004ResultSelector.data),
    useAppSelector(Pr004ResultSelector.error),
  ];

  const [pr005ResultLoading, pr005RawResult, pr005ResultError] = [
    useAppSelector(Pr005ResultSelector.loading),
    useAppSelector(Pr005ResultSelector.data),
    useAppSelector(Pr005ResultSelector.error),
  ];

  const [prc001ResultLoading, prc001RawResult, prc001ResultError] = [
    useAppSelector(Prc001ResultSelector.loading),
    useAppSelector(Prc001ResultSelector.data),
    useAppSelector(Prc001ResultSelector.error),
  ];

  const [prc002ResultLoading, prc002RawResult, prc002ResultError] = [
    useAppSelector(Prc002ResultSelector.loading),
    useAppSelector(Prc002ResultSelector.data),
    useAppSelector(Prc002ResultSelector.error),
  ];

  const [prc003ResultLoading, prc003RawResult, prc003ResultError] = [
    useAppSelector(Prc003ResultSelector.loading),
    useAppSelector(Prc003ResultSelector.data),
    useAppSelector(Prc003ResultSelector.error),
  ];

  const [pro001ResultLoading, pro001RawResult, pro001ResultError] = [
    useAppSelector(Pro001ResultSelector.loading),
    useAppSelector(Pro001ResultSelector.data),
    useAppSelector(Pro001ResultSelector.error),
  ];

  const [pro002ResultLoading, pro002RawResult, pro002ResultError] = [
    useAppSelector(Pro002ResultSelector.loading),
    useAppSelector(Pro002ResultSelector.data),
    useAppSelector(Pro002ResultSelector.error),
  ];

  const [pro003ResultLoading, pro003RawResult, pro003ResultError] = [
    useAppSelector(Pro003ResultSelector.loading),
    useAppSelector(Pro003ResultSelector.data),
    useAppSelector(Pro003ResultSelector.error),
  ];

  const [nhPrc001ResultLoading, nhPrc001RawResult, nhPrc001ResultError] = [
    useAppSelector(NhPrc001ResultSelector.loading),
    useAppSelector(NhPrc001ResultSelector.data),
    useAppSelector(NhPrc001ResultSelector.error),
  ];

  const [nhPrc002ResultLoading, nhPrc002RawResult, nhPrc002ResultError] = [
    useAppSelector(NhPrc002ResultSelector.loading),
    useAppSelector(NhPrc002ResultSelector.data),
    useAppSelector(NhPrc002ResultSelector.error),
  ];

  const [nhPrc003ResultLoading, nhPrc003RawResult, nhPrc003ResultError] = [
    useAppSelector(NhPrc003ResultSelector.loading),
    useAppSelector(NhPrc003ResultSelector.data),
    useAppSelector(NhPrc003ResultSelector.error),
  ];

  const [nhPro001ResultLoading, nhPro001RawResult, nhPro001ResultError] = [
    useAppSelector(NhPro001ResultSelector.loading),
    useAppSelector(NhPro001ResultSelector.data),
    useAppSelector(NhPro001ResultSelector.error),
  ];

  const [nhPro002ResultLoading, nhPro002RawResult, nhPro002ResultError] = [
    useAppSelector(NhPro002ResultSelector.loading),
    useAppSelector(NhPro002ResultSelector.data),
    useAppSelector(NhPro002ResultSelector.error),
  ];

  const [nhPro003ResultLoading, nhPro003RawResult, nhPro003ResultError] = [
    useAppSelector(NhPro003ResultSelector.loading),
    useAppSelector(NhPro003ResultSelector.data),
    useAppSelector(NhPro003ResultSelector.error),
  ];

  const [
    customerC001ResultLoading,
    customerC001RawResult,
    customerC001ResultError,
  ] = [
    useAppSelector(CustomerC001ResultSelector.loading),
    useAppSelector(CustomerC001ResultSelector.data),
    useAppSelector(CustomerC001ResultSelector.error),
  ];

  const [
    customerB004ResultLoading,
    customerB004RawResult,
    customerB004ResultError,
  ] = [
    useAppSelector(CustomerB004ResultSelector.loading),
    useAppSelector(CustomerB004ResultSelector.data),
    useAppSelector(CustomerB004ResultSelector.error),
  ];

  const [
    customerA001ResultLoading,
    customerA001RawResult,
    customerA001ResultError,
  ] = [
    useAppSelector(CustomerA001ResultSelector.loading),
    useAppSelector(CustomerA001ResultSelector.data),
    useAppSelector(CustomerA001ResultSelector.error),
  ];

  const [
    nhCustomerC001ResultLoading,
    nhCustomerC001RawResult,
    nhCustomerC001ResultError,
  ] = [
    useAppSelector(NhCustomerC001ResultSelector.loading),
    useAppSelector(NhCustomerC001ResultSelector.data),
    useAppSelector(NhCustomerC001ResultSelector.error),
  ];

  const [
    nhCustomerB004ResultLoading,
    nhCustomerB004RawResult,
    nhCustomerB004ResultError,
  ] = [
    useAppSelector(NhCustomerB004ResultSelector.loading),
    useAppSelector(NhCustomerB004ResultSelector.data),
    useAppSelector(NhCustomerB004ResultSelector.error),
  ];

  const [
    nhCustomerA001ResultLoading,
    nhCustomerA001RawResult,
    nhCustomerA001ResultError,
  ] = [
    useAppSelector(NhCustomerA001ResultSelector.loading),
    useAppSelector(NhCustomerA001ResultSelector.data),
    useAppSelector(NhCustomerA001ResultSelector.error),
  ];

  const [prst001ResultLoading, prst001RawResult, prst001ResultError] = [
    useAppSelector(Prst001ResultSelector.loading),
    useAppSelector(Prst001ResultSelector.data),
    useAppSelector(Prst001ResultSelector.error),
  ];

  const [prst002ResultLoading, prst002RawResult, prst002ResultError] = [
    useAppSelector(Prst002ResultSelector.loading),
    useAppSelector(Prst002ResultSelector.data),
    useAppSelector(Prst002ResultSelector.error),
  ];

  const [prst003ResultLoading, prst003RawResult, prst003ResultError] = [
    useAppSelector(Prst003ResultSelector.loading),
    useAppSelector(Prst003ResultSelector.data),
    useAppSelector(Prst003ResultSelector.error),
  ];

  const [prst004ResultLoading, prst004RawResult, prst004ResultError] = [
    useAppSelector(Prst004ResultSelector.loading),
    useAppSelector(Prst004ResultSelector.data),
    useAppSelector(Prst004ResultSelector.error),
  ];

  const [prst005ResultLoading, prst005RawResult, prst005ResultError] = [
    useAppSelector(Prst005ResultSelector.loading),
    useAppSelector(Prst005ResultSelector.data),
    useAppSelector(Prst005ResultSelector.error),
  ];

  const [
    RankingS001ResultLoading,
    RankingS001RawResult,
    RankingS001ResultError,
  ] = [
    useAppSelector(RankingS001ResultSelector.loading),
    useAppSelector(RankingS001ResultSelector.data),
    useAppSelector(RankingS001ResultSelector.error),
  ];

  const [WeeklyS001ResultLoading, WeeklyS001RawResult, WeeklyS001ResultError] =
    [
      useAppSelector(WeeklyS001ResultSelector.loading),
      useAppSelector(WeeklyS001ResultSelector.data),
      useAppSelector(WeeklyS001ResultSelector.error),
    ];

  const fetchPr001Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPr001Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchPr002Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPr002Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchPr003Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPr003Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchPr004Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPr004Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchPr005Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPr005Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchPrc001Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPrc001Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchPrc002Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPrc002Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchPrc003Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPrc003Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchPro001Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPro001Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchPro002Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPro002Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchPro003Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPro003Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchNhPrc001Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaNhPrc001Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchNhPrc002Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaNhPrc002Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchNhPrc003Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaNhPrc003Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchNhPro001Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaNhPro001Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchNhPro002Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaNhPro002Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchNhPro003Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaNhPro003Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchCustomerC001Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaCustomC001Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchCustomerB004Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaCustomB004Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchCustomerA001Result = useCallback(
    ({ apiUrl, inputList }: { apiUrl: string; inputList: string[] }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaCustomA001Result.request({
          apiUrl,
          inputList,
        })
      );
    },
    [dispatch]
  );

  const fetchNhCustomerC001Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaNhCustomC001Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchNhCustomerB004Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaNhCustomB004Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchNhCustomerA001Result = useCallback(
    ({ apiUrl, inputList }: { apiUrl: string; inputList: string[] }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaNhCustomA001Result.request({
          apiUrl,
          inputList,
        })
      );
    },
    [dispatch]
  );

  const fetchPrst001Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPrst001Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchPrst002Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPrst002Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchPrst003Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPrst003Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchPrst004Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPrst004Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchPrst005Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaPrst005Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchRankingS001Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaRankingS001Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const fetchWeeklyS001Result = useCallback(
    ({ apiUrl }: { apiUrl: string }) => {
      dispatch(
        personaDemoAsyncAction.fetchPersonaWeeklyS001Result.request({
          apiUrl,
        })
      );
    },
    [dispatch]
  );

  const pr001Result = useMemo(() => {
    const { personaResult } = pr001RawResult;
    if (!personaResult || personaResult?.length === 0) return;

    return {
      ...personaResult[0],
    };
  }, [pr001RawResult]);

  const pr002Result = useMemo(() => {
    const { personaResult } = pr002RawResult;
    if (!personaResult || personaResult?.length === 0) return;

    return {
      ...personaResult[0],
    };
  }, [pr002RawResult]);

  const pr003Result = useMemo(() => {
    const { personaResult } = pr003RawResult;
    if (!personaResult || personaResult?.length === 0) return;

    return [...personaResult];
  }, [pr003RawResult]);

  const pr004Result = useMemo(() => {
    const { personaResult } = pr004RawResult;
    if (!personaResult || personaResult?.length === 0) return;

    return {
      ...personaResult[0],
    };
  }, [pr004RawResult]);

  const pr005Result = useMemo(() => {
    const { personaResult } = pr005RawResult;
    if (!personaResult || personaResult?.length === 0) return;

    return {
      ...personaResult[0],
    };
  }, [pr005RawResult]);

  const prc001Result = useMemo(() => {
    const { personaResult } = prc001RawResult;
    if (!personaResult || personaResult?.length === 0) return;

    return personaResult;
  }, [prc001RawResult]);

  const prc002Result = useMemo(() => {
    const { personaResult } = prc002RawResult;
    if (!personaResult || personaResult?.length === 0) return;

    return personaResult;
  }, [prc002RawResult]);

  const prc003Result = useMemo(() => {
    const { personaResult } = prc003RawResult;
    if (!personaResult || personaResult?.length === 0) return;

    return personaResult;
  }, [prc003RawResult]);

  const pro001Result = useMemo(() => {
    const { personaResult } = pro001RawResult;
    if (!personaResult || personaResult?.length === 0) return;
    return personaResult;
  }, [pro001RawResult]);

  const pro002Result = useMemo(() => {
    const { personaResult } = pro002RawResult;
    if (!personaResult || personaResult?.length === 0) return;
    return personaResult;
  }, [pro002RawResult]);

  const pro003Result = useMemo(() => {
    const { personaResult } = pro003RawResult;
    if (!personaResult || personaResult?.length === 0) return;
    return personaResult;
  }, [pro003RawResult]);

  const nhPrc001Result = useMemo(() => {
    const { personaResult } = nhPrc001RawResult;
    if (!personaResult || personaResult?.length === 0) return;

    return personaResult;
  }, [nhPrc001RawResult]);

  const nhPrc002Result = useMemo(() => {
    const { personaResult } = nhPrc002RawResult;
    if (!personaResult || personaResult?.length === 0) return;

    return personaResult;
  }, [nhPrc002RawResult]);

  const nhPrc003Result = useMemo(() => {
    const { personaResult } = nhPrc003RawResult;
    if (!personaResult || personaResult?.length === 0) return;

    return personaResult;
  }, [nhPrc003RawResult]);

  const nhPro001Result = useMemo(() => {
    const { personaResult } = nhPro001RawResult;
    if (!personaResult || personaResult?.length === 0) return;
    return personaResult;
  }, [nhPro001RawResult]);

  const nhPro002Result = useMemo(() => {
    const { personaResult } = nhPro002RawResult;
    if (!personaResult || personaResult?.length === 0) return;
    return personaResult;
  }, [nhPro002RawResult]);

  const nhPro003Result = useMemo(() => {
    const { personaResult } = nhPro003RawResult;
    if (!personaResult || personaResult?.length === 0) return;
    return personaResult;
  }, [nhPro003RawResult]);

  const customerC001Result = useMemo(() => {
    const { results } = customerC001RawResult;
    if (!results || results?.length === 0) return;
    return results;
  }, [customerC001RawResult]);

  const customerB004Result = useMemo(() => {
    const { results } = customerB004RawResult;
    if (!results || results?.length === 0) return;
    return results;
  }, [customerB004RawResult]);

  const customerA001Result = useMemo(() => {
    const { results } = customerA001RawResult;
    if (!results || results?.length === 0) return;
    return results;
  }, [customerA001RawResult]);

  const nhCustomerC001Result = useMemo(() => {
    const { results } = nhCustomerC001RawResult;
    if (!results || results?.length === 0) return;
    return results;
  }, [nhCustomerC001RawResult]);

  const nhCustomerB004Result = useMemo(() => {
    const { results } = nhCustomerB004RawResult;
    if (!results || results?.length === 0) return;
    return results;
  }, [nhCustomerB004RawResult]);

  const nhCustomerA001Result = useMemo(() => {
    const { results } = nhCustomerA001RawResult;
    if (!results || results?.length === 0) return;
    return results;
  }, [nhCustomerA001RawResult]);

  const clearCustomerFetchResult = useCallback(() => {
    dispatch(personaAction.customerDataClear());
  }, [dispatch]);

  const clearNhCustomerFetchResult = useCallback(() => {
    dispatch(personaAction.nhCustomerDataClear());
  }, [dispatch]);

  const prst001Result = useMemo(() => {
    const { personaResult } = prst001RawResult;
    if (!personaResult || personaResult?.length === 0) return;
    return personaResult;
  }, [prst001RawResult]);

  const prst002Result = useMemo(() => {
    const { personaResult } = prst002RawResult;
    if (!personaResult || personaResult?.length === 0) return;
    return personaResult;
  }, [prst002RawResult]);

  const prst003Result = useMemo(() => {
    const { personaResult } = prst003RawResult;
    if (!personaResult || personaResult?.length === 0) return;
    return personaResult;
  }, [prst003RawResult]);

  const prst004Result = useMemo(() => {
    const { personaResult } = prst004RawResult;
    if (!personaResult || personaResult?.length === 0) return;
    return personaResult;
  }, [prst004RawResult]);

  const prst005Result = useMemo(() => {
    const { personaResult } = prst005RawResult;
    if (!personaResult || personaResult?.length === 0) return;
    return personaResult;
  }, [prst005RawResult]);

  const rankingS001Result = useMemo(() => {
    const { results } = RankingS001RawResult;
    if (!results || results?.length === 0) return;
    return results;
  }, [RankingS001RawResult]);

  const WeeklyS001Result = useMemo(() => {
    const { results } = WeeklyS001RawResult;
    if (!results || results?.length === 0) return;
    return results;
  }, [WeeklyS001RawResult]);

  return {
    pr001ResultLoading,
    pr001Result,
    pr001RawResult,
    pr001ResultError,
    pr002ResultLoading,
    pr002Result,
    pr002ResultError,
    pr003ResultLoading,
    pr003Result,
    pr003ResultError,
    pr004ResultLoading,
    pr004RawResult,
    pr004Result,
    pr004ResultError,
    pr005Result,
    pr005ResultError,

    prc001ResultLoading,
    prc001Result,
    prc001ResultError,
    prc002ResultLoading,
    prc002Result,
    prc002ResultError,
    prc003ResultLoading,
    prc003Result,
    prc003ResultError,

    pro001ResultLoading,
    pro001Result,
    pro001ResultError,
    pro002ResultLoading,
    pro002Result,
    pro002ResultError,
    pro003ResultLoading,
    pro003Result,
    pro003ResultError,

    nhPrc001ResultLoading,
    nhPrc001Result,
    nhPrc001ResultError,
    nhPrc002ResultLoading,
    nhPrc002Result,
    nhPrc002ResultError,
    nhPrc003ResultLoading,
    nhPrc003Result,
    nhPrc003ResultError,

    nhPro001ResultLoading,
    nhPro001Result,
    nhPro001ResultError,
    nhPro002ResultLoading,
    nhPro002Result,
    nhPro002ResultError,
    nhPro003ResultLoading,
    nhPro003Result,
    nhPro003ResultError,

    customerC001ResultLoading,
    customerC001Result,
    customerC001ResultError,
    customerB004ResultLoading,
    customerB004Result,
    customerB004ResultError,
    customerA001ResultLoading,
    customerA001Result,
    customerA001ResultError,

    nhCustomerC001ResultLoading,
    nhCustomerC001Result,
    nhCustomerC001ResultError,
    nhCustomerB004ResultLoading,
    nhCustomerB004Result,
    nhCustomerB004ResultError,
    nhCustomerA001ResultLoading,
    nhCustomerA001Result,
    nhCustomerA001ResultError,

    prst001ResultLoading,
    prst001Result,
    prst001ResultError,

    prst002ResultLoading,
    prst002Result,
    prst002ResultError,

    prst003ResultLoading,
    prst003Result,
    prst003ResultError,

    prst004ResultLoading,
    prst004Result,
    prst004ResultError,

    prst005ResultLoading,
    prst005Result,
    prst005ResultError,

    RankingS001ResultLoading,
    rankingS001Result,
    RankingS001ResultError,

    WeeklyS001ResultLoading,
    WeeklyS001Result,
    WeeklyS001ResultError,

    fetchPr001Result,
    fetchPr002Result,
    fetchPr003Result,
    fetchPr004Result,
    fetchPr005Result,

    fetchPrc001Result,
    fetchPrc002Result,
    fetchPrc003Result,

    fetchPro001Result,
    fetchPro002Result,
    fetchPro003Result,

    fetchNhPrc001Result,
    fetchNhPrc002Result,
    fetchNhPrc003Result,

    fetchNhPro001Result,
    fetchNhPro002Result,
    fetchNhPro003Result,

    fetchCustomerC001Result,
    fetchCustomerB004Result,
    fetchCustomerA001Result,

    fetchNhCustomerC001Result,
    fetchNhCustomerB004Result,
    fetchNhCustomerA001Result,

    fetchPrst001Result,
    fetchPrst002Result,
    fetchPrst003Result,
    fetchPrst004Result,
    fetchPrst005Result,

    fetchRankingS001Result,
    fetchWeeklyS001Result,

    clearCustomerFetchResult,
    clearNhCustomerFetchResult,

    nhUserID,
    setNhUserID,
  };
};

export default usePersonaDemoData;
