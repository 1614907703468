import { FC, ReactNode, useCallback, useEffect } from "react";
import { Navigate, Route, useLocation } from "react-router-dom";
import { RouteProps } from "react-router";
import { LoginStatusSelector } from "store/modules/user";
import { useAppSelector } from "store";
import _ from "lodash";
import { NEED_TO_LOGIN, SESSION_EXPIRED } from "assets/constants/number";
import { useUserData } from "hooks";

export const errorCodes = [SESSION_EXPIRED, NEED_TO_LOGIN];

export const ErrorHandler: FC = ({ children }) => {
  const error = useAppSelector(LoginStatusSelector.error);
  const location = useLocation();
  const { pathname, state } = location;

  const renderContent = useCallback(() => {
    if (
      _.negate(_.includes)(errorCodes, _.get(error, "data.code")) ||
      _.get(state, "from") === pathname
    ) {
      return children;
    }
    return <Navigate to="/login" state={{ from: pathname }} />;
  }, [children, error, state, pathname]);

  return <>{renderContent()}</>;
};

type ScrollToTopProps = {
  targetElement: HTMLElement | null;
  children: ReactNode;
};
export const ScrollToTop = ({ targetElement, children }: ScrollToTopProps) => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (!targetElement) return;
    targetElement.scrollTo(0, 0);
  }, [targetElement, pathname, children]);

  return <>{children}</>;
};

const AuthenticatedComponent = ({ auth, admin, children }: any) => {
  const { isLogin, authority } = useUserData();

  let childComponent = children;
  if (auth) {
    if (!isLogin) childComponent = <Navigate to={"/login"} />;
    if (admin && !(isLogin && authority === "admin"))
      childComponent = <Navigate to={"/login"} />;
  }
  return childComponent;
};
export { AuthenticatedComponent };
