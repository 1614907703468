import { Form, Input, message, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import { UPDATE_SELECTOR_NAME } from "apollo/mutation";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const useSelectorNameModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentSelectorId, setCurrentSelectorId] = useState(-1);
  const [currentSelectorData, setCurrentSelectorData] = useState({});
  const [isSelectorDetailModalVisible, setSelectorDetailModalVisible] =
    useState(false);
  useState({});
  const [
    updateSelectorName,
    { data: updatedSelectorName, loading: updatedSelectorNameLoading },
  ] = useMutation(UPDATE_SELECTOR_NAME);

  const [form] = Form.useForm();

  const resetFields = useCallback(() => {
    form.resetFields();
  }, [form]);

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  useEffect(() => {
    if (updatedSelectorName && !updatedSelectorNameLoading) {
      message.success("Selector 이름 변경이 완료되었습니다.");
    }
  }, [updatedSelectorName, updatedSelectorNameLoading]);

  const showSelectorDetailModal = () => {
    setSelectorDetailModalVisible(true);
  };
  const SelectorDetailModal = (props: any) => {
    return (
      <Modal
        width={window.innerWidth - 30}
        footer={ null}
        // cancelButtonProps={{ style: { display: 'none' } }}
        title="셀렉터 세부 정보"
        visible={isSelectorDetailModalVisible}
        onCancel={() => setSelectorDetailModalVisible(false)}
      >
        {props.children}
      </Modal>
    );
  };

  const SelectorNameModal = () => {
    return (
      <Modal
        title="셀렉터 이름 변경"
        visible={isModalVisible}
        onOk={() => {
          form.validateFields().then(() => {
            const { name } = form.getFieldsValue();
            updateSelectorName({
              variables: { data: { id: currentSelectorId, name } },
            });
            handleCancel();
          });
        }}
        onCancel={handleCancel}
        afterClose={resetFields}
        okText={"변경"}
        cancelText={"취소"}
      >
        <Form
          {...formItemLayout}
          name="selector-name"
          form={form}
          preserve={false}
        >
          <Form.Item
            name="name"
            label={"셀렉터 이름"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  return {
    SelectorNameModal,
    SelectorDetailModal,
    showModal,
    currentSelectorData,
    showSelectorDetailModal,
    setCurrentSelectorData,
    setCurrentSelectorId,
  };
};

export default useSelectorNameModal;
