export const getErrorMessage = (loginStatusError: any) => {
  if (loginStatusError.status === 401) return "중복 로그인이 되었습니다.";
  const errorCode = loginStatusError.data.code;
  switch (errorCode) {
    case 1002:
      return "해당 유저가 존재하지 않습니다.";
    case 1003:
      return "세션이 만료되었습니다!";
    case 1004:
      return "세션이 만료되었습니다!";
    case 1005:
      return "비밀번호를 다시 입력해 주세요!";
    case 1006:
      return "권한이 필요합니다";
    case 1007:
      return "사용이 비활성화된 유저입니다.";
    case 1008:
      return "해당 아이디가 이미 존재합니다.";
    case 1009:
      return "비밀번호를 변경해주세요!";
    case 1010:
      return "계정이 잠금 상태입니다";
    default:
      return "알 수 없는 에러가 발생했습니다. 관리자에게 문의주세요!";
  }
};
