import { FC } from "react";
import RenderRouter from "routes";
import { ErrorHandler } from "routes/config";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import { useThemeSwitcher } from "react-css-theme-switcher";
import {
  flattenMessages,
  getAntdLocale,
  getIntlLocale,
  getPrefixLocale,
} from "locales";
import { BrowserRouter } from "react-router-dom";
import { useUserData } from "hooks";
import { SuspendFallbackLoading } from "components/common";
const App: FC = () => {
  const { locale, authority } = useUserData();
  const { status } = useThemeSwitcher();
  if (status === "loading") {
    return <SuspendFallbackLoading />;
  }

  return (
    <ConfigProvider locale={getAntdLocale(locale)}>
      <IntlProvider
        locale={getPrefixLocale(locale)}
        messages={flattenMessages(getIntlLocale(locale))}
      >
        <BrowserRouter>
          <ErrorHandler>
            <RenderRouter />
          </ErrorHandler>
        </BrowserRouter>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default App;
