import { FC, useCallback, useMemo } from "react";
import { useAppSelector } from "store";
import { deviceSelector, localeSelector } from "store/modules/user";
import * as S from "pages/layout/header/style";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import { localeMap } from "locales";
import {
  LocaleSelectDropdown,
  UserActionDropdown,
} from "components/layout/header";
import { useTheme, useUserData } from "hooks";

import { MOBILE } from "assets/constants/string";

interface LayoutPageHeaderProps {
  collapsed: boolean;
  toggleCollapsed: () => void;
}

const LayoutPageHeader: FC<LayoutPageHeaderProps> = ({
  collapsed,
  toggleCollapsed,
}) => {
  const { authority } = useUserData();
  const device = useAppSelector(deviceSelector);
  const locale = useAppSelector(localeSelector);
  const isMobile = useMemo(() => device === MOBILE, [device]);
  const { ThemeButton, currentTheme } = useTheme();
  const getLocaleMap = useCallback(
    (isAdmin: boolean) => {
      if (!isAdmin) {
        const { dev, ...rest } = localeMap;
        return rest;
      }
      return localeMap;
    },
    [authority]
  );
  return (
    <S.LayoutPageHeader theme={currentTheme}>
      {!isMobile && (
        <S.LogoContainer
          to="/"
          $collapsed={collapsed}
          children={
            currentTheme === "light" ? (
              collapsed ? (
                <img src={S.Symbol} alt="eigene.ai" />
              ) : (
                <img src={S.Logo} alt="eigene.ai" />
              )
            ) : collapsed ? (
              <img src={S.DarkSymbol} alt="eigene.ai" />
            ) : (
              <img src={S.DarkLogo} alt="eigene.ai" />
            )
          }
        />
      )}
      <S.LayoutPageHeaderMain theme={currentTheme}>
        <S.ToggleCollapseButton
          type="text"
          onClick={toggleCollapsed}
          children={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        />
        <S.UserManagementContainer>
          <ThemeButton />
          <UserActionDropdown />
          <LocaleSelectDropdown
            localeMap={getLocaleMap(authority==="admin")}
            currentLocale={locale}
          />
        </S.UserManagementContainer>
      </S.LayoutPageHeaderMain>
    </S.LayoutPageHeader>
  );
};
export default LayoutPageHeader;
