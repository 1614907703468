import { useAppDispatch, useAppSelector } from "store";
import type { UserRequest } from "types/api";
import {
  collapsedSelector,
  CUIDSelector,
  deviceSelector,
  localeSelector,
  LoginStatusSelector,
  pwChangeSelector,
  userAction,
} from "store/modules/user";
import { userAsyncAction } from "store/modules/user/saga";
import { useCallback, useMemo } from "react";
import { Authority } from "types/user";
import { ADMIN } from "assets/constants/string";
import _ from "lodash";
import { isValidUserData } from "utils/isValidUserData";
import { getGlobalState } from "utils/getGlobalState";
import { TO_COLLAPSE_WIDTH } from "assets/constants/number";

const useUserData = () => {
  // const [cookies] = useCookies("XSRF-TOKEN");
  const dispatch = useAppDispatch();

  const [collapsed, device, locale] = [
    useAppSelector(collapsedSelector),
    useAppSelector(deviceSelector),
    useAppSelector(localeSelector),
  ];

  const [loginStatusLoading, loginStatusData, loginStatusError] = [
    useAppSelector(LoginStatusSelector.loading),
    useAppSelector(LoginStatusSelector.data),
    useAppSelector(LoginStatusSelector.error),
  ];

  const [pwChangeLoading, pwChangeData, pwChangeError] = [
    useAppSelector(pwChangeSelector.loading),
    useAppSelector(pwChangeSelector.data),
    useAppSelector(pwChangeSelector.error),
  ];

  const [cuidLoading, cuidData, cuidError, currentCuid] = [
    useAppSelector(CUIDSelector.loading),
    useAppSelector(CUIDSelector.data),
    useAppSelector(CUIDSelector.error),
    useAppSelector(CUIDSelector.current),
  ];

  const authority = useMemo(
    () => _.flow(_.get, _.head)(loginStatusData, "authorities"),
    [loginStatusData]
  );

  const userName = useMemo(
    () => _.get(loginStatusData, "displayUserName"),
    [loginStatusData]
  );

  const customerId = useMemo(
    () => _.get(loginStatusData, "customerId"),
    [loginStatusData]
  );

  const isLogin = useMemo(
    () => isValidUserData(loginStatusData),
    [loginStatusData]
  );

  const options = useMemo(
    () =>
      _.map(cuidData, ({ cuid, name }) => ({
        label: name,
        value: cuid,
      })),
    [cuidData]
  );

  const customerName = options?.filter(
    (option) => option?.value === currentCuid
  )[0]?.label;

  const toggleCollapsed = useCallback(() => {
    dispatch(userAction.setUserItem({ collapsed: !collapsed }));
  }, [collapsed, dispatch]);

  const changeCurrentCUID = useCallback(
    (cuid: string) => {
      dispatch(userAction.setCurrentCUID(cuid));
    },
    [dispatch]
  );

  const onWindowResize = useCallback(() => {
    const { device } = getGlobalState();
    const rect = document.body.getBoundingClientRect();
    dispatch(
      userAction.setUserItem({
        device,
        collapsed: rect.width < TO_COLLAPSE_WIDTH,
      })
    );
  }, [dispatch]);

  const signIn = useCallback(
    (userRequest: UserRequest) => {
      dispatch(userAsyncAction.signIn.request(userRequest));
    },
    [dispatch]
  );

  const pwChange = useCallback(
    (values: {
      newPassword: string;
      onSuccess: () => void;
      onFailure: () => void;
    }) => {
      dispatch(userAsyncAction.pwChange.request({ ...values }));
    },
    [dispatch]
  );
  const logout = useCallback(
    ({ onSuccess }: { onSuccess: () => void }) => {
      dispatch(userAction.clearLoginStatus());
      dispatch(userAsyncAction.logout.request({ onSuccess }));
    },
    [dispatch]
  );

  const fetchCUIDs = useCallback(
    (authority: Authority) => {
      if (!authority) return;
      const { actionType, payload } = {
        actionType:
          authority === ADMIN
            ? userAsyncAction.fetchAdminCUIDs
            : userAsyncAction.fetchCustomerCUIDs,
        payload: authority === ADMIN ? {} : { customerId },
      };
      dispatch(actionType.request(payload));
    },
    [dispatch, customerId]
  );

  return {
    collapsed,
    locale,
    device,
    loginStatusLoading,
    loginStatusData,
    loginStatusError,
    cuidLoading,
    cuidError,
    authority,
    customerId,
    customerName,
    currentCuid,
    options,
    cuidData,
    isLogin,
    userName,
    signIn,
    logout,
    fetchCUIDs,
    toggleCollapsed,
    changeCurrentCUID,
    onWindowResize,
    pwChangeLoading,
    pwChangeData,
    pwChangeError,
    pwChange,
  };
};

export default useUserData;
