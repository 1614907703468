import { createGlobalStyle } from "styled-components";
import Fonts from "assets/fonts";
const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 700;
    font-display: swap;
    src: local('Spoqa Han Sans Neo Bold'),
      url("${Fonts.SpoqaHanSansNeoBoldWoff2}")
        format('woff2'),
      url("${Fonts.SpoqaHanSansNeoBoldWoff}") format('woff'),
      url("${Fonts.SpoqaHanSansNeoBoldTtf}")
        format('truetype');
  }

  @font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 500;
    font-display: swap;
    src: local('Spoqa Han Sans Neo Medium'),
      url("${Fonts.SpoqaHanSansNeoMediumWoff2}")
        format('woff2'),
      url("${Fonts.SpoqaHanSansNeoMediumWoff}")
        format('woff'),
      url("${Fonts.SpoqaHanSansNeoMediumTtf}")
        format('truetype');
  }

  @font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 400;
    font-display: swap;
    src: local('Spoqa Han Sans Neo Regular'),
      url("${Fonts.SpoqaHanSansNeoRegularWoff2}")
        format('woff2'),
      url("${Fonts.SpoqaHanSansNeoRegularWoff}")
        format('woff'),
      url("${Fonts.SpoqaHanSansNeoRegularTtf}")
        format('truetype');
  }

  @font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 300;
    font-display: swap;
    src: local('Spoqa Han Sans Neo Light'),
      url("${Fonts.SpoqaHanSansNeoLightWoff2}")
        format('woff2'),
      url("${Fonts.SpoqaHanSansNeoLightWoff}")
        format('woff'),
      url("${Fonts.SpoqaHanSansNeoLightTtf}")
        format('truetype');
  }

  @font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 100;
    font-display: swap;
    src: local('Spoqa Han Sans Neo Thin'),
      url("${Fonts.SpoqaHanSansNeoThinWoff2}")
        format('woff2'),
      url("${Fonts.SpoqaHanSansNeoThinWoff}")
        format('woff'),
      url("${Fonts.SpoqaHanSansNeoThinTtf}")
        format('truetype');
  }

  @font-face {
    font-family: 'Jalnan';
    font-weight: 100;
    font-display: swap;
    src: local('Jalnan'),
    url("${Fonts.JalnanTtf}") format('truetype'),
    url("${Fonts.JalnanWoff}") format('woff'),
  }

  @font-face {
    font-family:'BMJUA';
    font-weight: 500;
    font-display: swap;
    src: local('BMJUA'),
    url("${Fonts.BMJUATtf}") format('truetype'),
    url("${Fonts.BMJUAWoff}") format('woff'),
  }

  @font-face {
    font-family:'Cafe24Dangdanghae';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.2/Cafe24Dangdanghae.woff') format('woff');
  }
  @font-face {
    font-family:"NanumSquare_ac";
    src: url("https://hangeul.pstatic.net/hangeul_static/css/nanum-square.css");
  }
  @font-face {
    font-family:'NanumSquare_ac';
    font-weight: 700;
    font-display: swap;
    src: local('NanumSquare_acEB'),
    url("${Fonts.NanumSquare_acEBTtf}") format('truetype'),
    url("${Fonts.NanumSquare_acEBWoff}") format('woff'),
  }

  @font-face {
    font-family:'NanumSquare_ac';
    font-weight: 600;
    font-display: swap;
    src: local('NanumSquare_acB'),
    url("${Fonts.NanumSquare_acBTtf}") format('truetype'),
    url("${Fonts.NanumSquare_acBWoff}") format('woff'),
  }

  @font-face {
    font-family:'NanumSquare_acB';
    font-weight: 600;
    font-display: swap;
    src: local('NanumSquare_acB'),
    url("${Fonts.NanumSquare_acBTtf}") format('truetype'),
    url("${Fonts.NanumSquare_acBWoff}") format('woff'),
  }

  @font-face {
    font-family:'NanumSquare_ac';
    font-weight: 500;
    font-display: swap;
    src: local('NanumSquare_acR'),
    url("${Fonts.NanumSquare_acRTtf}") format('truetype'),
    url("${Fonts.NanumSquare_acRWoff}") format('woff'),
  }
  @font-face {
    font-family:'NanumSquareExtraBold';
    font-weight: 900;
    font-display: swap;
    src: url("https://hangeul.pstatic.net/hangeul_static/css/nanum-square.css");
  }
  @font-face {
    font-family: 'twayair';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_tway@1.0/twayair.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
`;

export default GlobalFonts;
