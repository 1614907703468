import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  TotalDataSelector,
  SiteDataSelector,
  currentDateSelector,
  statusSelector,
  searchSelector,
  logAction,
} from "store/modules/log";
import { logAsyncAction } from "store/modules/log/saga";
import type { Status } from "types/log";
import _ from "lodash";
import toFixed from "utils/toFixed";
import { useComponentId } from "hooks";
import { parse, format } from "date-fns";
import { LOG_STATUS } from "assets/constants/string";

const useLogStatusData = () => {
  const componentId = useComponentId();
  const dispatch = useAppDispatch();
  const [totalDataLoading, totalData, totalDataError] = [
    useAppSelector(TotalDataSelector.loading),
    useAppSelector(TotalDataSelector.data),
    useAppSelector(TotalDataSelector.error),
  ];

  const [siteDataLoading, siteData, siteDataError] = [
    useAppSelector(SiteDataSelector.loading),
    useAppSelector(SiteDataSelector.data),
    useAppSelector(SiteDataSelector.error),
  ];
  const [currentDate, search, status] = [
    useAppSelector(currentDateSelector),
    useAppSelector(searchSelector),
    useAppSelector(statusSelector),
  ];

  const { totalStatusOriginalData, siteStatusTableData } = useMemo(() => {
    const siteStatusTableData = _.map(
      _.reverse(
        _.sortBy(
          _.map(
            _.entries(
              _.reduce(
                siteData,
                (
                  siteStatusTableData,
                  { dateKey, rowCount, errorCount, type }
                ) => {
                  const convertedDateKey = format(
                    parse(_.toString(dateKey), "yyyyMMddH", new Date()),
                    "yyyy-MM-dd HH시"
                  );
                  siteStatusTableData[convertedDateKey] = {
                    ..._.get(siteStatusTableData, convertedDateKey, {}),
                    total: {
                      logCount:
                        _.get(
                          siteStatusTableData,
                          [convertedDateKey, "total", "logCount"],
                          0
                        ) + rowCount,
                      errorCount:
                        _.get(
                          siteStatusTableData,
                          [convertedDateKey, "total", "errorCount"],
                          0
                        ) + errorCount,
                    },
                    [type]: {
                      logCount:
                        _.get(
                          siteStatusTableData,
                          [convertedDateKey, type, "logCount"],
                          0
                        ) + rowCount,
                      errorCount:
                        _.get(
                          siteStatusTableData,
                          [convertedDateKey, type, "errorCount"],
                          0
                        ) + errorCount,
                    },
                  };
                  return siteStatusTableData;
                },
                {} as { [key: string]: any }
              )
            ),
            ([
              date,
              {
                total = {},
                product = {},
                view = {},
                order = {},
                visit = {},
                search = {},
                ...rest
              },
            ]) => {
              return {
                ...rest,
                date,
                total: {
                  ...total,
                  errorRate: _.defaultTo(
                    _.toNumber(
                      toFixed(0)(
                        (_.get(total, "errorCount", 0) /
                          _.get(total, "logCount", 0)) *
                          100
                      )
                    ),
                    0
                  ),
                },
                product: {
                  ...product,
                  errorRate: _.defaultTo(
                    _.toNumber(
                      toFixed(0)(
                        (_.get(product, "errorCount", 0) /
                          _.get(product, "logCount", 0)) *
                          100
                      )
                    ),
                    0
                  ),
                },
                view: {
                  ...view,
                  errorRate: _.defaultTo(
                    _.toNumber(
                      toFixed(0)(
                        (_.get(view, "errorCount", 0) /
                          _.get(view, "logCount", 0)) *
                          100
                      )
                    ),
                    0
                  ),
                },
                order: {
                  ...order,
                  errorRate: _.defaultTo(
                    _.toNumber(
                      toFixed(0)(
                        (_.get(order, "errorCount", 0) /
                          _.get(order, "logCount", 0)) *
                          100
                      )
                    ),
                    0
                  ),
                },
                visit: {
                  ...visit,
                  errorRate: _.defaultTo(
                    _.toNumber(
                      toFixed(0)(
                        (_.get(visit, "errorCount", 0) /
                          _.get(visit, "logCount", 0)) *
                          100
                      )
                    ),
                    0
                  ),
                },
                search: {
                  ...search,
                  errorRate: _.defaultTo(
                    _.toNumber(
                      toFixed(0)(
                        (_.get(search, "errorCount", 0) /
                          _.get(search, "logCount", 0)) *
                          100
                      )
                    ),
                    0
                  ),
                },
              };
            }
          ),
          "date"
        )
      ),
      (row, idx) => {
        return {
          ...row,
          idx,
          key: `${componentId}-site-status-table-data-${idx}`,
        };
      }
    );

    const totalStatusOriginalData = _.map(
      _.entries(
        _.reduce(
          totalData,
          (
            totalStatusTableData,
            { cuid, customerName, name, rowCount, errorCount, type }
          ) => {
            totalStatusTableData[cuid] = {
              ..._.get(totalStatusTableData, cuid, {}),
              customerName,
              siteName: name,
              total: {
                logCount:
                  _.get(totalStatusTableData, [cuid, "total", "logCount"], 0) +
                  rowCount,
                errorCount:
                  _.get(
                    totalStatusTableData,
                    [cuid, "total", "errorCount"],
                    0
                  ) + errorCount,
              },
              [type]: {
                logCount:
                  _.get(totalStatusTableData, [cuid, type, "logCount"], 0) +
                  rowCount,
                errorCount:
                  _.get(totalStatusTableData, [cuid, type, "errorCount"], 0) +
                  errorCount,
              },
            };
            return totalStatusTableData;
          },
          {} as { [key: string]: any }
        )
      ),
      (
        [
          cuid,
          {
            total = {},
            product = {},
            view = {},
            order = {},
            visit = {},
            search = {},
            ...rest
          },
        ],
        idx
      ) => {
        return {
          ...rest,
          key: `${componentId}-total-status-table-data-${idx}`,
          idx,
          cuid,
          total: {
            ...total,
            errorRate: _.defaultTo(
              _.toNumber(
                toFixed(0)(
                  (_.get(total, "errorCount", 0) /
                    _.get(total, "logCount", 0)) *
                    100
                )
              ),
              0
            ),
          },
          product: {
            ...product,
            errorRate: _.defaultTo(
              _.toNumber(
                toFixed(0)(
                  (_.get(product, "errorCount", 0) /
                    _.get(product, "logCount", 0)) *
                    100
                )
              ),
              0
            ),
          },
          view: {
            ...view,
            errorRate: _.defaultTo(
              _.toNumber(
                toFixed(0)(
                  (_.get(view, "errorCount", 0) / _.get(view, "logCount", 0)) *
                    100
                )
              ),
              0
            ),
          },
          order: {
            ...order,
            errorRate: _.defaultTo(
              _.toNumber(
                toFixed(0)(
                  (_.get(order, "errorCount", 0) /
                    _.get(order, "logCount", 0)) *
                    100
                )
              ),
              0
            ),
          },
          visit: {
            ...visit,
            errorRate: _.defaultTo(
              _.toNumber(
                toFixed(0)(
                  (_.get(visit, "errorCount", 0) /
                    _.get(visit, "logCount", 0)) *
                    100
                )
              ),
              0
            ),
          },
          search: {
            ...search,
            errorRate: _.defaultTo(
              _.toNumber(
                toFixed(0)(
                  (_.get(search, "errorCount", 0) /
                    _.get(search, "logCount", 0)) *
                    100
                )
              ),
              0
            ),
          },
        };
      }
    );

    return { totalStatusOriginalData, siteStatusTableData };
  }, [totalData, componentId, siteData]);

  const { totalStatusTableData } = useMemo(() => {
    const totalStatusTableData = _.filter(
      _.filter(totalStatusOriginalData, ({ total: { errorRate } }) => {
        if (status === LOG_STATUS.ALL) return true;
        if (status === LOG_STATUS.WARN) return 0 < errorRate && errorRate < 10;
        if (status === LOG_STATUS.BAD) return 10 < errorRate;
        if (status === LOG_STATUS.GOOD) return errorRate === 0;
      }),
      ({ customerName, siteName }) => {
        return _.includes(customerName, search) || _.includes(siteName, search);
      }
    );
    return { totalStatusTableData };
  }, [status, search, totalStatusOriginalData]);

  const getTotalLogCollectStatus = useCallback(() => {
    dispatch(logAsyncAction.getTotalLogCollectStatus.request({}));
  }, [dispatch]);

  const getLogCollectStatusOfSite = useCallback(
    ({ cuid }: { cuid: string }) => {
      dispatch(logAsyncAction.getLogCollectStatusOfSite.request({ cuid }));
    },
    [dispatch]
  );
  const setCurrentDate = useCallback(
    ({ currentDate }: { currentDate: string }) => {
      dispatch(logAction.setCurrentDate({ currentDate }));
    },
    [dispatch]
  );

  const setSearch = useCallback(
    ({ search }: { search: string }) => {
      dispatch(logAction.setSearch({ search }));
    },
    [dispatch]
  );

  const setStatus = useCallback(
    ({ status }: { status: Status }) => {
      dispatch(logAction.setStatus({ status }));
    },
    [dispatch]
  );

  return {
    totalDataLoading,
    siteDataLoading,
    currentDate,
    search,
    status,
    totalStatusTableData,
    siteStatusTableData,
    setCurrentDate,
    setSearch,
    setStatus,
    getTotalLogCollectStatus,
    getLogCollectStatusOfSite,
  };
};
export default useLogStatusData;
