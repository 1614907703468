import Client from "apis";
import { DsocDemoRequest } from "types/api";

class DsocDemoService {
  static fetchDsocResult = async (dsocDemoResultRequest: DsocDemoRequest) => {
    return await Client.publicInstance.get(`${dsocDemoResultRequest.apiUrl}`, {
      headers: { Authorization: `Bearer ${dsocDemoResultRequest.token}` },
    });
  };
}

export default DsocDemoService;
