import SpoqaHanSansNeoBoldWoff2 from "./Spoqa Han Sans Neo Bold.woff2";
import SpoqaHanSansNeoBoldWoff from "./SpoqaHanSansNeo-Bold.woff";
import SpoqaHanSansNeoBoldTtf from "./SpoqaHanSansNeo-Bold.ttf";

import SpoqaHanSansNeoMediumWoff2 from "./Spoqa Han Sans Neo Medium.woff2";
import SpoqaHanSansNeoMediumWoff from "./SpoqaHanSansNeo-Medium.woff";
import SpoqaHanSansNeoMediumTtf from "./SpoqaHanSansNeo-Medium.ttf";

import SpoqaHanSansNeoRegularWoff2 from "./Spoqa Han Sans Neo Regular.woff2";
import SpoqaHanSansNeoRegularWoff from "./SpoqaHanSansNeo-Regular.woff";
import SpoqaHanSansNeoRegularTtf from "./SpoqaHanSansNeo-Regular.ttf";

import SpoqaHanSansNeoLightWoff2 from "./Spoqa Han Sans Neo Light.woff2";
import SpoqaHanSansNeoLightWoff from "./SpoqaHanSansNeo-Light.woff";
import SpoqaHanSansNeoLightTtf from "./SpoqaHanSansNeo-Light.ttf";

import SpoqaHanSansNeoThinWoff2 from "./Spoqa Han Sans Neo Thin.woff2";
import SpoqaHanSansNeoThinWoff from "./SpoqaHanSansNeo-Thin.woff";
import SpoqaHanSansNeoThinTtf from "./SpoqaHanSansNeo-Thin.ttf";

import JalnanWoff from "./JalnanOTF.woff";
import JalnanTtf from "./Jalnan.ttf";

import BMJUATtf from "./BMJUA_ttf.ttf";
import BMJUAWoff from "./BMJUA_ttf_1.woff";

import Cafe24DangdanghaeTtf from "./Cafe24Dangdanghae.ttf";
import Cafe24DangdanghaeWoff from "./Cafe24Dangdanghae.woff";

import NanumSquare_acEBTtf from "./NanumSquare_acEB.ttf";
import NanumSquare_acEBWoff from "./NanumSquare_acEB.woff";

import NanumSquare_acBTtf from "./NanumSquare_acB.ttf";
import NanumSquare_acBWoff from "./NanumSquare_acB.woff";

import NanumSquare_acRTtf from "./NanumSquare_acR.ttf";
import NanumSquare_acRWoff from "./NanumSquare_acR.woff";

import NanumSquare_acLTtf from "./NanumSquare_acL.ttf";
import NanumSquare_acLWoff from "./NanumSquare_acL.woff";

import NanumSquareEBttf from "./NanumSquareEB.ttf";
import NanumSquareEBwoff from "./NanumSquareEB.woff";

// 티에이 폰트
import Tway_Air from "./tway_air.ttf";

const Fonts = {
  SpoqaHanSansNeoBoldWoff2,
  SpoqaHanSansNeoBoldWoff,
  SpoqaHanSansNeoBoldTtf,
  SpoqaHanSansNeoMediumWoff2,
  SpoqaHanSansNeoMediumWoff,
  SpoqaHanSansNeoMediumTtf,
  SpoqaHanSansNeoRegularWoff2,
  SpoqaHanSansNeoRegularWoff,
  SpoqaHanSansNeoRegularTtf,
  SpoqaHanSansNeoLightWoff2,
  SpoqaHanSansNeoLightWoff,
  SpoqaHanSansNeoLightTtf,
  SpoqaHanSansNeoThinWoff2,
  SpoqaHanSansNeoThinWoff,
  SpoqaHanSansNeoThinTtf,
  JalnanWoff,
  JalnanTtf,
  BMJUATtf,
  BMJUAWoff,
  Cafe24DangdanghaeTtf,
  Cafe24DangdanghaeWoff,
  NanumSquare_acEBTtf,
  NanumSquare_acEBWoff,
  NanumSquare_acBTtf,
  NanumSquare_acBWoff,
  NanumSquare_acRTtf,
  NanumSquare_acRWoff,
  NanumSquare_acLTtf,
  NanumSquare_acLWoff,
  NanumSquareEBttf,
  NanumSquareEBwoff,
  Tway_Air,
};

export default Fonts;
