import ko from "locales/ko.json";
import en from "locales/en.json";
import ja from "locales/ja.json";
import template from "locales/template.json";

import { ReactComponent as EnUsSvg } from "assets/img/en_US.svg";
import { ReactComponent as KoKrSvg } from "assets/img/ko_KR.svg";
import { ReactComponent as JaJpSvg } from "assets/img/ja_JP.svg";

import type { Locale } from "types/user";
import ko_KR from "antd/lib/locale/ko_KR";
import ja_JP from "antd/lib/locale/ja_JP";
import en_US from "antd/lib/locale/en_US";
import _ from "lodash";

export const flattenMessages = (nestedMessages: any, prefix = "") => {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export const localeMap = {
  ko_KR: {
    label: "한국어",
    SvgComponent: KoKrSvg,
    antdLocale: ko_KR,
    intlLocale: ko,
  },
  en_US: {
    label: "English",
    SvgComponent: EnUsSvg,
    antdLocale: en_US,
    intlLocale: _.reduce(
      _.map(_.entries(flattenMessages(en)), ([key, value]) => ({
        [key]: `${value}`,
      })),
      (en, row) => ({ ...en, ...row }),
      {}
    ),
  },
  ja_JP: {
    label: "日本語",
    SvgComponent: JaJpSvg,
    antdLocale: ja_JP,
    intlLocale: _.reduce(
      _.map(_.entries(flattenMessages(ja)), ([key, value]) => ({
        [key]: `${value}`,
      })),
      (ja, row) => ({ ...ja, ...row }),
      {}
    ),
  },
  dev: {
    label: "TEXT_KEY",
    antdLocale: ko_KR,
    SvgComponent: KoKrSvg,
    intlLocale: template,
  },
};

export const getAntdLocale = (locale: Locale) =>
  _.get(localeMap, [locale, "antdLocale"]);
export const getIntlLocale = (locale: Locale) =>
  _.get(localeMap, [locale, "intlLocale"]);
export const getPrefixLocale = (locale: Locale) =>
  _.flow([_.split, _.head])(
    _.find(_.keys(localeMap), (key) => key === locale),
    "_"
  );
