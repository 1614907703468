import { FC } from "react";
import * as S from "pages/layout/footer/style";
import { getYear } from "date-fns";
import { useTheme } from "hooks";

const currentYear = getYear(new Date());

interface LayoutPageFooterProps {}
const LayoutPageFooter: FC<LayoutPageFooterProps> = () => {
  const { currentTheme } = useTheme();

  return (
    <S.LayoutPageFooter
      style ={{padding:"0.2em 2em"}}
      theme={currentTheme}
    >{`© ${currentYear} - EIGENE`}</S.LayoutPageFooter>
  );
};
export default LayoutPageFooter;
