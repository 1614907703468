const ADMIN = "admin";
const CLIENT = "client";

const MOBILE = "mobile";
const DESKTOP = "desktop";

const COMPLETE = "complete";
const INCOMPLETE = "incomplete";

const COMPLETE_COLOR = "rgb(24, 144, 255)";
const INCOMPLETE_COLOR = "#eb2f96";

const ALL = "all";
const PC = "pc";
const MO = "mo";

const CRITERION_STATUS = {
  VIEW_COUNT: "viewCount",
  ORDER_COUNT: "orderCount",
  REVENUE: "revenue",
  CONVERSION_RATE_SESSION: "conversionRateSession",
} as const;

const DAILY = "daily";
const WEEKLY = "weekly";
const MONTHLY = "monthly";

const PERSONA_INFO = "·····················································";

const Y = "y";
const N = "n";

const LOG_STATUS = {
  ALL: "all",
  GOOD: "good",
  WARN: "warn",
  BAD: "bad",
} as const;

const MANAGE_STATUS = {
  ALL: "all",
  ENABLE: "enable",
  DISABLE: "disable",
} as const;

const DSOC_ALL = "전체";
const DSOC_NOT_SELECTED = "선택안함";

const DSOC_STATUS = {
  ALL: "all",
  NORMAL: "normal",
  CUBOT: "cubot",
} as const;

const DSOC_CHANNEL_STATUS = {
  CHANNEL_ALL: "all",
  CHANNEL_PC: "pc",
  CHANNEL_MO: "mo",
} as const;

const CONFIGURATION_STATUS = {
  LIVE: "attached_live",
  TEST: "attached_test",
  DETACHED: "detached",
} as const;

export {
  ADMIN,
  CLIENT,
  MOBILE,
  DESKTOP,
  COMPLETE,
  INCOMPLETE,
  COMPLETE_COLOR,
  INCOMPLETE_COLOR,
  ALL,
  PC,
  MO,
  DAILY,
  WEEKLY,
  MONTHLY,
  LOG_STATUS,
  MANAGE_STATUS,
  PERSONA_INFO,
  DSOC_ALL,
  DSOC_NOT_SELECTED,
  DSOC_STATUS,
  DSOC_CHANNEL_STATUS,
  CONFIGURATION_STATUS,
  Y,
  N,
  CRITERION_STATUS,
};
