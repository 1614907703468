import { createAsyncAction } from "typesafe-actions";
import type {
  RecRequest,
  RecResultResponse,
  PersonaRequest,
  PersonaResponse,
  APIError,
} from "types/api";
import { RecDemoService, PersonaDemoService } from "apis/services";
import { AxiosResponse } from "axios";
import { put, takeLatest } from "@redux-saga/core/effects";
import { callWrapperSaga } from "utils/callWrapperSaga";
import { itemDeduplication } from "utils/personaUtil";

export const FETCH_PERSONA_PR001_RESULT = `persona/FETCH_PERSONA_PR001_RESULT`;
export const FETCH_PERSONA_PR001_RESULT_SUCCESS = `persona/FETCH_PERSONA_PR001_RESULT_SUCCESS`;
export const FETCH_PERSONA_PR001_RESULT_ERROR = `persona/FETCH_PERSONA_PR001_RESULT_ERROR`;

export const FETCH_PERSONA_PR002_RESULT = `persona/FETCH_PERSONA_PR002_RESULT`;
export const FETCH_PERSONA_PR002_RESULT_SUCCESS = `persona/FETCH_PERSONA_PR002_RESULT_SUCCESS`;
export const FETCH_PERSONA_PR002_RESULT_ERROR = `persona/FETCH_PERSONA_PR002_RESULT_ERROR`;

export const FETCH_PERSONA_PR003_RESULT = `persona/FETCH_PERSONA_PR003_RESULT`;
export const FETCH_PERSONA_PR003_RESULT_SUCCESS = `persona/FETCH_PERSONA_PR003_RESULT_SUCCESS`;
export const FETCH_PERSONA_PR003_RESULT_ERROR = `persona/FETCH_PERSONA_PR003_RESULT_ERROR`;

export const FETCH_PERSONA_PR004_RESULT = `persona/FETCH_PERSONA_PR004_RESULT`;
export const FETCH_PERSONA_PR004_RESULT_SUCCESS = `persona/FETCH_PERSONA_PR004_RESULT_SUCCESS`;
export const FETCH_PERSONA_PR004_RESULT_ERROR = `persona/FETCH_PERSONA_PR004_RESULT_ERROR`;

export const FETCH_PERSONA_PR005_RESULT = `persona/FETCH_PERSONA_PR005_RESULT`;
export const FETCH_PERSONA_PR005_RESULT_SUCCESS = `persona/FETCH_PERSONA_PR005_RESULT_SUCCESS`;
export const FETCH_PERSONA_PR005_RESULT_ERROR = `persona/FETCH_PERSONA_PR005_RESULT_ERROR`;

export const FETCH_PERSONA_PRC001_RESULT = `persona/FETCH_PERSONA_PRC001_RESULT`;
export const FETCH_PERSONA_PRC001_RESULT_SUCCESS = `persona/FETCH_PERSONA_PRC001_RESULT_SUCCESS`;
export const FETCH_PERSONA_PRC001_RESULT_ERROR = `persona/FETCH_PERSONA_PRC001_RESULT_ERROR`;

export const FETCH_PERSONA_PRC002_RESULT = `persona/FETCH_PERSONA_PRC002_RESULT`;
export const FETCH_PERSONA_PRC002_RESULT_SUCCESS = `persona/FETCH_PERSONA_PRC002_RESULT_SUCCESS`;
export const FETCH_PERSONA_PRC002_RESULT_ERROR = `persona/FETCH_PERSONA_PRC002_RESULT_ERROR`;

export const FETCH_PERSONA_PRC003_RESULT = `persona/FETCH_PERSONA_PRC003_RESULT`;
export const FETCH_PERSONA_PRC003_RESULT_SUCCESS = `persona/FETCH_PERSONA_PRC003_RESULT_SUCCESS`;
export const FETCH_PERSONA_PRC003_RESULT_ERROR = `persona/FETCH_PERSONA_PRC003_RESULT_ERROR`;

export const FETCH_PERSONA_PRO001_RESULT = `persona/FETCH_PERSONA_PRO001_RESULT`;
export const FETCH_PERSONA_PRO001_RESULT_SUCCESS = `persona/FETCH_PERSONA_PRO001_RESULT_SUCCESS`;
export const FETCH_PERSONA_PRO001_RESULT_ERROR = `persona/FETCH_PERSONA_PRO001_RESULT_ERROR`;

export const FETCH_PERSONA_PRO002_RESULT = `persona/FETCH_PERSONA_PRO002_RESULT`;
export const FETCH_PERSONA_PRO002_RESULT_SUCCESS = `persona/FETCH_PERSONA_PRO002_RESULT_SUCCESS`;
export const FETCH_PERSONA_PRO002_RESULT_ERROR = `persona/FETCH_PERSONA_PRO002_RESULT_ERROR`;

export const FETCH_PERSONA_PRO003_RESULT = `persona/FETCH_PERSONA_PRO003_RESULT`;
export const FETCH_PERSONA_PRO003_RESULT_SUCCESS = `persona/FETCH_PERSONA_PRO003_RESULT_SUCCESS`;
export const FETCH_PERSONA_PRO003_RESULT_ERROR = `persona/FETCH_PERSONA_PRO003_RESULT_ERROR`;

export const FETCH_PERSONA_NH_PRC001_RESULT = `persona/FETCH_PERSONA_NH_PRC001_RESULT`;
export const FETCH_PERSONA_NH_PRC001_RESULT_SUCCESS = `persona/FETCH_PERSONA_NH_PRC001_RESULT_SUCCESS`;
export const FETCH_PERSONA_NH_PRC001_RESULT_ERROR = `persona/FETCH_PERSONA_NH_PRC001_RESULT_ERROR`;

export const FETCH_PERSONA_NH_PRC002_RESULT = `persona/FETCH_PERSONA_NH_PRC002_RESULT`;
export const FETCH_PERSONA_NH_PRC002_RESULT_SUCCESS = `persona/FETCH_PERSONA_NH_PRC002_RESULT_SUCCESS`;
export const FETCH_PERSONA_NH_PRC002_RESULT_ERROR = `persona/FETCH_PERSONA_NH_PRC002_RESULT_ERROR`;

export const FETCH_PERSONA_NH_PRC003_RESULT = `persona/FETCH_PERSONA_NH_PRC003_RESULT`;
export const FETCH_PERSONA_NH_PRC003_RESULT_SUCCESS = `persona/FETCH_PERSONA_NH_PRC003_RESULT_SUCCESS`;
export const FETCH_PERSONA_NH_PRC003_RESULT_ERROR = `persona/FETCH_PERSONA_NH_PRC003_RESULT_ERROR`;

export const FETCH_PERSONA_NH_PRO001_RESULT = `persona/FETCH_PERSONA_NH_PRO001_RESULT`;
export const FETCH_PERSONA_NH_PRO001_RESULT_SUCCESS = `persona/FETCH_PERSONA_NH_PRO001_RESULT_SUCCESS`;
export const FETCH_PERSONA_NH_PRO001_RESULT_ERROR = `persona/FETCH_PERSONA_NH_PRO001_RESULT_ERROR`;

export const FETCH_PERSONA_NH_PRO002_RESULT = `persona/FETCH_PERSONA_NH_PRO002_RESULT`;
export const FETCH_PERSONA_NH_PRO002_RESULT_SUCCESS = `persona/FETCH_PERSONA_NH_PRO002_RESULT_SUCCESS`;
export const FETCH_PERSONA_NH_PRO002_RESULT_ERROR = `persona/FETCH_PERSONA_NH_PRO002_RESULT_ERROR`;

export const FETCH_PERSONA_NH_PRO003_RESULT = `persona/FETCH_PERSONA_NH_PRO003_RESULT`;
export const FETCH_PERSONA_NH_PRO003_RESULT_SUCCESS = `persona/FETCH_PERSONA_NH_PRO003_RESULT_SUCCESS`;
export const FETCH_PERSONA_NH_PRO003_RESULT_ERROR = `persona/FETCH_PERSONA_NH_PRO003_RESULT_ERROR`;

export const FETCH_PERSONA_CUSTOMER_C001_RESULT = `persona/FETCH_PERSONA_CUSTOMER_C001_RESULT`;
export const FETCH_PERSONA_CUSTOMER_C001_RESULT_SUCCESS = `persona/FETCH_PERSONA_CUSTOMER_C001_RESULT_SUCCESS`;
export const FETCH_PERSONA_CUSTOMER_C001_RESULT_ERROR = `persona/FETCH_PERSONA_CUSTOMER_C001_RESULT_ERROR`;

export const FETCH_PERSONA_CUSTOMER_B004_RESULT = `persona/FETCH_PERSONA_CUSTOMER_B004_RESULT`;
export const FETCH_PERSONA_CUSTOMER_B004_RESULT_SUCCESS = `persona/FETCH_PERSONA_CUSTOMER_B004_RESULT_SUCCESS`;
export const FETCH_PERSONA_CUSTOMER_B004_RESULT_ERROR = `persona/FETCH_PERSONA_CUSTOMER_B004_RESULT_ERROR`;

export const FETCH_PERSONA_CUSTOMER_A001_RESULT = `persona/FETCH_PERSONA_CUSTOMER_A001_RESULT`;
export const FETCH_PERSONA_CUSTOMER_A001_RESULT_SUCCESS = `persona/FETCH_PERSONA_CUSTOMER_A001_RESULT_SUCCESS`;
export const FETCH_PERSONA_CUSTOMER_A001_RESULT_ERROR = `persona/FETCH_PERSONA_CUSTOMER_A001_RESULT_ERROR`;

export const FETCH_PERSONA_NH_CUSTOMER_C001_RESULT = `persona/FETCH_PERSONA_NH_CUSTOMER_C001_RESULT`;
export const FETCH_PERSONA_NH_CUSTOMER_C001_RESULT_SUCCESS = `persona/FETCH_PERSONA_NH_CUSTOMER_C001_RESULT_SUCCESS`;
export const FETCH_PERSONA_NH_CUSTOMER_C001_RESULT_ERROR = `persona/FETCH_PERSONA_NH_CUSTOMER_C001_RESULT_ERROR`;

export const FETCH_PERSONA_NH_CUSTOMER_B004_RESULT = `persona/FETCH_PERSONA_NH_CUSTOMER_B004_RESULT`;
export const FETCH_PERSONA_NH_CUSTOMER_B004_RESULT_SUCCESS = `persona/FETCH_PERSONA_NH_CUSTOMER_B004_RESULT_SUCCESS`;
export const FETCH_PERSONA_NH_CUSTOMER_B004_RESULT_ERROR = `persona/FETCH_PERSONA_NH_CUSTOMER_B004_RESULT_ERROR`;

export const FETCH_PERSONA_NH_CUSTOMER_A001_RESULT = `persona/FETCH_PERSONA_NH_CUSTOMER_A001_RESULT`;
export const FETCH_PERSONA_NH_CUSTOMER_A001_RESULT_SUCCESS = `persona/FETCH_PERSONA_NH_CUSTOMER_A001_RESULT_SUCCESS`;
export const FETCH_PERSONA_NH_CUSTOMER_A001_RESULT_ERROR = `persona/FETCH_PERSONA_NH_CUSTOMER_A001_RESULT_ERROR`;

export const FETCH_PERSONA_PRST001_RESULT = `persona/FETCH_PERSONA_PRST001_RESULT`;
export const FETCH_PERSONA_PRST001_RESULT_SUCCESS = `persona/FETCH_PERSONA_PRST001_RESULT_SUCCESS`;
export const FETCH_PERSONA_PRST001_RESULT_ERROR = `persona/FETCH_PERSONA_PRST001_RESULT_ERROR`;

export const FETCH_PERSONA_PRST002_RESULT = `persona/FETCH_PERSONA_PRST002_RESULT`;
export const FETCH_PERSONA_PRST002_RESULT_SUCCESS = `persona/FETCH_PERSONA_PRST002_RESULT_SUCCESS`;
export const FETCH_PERSONA_PRST002_RESULT_ERROR = `persona/FETCH_PERSONA_PRST002_RESULT_ERROR`;

export const FETCH_PERSONA_PRST003_RESULT = `persona/FETCH_PERSONA_PRST003_RESULT`;
export const FETCH_PERSONA_PRST003_RESULT_SUCCESS = `persona/FETCH_PERSONA_PRST003_RESULT_SUCCESS`;
export const FETCH_PERSONA_PRST003_RESULT_ERROR = `persona/FETCH_PERSONA_PRST003_RESULT_ERROR`;

export const FETCH_PERSONA_PRST004_RESULT = `persona/FETCH_PERSONA_PRST004_RESULT`;
export const FETCH_PERSONA_PRST004_RESULT_SUCCESS = `persona/FETCH_PERSONA_PRST004_RESULT_SUCCESS`;
export const FETCH_PERSONA_PRST004_RESULT_ERROR = `persona/FETCH_PERSONA_PRST004_RESULT_ERROR`;

export const FETCH_PERSONA_PRST005_RESULT = `persona/FETCH_PERSONA_PRST005_RESULT`;
export const FETCH_PERSONA_PRST005_RESULT_SUCCESS = `persona/FETCH_PERSONA_PRST005_RESULT_SUCCESS`;
export const FETCH_PERSONA_PRST005_RESULT_ERROR = `persona/FETCH_PERSONA_PRST005_RESULT_ERROR`;

export const FETCH_PERSONA_RANKING_S001_RESULT = `persona/FETCH_PERSONA_RANKING_S001_RESULT`;
export const FETCH_PERSONA_RANKING_S001_RESULT_SUCCESS = `persona/FETCH_PERSONA_RANKING_S001_RESULT_SUCCESS`;
export const FETCH_PERSONA_RANKING_S001_RESULT_ERROR = `persona/FETCH_PERSONA_RANKING_S001_RESULT_ERROR`;

export const FETCH_PERSONA_WEEKLY_S001_RESULT = `persona/FETCH_PERSONA_WEEKLY_S001_RESULT`;
export const FETCH_PERSONA_WEEKLY_S001_RESULT_SUCCESS = `persona/FETCH_PERSONA_WEEKLY_S001_RESULT_SUCCESS`;
export const FETCH_PERSONA_WEEKLY_S001_RESULT_ERROR = `persona/FETCH_PERSONA_WEEKLY_S001_RESULT_ERROR`;

export const fetchPersonaPr001Result = createAsyncAction(
  FETCH_PERSONA_PR001_RESULT,
  FETCH_PERSONA_PR001_RESULT_SUCCESS,
  FETCH_PERSONA_PR001_RESULT_ERROR
)<PersonaRequest, { pr001: PersonaResponse }, APIError>();

function* fetchPersonaPr001ResultSaga(
  action: ReturnType<typeof fetchPersonaPr001Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPr001Result.success({ pr001: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPr001Result.failure({ data, status }));
  }
}

export const fetchPersonaPr002Result = createAsyncAction(
  FETCH_PERSONA_PR002_RESULT,
  FETCH_PERSONA_PR002_RESULT_SUCCESS,
  FETCH_PERSONA_PR002_RESULT_ERROR
)<PersonaRequest, { pr002: PersonaResponse }, APIError>();

function* fetchPersonaPr002ResultSaga(
  action: ReturnType<typeof fetchPersonaPr002Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );
    yield put(fetchPersonaPr002Result.success({ pr002: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPr002Result.failure({ data, status }));
  }
}

export const fetchPersonaPr003Result = createAsyncAction(
  FETCH_PERSONA_PR003_RESULT,
  FETCH_PERSONA_PR003_RESULT_SUCCESS,
  FETCH_PERSONA_PR003_RESULT_ERROR
)<PersonaRequest, { pr003: PersonaResponse }, APIError>();

function* fetchPersonaPr003ResultSaga(
  action: ReturnType<typeof fetchPersonaPr003Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPr003Result.success({ pr003: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPr003Result.failure({ data, status }));
  }
}

export const fetchPersonaPr004Result = createAsyncAction(
  FETCH_PERSONA_PR004_RESULT,
  FETCH_PERSONA_PR004_RESULT_SUCCESS,
  FETCH_PERSONA_PR004_RESULT_ERROR
)<PersonaRequest, { pr004: PersonaResponse }, APIError>();

function* fetchPersonaPr004ResultSaga(
  action: ReturnType<typeof fetchPersonaPr004Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPr004Result.success({ pr004: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPr004Result.failure({ data, status }));
  }
}

export const fetchPersonaPr005Result = createAsyncAction(
  FETCH_PERSONA_PR005_RESULT,
  FETCH_PERSONA_PR005_RESULT_SUCCESS,
  FETCH_PERSONA_PR005_RESULT_ERROR
)<PersonaRequest, { pr005: PersonaResponse }, APIError>();

function* fetchPersonaPr005ResultSaga(
  action: ReturnType<typeof fetchPersonaPr005Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPr005Result.success({ pr005: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPr005Result.failure({ data, status }));
  }
}

export const fetchPersonaPrc001Result = createAsyncAction(
  FETCH_PERSONA_PRC001_RESULT,
  FETCH_PERSONA_PRC001_RESULT_SUCCESS,
  FETCH_PERSONA_PRC001_RESULT_ERROR
)<PersonaRequest, { prc001: PersonaResponse }, APIError>();

function* fetchPersonaPrc001ResultSaga(
  action: ReturnType<typeof fetchPersonaPrc001Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPrc001Result.success({ prc001: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPrc001Result.failure({ data, status }));
  }
}

export const fetchPersonaPrc002Result = createAsyncAction(
  FETCH_PERSONA_PRC002_RESULT,
  FETCH_PERSONA_PRC002_RESULT_SUCCESS,
  FETCH_PERSONA_PRC002_RESULT_ERROR
)<PersonaRequest, { prc002: PersonaResponse }, APIError>();

function* fetchPersonaPrc002ResultSaga(
  action: ReturnType<typeof fetchPersonaPrc002Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPrc002Result.success({ prc002: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPrc002Result.failure({ data, status }));
  }
}

export const fetchPersonaPrc003Result = createAsyncAction(
  FETCH_PERSONA_PRC003_RESULT,
  FETCH_PERSONA_PRC003_RESULT_SUCCESS,
  FETCH_PERSONA_PRC003_RESULT_ERROR
)<PersonaRequest, { prc003: PersonaResponse }, APIError>();

function* fetchPersonaPrc003ResultSaga(
  action: ReturnType<typeof fetchPersonaPrc003Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPrc003Result.success({ prc003: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPrc003Result.failure({ data, status }));
  }
}

export const fetchPersonaPro001Result = createAsyncAction(
  FETCH_PERSONA_PRO001_RESULT,
  FETCH_PERSONA_PRO001_RESULT_SUCCESS,
  FETCH_PERSONA_PRO001_RESULT_ERROR
)<PersonaRequest, { pro001: PersonaResponse }, APIError>();

function* fetchPersonaPro001ResultSaga(
  action: ReturnType<typeof fetchPersonaPro001Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPro001Result.success({ pro001: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPro001Result.failure({ data, status }));
  }
}

export const fetchPersonaPro002Result = createAsyncAction(
  FETCH_PERSONA_PRO002_RESULT,
  FETCH_PERSONA_PRO002_RESULT_SUCCESS,
  FETCH_PERSONA_PRO002_RESULT_ERROR
)<PersonaRequest, { pro002: PersonaResponse }, APIError>();

function* fetchPersonaPro002ResultSaga(
  action: ReturnType<typeof fetchPersonaPro002Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPro002Result.success({ pro002: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPro002Result.failure({ data, status }));
  }
}

export const fetchPersonaPro003Result = createAsyncAction(
  FETCH_PERSONA_PRO003_RESULT,
  FETCH_PERSONA_PRO003_RESULT_SUCCESS,
  FETCH_PERSONA_PRO003_RESULT_ERROR
)<PersonaRequest, { pro003: PersonaResponse }, APIError>();

function* fetchPersonaPro003ResultSaga(
  action: ReturnType<typeof fetchPersonaPro003Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPro003Result.success({ pro003: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPro003Result.failure({ data, status }));
  }
}

export const fetchPersonaNhPrc001Result = createAsyncAction(
  FETCH_PERSONA_NH_PRC001_RESULT,
  FETCH_PERSONA_NH_PRC001_RESULT_SUCCESS,
  FETCH_PERSONA_NH_PRC001_RESULT_ERROR
)<PersonaRequest, { nh_prc001: PersonaResponse }, APIError>();

function* fetchPersonaNhPrc001ResultSaga(
  action: ReturnType<typeof fetchPersonaNhPrc001Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaNhPrc001Result.success({ nh_prc001: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaNhPrc001Result.failure({ data, status }));
  }
}

export const fetchPersonaNhPrc002Result = createAsyncAction(
  FETCH_PERSONA_NH_PRC002_RESULT,
  FETCH_PERSONA_NH_PRC002_RESULT_SUCCESS,
  FETCH_PERSONA_NH_PRC002_RESULT_ERROR
)<PersonaRequest, { nh_prc002: PersonaResponse }, APIError>();

function* fetchPersonaNhPrc002ResultSaga(
  action: ReturnType<typeof fetchPersonaNhPrc002Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaNhPrc002Result.success({ nh_prc002: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaNhPrc002Result.failure({ data, status }));
  }
}

export const fetchPersonaNhPrc003Result = createAsyncAction(
  FETCH_PERSONA_NH_PRC003_RESULT,
  FETCH_PERSONA_NH_PRC003_RESULT_SUCCESS,
  FETCH_PERSONA_NH_PRC003_RESULT_ERROR
)<PersonaRequest, { nh_prc003: PersonaResponse }, APIError>();

function* fetchPersonaNhPrc003ResultSaga(
  action: ReturnType<typeof fetchPersonaNhPrc003Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaNhPrc003Result.success({ nh_prc003: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaNhPrc003Result.failure({ data, status }));
  }
}

export const fetchPersonaNhPro001Result = createAsyncAction(
  FETCH_PERSONA_NH_PRO001_RESULT,
  FETCH_PERSONA_NH_PRO001_RESULT_SUCCESS,
  FETCH_PERSONA_NH_PRO001_RESULT_ERROR
)<PersonaRequest, { nh_pro001: PersonaResponse }, APIError>();

function* fetchPersonaNhPro001ResultSaga(
  action: ReturnType<typeof fetchPersonaNhPro001Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaNhPro001Result.success({ nh_pro001: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaNhPro001Result.failure({ data, status }));
  }
}

export const fetchPersonaNhPro002Result = createAsyncAction(
  FETCH_PERSONA_NH_PRO002_RESULT,
  FETCH_PERSONA_NH_PRO002_RESULT_SUCCESS,
  FETCH_PERSONA_NH_PRO002_RESULT_ERROR
)<PersonaRequest, { nh_pro002: PersonaResponse }, APIError>();

function* fetchPersonaNhPro002ResultSaga(
  action: ReturnType<typeof fetchPersonaNhPro002Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaNhPro002Result.success({ nh_pro002: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaNhPro002Result.failure({ data, status }));
  }
}

export const fetchPersonaNhPro003Result = createAsyncAction(
  FETCH_PERSONA_NH_PRO003_RESULT,
  FETCH_PERSONA_NH_PRO003_RESULT_SUCCESS,
  FETCH_PERSONA_NH_PRO003_RESULT_ERROR
)<PersonaRequest, { nh_pro003: PersonaResponse }, APIError>();

function* fetchPersonaNhPro003ResultSaga(
  action: ReturnType<typeof fetchPersonaNhPro003Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaNhPro003Result.success({ nh_pro003: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaNhPro003Result.failure({ data, status }));
  }
}

export const fetchPersonaCustomC001Result = createAsyncAction(
  FETCH_PERSONA_CUSTOMER_C001_RESULT,
  FETCH_PERSONA_CUSTOMER_C001_RESULT_SUCCESS,
  FETCH_PERSONA_CUSTOMER_C001_RESULT_ERROR
)<RecRequest, { customer_c001: RecResultResponse }, APIError>();

function* fetchPersonaCustomC001ResultSaga(
  action: ReturnType<typeof fetchPersonaCustomC001Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      RecDemoService.fetchRecResult,
      action.payload
    );

    yield put(fetchPersonaCustomC001Result.success({ customer_c001: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaCustomC001Result.failure({ data, status }));
  }
}

export const fetchPersonaCustomB004Result = createAsyncAction(
  FETCH_PERSONA_CUSTOMER_B004_RESULT,
  FETCH_PERSONA_CUSTOMER_B004_RESULT_SUCCESS,
  FETCH_PERSONA_CUSTOMER_B004_RESULT_ERROR
)<RecRequest, { customer_b004: RecResultResponse }, APIError>();

function* fetchPersonaCustomB004ResultSaga(
  action: ReturnType<typeof fetchPersonaCustomB004Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      RecDemoService.fetchRecResult,
      action.payload
    );

    yield put(fetchPersonaCustomB004Result.success({ customer_b004: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaCustomB004Result.failure({ data, status }));
  }
}

export const fetchPersonaCustomA001Result = createAsyncAction(
  FETCH_PERSONA_CUSTOMER_A001_RESULT,
  FETCH_PERSONA_CUSTOMER_A001_RESULT_SUCCESS,
  FETCH_PERSONA_CUSTOMER_A001_RESULT_ERROR
)<RecRequest, { customer_a001: RecResultResponse }, APIError>();

function* fetchPersonaCustomA001ResultSaga(
  action: ReturnType<typeof fetchPersonaCustomA001Result.request>
) {
  try {
    let customResult: RecResultResponse = { results: [] };
    const { apiUrl, inputList } = action.payload;
    if (!inputList || inputList.length < 1) return;

    for (let input of inputList) {
      let apiUrlAddedInput = apiUrl + input;
      const { data } = yield callWrapperSaga(RecDemoService.fetchRecResult, {
        apiUrl: apiUrlAddedInput,
      });
      const { results } = data;
      customResult.results.push(...results.slice(0, 5));
    }
    customResult.results = itemDeduplication(customResult.results);

    yield put(
      fetchPersonaCustomA001Result.success({ customer_a001: customResult })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaCustomA001Result.failure({ data, status }));
  }
}

export const fetchPersonaNhCustomC001Result = createAsyncAction(
  FETCH_PERSONA_NH_CUSTOMER_C001_RESULT,
  FETCH_PERSONA_NH_CUSTOMER_C001_RESULT_SUCCESS,
  FETCH_PERSONA_NH_CUSTOMER_C001_RESULT_ERROR
)<RecRequest, { nh_customer_c001: RecResultResponse }, APIError>();

function* fetchPersonaNhCustomC001ResultSaga(
  action: ReturnType<typeof fetchPersonaNhCustomC001Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      RecDemoService.fetchRecResult,
      action.payload
    );

    yield put(
      fetchPersonaNhCustomC001Result.success({ nh_customer_c001: data })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaNhCustomC001Result.failure({ data, status }));
  }
}

export const fetchPersonaNhCustomB004Result = createAsyncAction(
  FETCH_PERSONA_NH_CUSTOMER_B004_RESULT,
  FETCH_PERSONA_NH_CUSTOMER_B004_RESULT_SUCCESS,
  FETCH_PERSONA_NH_CUSTOMER_B004_RESULT_ERROR
)<RecRequest, { nh_customer_b004: RecResultResponse }, APIError>();

function* fetchPersonaNhCustomB004ResultSaga(
  action: ReturnType<typeof fetchPersonaNhCustomB004Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      RecDemoService.fetchRecResult,
      action.payload
    );

    yield put(
      fetchPersonaNhCustomB004Result.success({ nh_customer_b004: data })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaNhCustomB004Result.failure({ data, status }));
  }
}

export const fetchPersonaNhCustomA001Result = createAsyncAction(
  FETCH_PERSONA_NH_CUSTOMER_A001_RESULT,
  FETCH_PERSONA_NH_CUSTOMER_A001_RESULT_SUCCESS,
  FETCH_PERSONA_NH_CUSTOMER_A001_RESULT_ERROR
)<RecRequest, { nh_customer_a001: RecResultResponse }, APIError>();

function* fetchPersonaNhCustomA001ResultSaga(
  action: ReturnType<typeof fetchPersonaNhCustomA001Result.request>
) {
  try {
    let customResult: RecResultResponse = { results: [] };
    const { apiUrl, inputList } = action.payload;
    if (!inputList || inputList.length < 1) return;

    for (let input of inputList) {
      let apiUrlAddedInput = apiUrl + input;
      const { data } = yield callWrapperSaga(RecDemoService.fetchRecResult, {
        apiUrl: apiUrlAddedInput,
      });
      const { results } = data;
      customResult.results.push(...results.slice(0, 5));
    }
    customResult.results = itemDeduplication(customResult.results);
    yield put(
      fetchPersonaNhCustomA001Result.success({ nh_customer_a001: customResult })
    );
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaNhCustomA001Result.failure({ data, status }));
  }
}

export const fetchPersonaPrst001Result = createAsyncAction(
  FETCH_PERSONA_PRST001_RESULT,
  FETCH_PERSONA_PRST001_RESULT_SUCCESS,
  FETCH_PERSONA_PRST001_RESULT_ERROR
)<PersonaRequest, { prst001: PersonaResponse }, APIError>();

function* fetchPersonaPrst001ResultSaga(
  action: ReturnType<typeof fetchPersonaPrst001Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPrst001Result.success({ prst001: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPrst001Result.failure({ data, status }));
  }
}

export const fetchPersonaPrst002Result = createAsyncAction(
  FETCH_PERSONA_PRST002_RESULT,
  FETCH_PERSONA_PRST002_RESULT_SUCCESS,
  FETCH_PERSONA_PRST002_RESULT_ERROR
)<PersonaRequest, { prst002: PersonaResponse }, APIError>();

function* fetchPersonaPrst002ResultSaga(
  action: ReturnType<typeof fetchPersonaPrst002Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPrst002Result.success({ prst002: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPrst002Result.failure({ data, status }));
  }
}

export const fetchPersonaPrst003Result = createAsyncAction(
  FETCH_PERSONA_PRST003_RESULT,
  FETCH_PERSONA_PRST003_RESULT_SUCCESS,
  FETCH_PERSONA_PRST003_RESULT_ERROR
)<PersonaRequest, { prst003: PersonaResponse }, APIError>();

function* fetchPersonaPrst003ResultSaga(
  action: ReturnType<typeof fetchPersonaPrst003Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPrst003Result.success({ prst003: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPrst003Result.failure({ data, status }));
  }
}

export const fetchPersonaPrst004Result = createAsyncAction(
  FETCH_PERSONA_PRST004_RESULT,
  FETCH_PERSONA_PRST004_RESULT_SUCCESS,
  FETCH_PERSONA_PRST004_RESULT_ERROR
)<PersonaRequest, { prst004: PersonaResponse }, APIError>();

function* fetchPersonaPrst004ResultSaga(
  action: ReturnType<typeof fetchPersonaPrst004Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPrst004Result.success({ prst004: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPrst004Result.failure({ data, status }));
  }
}

export const fetchPersonaPrst005Result = createAsyncAction(
  FETCH_PERSONA_PRST005_RESULT,
  FETCH_PERSONA_PRST005_RESULT_SUCCESS,
  FETCH_PERSONA_PRST005_RESULT_ERROR
)<PersonaRequest, { prst005: PersonaResponse }, APIError>();

function* fetchPersonaPrst005ResultSaga(
  action: ReturnType<typeof fetchPersonaPrst005Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      PersonaDemoService.fetchPersonaResult,
      action.payload
    );

    yield put(fetchPersonaPrst005Result.success({ prst005: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaPrst005Result.failure({ data, status }));
  }
}

export const fetchPersonaRankingS001Result = createAsyncAction(
  FETCH_PERSONA_RANKING_S001_RESULT,
  FETCH_PERSONA_RANKING_S001_RESULT_SUCCESS,
  FETCH_PERSONA_RANKING_S001_RESULT_ERROR
)<RecRequest, { ranking_s001: RecResultResponse }, APIError>();

function* fetchPersonaRankingS001ResultSaga(
  action: ReturnType<typeof fetchPersonaRankingS001Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      RecDemoService.fetchRecResult,
      action.payload
    );

    yield put(fetchPersonaRankingS001Result.success({ ranking_s001: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaRankingS001Result.failure({ data, status }));
  }
}

export const fetchPersonaWeeklyS001Result = createAsyncAction(
  FETCH_PERSONA_WEEKLY_S001_RESULT,
  FETCH_PERSONA_WEEKLY_S001_RESULT_SUCCESS,
  FETCH_PERSONA_WEEKLY_S001_RESULT_ERROR
)<RecRequest, { weekly_s001: RecResultResponse }, APIError>();

function* fetchPersonaWeeklyS001ResultSaga(
  action: ReturnType<typeof fetchPersonaWeeklyS001Result.request>
) {
  try {
    const { data } = yield callWrapperSaga(
      RecDemoService.fetchRecResult,
      action.payload
    );

    yield put(fetchPersonaWeeklyS001Result.success({ weekly_s001: data }));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchPersonaWeeklyS001Result.failure({ data, status }));
  }
}
export const personaDemoAsyncAction = {
  fetchPersonaPr001Result,
  fetchPersonaPr002Result,
  fetchPersonaPr003Result,
  fetchPersonaPr004Result,
  fetchPersonaPr005Result,
  fetchPersonaPrc001Result,
  fetchPersonaPrc002Result,
  fetchPersonaPrc003Result,
  fetchPersonaPro001Result,
  fetchPersonaPro002Result,
  fetchPersonaPro003Result,
  fetchPersonaCustomC001Result,
  fetchPersonaCustomB004Result,
  fetchPersonaCustomA001Result,
  fetchPersonaNhPrc001Result,
  fetchPersonaNhPrc002Result,
  fetchPersonaNhPrc003Result,
  fetchPersonaNhPro001Result,
  fetchPersonaNhPro002Result,
  fetchPersonaNhPro003Result,
  fetchPersonaNhCustomC001Result,
  fetchPersonaNhCustomB004Result,
  fetchPersonaNhCustomA001Result,
  fetchPersonaPrst001Result,
  fetchPersonaPrst002Result,
  fetchPersonaPrst003Result,
  fetchPersonaPrst004Result,
  fetchPersonaPrst005Result,
  fetchPersonaRankingS001Result,
  fetchPersonaWeeklyS001Result,
};

export default function* personaDemoSaga() {
  yield takeLatest(FETCH_PERSONA_PR001_RESULT, fetchPersonaPr001ResultSaga);
  yield takeLatest(FETCH_PERSONA_PR002_RESULT, fetchPersonaPr002ResultSaga);
  yield takeLatest(FETCH_PERSONA_PR003_RESULT, fetchPersonaPr003ResultSaga);
  yield takeLatest(FETCH_PERSONA_PR004_RESULT, fetchPersonaPr004ResultSaga);
  yield takeLatest(FETCH_PERSONA_PR005_RESULT, fetchPersonaPr005ResultSaga);
  yield takeLatest(FETCH_PERSONA_PRC001_RESULT, fetchPersonaPrc001ResultSaga);
  yield takeLatest(FETCH_PERSONA_PRC002_RESULT, fetchPersonaPrc002ResultSaga);
  yield takeLatest(FETCH_PERSONA_PRC003_RESULT, fetchPersonaPrc003ResultSaga);
  yield takeLatest(FETCH_PERSONA_PRO001_RESULT, fetchPersonaPro001ResultSaga);
  yield takeLatest(FETCH_PERSONA_PRO002_RESULT, fetchPersonaPro002ResultSaga);
  yield takeLatest(FETCH_PERSONA_PRO003_RESULT, fetchPersonaPro003ResultSaga);
  yield takeLatest(
    FETCH_PERSONA_NH_PRC001_RESULT,
    fetchPersonaNhPrc001ResultSaga
  );
  yield takeLatest(
    FETCH_PERSONA_NH_PRC002_RESULT,
    fetchPersonaNhPrc002ResultSaga
  );
  yield takeLatest(
    FETCH_PERSONA_NH_PRC003_RESULT,
    fetchPersonaNhPrc003ResultSaga
  );
  yield takeLatest(
    FETCH_PERSONA_NH_PRO001_RESULT,
    fetchPersonaNhPro001ResultSaga
  );
  yield takeLatest(
    FETCH_PERSONA_NH_PRO002_RESULT,
    fetchPersonaNhPro002ResultSaga
  );
  yield takeLatest(
    FETCH_PERSONA_NH_PRO003_RESULT,
    fetchPersonaNhPro003ResultSaga
  );

  yield takeLatest(
    FETCH_PERSONA_CUSTOMER_C001_RESULT,
    fetchPersonaCustomC001ResultSaga
  );
  yield takeLatest(
    FETCH_PERSONA_CUSTOMER_B004_RESULT,
    fetchPersonaCustomB004ResultSaga
  );
  yield takeLatest(
    FETCH_PERSONA_CUSTOMER_A001_RESULT,
    fetchPersonaCustomA001ResultSaga
  );

  yield takeLatest(
    FETCH_PERSONA_NH_CUSTOMER_C001_RESULT,
    fetchPersonaNhCustomC001ResultSaga
  );
  yield takeLatest(
    FETCH_PERSONA_NH_CUSTOMER_B004_RESULT,
    fetchPersonaNhCustomB004ResultSaga
  );
  yield takeLatest(
    FETCH_PERSONA_NH_CUSTOMER_A001_RESULT,
    fetchPersonaNhCustomA001ResultSaga
  );
  yield takeLatest(FETCH_PERSONA_PRST001_RESULT, fetchPersonaPrst001ResultSaga);
  yield takeLatest(FETCH_PERSONA_PRST002_RESULT, fetchPersonaPrst002ResultSaga);
  yield takeLatest(FETCH_PERSONA_PRST003_RESULT, fetchPersonaPrst003ResultSaga);
  yield takeLatest(FETCH_PERSONA_PRST004_RESULT, fetchPersonaPrst004ResultSaga);
  yield takeLatest(FETCH_PERSONA_PRST005_RESULT, fetchPersonaPrst005ResultSaga);
  yield takeLatest(
    FETCH_PERSONA_RANKING_S001_RESULT,
    fetchPersonaRankingS001ResultSaga
  );
  yield takeLatest(
    FETCH_PERSONA_WEEKLY_S001_RESULT,
    fetchPersonaWeeklyS001ResultSaga
  );
}
