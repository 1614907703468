import Client from "apis";
import { RandomRequest, RandomRecRequest } from "types/api";

class RandomDemoService {
  static fetchRandomInput = async (randomRequest: RandomRequest) => {
    return await Client.publicInstance.get(
      `https://persona-random.eigene.io/?cuid=${randomRequest.cuid}&type=${randomRequest.type}`
    );
  };
  static fetchRandomRecInput = async (randomRecRequest: RandomRecRequest) => {
    return await Client.publicInstance.get(
      `https://1o2t9yjltb.execute-api.ap-northeast-2.amazonaws.com/prod?cuid=${randomRecRequest.cuid}&type=${randomRecRequest.param}`
    );
  };
}

export default RandomDemoService;
