import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import type { APIError, DsocDemoResponse } from "types/api";
import type { DsocDemo } from "types/dsoc";
import { RootState } from "store";

import { dsocDemoAsyncAction } from "store/modules/demo/dsoc/saga";

export const DSOCDEMO = "dsocdemo";

const initialState: DsocDemo = {
  dsocdemo: {
    loading: false,
    data: null,
    error: null,
  },
};

const dsocDemoSlice = createSlice({
  name: DSOCDEMO,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(`${dsocDemoAsyncAction.fetchDsocResult.request}`, (state) => {
        state.dsocdemo.loading = true;
      })
      .addCase(
        `${dsocDemoAsyncAction.fetchDsocResult.success}`,
        (state, action: PayloadAction<{ dsocResult: DsocDemoResponse }>) => {
          state.dsocdemo.loading = false;
          state.dsocdemo.data = action.payload.dsocResult;
        }
      )
      .addCase(
        `${dsocDemoAsyncAction.fetchDsocResult.failure}`,
        (state, action: PayloadAction<APIError>) => {
          state.dsocdemo.loading = false;
          state.dsocdemo.data = initialState.dsocdemo.data;
          state.dsocdemo.error = action.payload;
        }
      );
  },
});

const dsocDemoSelector = (state: RootState) => state[DSOCDEMO].dsocdemo;

export const DsocDemoSelector = {
  loading: createSelector(dsocDemoSelector, (dsocdemo) => dsocdemo.loading),
  data: createSelector(dsocDemoSelector, (dsocdemo) => dsocdemo.data),
  error: createSelector(dsocDemoSelector, (dsocdemo) => dsocdemo.error),
};

export const dsocDemoAction = dsocDemoSlice.actions;
export const dsocDemoReducer = dsocDemoSlice.reducer;
