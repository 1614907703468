import { createAsyncAction } from "typesafe-actions";
import type {
  UserRequest,
  UserResponse,
  ManageRequest,
  PWChangeRequest,
} from "types/api";
import { UserService, ManageService } from "apis/services";
import { AxiosResponse } from "axios";
import { put, takeLatest, takeEvery } from "@redux-saga/core/effects";
import type { APIError, SitesResponse } from "types/api";
import { callWrapperSaga } from "utils/callWrapperSaga";
import { fork, select } from "redux-saga/effects";
import { LoginStatusSelector } from ".";

export const SIGN_IN = `user/SIGN_IN`;
export const SIGN_IN_SUCCESS = `user/SIGN_IN_SUCCESS`;
export const SIGN_IN_ERROR = `user/SIGN_IN_ERROR`;

const signIn = createAsyncAction(SIGN_IN, SIGN_IN_SUCCESS, SIGN_IN_ERROR)<
  UserRequest,
  UserResponse,
  APIError
>();

function* signInSaga(action: ReturnType<typeof signIn.request>) {
  try {
    const { data }: AxiosResponse<UserResponse> = yield callWrapperSaga(
      UserService.signIn,
      action.payload
    );
    yield put(signIn.success(data));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(signIn.failure({ data, status }));
  }
}
export const PW_CHANGE = `user/PW_CHANGE`;
export const PW_CHANGE_SUCCESS = `user/PW_CHANGE_SUCCESS`;
export const PW_CHANGE_ERROR = `user/PW_CHANGE_ERROR`;

const pwChange = createAsyncAction(
  PW_CHANGE,
  PW_CHANGE_SUCCESS,
  PW_CHANGE_ERROR
)<
  PWChangeRequest & { onSuccess: () => void; onFailure: () => void },
  void,
  APIError
>();

function* pwChangeSaga(action: ReturnType<typeof pwChange.request>) {
  const { onSuccess, onFailure, newPassword } = action.payload;
  try {
    yield callWrapperSaga(UserService.pwChange, { newPassword });
    yield put(pwChange.success());
    yield fork(onSuccess);
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(pwChange.failure({ data, status }));
    yield fork(onFailure);
  }
}

export const LOGOUT = `user/LOGOUT`;
export const LOGOUT_SUCCESS = `user/LOGOUT_SUCCESS`;
export const LOGOUT_ERROR = `user/LOGOUT_ERROR`;

const logout = createAsyncAction(LOGOUT, LOGOUT_SUCCESS, LOGOUT_ERROR)<
  { onSuccess: () => void },
  void,
  void
>();

function* logoutSaga(action: ReturnType<typeof logout.request>) {
  const { onSuccess } = action.payload;
  try {
    yield callWrapperSaga(UserService.logout);
    yield put(logout.success());
    yield callWrapperSaga(onSuccess);
  } catch (e) {
    yield put(logout.failure());
  }
}

export const FETCH_ADMIN_CUIDS = `user/FETCH_ADMIN_CUIDS`;
export const FETCH_ADMIN_CUIDS_SUCCESS = `user/FETCH_ADMIN_CUIDS_SUCCESS`;
export const FETCH_ADMIN_CUIDS_ERROR = `user/FETCH_ADMIN_CUIDS_ERROR`;

const fetchAdminCUIDs = createAsyncAction(
  FETCH_ADMIN_CUIDS,
  FETCH_ADMIN_CUIDS_SUCCESS,
  FETCH_ADMIN_CUIDS_ERROR
)<ManageRequest, SitesResponse, APIError>();

function* fetchAdminCUIDsSaga(
  action: ReturnType<typeof fetchAdminCUIDs.request>
) {
  try {
    const { data }: AxiosResponse<SitesResponse> = yield callWrapperSaga(
      ManageService.fetchAllSites,
      action.payload
    );
    yield put(fetchAdminCUIDs.success(data));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchAdminCUIDs.failure({ data, status }));
  }
}

export const FETCH_CUSTOMER_CUIDS = `user/FETCH_CUSTOMER_CUIDS`;
export const FETCH_CUSTOMER_CUIDS_SUCCESS = `user/FETCH_CUSTOMER_CUIDS_SUCCESS`;
export const FETCH_CUSTOMER_CUIDS_ERROR = `user/FETCH_CUSTOMER_CUIDS_ERROR`;

const fetchCustomerCUIDs = createAsyncAction(
  FETCH_CUSTOMER_CUIDS,
  FETCH_CUSTOMER_CUIDS_SUCCESS,
  FETCH_CUSTOMER_CUIDS_ERROR
)<ManageRequest, SitesResponse, APIError>();

function* fetchCustomerCUIDsSaga(
  action: ReturnType<typeof fetchCustomerCUIDs.request>
) {
  try {
    const { data }: AxiosResponse<SitesResponse> = yield callWrapperSaga(
      ManageService.fetchSitesOfCustomer,
      action.payload
    );
    yield put(fetchCustomerCUIDs.success(data));
  } catch ({ response }) {
    const { data, status } = response as AxiosResponse;
    yield put(fetchCustomerCUIDs.failure({ data, status }));
  }
}

export const userAsyncAction = {
  signIn,
  pwChange,
  logout,
  fetchAdminCUIDs,
  fetchCustomerCUIDs,
};

export default function* userSaga() {
  yield takeLatest(SIGN_IN, signInSaga);
  yield takeLatest(PW_CHANGE, pwChangeSaga);
  yield takeEvery(LOGOUT, logoutSaga);
  yield takeLatest(FETCH_ADMIN_CUIDS, fetchAdminCUIDsSaga);
  yield takeLatest(FETCH_CUSTOMER_CUIDS, fetchCustomerCUIDsSaga);
}
