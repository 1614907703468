import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { useQuery } from "@apollo/client";

import { DesignerDataSelector, dsocAction } from "store/modules/dsoc";

import {
  GET_DESIGNER_SEG,
  GET_FILTERED_CONFIGURATION,
  GET_TRIGGER,
  GET_TRIGGER_TYPE,
} from "apollo/query";
import useUserData from "./useUserData";

import type { SegNode } from "apollo/type";
import { CubotExtraProps } from "../types/dsoc";

const useDesignerData = () => {
  const dispatch = useAppDispatch();
  const { currentCuid } = useUserData();

  const { loading: triggerTypeListDataLoading, data: triggerTypeListData } =
    useQuery(GET_TRIGGER_TYPE);

  const { loading: triggerListDataLoading, data: triggerListData } = useQuery(
    GET_TRIGGER,
    {
      variables: {
        filter: {
          columns: {
            cuid: {
              eq: currentCuid,
            },
          },
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const { loading: segLoading, data: segData } = useQuery(GET_DESIGNER_SEG);
  const [designerLoading, designerData, designerError] = [
    useAppSelector(DesignerDataSelector.loading),
    useAppSelector(DesignerDataSelector.data),
    useAppSelector(DesignerDataSelector.error),
  ];

  const {
    loading: configurationListLoading,
    data: configurationListData,
    refetch: configurationListRefetch,
  } = useQuery(GET_FILTERED_CONFIGURATION, {
    variables: {
      filter: {
        columns: {
          cuid: {
            eq: currentCuid,
          },
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const configurationList = useMemo(
    () =>
      configurationListData?.campaign?.edges
        ?.flatMap((row: any) => {
          const { node } = row;
          return node?.CampaignConfigurations;
        })
        .filter((x: any) => !!x) ?? [],
    [configurationListData?.campaign?.edges]
  );

  const setDesignerCubotActionTrigger = useCallback(
    ({ cubotActionTrigger }: { cubotActionTrigger: string }) => {
      dispatch(
        dsocAction.setDesignerCubotActionTrigger({ cubotActionTrigger })
      );
    },
    [dispatch]
  );

  const setDesignerCubotExtraProps = useCallback(
    (cubotExtraProps: CubotExtraProps) => {
      dispatch(dsocAction.setDesignerCubotExtraProps({ ...cubotExtraProps }));
    },
    [dispatch]
  );

  const setDesignerCubotTriggerId = useCallback(
    ({ triggerId }: { triggerId: any }) => {
      dispatch(dsocAction.setCubotTriggerId({ triggerId }));
    },
    [dispatch]
  );

  const setDesignerCubotSpeechMessage = useCallback(
    ({
      cubotSpeechMessage,
      deps,
    }: {
      cubotSpeechMessage: string;
      deps?: Array<String>;
    }) => {
      dispatch(
        dsocAction.setDesignerCubotSpeechMessage({ cubotSpeechMessage })
      );

      dispatch(dsocAction.setDesignerCubotMessageDeps(deps || null));
    },
    [dispatch]
  );

  const setDesignerCubotMessageDeps = useCallback(
    (deps) => {
      dispatch(dsocAction.setDesignerCubotMessageDeps(deps));
    },
    [dispatch]
  );

  const setDesignerCubotRecTitle = useCallback(
    ({ cubotRecTitle }: { cubotRecTitle: string }) => {
      dispatch(dsocAction.setDesignerCubotRecTitle({ cubotRecTitle }));
    },
    [dispatch]
  );

  const setDesignerCurrentPhase = useCallback(
    (currentPhase: "D2" | "D3" | "D4") => {
      dispatch(dsocAction.setDesignerCurrentPhase({ phase: currentPhase }));
    },
    [dispatch]
  );

  const setDesignerSeg = useCallback(
    ({ seg }: { seg: { id: string; code: string } }) => {
      dispatch(dsocAction.setDesignerSeg({ seg }));
    },
    [dispatch]
  );

  const setDesignerTraffic = useCallback(
    ({ traffic }: { traffic: string }) => {
      dispatch(dsocAction.setDesignerTraffic({ traffic }));
    },
    [dispatch]
  );

  const setDesignerPcidTraffic = useCallback(
    ({ pcidTraffic }: { pcidTraffic: string }) => {
      dispatch(dsocAction.setDesignerPcidTraffic({ pcidTraffic }));
    },
    [dispatch]
  );

  const setDesignerExtraTraffic = useCallback(
    ({
      type,
      value,
    }: {
      type: "viewRatio" | "orderRatio" | "revenueRatio" | "basketSize";
      value: number;
    }) => {
      dispatch(dsocAction.setDesignerExtraTraffic({ type, value }));
    },
    [dispatch]
  );

  const setDesignerSelector = useCallback(
    ({ selector }: { selector: { id: number | null; name: string } }) => {
      dispatch(dsocAction.setDesignerSelector({ selector }));
    },
    [dispatch]
  );

  const setDesignerOperator = useCallback(
    ({ operator }: { operator: { id: number | null; name: string } }) => {
      dispatch(dsocAction.setDesignerOperator({ operator }));
    },
    [dispatch]
  );

  const clearDesignerData = useCallback(() => {
    dispatch(dsocAction.clearDesignerData());
  }, [dispatch]);

  const clearCubotTrigger = useCallback(() => {
    dispatch(dsocAction.clearCubotTrigger());
  }, [dispatch]);

  const triggerTypeList = useMemo(
    () =>
      triggerTypeListData?.triggerType?.edges.map((row: any) => {
        const { node } = row;
        return node;
      }),
    [triggerTypeListData]
  );

  const triggerList = useMemo(
    () =>
      triggerListData?.trigger?.edges.map((row: any) => {
        const { node } = row;
        const { TriggerType, id, extraProps } = node;
        const { param } = extraProps;
        return { key: id, id, TriggerType, param };
      }),
    [triggerListData]
  );

  const segList = useMemo(
    () =>
      segData?.analyticalSegmentation?.edges
        .map((segNode: SegNode) => {
          const { node } = segNode;
          return node;
        })
        .sort((a: any, b: any) => {
          const { code: aCode } = a;
          const { code: bCode } = b;
          if (aCode === "all") return -1;
          if (bCode === "all") return 1;
          return 0;
        }),
    [segData?.analyticalSegmentation?.edges]
  );

  return {
    designerLoading,
    designerData,
    designerError,
    setDesignerCubotActionTrigger,
    setDesignerCubotExtraProps,
    setDesignerCubotSpeechMessage,
    // setDesignerCubotMessageDeps,
    setDesignerCubotRecTitle,
    setDesignerCurrentPhase,
    setDesignerSeg,
    setDesignerTraffic,
    setDesignerPcidTraffic,
    setDesignerSelector,
    setDesignerExtraTraffic,
    triggerTypeList,
    triggerTypeListDataLoading,
    configurationListLoading,
    configurationListData,
    configurationListRefetch,
    configurationList,
    setDesignerOperator,
    clearDesignerData,
    clearCubotTrigger,
    triggerListDataLoading,
    triggerList,
    setDesignerCubotTriggerId,
    segLoading,
    segList,
  };
};

export default useDesignerData;
